import request from '@/utils/request.js';

const prefix = 'https://eapi.parapack.net/';

export async function supplyAgent(params) {
    const url = prefix + 'agent/supply';

    return request.post({ url, params });
}

export async function getDemand(params) {
    const url = prefix + 'agent/demand';

    return request.get({ url, params });
}

export async function getInfo(params) {
    const url = prefix + 'agent/info';

    return request.get({ url, params });
}

export async function getStatus(params) {
    const url = prefix + 'agent/status';

    return request.get({ url, params });
}

export async function deleteAgent(params) {
    const url = prefix + 'agent/delete';

    return request.post({ url, params });
}

export async function getUsers(params) {
    const url = prefix + 'agent/users';

    return request.get({ url, params });
}

export async function getProfits(params) {
    const url = prefix + 'agent/profits';

    return request.get({ url, params });
}

export async function setShares(params) {
    const url = prefix + 'agent/set-shares';

    return request.post({ url, params });
}