<template>
  <div class="container" :class="{dark: bodyStyle === 'dark'}">
    <div class="tabs">
      <div class="tab-item" :class="{active: tabActive===0}" @click="tabActive=0">{{$t('events.hotEvents')}}</div>
      <div class="tab-item" :class="{active: tabActive===1}" @click="tabActive=1">{{$t('events.myEvents')}}</div>
    </div>
    <div class="event-lists" v-if="tabActive===0">
      <div class="event-item" v-for="(item, idx) in lists" :key="idx">
        <div class="cover-con">
          <a :href="'https://business.parapack.net/#/event-info?id=' + item.id" target="_blank">
            <el-image class="cover" :src="item.cover"></el-image>
          </a>
        </div>
        <div class="brief flex-1">
          <div class="title">
            <a :href="'https://business.parapack.net/#/event-info?id=' + item.id" target="_blank">
              {{item.title}}
            </a>
          </div>
          <div class="flex mgt-5">
            <div class="flex-1" v-if="item.rewards">
              <div class="sub-title flex">
                <img src="../assets/imgs/pc/btc.png" class="icon"/>
                {{item.rewards.token_amount}} {{item.rewards.token_name}}
              </div>
              <div class="bottom-btns mgt-5">
                <div class="btn text-blue">{{rewardTypes[item.rewards.reward_type]}}</div>
              </div>
            </div>
            <div class="join-btn">{{$t('events.join')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-con" v-if="tabActive===0">
      <div class="text-btn">{{$t('events.moreEvents')}}</div>
    </div>

    <div v-if="tabActive===1">
      <div v-if="!userInfo">
        <div class="login-con">
          <img src="../assets/imgs/login-icon.png" class="login-icon"/>
          <div class="login-text">{{$t('common.notLogin')}}</div>
          <a :href="twitterLoginUrl" target="_blank">
            <div class="login-btn">{{$t('common.login')}}</div>
          </a>
        </div>
      </div>

      <div v-else-if="userInfo">
        <div class="my-tab" @click="myTab = myTab===0 ? -1 : 0">
          <label>{{$t('events.myCreated')}} ({{myLists.length}})</label>
          <i class="el-icon-arrow-down" v-if="myTab===0"></i>
          <i class="el-icon-arrow-right" v-else></i>
        </div>
        <div class="event-lists" style="padding-bottom: 0;" v-if="myTab===0">
          <div class="event-item" v-for="(item, idx) in myLists" :key="idx" v-if="myLists.length > 0">
            <div class="cover-con">
              <a :href="'https://business.parapack.net/#/event-info?id=' + item.id" target="_blank">
                <el-image class="cover" :src="item.cover[item.cover.def]"></el-image>
              </a>
            </div>
            <div class="brief flex-1">
              <div class="title">
                <a :href="'https://business.parapack.net/#/event-info?id=' + item.id" target="_blank">
                  {{item.title[item.title.def]}}
                </a>
              </div>
              <div class="flex mgt-5">
                <div class="flex-1" v-if="item.rewards">
                  <div class="sub-title flex">
                    <img src="../assets/imgs/pc/btc.png" class="icon"/>
                    {{item.rewards.token_amount}} {{item.rewards.token_name}}
                  </div>
                  <div class="bottom-btns mgt-5">
                    <div class="btn text-blue">{{rewardTypes[item.rewards.reward_type]}}</div>
                  </div>
                </div>
                <div class="join-btn">{{item.status ? $t('events.isRunning') : $t('events.isChecking')}}</div>
              </div>
            </div>
          </div>
          <div class="no-data" v-else>{{$t('common.noData')}}</div>
        </div>

        <div class="my-tab" @click="myTab = myTab===1 ? -1 : 1">
          <label>{{$t('events.myJoined')}} (0)</label>
          <i class="el-icon-arrow-down" v-if="myTab===1"></i>
          <i class="el-icon-arrow-right" v-else></i>
        </div>
        <div v-if="myTab===1">
          <div class="no-data">{{$t('common.noData')}}</div>
        </div>

        <div class="my-tab" @click="myTab = myTab===2 ? -1 : 2">
          <label>{{$t('events.myLiked')}} (0)</label>
          <i class="el-icon-arrow-down" v-if="myTab===2"></i>
          <i class="el-icon-arrow-right" v-else></i>
        </div>
        <div class="event-lists" style="padding-bottom: 0;" v-if="myTab===2">
          <div class="event-item" v-for="(item, idx) in myLikeLists" :key="idx">
            <div class="cover-con">
              <a :href="'https://business.parapack.net/#/event-info?id=' + item.id" target="_blank">
                <el-image class="cover" :src="item.cover"></el-image>
              </a>
            </div>
            <div class="brief flex-1">
              <div class="title">
                <a :href="'https://business.parapack.net/#/event-info?id=' + item.id" target="_blank">{{item.title}}</a>
              </div>
              <div class="flex mgt-5">
                <div class="flex-1" v-if="item.rewards">
                  <div class="sub-title flex">
                    <img src="../assets/imgs/pc/btc.png" class="icon"/>
                    {{item.rewards.token_amount}} {{item.rewards.token_name}}
                  </div>
                  <div class="bottom-btns mgt-5">
                    <div class="btn text-blue">{{rewardTypes[item.rewards.reward_type]}}</div>
                  </div>
                </div>
                <div class="join-btn">{{item.status ? '进行中' : '待审核'}}</div>
              </div>
            </div>
          </div>
          <div class="no-data" v-if="myLikeLists.length === 0">{{$t('common.noData')}}</div>
        </div>

        <div style="height: 26px;"></div>
      </div>
      <div class="footer-con" v-if="tabActive===1 && userInfo">
        <a href="https://business.parapack.net/#/publish" target="_blank" class="text-btn">{{$t('events.toCreate')}}</a>
      </div>
    </div>

  </div>
</template>

<script>
import * as EventsApi from "@/api/events";
import * as TwitterApi from "@/api/twitter";
import {getLikeLists} from "@/api/events";

export default {
  data(){
    return {
      bodyStyle: null,
      tabActive: 0,
      myTab: 0,
      rewardTypes: {
        1: '抽奖',
        2: 'FCFS',
        3: '总量瓜分'
      },
      lists: [],
      myLists: [],
      myLikeLists: [],
      myLikeTotal: 0,
      userInfo: null,
      twitterLoginUrl: null
    }
  },
  mounted() {
    const { style } = this.$route.query;
    this.bodyStyle = style;
    document.getElementsByTagName('html')[0].className = this.bodyStyle;

    const self = this;
    window.addEventListener('message', (event) => {
      if(event.origin !== 'https://x.com'){
        return;
      }
      if (event.data.type === 'USER_IS_LOGIN') {
        const msg = event.data;
        self.userInfo = msg.data;
        localStorage.setItem('USERINFO', JSON.stringify(self.userInfo));
        this.loadMyLists();
        this.loadLikeLists();
      }
    });

    this.initData();
    this.loadLists();
  },
  methods: {
    async initData(){
      // 加载userInfo
      let userInfo = localStorage.getItem('USERINFO');
      userInfo = userInfo ? JSON.parse(userInfo) : null;
      const now = parseInt(new Date().getTime()/1000);
      if(userInfo && now - userInfo.expire_time <= 0){
        this.userInfo = userInfo;
        window.postMessage({ action: 'loginSuccess', target: 'Events', options: { userInfo } }, window.location.origin);
        this.loadMyLists();
        this.loadLikeLists();
      } else {
        const loginRes = await TwitterApi.getLogin();
        if(loginRes && loginRes.data){
          this.twitterLoginUrl = loginRes.data.loginUrl;
        }
      }
    },
    async loadLists(){
      const res = await EventsApi.getLists();
      if(res && res.data){
        this.lists = res.data.lists;
      }
    },
    async loadMyLists(){
      if(!this.userInfo.id){
        this.myLists = [];
        return;
      }
      const res = await EventsApi.manageLists();
      if(res && res.data){
        this.myLists = res.data.lists || [];
      }
    },
    async loadLikeLists() {
      if(!this.userInfo.id){
        this.myLikeLists = [];
        this.myLikeTotal = 0;
        return;
      }
      const res = await EventsApi.getLikeLists();
      if(res && res.data){
        this.myLikeLists = res.data.lists || [];
        this.myLikeTotal = res.data.total;
      }
    }
  }
}
</script>

<style>
@media only screen and (max-width: 750px) {
  html, body {
    padding: 0 !important;
    margin: 0 !important;
  }
}
</style>
<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

@media only screen and (max-width: 750px) {
  body {
    padding-bottom: 0 !important;
  }
}

.tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-left: 10px;
  top: 0;
  position: sticky;
  z-index: 1;
  background-color: #fff;

  .tab-item {
    color: @sub-font-color;
    font-size: 12px;
    margin-right: 10px;
    padding: 10px 0;
    cursor: pointer;

    &.active {
      font-size: 13px;
      color: @font-color;
      font-weight: bold;
      position: relative;
      &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        height: 3px;
        background-color: @light-color;
        transform: skew(-30deg, 0);
      }
    }
  }
}
.container {
  max-height: 300px;
  overflow-y: scroll;
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
  .el-tabs__content{
    max-height: calc(100% - 48px);
  }

  &.dark {
    background-color: #000000;

    .my-tab {
      background-color: #000000;
      border-bottom: 1px solid #3e3e3e;
      color: #fff;
    }
    .tabs {
      background-color: #000000;
      border-bottom: 1px solid #3e3e3e;
      .tab-item {
        &.active {
          color: #fff;
        }
      }
    }

    .event-lists {
      .event-item {
        border-bottom: 1px solid #3e3e3e;
        .title {
          color: #fff;
          a {
            color: #fff;
          }
        }
        .brief {
          color: #eee;
        }
      }
    }

    .login-con {
      .login-text {
        color: #eee;
      }
    }

    .footer-con {
      background-color: #000;
    }
  }
}
.my-tab {
  font-size: 13px;
  padding: 8px 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  label {
    flex: 1;
  }
}
.event-lists {
  width: 100%;
  padding-bottom: 26px;
  .event-item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    font-size: 12px;
    padding: 12px 10px;
    border-bottom: 1px solid #eee;
    height: 80px;

    .cover-con {
      margin-right: 10px;
      height: 60px;
      width: 60px;
      background-color: #ccc;
      border-radius: 5px;
      .cover {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
    .brief {
      width: calc(100% - 100px);
    }
    .title {
      font-weight: bold;
      color: @font-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      a {
        text-decoration: none;
        color: @font-color;
      }
    }
    .sub-title {
      margin: 3px 0 3px 0;
      font-size: 12px;

      .icon {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
    a {
      text-decoration: none;
    }
    .bottom-btns {
      display: flex;
      font-size: 12px;
      .btn {
        margin-right: 20px;
      }
    }
    .join-btn {
      background-color: @light-color;
      padding: 3px 8px;
      color: #fff;
      border-radius: 5px;
      margin-top: 5px;
    }
  }
}
.login-con {
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login-icon {
    width: 80px;
    height: 80px;
  }
  .login-text {
    margin-top: 10px;
    font-size: 14px;
  }
  .login-btn {
    background-color: @light-color;
    color: #fff;
    padding: 8px 18px;
    border-radius: 5px;
    margin-top: 15px;
  }
}
.footer-con{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  .text-btn {
    color: @light-color;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
  }
}
.no-data {
  text-align: center;
  font-size: 12px;
  color: #aaa;
  padding: 50px 0;
}
</style>
