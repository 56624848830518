
/**
 * 获取日期
 */
export function getDate(timestamp) {
    const len = timestamp ? timestamp.toString().length : 0;
    let ms = 0;
    if(len == 13){
        ms = timestamp%1000;
    }
    const date = timestamp ? new Date(len == 13 ? timestamp : timestamp * 1000) : new Date();//当前时间
    const month = zeroFill(date.getMonth() + 1);//月
    const day = zeroFill(date.getDate());//日
    const hour = zeroFill(date.getHours());//时
    const minute = zeroFill(date.getMinutes());//分
    const second = zeroFill(date.getSeconds());//秒

    //当前时间
    const curTime = date.getFullYear() + "-" + month + "-" + day
        + " " + hour + ":" + minute + ":" + second + (len == 13 ? '.' + padWithZero(ms, 3) : '');

    return curTime;
}
function zeroFill(i){
    if (i >= 0 && i <= 9) {
        return "0" + i;
    } else {
        return i;
    }
}

/**
 * 数字前面补0
 * @param number
 * @param length
 * @returns {string}
 */
export function padWithZero(number, length) {
    // 使用String()将数字转换为字符串
    let strNumber = String(number);

    // 如果字符串长度不足3位，则在前面补零
    while (strNumber.length < length) {
        strNumber = '0' + strNumber;
    }

    return strNumber;
}

/**
 * 休眠 ms
 */
export async function sleep(ms) {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
}

/*
 * 随机数，前后全包含
 */
export function random (min, max) {
    return Math.round(Math.random() * (max - min)) + min;
}

export function isNumeric(value) {
    // 使用正则表达式检查是否只包含数字字符
    return /^\d+$/.test(value);
}

export function isJSON(str) {
    if (typeof str == 'string') {
        try {
            JSON.parse(str);
            return true;
        } catch(e) {
            return false;
        }
    }
    return false;
}

/**
 * 对object排序
 * @param obj
 * @returns {{[p: string]: unknown}}
 */
export function sortObject(obj, key){
    // 将对象转换为数组，每个元素是一个包含键和值的数组
    const arr = Object.entries(obj);
    // 对数组进行排序，根据值从大到小排序
    arr.sort(
        (a, b) => {
            if(key){
                return b[1][key] - a[1][key]
            } else {
                return b[1] - a[1]
            }
        }
    );
    const sortedArr = arr.map(r => Object.assign({}, r[1], { key: r[0] }))

    return sortedArr;
}

export function timeToLocal(originalTime) {
    const d = new Date(originalTime * 1000);
    return (
        Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds(),
            d.getMilliseconds()
        ) / 1000
    );
}

/**
 * 计算中英文混合字符串长度
 * @param val
 * @returns {number}
 */
export function getStringLen(val) {
    let len = 0;
    for (let i = 0; i < val.length; i++) {
        let length = val.charCodeAt(i);
        if( length >= 0 && length <= 128 ) {
            len += 1;
        } else {
            len += 2;
        }
    }
    return len;
}


/**
 * 过滤字符串
 * @param str
 * @returns {*}
 */
export function filterStr(str){
    str = str.replace(/\\/g, "") // 确保反斜杠被正确转义
        // .replace(/'/g, "\\'") // 转义单引号
        .replace(/,\s*}/g, '}') // 移除对象最后一个键值对后的多余逗号
        .replace(/,\s*]/g, ']') // 移除数组最后一个元素后的多余逗号
        .replace(/\\'/g, "\\'") // 确保单引号被正确转义
        .replace(/\\"/g, '\\"') // 确保双引号被正确转义
        .replace(/\\&/g, "\\&")
        .replace(/\\r/g, "")
        .replace(/\\t/g, "")
        .replace(/\\b/g, "")
        .replace(/\\f/g, "")
        .replace(/[\u0000-\u0019]+/g, ""); // 移除其他控制字符
    str = str.replace(/<script.*?>([\s\S]*?)<\/script>/gi, '$1');

    return str;
}
export function escapeJsonStr(str){
    str = str.replace(/\\/g, "") // 确保反斜杠被正确转义
        .replace(/'/g, "\\'") // 转义单引号
        .replace(/,\s*}/g, '}') // 移除对象最后一个键值对后的多余逗号
        .replace(/,\s*]/g, ']') // 移除数组最后一个元素后的多余逗号
        .replace(/\r\n/g, "\n") // 处理 Windows 风格换行符
        .replace(/\n/g, "\n") // 处理 Linux 风格换行符
        .replace(/\\n/g, "\n") // 确保换行符被正确转义
        .replace(/\\'/g, "\'") // 确保单引号被正确转义
        .replace(/\\"/g, '\"') // 确保双引号被正确转义
        .replace(/\\&/g, "\&")
        .replace(/\\r/g, "")
        .replace(/\\t/g, "")
        .replace(/\\b/g, "")
        .replace(/\\f/g, "")
        .replace(/[\u0000-\u0019]+/g, ""); // 移除其他控制字符

    return str;
}

export function toJson(jsonString) {
    try {
        // 尝试直接解析 JSON 字符串
        const json = JSON.parse(jsonString);

        return json;
    } catch (error) {
        // 修复常见问题：替换单引号、移除多余逗号、转义特殊字符等
        let fixedString = escapeJsonStr(jsonString);

        // 尝试再次解析修正后的字符串
        try {
            return JSON.parse(fixedString);
        } catch (fixedError) {
            throw new Error("Failed to parse JSON after attempting to fix it.");
        }
    }
}

export function friendNum(v){
    if(v > 1000000){
        return parseFloat(v/1000000).toFixed(2) + 'M'
    }
    return v;
}