<template>
  <div class="container">
    <div class="top-bg">
      <pc-header></pc-header>

      <section class="content-center">
        <div class="big-title mgt-100">
          {{$t('web.index.web3Title')}}<br/>{{$t('web.index.web3SubTitle')}}
        </div>
        <div class="brief mgt-30" v-html="$t('web.index.web3Brief')"></div>
        <router-link to="/download"><div class="btn-big mgt-50">{{$t('web.index.find')}}</div></router-link>
      </section>

      <section class="stat-section">
        <div class="number-row">
          <div class="number">
            <p><img src="../assets/imgs/index/icon-s-1.png" class="icon"/>{{$t('web.index.userCount')}}</p>
            <h2>{{consoleData.homePage.userCount || 0}}</h2>
          </div>
          <div class="flex-1"></div>
          <div class="number">
            <p><img src="../assets/imgs/index/icon-s-2.png" class="icon"/>{{$t('web.index.dailyActiveCount')}}</p>
            <h2>{{consoleData.homePage.dailyActiveCount || 0}}</h2>
          </div>
          <div class="flex-1"></div>
          <div class="number">
            <p><img src="../assets/imgs/index/icon-s-3.png" class="icon"/>{{$t('web.index.merchants')}}</p>
            <h2>{{consoleData.homePage.merchants || 0}}</h2>
          </div>
        </div>
      </section>
    </div>

    <section class="content-center con-w">
      <div class="main-title mgt-100">
        {{$t('web.index.web3Title2')}}
      </div>
      <div class="brief mgt-16" v-html="$t('web.index.web3SubTitle2')"></div>
      <div class="poster-pic">
        <el-image src="../assets/imgs/index/1.png" lazy class="poster mgt-30"></el-image>
      </div>
    </section>

    <section class="content-center con-w">
      <div class="main-title mgt-100">
        {{$t('web.index.web3Title3')}}
      </div>
      <div class="brief mgt-16" v-html="$t('web.index.web3SubTitle3')"></div>
      <div class="grid-con mgt-50">
        <div class="grid-item">
          <div class="icon-img">
            <img src="../assets/imgs/index/5.png"/>
          </div>
          <div class="text">{{$t('web.index.walletTag1')}}</div>
        </div>
        <div class="flex-1"></div>
        <div class="grid-item">
          <div class="icon-img">
            <img src="../assets/imgs/index/6.png"/>
          </div>
          <div class="text">{{$t('web.index.walletTag2')}}</div>
        </div>
        <div class="flex-1"></div>
        <div class="grid-item">
          <div class="icon-img">
            <img src="../assets/imgs/index/7.png"/>
          </div>
          <div class="text">{{$t('web.index.walletTag3')}}</div>
        </div>
      </div>
      <div class="poster-pic" style="margin-top: 50px;">
        <el-image src="../assets/imgs/index/2.png" lazy class="poster"></el-image>
      </div>
    </section>

    <section class="content-center con-w">
      <div class="main-title mgt-100">
        {{$t('web.index.web3Title4')}}
      </div>
      <div class="brief mgt-16" v-html="$t('web.index.web3SubTitle4')"></div>
      <div class="grid-con mgt-50">
        <div class="grid-item">
          <div class="icon-img">
            <img src="../assets/imgs/index/icon-22.png"/>
          </div>
          <div class="text">{{$t('web.index.taskTag1')}}</div>
        </div>
        <div class="flex-1"></div>
        <div class="grid-item">
          <div class="icon-img">
            <img src="../assets/imgs/index/23.png"/>
          </div>
          <div class="text">{{$t('web.index.taskTag2')}}</div>
        </div>
        <div class="flex-1"></div>
        <div class="grid-item">
          <div class="icon-img">
            <img src="../assets/imgs/index/24.png"/>
          </div>
          <div class="text">{{$t('web.index.taskTag3')}}</div>
        </div>
      </div>
      <div class="poster-pic">
        <el-image src="../assets/imgs/index/3.png" lazy class="poster"></el-image>
      </div>
    </section>

    <div class="section-3">
      <section class="content-center con-w">
        <div class="main-title mgt-100">
          {{$t('web.index.web3Title5')}}
        </div>
        <div class="brief mgt-16" v-html="$t('web.index.web3SubTitle5')"></div>
        <div class="grid-con mgt-50">
          <div class="grid-item">
            <div class="icon-img">
              <img src="../assets/imgs/index/27.png"/>
            </div>
            <div class="text">{{$t('web.index.videoTag1')}}</div>
          </div>
          <div class="flex-1"></div>
          <div class="grid-item">
            <div class="icon-img">
              <img src="../assets/imgs/index/28.png"/>
            </div>
            <div class="text">{{$t('web.index.videoTag2')}}</div>
          </div>
          <div class="flex-1"></div>
          <div class="grid-item">
            <div class="icon-img">
              <img src="../assets/imgs/index/29.png"/>
            </div>
            <div class="text">{{$t('web.index.videoTag3')}}</div>
          </div>
        </div>
        <div class="pic-section-3">
          <el-image src="../assets/imgs/index/pic-index-1.png" lazy class="poster w-auto"></el-image>
        </div>
      </section>
    </div>

    <div class="bg-4">
      <section class="content-center con-w">
        <div class="main-title mgt-100">
          {{$t('web.index.web3Title7')}}
        </div>
        <div class="poster-pic" style="margin-top: 100px;">
          <el-image src="../assets/imgs/index/4.png" lazy class="poster"/>
        </div>
      </section>
    </div>

    <section class="content-center con-w">
      <div class="main-title mgt-100">
        {{$t('web.index.web3Title6')}}
      </div>
      <div class="brief mgt-16" v-html="$t('web.index.web3SubTitle6')"></div>
      <div class="links mgt-30">
        <div class="link-item" v-for="(r,i) in consoleData.partner.slice(0, 4)" :key="i">
          <div class="icon">
            <img :src="r.logo" />
          </div>
          <div class="name">{{r.name}}</div>
        </div>
      </div>
      <div class="links">
        <div class="link-item" v-for="(r,i) in consoleData.partner.slice(4, 9)" :key="i">
          <div class="icon">
            <img :src="r.logo" />
          </div>
          <div class="name">{{r.name}}</div>
        </div>
      </div>
      <div class="links">
        <div class="link-item" v-for="(r,i) in consoleData.partner.slice(-4)" :key="i">
          <div class="icon">
            <img :src="r.logo" />
          </div>
          <div class="name">{{r.name}}</div>
        </div>
      </div>
      <div class="mgt-30"></div>
    </section>

    <section class="content-center row slider-section">
      <div class="left-arrow">
        <img src="../assets/imgs/index/icon-slide-left.png"/>
      </div>
      <div class="slider-con con-w">
        <div class="slider-item" v-for="(item, idx) in consoleData.banner" :key="idx">
<!--          <el-image :src="item.logo" class="banner-poster" lazy></el-image>-->
          <div class="main-title">{{item.title}}</div>
          <div class="sub-title">{{item.content}}</div>
        </div>
      </div>
      <div class="right-arrow">
        <img src="../assets/imgs/index/icon-slide-right.png"/>
      </div>
    </section>

    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "@/components/Header";
import PcFooter from "@/components/Footer";

import * as WebApi from "@/api/web";
import * as EventsApi from "@/api/events";

export default {
  components: {
    PcHeader,
    PcFooter
  },
  data(){
    return {
      width: window.innerWidth,
      consoleData: {
        homePage: {},
        banner: [],
        partner: []
      },
      support: {
        chains: []
      }
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData(){
      // support
      EventsApi.getSupport().then(res => {
        if(res && res.data){
          this.support = res.data;
        }
      });
      // stat data
      const res = await WebApi.getConsoleData();
      const targetRes = JSON.parse(JSON.stringify(res.data));
      for(let i = 1; i <= 10; i++){
        for(let k in res.data){
          if(['activity', 'homePage', 'wallet'].indexOf(k) === -1) continue;
          for(let kk in res.data[k]){
            const v = parseFloat(targetRes[k][kk]);
            if(!/^\d+(\.\d+)?$/.test(v)) continue;
            res.data[k][kk] = parseInt(v * (i/10));
          }
        }

        this.$set(this, 'consoleData', res.data);
        await this.$func.sleep(100);
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";
@import "../assets/css/pc.less";

a {
  text-decoration: none;
}
.container {
  .top-bg {
    background-image: url("../assets/imgs/index/top-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bg-3 {
    background-image: url("../assets/imgs/index/pic-ai-3.png");
    background-repeat: no-repeat;
    background-size: auto 700px;
    background-position: bottom right;
  }
  .bg-4 {
    background-image: linear-gradient(to right, #ffffff, #F4FCFF);
  }
}

.btn-big {
  height: 50px;
  background-color: @light-color;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  border-radius: 25px;
}

.links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .link-item {
    background-color: #eee;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin: 10px 10px;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
    .icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #fff;
      overflow: hidden;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
      }
      .name {
        font-size: 16px;
      }
    }
  }
}

.slider-section {
  background-color: #eee;
  height: 300px;
  margin-top: 50px;
  background-image: url("../assets/imgs/index/slide-1.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left top;
  position: relative;

  &::before {
    display: block;
    content: ' ';
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("../assets/imgs/index/slide-2.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right top;
  }
  .left-arrow, .right-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    img {
      height: 30px;
    }
  }
  .left-arrow {
    margin-left: -600px;
  }
  .right-arrow {
    margin-left: 600px;
  }
}
.slider-con {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  .slider-item {
    max-width: 60%;
    .banner-poster {
      width: 90%;
    }
    .sub-title {
      margin-top: 10px;
    }
  }
}

@media (max-width: 1000px) {
  .links{
    .link-item {
      height: 36px;
      border-radius: 18px;
      padding: 0 12px;
      margin: 6px 6px;
      .icon {
        width: 16px;
        height: 16px;
        img {
          width: 14px;
          height: 14px;
          border-radius: 50%;
        }
        .name {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
