<template>
  <div class="preview-con scroll-con" v-if="form && form.title">
    <div class="top-header">
      <div class="user-brief flex">
        <div class="avatar">
          <img src="../../assets/imgs/pc/2.png"/>
        </div>
        <div class="fs-14 flex-1">UID {{form.uid}}</div>
        <img src="../../assets/imgs/pc/publish/7.png" class="icon" />
        <img src="../../assets/imgs/pc/publish/8.png" class="icon" />
      </div>
      <div class="title bold fs-14">{{form.titleLang ? form.title[form.titleLang] : form.title}}</div>
      <div class="time flex">
        <div class="tag">{{$t('preview')}}</div>
        <div>(UTC+8){{showDate(form.timeRange)}}</div>
      </div>
    </div>
    <div class="section-title">
      {{$t('task.eventReward')}}
    </div>
    <div class="data-section">
      <div class="data-row" v-if="form.rewards && form.rewards.token_amount">
        <label>{{$t('task.allRewards')}}</label>
        <div class="value">
          <div class="v">
            <img :src="chains[form.rewards.token_chain]?.logo" class="icon" />
            {{form.rewards.token_amount}} {{form.rewards.token_name}}
          </div>
          <div class="mark">Token</div>
        </div>
      </div>
      <div class="data-row" v-if="form.rewards && form.rewards.token_amount">
        <label>{{$t('task.rewardMode')}}</label>
        <div class="value">
          <div class="v text-blue">{{rewardTypes[form.rewards.reward_type]}}</div>
        </div>
      </div>
      <div class="data-row" v-if="form.rewards && form.rewards.reward_users_count">
        <label>{{$t('task.issuerReward')}}</label>
        <div class="value">
          <div class="v">
            {{form.rewards.token_amount/form.rewards.reward_users_count}} {{form.rewards.token_name}}/{{$t('common.per')}}
          </div>
          <div class="mark">{{$t('common.total')}} {{form.rewards.reward_users_count}} {{$t('common.per')}}</div>
        </div>
      </div>
      <div class="data-row" v-if="form.cover && form.cover !== 'null'">
        <img :src="form.coverLang ? form.cover[form.coverLang] : form.cover" class="cover"/>
      </div>
    </div>

    <div class="section-title flex">
      <div class="tab-item" :class="{active:showContentTab===0}" @click="showContentTab=0">{{$t('common.tasks')}}</div>
      <div class="tab-item" :class="{active:showContentTab===1}" @click="showContentTab=1">{{$t('common.brief')}}</div>
    </div>

    <div class="data-section" v-if="showContentTab===1">
      <div class="data-row" v-html="form.contentLang ? form.content[form.contentLang] : form.content">
      </div>
    </div>
    <div class="data-section" v-if="showContentTab===0">
      <div class="data-title" v-if="false">
        <img src="../../assets/imgs/pc/2.png" class="icon"/>
        {{$t('task.xTask')}}
      </div>
      <div class="data-row" v-if="form.tasks && form.tasks.twitter_reg_long">
        {{$t('task.regLongPre')}} <span class="text-blue">{{form.tasks.twitter_reg_long}}</span> {{$t('task.perMonth')}}
      </div>
      <div class="data-row" v-for="(row, i) in form.tasks.like_twitter" v-if="form && form.tasks && form.tasks.like_twitter">
        {{$t('task.followX')}} <a :href="'https://x.com/' + row" target="_blank" class="text-blue">{{row}}</a>
      </div>

      <div v-for="(row, i) in form.tasks.twitter_active_account">
        <div class="data-row" v-if="row.retweet3">
          {{$t('task.retweet')}} <a :href="'https://x.com/' + row.name" target="_blank" class="text-blue">{{row.name}}</a> {{$t('task.retweetApfix')}}
        </div>
        <div class="data-row" v-if="row.like">
          {{$t('task.like')}} <a :href="'https://x.com/' + row.name" target="_blank" class="text-blue">{{row.name}}</a> {{$t('task.lastTweet')}}
        </div>
      </div>

      <div v-for="(row, i) in form.tasks.tweet_active_id">
        <div class="data-row" v-if="row.retweet3">
          {{$t('task.retweet')}} <a :href="row.tweet_url" target="_blank" class="text-blue">
          {{row.tweet_id.split('/').pop()}}
        </a> {{$t('task.retweetApfix')}}
        </div>
        <div class="data-row" v-if="row.like">
          {{$t('task.like')}} <a :href="row.tweet_url" target="_blank" class="text-blue">
          {{row.tweet_id.split('/').pop()}}
        </a> {{$t('task.tweet')}}
        </div>
      </div>
      <div v-if="form.tasks && form.tasks.auto_tweet">
        <div class="data-row" v-if="form.tasks.tweet_active_retweet3">
          {{$t('task.retweet')}} <a href="#" class="text-blue">{{$t('task.autoTweet')}}</a> {{$t('task.retweetfix')}}
        </div>
        <div class="data-row" v-if="form.tasks.tweet_active_like">
          {{$t('task.like')}} <a href="#" class="text-blue">{{$t('task.autoTweet')}}</a> {{$t('task.tweet')}}
        </div>
      </div>

      <div v-if="form.tasks.has_token && form.tasks.has_token.length > 0">
        <div class="data-title">
          <img src="../../assets/imgs/pc/publish/15.png" class="icon"/>
          {{$t('task.balanceNeed')}}
        </div>
        <div class="data-row" v-for="(row, i) in form.tasks.has_token">
          {{$t('task.has')}} {{row.chain}} {{$t('task.hasToken')}} <span class="text-blue">[{{row.name}}]</span> {{$t('task.noLessThan')}}{{row.amount}}
        </div>
      </div>

      <div v-if="form.tasks.has_trade && form.tasks.has_trade.length > 0">
        <div class="data-title">
          <img src="../../assets/imgs/pc/publish/15.png" class="icon"/>
          {{$t('task.tradeNeed')}}
        </div>
        <div class="data-row" v-for="(row, i) in form.tasks.has_trade">
          {{$t('task.trade')}} {{row.chain}} {{$t('task.hasToken')}} <span class="text-blue">[{{row.name}}]</span> {{$t('task.noLessThan')}}{{row.amount}}
        </div>
      </div>

      <div v-if="form.tasks.other">
        <div class="data-title">
          <img src="../../assets/imgs/pc/publish/17.png" class="icon"/>
          {{$t('task.other')}}
        </div>
        <div class="data-row">
          <div class="text-info">{{form.tasks.other[lang] || ''}}</div>
        </div>
      </div>

      <div class="data-title">
        <img src="../../assets/imgs/pc/publish/9.png" class="icon"/>
        {{$t('task.receiveAddress')}}
      </div>
      <div class="data-row">
        {{$t('task.pleaseUse')}} {{form.rewards.token_chain}} {{$t('task.pleaseUseApfix')}}
      </div>
      <div class="data-row">
        <el-input disabled size="small"></el-input>
      </div>
    </div>
  </div>
  <div v-else>
    <i class="el-icon-loading"></i>
    {{$t('common.loading')}}
  </div>
</template>

<script>
const currentLanguage = localStorage.getItem('CURRENT_LANGUAGE');

export default {
  props: ['support'],
  data(){
    return {
      lang: currentLanguage ? currentLanguage : 'en_US',
      chains: {},
      coins: {},
      showContentTab: 0,
      rewardTypes: {},
      form: {
        tasks: {}
      }
    }
  },
  mounted() {
    this.rewardTypes = {
      1: this.$t('events.getReward'),
      2: this.$t('events.fcfs'),
      3: this.$t('events.divideAmount')
    };

    this.$eventBus.$on('UPDATE_PREVIEW', data => {
      if(data && !data.timeRange){
        data.timeRange = [
          this.$func.getDate(data.start_time),
          this.$func.getDate(data.end_time)
        ];
      }
      data && this.$set(this, 'form', data);
    });

    this.support.coins.map(row => {
      this.coins[row.coinName] = row;
    });
    this.support.chains.map(row => {
      this.chains[row.chainName] = row;
    });
  },
  methods: {
    showDate(timeRange){
      if(!timeRange){
        return this.$t('common.noSetting');
      }
      const startDate = this.$func.getDate(new Date(timeRange[0]).getTime()/1000);
      const endDate = this.$func.getDate(new Date(timeRange[1]).getTime()/1000);
      return startDate + ' - ' + endDate.split(' ')[1];
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.preview-con {
  width: 325px;
  height: 650px;
}
.top-header {
  padding: 8px 10px;
  .title {
    padding: 10px 0;
  }
  .time {
    font-size: 12px;
    .tag {
      background-color: @light-color;
      color: #fff;
      padding: 3px 8px;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
}
.user-brief {
  .avatar {
    margin-right: 5px;
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}
.section-title {
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  background-color: #F3F7FC;
  .tab-item {
    margin-right: 20px;
    cursor: pointer;
    &.active {
      color: @light-color;
    }
  }
}
.data-section {
  .data-title {
    font-weight: bold;
    font-size: 15px;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    .icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .data-row {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    font-size: 13px;
    label {
      font-size: 13px;
      flex: 1;
    }
    a {
      text-decoration: none;
    }
    .value {
      font-size: 13px;
      text-align: right;
      .v {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .mark {
        font-size: 12px;
        color: @sub-font-color;
        margin-top: 5px;
      }
    }
    .cover {
      border-radius: 5px;
      width: 100%;
      height: 189px;
    }
  }
}
</style>