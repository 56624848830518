var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container service-platform"},[_vm._m(0),_c('div',{staticClass:"section-con bg-1"},[_c('div',{staticClass:"left-con",staticStyle:{"padding-top":"80px"}},[_vm._m(1),_c('div',{staticClass:"btn-con"},[_c('el-button',{attrs:{"size":"medium","type":"primary"},on:{"click":_vm.startUse}},[_vm._v("开始使用")])],1)]),_c('div',{staticClass:"flex-1"}),_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"tip-dialog"},[_vm._m(9),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$com.copyText('https://business.parapack.net')}}},[_vm._v("复 制")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-con"},[_c('div',{staticClass:"flex content-con"},[_c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"https://www.parapack.net"}},[_c('img',{attrs:{"src":require("../assets/imgs/pc/22.png")}})])]),_c('div',{staticClass:"flex-1"}),_c('div',{staticClass:"right-con"},[_c('div',{staticClass:"icon-btn"},[_c('a',{attrs:{"href":"https://t.me/ParaPack_Community","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/imgs/pc/11.png")}})])]),_c('div',{staticClass:"icon-btn"},[_c('a',{attrs:{"href":"https://twitter.com/parapack_pp","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/imgs/pc/12.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title black-bold",staticStyle:{"font-size":"50px"}},[_vm._v("ParaPack服务平台")]),_c('div',{staticClass:"brief"},[_vm._v("活动发布、视频创作、Dapp/App上架、项目/代币信息维护服务等多方位一站式服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/imgs/pc/home/img-1.png"),"width":"450"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-con column bg-2"},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("../assets/imgs/pc/home/pic-7.png"),"height":"36"}})]),_c('div',{staticClass:"flex content-con mgtb"},[_c('div',[_c('img',{attrs:{"src":require("../assets/imgs/pc/home/pic-1.png"),"width":"450"}})]),_c('div',{staticClass:"flex-1"}),_c('div',[_c('div',{staticClass:"title black-bold"},[_vm._v("活动发布及管理")]),_c('div',{staticClass:"brief-2"},[_vm._v(" 提供关注项目方社交账号、答题、链上操作以及任务悬赏等任务，用户完"),_c('br'),_vm._v(" 成后 可获得积分、NFT以及token奖励。 Para任务平台聚合Web3"),_c('br'),_vm._v(" 和Web2，兼容链上链下。 ")]),_c('div',{staticClass:"brief-rows"},[_c('div',{staticClass:"brief-row"},[_c('i',{staticClass:"el-icon-check"}),_vm._v(" 创建发布新活动，查看活动进程和数据 ")]),_c('div',{staticClass:"brief-row"},[_c('i',{staticClass:"el-icon-check"}),_vm._v(" 协助您进行活动效果分析 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-con bg-3"},[_c('div',{staticClass:"flex content-con"},[_c('div',{staticClass:"left-con"},[_c('div',{staticClass:"title text-right black-bold"},[_vm._v("视频创作")]),_c('div',{staticClass:"flex mgt-16"},[_c('div',{staticClass:"flex-1"}),_c('img',{staticClass:"mgr-5",attrs:{"src":require("../assets/imgs/pc/home/pic-11.png"),"height":"20"}}),_vm._v(" 视频发布 ")]),_c('div',{staticClass:"brief-2 text-right"},[_vm._v("提供视频发布和作品管理服务，方便于创作者进行作品管理和效果分析")]),_c('div',{staticClass:"flex mgt-16"},[_c('div',{staticClass:"flex-1"}),_c('img',{staticClass:"mgr-5",attrs:{"src":require("../assets/imgs/pc/home/pic-12.png"),"height":"20"}}),_vm._v(" 数字直播 ")]),_c('div',{staticClass:"brief-2 text-right"},[_vm._v("AIGC数字人服务，自动生成文字、图片和音频等素材，自动生成视频和直播内容")])]),_c('div',{staticClass:"flex-1"}),_c('div',[_c('img',{attrs:{"src":require("../assets/imgs/pc/home/pic-2.png"),"width":"550"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-con bg-4"},[_c('div',{staticClass:"flex-1"}),_c('div',{staticClass:"right-con",staticStyle:{"margin-right":"100px"}},[_c('div',{staticClass:"title black-bold",staticStyle:{"color":"#fff"}},[_vm._v("改变正在悄悄发生")]),_c('div',{staticClass:"brief-2",staticStyle:{"color":"#fff"}},[_vm._v(" 我们一起为Web3提供更有价值的内容和服务 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-con bg-2"},[_c('div',{staticClass:"flex content-con"},[_c('div',[_c('img',{attrs:{"src":require("../assets/imgs/pc/home/pic-3.png"),"width":"450"}})]),_c('div',{staticClass:"flex-1"}),_c('div',{staticClass:"right-con"},[_c('div',{staticClass:"title black-bold"},[_vm._v("项目信息维护")]),_c('div',{staticClass:"brief-2"},[_vm._v(" 项目官方信息更新维护 ")]),_c('div',{staticClass:"brief-rows"},[_c('div',{staticClass:"brief-row"},[_c('i',{staticClass:"num"},[_vm._v("1")]),_vm._v(" DApp/App、代币logo上架申请 ")]),_c('div',{staticClass:"brief-row"},[_c('i',{staticClass:"num"},[_vm._v("2")]),_vm._v(" 项目官方信息更新维护 ")]),_c('div',{staticClass:"brief-row"},[_c('i',{staticClass:"num"},[_vm._v("3")]),_vm._v(" 多板块一站式营销分发 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-con bg-5"},[_c('div',{staticClass:"flex content-con"},[_c('div',{staticClass:"left-con"},[_c('div',{staticClass:"title text-right black-bold"},[_vm._v("数据服务")]),_c('div',{staticClass:"brief-2 text-right"},[_vm._v(" 提供内容及数据收集反馈，用户喜好和行为数据分析，效果评"),_c('br'),_vm._v(" 估和增长改进建议等全方位的数据服务，帮助您明晰用户偏好"),_c('br'),_vm._v(" 并做好用户服务。精确的数据服务为您的决策提供科学、准确"),_c('br'),_vm._v(" 的数据支持，帮助您做出更明智的决策。 ")])]),_c('div',{staticClass:"flex-1"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-con"},[_c('div',{staticClass:"copyright"},[_c('span',{staticClass:"mark"},[_vm._v("©")]),_vm._v("2024 PP ParaPack All Rights Reserved ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v("请使用PC访问Parapack服务平台"),_c('br'),_vm._v("网址：https://business.parapack.net")])
}]

export { render, staticRenderFns }