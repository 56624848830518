<template>
  <div>
    <div class="page-title">{{$t('agent.agentManage')}} - {{$t('agent.agentSupply')}}</div>

    <div class="form-con">
      <div class="fs-20 mgl-10">*{{$t('agent.profitsBriefTitle')}}:</div>
      <div class="fs-13 mgt-16 mgl-20">
        {{$t('agent.profitsBrief')}}
      </div>
      <el-form ref="form" :model="form" label-width="50px" size="small" class="mgt-30">
        <div class="flex" v-for="(v, i) in 3" :key="i">
          <el-form-item :label="(vv + (v - 1) * 3) + $t('agent.gradeMark')" :prop="'level' + (vv + (v - 1) * 3)" v-for="(vv, ii) in 3">
            <el-input v-model="form['level' + (vv + (v - 1) * 3)]" :placeholder="$t('agent.pleaseInput')">
              <template slot="append">
                %
              </template>
            </el-input>
          </el-form-item>
        </div>
        <div class="text-center">
          <el-button type="primary" size="medium" style="width: 580px; margin-left: 20px;" @click="submitForm">{{$t('common.save')}}</el-button>
        </div>
      </el-form>
    </div>

  </div>
</template>

<script>
import * as AgentApi from "@/api/agent";

export default {
  data(){
    return {
      type: null,
      form: {}
    }
  },
  mounted() {
    let { type, shares } = this.$route.query;
    this.type = type;
    if(shares){
      shares = JSON.parse(shares);
      for(let k in shares){
        shares[k] = parseFloat(parseFloat(shares[k] * 100).toFixed(2));
      }
      this.form = shares;
    }
  },
  methods: {
    async submitForm(){
      if(!this.type){
        this.$message.error(this.$t('agent.errorParams'));
        return;
      }
      const isValidNumber = (value) => {
        const regex = /^(0|[1-9]\d*)(\.\d+)?$/;
        return regex.test(value);
      };

      let total = 0;
      for(let i in this.form){
        if(this.form[i] && !isValidNumber(this.form[i])){
          this.$message.error(this.$t('agent.errorProfit'));
          return;
        }
        total += parseFloat(this.form[i]);
      }
      if(total === 0){
        this.$message.error(this.$t('agent.minOneProfit'));
        return;
      }
      if(total - 100 > 0){
        this.$message.error(this.$t('agent.maxProfit'));
        return;
      }
      const res = await AgentApi.setShares(Object.assign({}, this.form, { type: this.type }));
      if(res && res.data && res.data.code === 200){
        this.$message.success(this.$t('agent.setSuccessful'));
        this.$router.replace('/agent');
      } else {
        this.$message.error(this.$t('agent.setFailed'));
      }
    }
  }
}
</script>

<style lang="less" scoped>
.form-con {
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: calc(100vh - 50px - 100px);
  flex-direction: column;
}
</style>