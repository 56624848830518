module.exports = {
    header: {
        'logout': '退出'
    },
    web: {
        header: {
            index: '首页',
            found: '发现',
            ecology: '生态',
            activity: '活动',
            download: '下载',
            wallet: '钱包',
            video: '视频',
            service: '服务平台'
        },
        footer: {
            slogn: '专注Web3活动支持、营销增长，实现流量的聚合增长',
            useApp: '体验APP',
            viewWhiteBook: '查看白皮书',
            wallet: '钱包',
            appPublish: '应用上架',
            tokenPublish: '代币提交',
            video: '视频',
            kolJoin: 'KOL入驻',
            groupJoin: '社区入驻',
            creatorCenter: '创作中心',
            videoGuide: '视频教程',
            activity: '活动',
            activityGuide: '活动教程',
            activityPublish: '活动发布',
            airdrop: '联合空投',
            support: '支持',
            about: '关于ParaPack',
            contact: '联系ParaPack',
            agentSupply: '代理商申请',
            serverPlatform: '服务平台',
            serviceAgreement: '服务协议',
            privacy: '隐私条款'
        },
        index: {
            'web3Title': 'WEB3.0',
            'web3SubTitle': '全球一站式自媒体聚合平台',
            'web3Brief': `通过ParaPack，用户可以实现同时管理多个自媒体账号，实现统一<br/>管理、数据分析、内容同步、多渠道营销等功能，ParaPack的目标是帮助内容创作者和营销人员简化工作流程，<br/>提高内容传播的效率和效果。`,
            'find': '探索',
            'userCount': '注册用户',
            'dailyActiveCount': '日活用户',
            'merchants': '入驻商家',
            'web3Title2': 'Web3流量聚合营销',
            'web3SubTitle2': `以去中心化钱包为载体，活动和AI短视频为工具`,
            'web3Title3': '多链Web3钱包',
            'web3SubTitle3': `去中心化加密资产钱包，兼容多种区块链网络，支持多种主流加密资产`,
            'walletTag1': '支持管理20+网络Token&NFTS',
            'walletTag2': 'Dapp区块浏览器及Magic Sotre',
            'walletTag3': '链上非托管交易安全快捷',
            'web3Title4': '任务平台',
            'web3SubTitle4': `链接用户与项目方，用户增长营销工具，社区成长有力帮手`,
            'taskTag1': '聚合链上链下多维度数据驱动',
            'taskTag2': '先行验资自动发奖透明有保障',
            'taskTag3': '多渠道推广方式高效易传播',
            'web3Title5': 'AI短视频',
            'web3SubTitle5': `AIGC数字人短视频创作平台，赋能于营销和内容传播`,
            'videoTag1': '专注Web3内容分享打造行业IP',
            'videoTag2': 'AIGC智能应用易用高效成本低',
            'videoTag3': '多板块联动一站式分',
            'web3Title6': '伙伴关系',
            'web3SubTitle6': `去中心化加密资产钱包，兼容多种区块链网络，支持多种主流加密资产去中心化<br/>
        加密资产钱包，兼容多种区块链网络，支持多种主流加密资产去中心化加密资产钱包，兼容多种区`,
            'web3Title7': '流量聚合转化增长'
        },
        activity: {
            ppCompagin: 'ParaPackCompagin',
            ppCompaginSubTitle: 'Web3任务管理及流量增长',
            joinActivity: '参与活动',
            publishActivity: '发布活动',
            regUsers: '注册用户',
            activeUsers: '日活用户',
            shopes: '入驻商家',
            publishCount: '活动发布',
            rewardsAmount: '奖励规模',
            title2: '聚合链上链下',
            subTitle2: '多维度用户行为考量，不同平台用户相互转化',
            title3: '矩阵式传播',
            subTitle3: '多板块互联，视频、广告、应用介绍等多形式并行宣发',
            title4: '奖励公正透明',
            subTitle4: '活动奖励提前进入金库锁定，保障活动的真实性和奖励的发放',
            title5: '易用的数据服务',
            subTitle5: '提供多维度数据服务，帮助发布者做好效果评估和用户分析'
        },
        down: {
            title: '全球一站式自媒体聚合平台',
            subTitle: 'Global one-stop self media aggregation platform'
        },
        wallet: {
            title: 'ParaPack Wallet',
            subTitle: '一站式的Web3入口及服务',
            brief: `对消费者来说，数字钱包是免费的，可以相当容易得到。例如，当消费者在建立<br/>了处理服务器端数字钱包的商家网站上购买东西时，他把其名字、付款额和送货<br/>信息输入到商家自己的表格。`,
            userScale: '用户规模',
            txs: '日均交易',
            swap: '闪兑规模',
            title2: '加密资产支持',
            subTitle2: '随时随地安全管理加密资产',
            title3: '快速便捷的闪兑服务',
            brief3: `支持不同公链资产跨链兑换，去中心化交易，<br/>安全便捷`,
            title4: '丰富的Magic Store',
            brief4: `Web3最新最热DAPP/APP,内置区块浏览器，<br/>轻松访问Dapp`,
            title5: '完善的应用生态',
            subTitle5: '我们正在开发和即将开发的应用功能，打造更加完善的生态布局',
            tag1: '行业资讯',
            tag1Brief: `接入Token及项目相关最新资讯，方便<br/>用户及时了解最新动向，辅助决策`,
            tag2: '投研排行',
            tag2Brief: `Para投研团队有完备的一级市场投研体<br/>系，会不断发掘跟进优质早期项目`,
            tag3: '实用工具',
            tag3Brief: `提供多种实用的链上工具，如:授权查<br/>询及取消、价格波动提醒等`
        }
    },
    leftMenus: {
        'setting': '用户设置',
        'basicInfo': '展示信息',
        'accountManage': '账号管理',
        'eventsManage': '活动管理',
        'eventsLists': '活动列表',
        'eventsPublish': '发布活动',
        'projectManage': '项目管理',
        'appManage': '应用管理',
        'tokenManage': '代币管理',
        'agentManage': '代理商',
        'collections': '合集管理'
    },
    basicInfo: {
        'title1': '基本信息（双击可编辑）',
        'title2': '官方社区（双击可编辑）',
        'nickname': '账户昵称',
        'brief': '介绍签名',
        'site': '官方网站',
        'infoTips': '展示信息将出现在您的个人主页',
        'noSet': '未设置'
    },
    common: {
        'confirm': '确认',
        'cancel': '取消',
        'save': '保存',
        'noData': '暂无数据',
        'loading': '加载中...',
        'deleteSuccessful': '删除成功',
        'deleteFailed': '删除失败',
        'saveSuccessful': '保存成功',
        'saveFailed': '保存失败',
        'total': '共',
        'per': '份',
        'tasks': '任务',
        'brief': '介绍',
        'noSetting': '未设置',
        'login': '登录',
        'notLogin': '您还未登录',
        'pleaseSelect': '请选择',
        'copySuccess': '复制成功',
        'handleSuccess': '操作成功',
        'handleFailed': '操作失败'
    },
    setting: {
        'uidTips': 'UID是唯一身份区别，UID下绑定的其他账户都可以登录此UID',
        'userSetting': '用户设置',
        'accountManage': '账号管理',
        'email': '邮箱',
        'change': '更换',
        'loginPwd': '登录密码',
        'noSetPwd': '暂未设置密码',
        'set': '设置',
        'notBind': '未绑定',
        'unbind': '解绑',
        'bind': '绑定',
        'updateAvatar': '修改头像',
        'confirm': '确认',
        'cancel': '取消',
        'save': '保存',
        'bindEmailTip': '绑定邮箱后完成注册',
        'verifyCode': '验证码',
        'send': '发送',
        'sendVerifyCode': '发送验证码',
        'toBind': '立即绑定',
        'userAgreement': '用户协议',
        'privacyPolicy': '隐私政策',
        'read': '我已阅读并同意',
        'userAgreementApfix': '未注册的账号验证后将自动注册',
        'changeEmail': '更换邮箱',
        'bindEmail': '绑定邮箱',
        'needEmail': '需要绑定的新邮箱',
        'unbindTwitter': '解除绑定',
        'isUnbindTwitter': '确定要解绑推特吗',
        'changePwd': '更换密码',
        'newPwd': '新的登录密码',
        'newPwd_': '新密码',
        'setPwd': '设置密码',
        'pleaseSetPwd': '请设置登录密码',
        'repeatPwd': '重复密码',
        'bindedEmail': '已绑定邮箱'
    },
    events: {
        'basicInfo': '基本信息',
        'tasksNeed': '任务要求',
        'tasksReward': '任务奖励',
        'eventPublish': '活动发布',
        'eventsManage': '活动管理',
        'eventsLists': '活动列表',

        'collectionsLists': '合集列表',
        'collectionManage': '合集管理',
        'collectionInfo': '合集详情',
        'removeCollection': '释放合集',
        'removeEndEvents': '移除结束的活动',
        'isRemoveColl': '确认要释放合集吗？',
        'isRemoveEndEvents': '确认要移除已结束的活动吗？',

        'notSetTitle': '标题未设置',
        'isDeleteDraft': '删除当前草稿？',
        'getReward': '抽奖',
        'fcfs': 'FCFS',
        'divideAmount': '总量瓜分',
        'toCreate': '创建活动',
        'isPublished': '已发布',
        'isChecking': '审核中',
        'isRunning': '进行中',
        'waitRunning': '即将开始',
        'isEnd': '已结束',
        'draft': '草稿',
        'preview': '预览',
        'eventData': '活动数据',
        'basicData': '基本数据',
        'taskData': '任务数据',
        'finished': '完成',
        'join': '参与',
        'view': '查看',
        'share': '分享',
        'like': '收藏',
        'eventsCount': '活动数',
        'myEvents': '我的活动',
        'hotEvents': '热门活动',
        'moreEvents': '更多精彩活动',
        'myCreated': '我创建的',
        'myJoined': '我参与的',
        'myLiked': '我收藏的',
        'publishCollection': '创建合集',
        'edit': '编辑',
        'collection': '合集',
        'submitBy': '提交于',
        'ifDel': '删除当前活动？',
        'cancel': '取消',
        'confirm': '确认',
        'minTwoEvents': '至少选择两个活动',
        'failedCheck': '审核失败',
        'checking': '审核中',
        'cancelSelect': '取消选择',
        'selectEvents': '选择活动',
        'createCollection': '生成合集',
        'endTime': '结束时间',
        'startTime': '开始时间',
        'submitAndWaitCheck': '提交成功，等待审核！',
        'submitFailed': '提交失败',
        'emptyLogo': '项目图标不能为空',
        'max300kb': '上传图片大小不能超过 300KB!',
        'errorFormat': '图片格式无效!',
        'collectionName': '合集名称',
        'emptyCollectionName': '合集名称不能为空',
        'placeCollectionName': '请输入合集名称',
        'collectionLogo': '合集Logo',
        'tipCollectionLogo': `仅支持jpg/png图片上传，分辨率推荐为168*168，<br/>文件大小小于300KB，保持视觉元素居中。`,
        'collectionBrief': '合集介绍',
        'emptyCollectionBrief': '合集介绍不能为空',
        'placeCollectionBrief': '请输入合集介绍',
        'eventTime': '活动时间',
        'emptyEventTime': '活动时间不能为空',
        'placeEventTime': '请输入活动时间'
    },
    task: {
        'xTask': 'X任务',
        'tradeNeed': '交易任务',
        'platformTasks': '平台任务',
        'projectTasks': '项目任务',
        'trade': '交易',
        'eventReward': '活动奖励',
        'allRewards': '全部赠品',
        'rewardMode': '奖励机制',
        'issuerReward': '中签奖励',
        'regLongPre': '账号注册不少于',
        'perMonth': '个月',
        'followX': '关注X账户',
        'retweet': '转发',
        'retweetfix': '推文并@三个好友',
        'retweetApfix': '最新推文并@三个好友',
        'like': '点赞',
        'lastTweet': '最新推文',
        'tweet': '推文',
        'balanceNeed': '资产要求',
        'has': '持有',
        'hasToken': '链Token',
        'noLessThan': '不少于',
        'other': '其他',
        'receiveAddress': '奖励接收地址',
        'pleaseUse': '请使用',
        'pleaseUseApfix': '代币接收地址，若您获得本次活动奖励,奖励会自动发放到该地址',
        'textCollect': '文本收集',
        'textInfo': '文本信息',
        'userNickname': '用户昵称',
        'userID': '用户ID',
        'autoTweet': '代发',
        'hasTokens': '持有指定token',
        'tradeTokens': '交易指定token',
        'otherTasks': '其他',
        'selfTasks': '自定义任务',
        'promote': '活动推广',
        'syncX': '将此活动同步到PP钱包活动官x账号',
        'placeAutoTweet': '代发推文内容'
    },
    publish: {
        'saveDraft': '保存草稿',
        'nextStep': '下一步',
        'default': '默认',
        'title': '活动标题',
        'time': '活动时间',
        'startTime': '开始日期',
        'to': '至',
        'endTime': '结束日期',
        'cover': '活动海报',
        'logo': '活动Logo',
        'coverSize': '建议168*168',
        'coverBytes': '不超过3MB',
        'coverWait': '请等待图片上传完成',
        'content': '活动说明',
        'tags': '活动标签',
        'taskLists': '任务列表',
        'noTaskLists': '请至少添加一项任务要求！',
        'regLong': '参与者X账号最小注册月数',
        'followX': '关注指定推特',
        'xActive': '参与指定推特最新推文互动',
        'tweetActive': '参与指定推文互动',
        'retweet3': '转发并标记3个好友',
        'like': '点赞',
        'linkTweetUrl': '关联已有推文Url',
        'autoTweetActive': '参与代发推文互动',
        'autoTweetContent': '代发推文内容',
        'tweetContentTip': '请编辑推文内容（200字符以内）',
        'tweetContentTip2': '活动发布成功以后，Parapack会通过您授权的',
        'tweetContentTip3': 'X账户帮您发送此推文',
        'authedX': '已授权Twitter',
        'notAuthX': 'Twitter未授权',
        'toBind': '去绑定',
        'balanceNeed': '资产要求',
        'hasToken': '持有指定token',
        'chain': '发行链',
        'contractAddress': '合约地址',
        'tokenName': '资产名称',
        'minNum': '最小数量',
        'other': '其他',
        'textDesp': '文本描述',
        'rewardNum': '赠品份额',
        'rewardSetting': '赠品设置',
        'topUpReward': '存入赠品',
        'rewartValue': '赠品金额',
        'rewardTip': '该部分资金将质押在PP，确保活动顺利进行，活动结束后有剩余的部分会如数退回',
        'serviceFee': '服务费用',
        'serviceFeeTip': '此次活动PP收取的服务费，活动一经发布，不予退回',
        'topUpAmount': '存款金额',
        'topUpAmountTip': '此次活动发布需要存入的token数量(包含了赠品金额和服务费用两部分)',
        'topUpAddress': '存入地址',
        'topUpTip': '此地址只可存入',
        'topUpTip2': '链资产，存入其他资产可能会造成资金损失',
        'backAddressTip': '当活动结束后仍有代币剩余或其他原因导致的代币退回，剩余代币将如数退回到转账地址。',
        'backAddressTip2': '此地址必须与存款地址同一网络，否则您可能会有资产损失!',
        'topUpAddressPlaceholder': '请提供一个您的赠品代币接收地址',
        'backAddress': '退回地址',
        'netAddress': '网络地址',
        'txid': '转账哈希',
        'txidPlaceholder': '请提供您往存款地址转账的链上哈希',
        'tokenAmountPlaceholder': '请输入奖励Token总量',
        'xAccount': '推特号',
        'xAccountPlace': 'eg. @elonmusk',
        'toPublish': '我已完成付款，发布活动',
        'setRewardCount': '设置中奖人数（份）',
        'publishLang': '发布语言',
        'customTask': '自定义任务',
        'hasTrade': '交易任务',
        'tradePath': '交易路径',
        'tradeNum': '交易次数',
        'minTradeNumber': '最小交易次数',
        'minAmount': '交易数额',
        'errorFileSize': '文件大小超出限制',
        'errorPicSize': '圖片尺寸不符合要求',
        'errorTitle': '活动标题不能为空',
        'errorTimeRange': '活动时间不能为空',
        'errorEndTime': '结束时间必须大于开始时间',
        'errorCover': '活动海报不能为空',
        'coverUploading': '活动海报正在上传中，请稍侯...',
        'errorContent': '活动说明不能为空',
        'minTasksCount': '请至少添加一项任务要求！',
        'errorXLong': '请设置x账号注册时长',
        'errorTasksNeed': '请完善任务要求',
        'repeatTasks': '任务重复',
        'errorRewardToken': '请设置奖励token',
        'errorRewardType': '请选择奖励类型',
        'errorAutoTweet': '请填代发推文内容',
        'isUploading': '图片正在上传中，请稍侯...',
        'isPublishing': '正在发布...',
        'publishSuccessful': '发布成功',
        'publishFailed': '发布失败',
        'taskBrief': '任务介绍',
        'customLink': '任务链接',
        'customCheckLink': '验证API',
        'placeCustomCheckLink': '任务校验API接口',

        'errorTags': '活动标签不能为空',
        'errorLangs': '多語言內容填寫不完善'
    },
    login: {
        title: '服务平台登录',
        email: '邮箱',
        verifyCode: '验证码',
        pwd: '密码',
        toLogin: '立即登录',
        sendCode: '发送验证码',
        thirdLogin: '三方账号登录',
        xLogin: 'Twitter登录',
        scanLogin: '扫码登录',
        downApp: '下载Parapack',
        toBind: '立即绑定',
        otherLogin: '其他登录方式',
        bindEmail: '绑定邮箱',
        bindEmailTip: '绑定邮箱后完成注册'
    },
    errMsg: {
        pleaseRead: '请阅读并同意平台协议',
        badNetwork: '您当前的网络不佳，请稍后刷新重试！',
        pleaseInputEmail: '请输入邮箱',
        pleaseInputCode: '请输入验证码',
        pleaseInputPwd: '请输入密码',
        sendFailed: '发送失败',
        loginFailed: '登录失败',
        bindFailed: '绑定失败',
        noLoginPermit: '您当前还不是商家，请前往APP申请入驻'
    },
    successMsg: {
        loginSuccess: '登录成功',
        bindSuccess: '绑定成功'
    },
    server: {
        'Not login!': '请重新登录',
        'Invalid params!': '请求参数无效',
        'No data!': '暂无数据',
        'success': '操作成功',
        'fail': '操作失败',
        'Uploading, please wait!': '上传中，请稍候！',
        'Publishing too frequently, please wait a moment!': '发布太频繁，请稍后再试！',
        'Cover image is being uploaded, please submit again later!': '正在上传封面图！',
        'Asset verification failed, please refresh the recharge address!': '资产校验失败，请刷新充值地址！',
        'Asset verification failed and the recharge address has expired!': '资产校验失败，充值地址已失效！',
        'Hash has been used!': 'Hash已使用过！',
        'Asset verification failed and the asset balance is insufficient!': '资产校验失败，资产余额不足！',
        'Asset verification failed, invalid hash!': '资产校验失败，无效的hash！',
        'Mission request contains an invalid Twitter account:': '任务要求含有无效的推特账户',
        'X account is not bound!': 'X账号未绑定',
        'The URL of the specified tweet is incorrectly filled in!': '指定推文的Url填写错误',
        'Unable to find the specified tweet!': '无法查询到指定的推文',
        'Save failed!': '保存失败',
        'Save event failed!': '活动保存失败',
        'Save params failed!': '活动参数保存失败',
    },
    dapp: {
        projectManage: '项目管理',
        dappManage: '应用管理',
        basicInfo: '基本信息',
        otherInfo: '其他信息',
        submit: '提交申请',
        name: '名称',
        type: '类型',
        tags: '标签',
        ctime: '操作时间',
        status: '状态',
        handle: '操作',
        view: '查看',
        del: '删除',
        draft: '草稿',
        refused: '已拒绝',
        checking: '审核中',
        published: '已上架',
        tip: '提示',
        delTip: '删除后您的应用将会自动下架，将不再收录在PP的应用商城！',
        logo: '项目图标',
        errorLogo: '项目图标不能为空',
        logoTip: `仅支持jpg/png图片上传，分辨率推荐为100*100以上，<br/>文件大小小于300KB，保持视觉元素居中。`,
        appName: '应用名称',
        errorAppName: '应用名称不能为空',
        placeAppName: '请输入应用名称',
        subName: '应用简称',
        errorSubName: '应用简称不能为空',
        placeSubName: '最能识别应用的1到2个词',
        desc: '应用介绍',
        errorDesc: '默认语言的应用介绍必须填写',
        placeDesc: '默认语言的介绍内容必须填写',
        defLang: '默认',
        appType: '应用类型',
        errorAppType: '请选择应用类型',
        appChains: '支持的链',
        errorAppChains: '请选择应用支持的链',
        placeAppChains: '请选择应用支持的链',
        appTags: '应用标签',
        errorAppTags: '请选择应用标签',
        errorAppTags2: '最多支持5个标签',
        placeAppTags: '请选择应用标签，最多选择5个',
        dappUrl: '',
        errorDappUrl: '请填写应用访问链接',
        placeDappUrl: 'Dapp应用的访问链接',
        appUrl: '',
        errorAppUrl: '请填写应用下载链接',
        placeAppUrl: '应用的下载链接',
        checkStatus: '审计信息',
        checkStatusYes: '已审计',
        checkStatusNo: '未审计',
        errorCheckStatus: '请选择审计状态',
        checkUrl: '',
        errorCheckUrl: '请填写审计报告链接',
        placeCheckUrl: '请填写审计报告链接',
        pleaseSelect: '请选择',
        telegramUrl: '联系人Telegram',
        errorTelegramUrl: '请填写联系人Telegram',
        otherFormData: '其他信息（选填）',
        siteUrl: '官方网站',
        placeSiteUrl: '请输入网址链接https://',
        whiteBook: '白皮书',
        placeWhiteBook: '请输入白皮书链接https://',
        placeTwitter: '请输入X账号链接https://',
        placeTg: '请输入Telegram账号链接https://',
        placeDiscord: '请输入Discord链接https://',
        placeFacebook: '请输入Facebook账号链接https://',
        ppLink: '关联PP',
        placePPLink: '关联后可以跳转发布者PP账号个人中心',
        ppLinkOptions: {
            v1: '不关联',
            v2: '关联活动',
            v3: '关联视频',
            v4: '关联活动&视频'
        },
        tokenAddress: '代币信息1',
        tipTokenAddress: '如果您的代币在PP上架，我们将帮您关联代币',
        tokenAddress2: '代币信息2',
        placeTokenAddress: '请输入代币合约URL https://',
        email: '联系邮箱',
        placeEmail: '项目联系人邮箱地址',
        contact: '对接人',
        placeContact: '如有对接的BD，请填写',
        brief: '项目描述',
        placeBrief: '向我们介绍一下你的应用，方便我们对你的应用更充分了解，这有助于此次申请审核',
        saveDraft: '保存草稿',
        resetForm: '重置',
        nextStep: '下一步',
        submitForm: '提交审核'
    },
    agent: {
        agentSupply: '代理申请',
        agentManage: '代理管理',
        agentProfit: '代理收益',
        urealizedProfit: '未提现收益',
        shareRate: '业务分成比例',
        requirement: '团队考核要求',
        requirementCount: '新增活动新用户',
        profitsBriefTitle: '团队分成说明',
        realizedYes: '已达标',
        realizedNo: '未达标',
        groupShares: '团队分成比例',
        teamCount: '团队人数',
        realizedCount: '活动拉新',
        teamUser: '团队用户',
        searchUserKey: 'UID/昵称',
        nickname: '昵称',
        regTime: '注册日期',
        brokerType: '达标类型',
        incomeRecords: '收益记录',
        searchIncomeKey: 'UID/事项',
        dateTime: '时间',
        incomeTitle: '事项',
        inviteUid: '拓展者UID',
        brokerReward: '代理提成',
        teamReward: '团队提成',
        incomeStatus: '状态',
        receivedNo: '待领取',
        receivedYes: '已领取',
        profitsBrief: `1.设定分成比例后，团队用户将按设定的分成比例享受下级贡献的业务收入，例:设定1级30%，直接邀请一个用户参与生态贡献了10万业务收入，直接推荐人可享受3万奖励;<br/><br/>2.您设定的所有层级比例之和不能大于100%。`,
        pleaseInput: '请输入',
        gradeMark: '级',
        errorParams: '请求参数无效',
        errorProfit: '比例必须为大于0的数字，没有可留空！',
        minOneProfit: '至少设置一项比例',
        maxProfit: '总比例不能超过100%',
        setSuccessful: '设置成功',
        setFailed: '设置失败',
        adAgent: '广告代理',
        activityAgent: '活动代理',
        appAgent: '应用代理'
    }
}