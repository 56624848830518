<template>
  <div v-if="userInfo">
    <div class="page-title">{{$t('setting.userSetting')}} - {{$t('setting.accountManage')}}</div>
    <div style="height: 80px;"></div>

    <div class="avatar-con">
      <div class="avatar-img">
        <img :src="userInfo.avatar || '../../assets/imgs/2.png'" class="avatar"/>
        <div class="setting-btn" @click="showResetAvatar=true">
          <img src="../../assets/imgs/pc/setting.png" />
        </div>
      </div>
      <div class="nickname">{{userInfo.twitter_name}}</div>
    </div>

    <div class="user-info">
      <div class="row-data">
        <img src="../../assets/imgs/9.png" class="icon"/>
        <div class="label">UID</div>
        <div class="value">{{userInfo.id}}</div>
        <div class="right-btn" @click="$com.copyText(userInfo.id)">
          <img src="../../assets/imgs/pc/25.png"/>
        </div>
      </div>
      <div class="row-data">
        <img src="../../assets/imgs/10.png" class="icon"/>
        <div class="label">{{$t('setting.email')}}</div>
        <div class="value">{{userInfo.email}}</div>
        <div class="right-btn" @click="showChangeEmail=true">{{$t('setting.change')}}</div>
      </div>
      <div class="row-data">
        <img src="../../assets/imgs/10.png" class="icon"/>
        <div class="label">{{$t('setting.loginPwd')}}</div>
        <div class="value">{{userInfo.pwd ? '******' : $t('setting.noSetPwd')}}</div>
        <div class="right-btn" @click="showResetPwd=true" v-if="userInfo.pwd">{{$t('setting.change')}}</div>
        <div class="right-btn text-warning" @click="showSetPwd=true" v-else>{{$t('setting.set')}}</div>
      </div>
      <div class="row-data">
        <img src="../../assets/imgs/11.png" class="icon"/>
        <div class="label">Twitter</div>
        <div class="value">{{userInfo.x_account || $t('setting.notBind')}}</div>
        <div
            class="right-btn"
            @click="showUnbindTwitter=true"
            v-if="userInfo.x_account"
        >{{$t('setting.unbind')}}</div>
        <div class="right-btn" v-else>
          <a :href="twitterBindUrl">{{$t('setting.bind')}}</a>
        </div>
      </div>
      <div class="remark">
        <b class="text-red">*</b>{{$t('setting.uidTips')}}
      </div>
    </div>

    <!-- 修改头像 -->
    <div class="login-dialog small" v-if="showResetAvatar">
      <div>
        <i class="el-icon-close close-btn" @click="closeSetAvatar"></i>
      </div>
      <div class="title">{{$t('setting.updateAvatar')}}</div>

      <div class="form-con mgt-20">
        <div class="avatar-editor" @click="!avatarPic && $refs.picSelector.click()">
<!--          <img :src="avatarPic" class="avatar-bg" v-if="avatarPic">-->
          <div ref="croppieElement" class="clip-box"></div>
        </div>
        <input type="file" ref="picSelector" v-on:change="onChangePic" accept="image/*" style="opacity: 0;position: absolute;top:-500px;left:0;" />
      </div>

      <div style="height: 30px;"></div>
      <div class="btn-group">
        <div class="cancel-btn" @click="closeSetAvatar">{{$t('setting.cancel')}}</div>
        <div class="confirm-btn" @click="setAvatar">{{$t('setting.save')}}</div>
      </div>
    </div>

    <!-- 绑定邮箱 -->
    <div class="login-dialog small" v-if="showBindEmail">
      <div><i class="el-icon-close close-btn" @click="showBindEmail=false"></i></div>
      <div class="title">{{$t('setting.bindEmail')}}<div class="sub-title">{{$t('setting.bindEmailTip')}}</div></div>
      <div class="form-con">
        <label>{{$t('setting.email')}}</label>
        <el-input v-model="email" :placeholder="$t('setting.email')" class="mgt-10"></el-input>
        <div>
          <label>{{$t('setting.verifyCode')}}</label>
          <el-input v-model="code" :placeholder="$t('setting.verifyCode')" class="mgt-10">
            <template slot="append">
              <div class="send-code-btn" v-if="codeTimer">{{codeTimer}}s</div>
              <div class="send-code-btn" @click="sendEmailCode" v-else>{{$t('setting.sendVerifyCode')}}</div>
            </template>
          </el-input>
        </div>
        <div style="height: 30px;"></div>
        <div class="btn" @click="emailBind">{{$t('setting.toBind')}}</div>
        <div class="agreement" :class="{on:agreement}" @click="agreement = !agreement">
          {{$t('setting.read')}}<a href="https://insex.parapack.net/ServiceAgreement.html" target="_blank">{{$t('setting.userAgreement')}}</a>、<a href="https://insex.parapack.net/PrivacyAgreement.html" target="_blank">{{$t('setting.privacyPolicy')}}</a>,{{$t('setting.userAgreementApfix')}}
        </div>
      </div>
    </div>

    <!-- 更换邮箱 -->
    <div class="login-dialog small" v-if="showChangeEmail">
      <div><i class="el-icon-close close-btn" @click="showChangeEmail=false"></i></div>
      <div class="title">{{$t('setting.changeEmail')}}</div>
      <div class="form-con">
        <label>{{$t('setting.bindEmail')}}</label>
        <el-input disabled v-model="userInfo.email" :placeholder="$t('setting.email')" class="mgt-10"></el-input>
        <div>
          <label>{{$t('setting.verifyCode')}}</label>
          <el-input v-model="oldCode" :placeholder="$t('setting.verifyCode')" class="mgt-10">
            <template slot="append">
              <div class="send-code-btn" v-if="oldCodeTimer">{{oldCodeTimer}}s</div>
              <div class="send-code-btn" @click="emailUpdateVerify('old')" v-else>{{$t('setting.sendVerifyCode')}}</div>
            </template>
          </el-input>
        </div>
        <label>{{$t('setting.needEmail')}}</label>
        <el-input v-model="email" :placeholder="$t('setting.email')" class="mgt-10"></el-input>
        <div>
          <label>{{$t('setting.verifyCode')}}</label>
          <el-input v-model="code" :placeholder="$t('setting.verifyCode')" class="mgt-10">
            <template slot="append">
              <div class="send-code-btn" v-if="codeTimer">{{codeTimer}}s</div>
              <div class="send-code-btn" @click="emailUpdateVerify('new')" v-else>{{$t('setting.sendVerifyCode')}}</div>
            </template>
          </el-input>
        </div>
        <div style="height: 30px;"></div>
        <div class="btn" @click="emailUpdate">{{$t('setting.toBind')}}</div>
        <div class="agreement" :class="{on:agreement}" @click="agreement = !agreement">
          {{$t('setting.read')}}<a href="https://insex.parapack.net/ServiceAgreement.html" target="_blank">{{$t('setting.userAgreement')}}</a>、<a href="https://insex.parapack.net/PrivacyAgreement.html" target="_blank">{{$t('setting.privacyPolicy')}}</a>,{{$t('setting.userAgreementApfix')}}
        </div>
      </div>
    </div>

    <!-- 设置密码 -->
    <div class="login-dialog small" v-if="showSetPwd">
      <div><i class="el-icon-close close-btn" @click="showSetPwd=false"></i></div>
      <div class="title">{{$t('setting.setPwd')}}</div>
      <div class="form-con">
        <label>{{$t('setting.pleaseSetPwd')}}</label>
        <el-input v-model="pwd" type="password" :placeholder="$t('setting.loginPwd')" class="mgt-10"></el-input>
        <label>{{$t('setting.repeatPwd')}}</label>
        <el-input v-model="rePwd" type="password" :placeholder="$t('setting.repeatPwd')" class="mgt-10"></el-input>
        <div style="height: 30px;"></div>
        <div class="btn" @click="setPwd">{{$t('setting.confirm')}}</div>
        <div class="agreement" :class="{on:agreement}" @click="agreement = !agreement">
          {{$t('setting.read')}}<a href="https://insex.parapack.net/ServiceAgreement.html" target="_blank">{{$t('setting.userAgreement')}}</a>、<a href="https://insex.parapack.net/PrivacyAgreement.html" target="_blank">{{$t('setting.privacyPolicy')}}</a>,{{$t('setting.userAgreementApfix')}}
        </div>
      </div>
    </div>

    <!-- 修改密码 -->
    <div class="login-dialog small" v-if="showResetPwd">
      <div><i class="el-icon-close close-btn" @click="showResetPwd=false"></i></div>
      <div class="title">{{$t('setting.changePwd')}}</div>
      <div class="form-con">
        <label>{{$t('setting.newPwd')}}</label>
        <el-input v-model="pwd" type="password" :placeholder="$t('setting.newPwd_')" class="mgt-10"></el-input>
        <label>{{$t('setting.bindedEmail')}}</label>
        <el-input v-model="userInfo.email" disabled :placeholder="$t('setting.email')" class="mgt-10"></el-input>
        <div>
          <label>{{$t('setting.verifyCode')}}</label>
          <el-input v-model="oldCode" type="password" :placeholder="$t('setting.verifyCode')" class="mgt-10">
            <template slot="append">
              <div class="send-code-btn" v-if="oldCodeTimer">{{oldCodeTimer}}s</div>
              <div class="send-code-btn" @click="sendOldEmailCode" v-else>{{$t('setting.sendVerifyCode')}}</div>
            </template>
          </el-input>
        </div>
        <div style="height: 30px;"></div>
        <div class="btn" @click="resetPwd">{{$t('setting.confirm')}}</div>
        <div class="agreement" :class="{on:agreement}" @click="agreement = !agreement">
          {{$t('setting.read')}}<a href="https://insex.parapack.net/ServiceAgreement.html" target="_blank">{{$t('setting.userAgreement')}}</a>、<a href="https://insex.parapack.net/PrivacyAgreement.html" target="_blank">{{$t('setting.privacyPolicy')}}</a>,{{$t('setting.userAgreementApfix')}}
        </div>
      </div>
    </div>

    <!-- 解绑推特 -->
    <div class="login-dialog confirm" v-if="showUnbindTwitter">
      <div class="title">{{$t('setting.unbindTwitter')}}</div>
      <div class="sub-title">
        {{$t('setting.isUnbindTwitter')}}
      </div>
      <div class="btn-group">
        <div class="cancel-btn" @click="showUnbindTwitter=false">{{$t('setting.cancel')}}</div>
        <div class="confirm-btn" @click="unbindTwitter">{{$t('setting.confirm')}}</div>
      </div>
    </div>

  </div>
  <div v-else></div>
</template>

<script>
import * as AccountApi from "@/api/account";
import * as TwitterApi from "@/api/twitter";
import Croppie from 'croppie';
import 'croppie/croppie.css';

export default {
  data(){
    return {
      userInfo: false,
      twitterBindUrl: '',
      // 显示dialog
      showResetAvatar: false,
      showChangeEmail: false,
      showBindEmail: false,
      showSetPwd: false,
      showResetPwd: false,
      showUnbindTwitter: false,
      // 表单
      agreement: false,
      // 设置密码
      pwd: null,
      rePwd: null,
      // bind email
      email: null,
      code: null,
      codeTimer: null,
      // update email
      oldCode: null,
      oldCodeTimer: null,
      // clip avatar
      avatarPic: null,
      croppieInstance: null
    }
  },
  watch: {
    userInfo(newData) {
      // if(!newData.email){
      //   this.showBindEmail = true;
      // }
      if(!newData.x_id){
        TwitterApi.getBind().then(res => {
          if(res && res.data){
            this.twitterBindUrl = res.data.bindUrl;
          }
        });
      }
    }
  },
  mounted() {
    this.$eventBus.$on('LOGIN-SUCCESS', userInfo => {
      this.userInfo = userInfo;
    });
    this.$eventBus.$on('ACCOUNT_INFO', res => {
      this.userInfo = res.userInfo;
    });

    // 请求加载初始化数据
    this.$eventBus.$emit('LOAD_INIT_DATA');
  },
  methods: {
    /**
     * 确认头像
     * @returns {Promise<void>}
     */
    async setAvatar(){
      this.croppieInstance.result({ type: 'base64', size: 'viewport' }).then((base64) => {
        this.closeSetAvatar();
        AccountApi.updateAvatar({ avatar: base64 }).then(res => {
          if(res && res.data === 'success'){
            this.$message.success('头像设置成功');
            this.userInfo.avatar = base64;
            localStorage.setItem('USERINFO', JSON.stringify(this.userInfo));
            // 同步给插件
            window.postMessage({ action: 'loginSuccess', target: 'Events', options: { userInfo: this.userInfo } }, window.location.origin);
          } else {
            this.$message.error('头像设置失败');
          }
        });
      }).catch((err) => {
        this.$message.error('头像编辑失败');
      });
    },
    closeSetAvatar(){
      this.showResetAvatar = false;
      this.avatarPic = null;
      this.croppieInstance && this.croppieInstance.destroy();
      this.croppieInstance = null;
    },
    /**
     * 选择头像图片
     * @param e
     */
    onChangePic(e){
      const file = e.target.files[0]; // 获取文件引用
      if (!file) return;
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64 = e.target.result;
        this.avatarPic = base64;
        // 头像编辑器
        if(!this.croppieInstance){
          this.croppieInstance = new Croppie(this.$refs.croppieElement, {
            viewport: { width: 100, height: 100 },
            boundary: { width: 300, height: 300 }, // 可视区域和边界大小可以根据需要调整
            showZoomer: false
          });
        }
        this.croppieInstance.bind({
          url: this.avatarPic
        });
      };
      reader.readAsDataURL(file);
    },

    /**
     * 发送邮箱验证码
     * @returns {Promise<void>}
     */
    async sendEmailCode(){
      if(this.codeTimer){
        return;
      }
      if(!this.email){
        this.$message.error('请输入邮箱');
        return;
      }
      const res = await AccountApi.sendEmailCode({ email: this.email });
      if(res && res.data && res.data === 'success'){
        this.codeTimer = 60;
        const timer = setInterval(() => {
          this.codeTimer = this.codeTimer - 1;
          if(this.codeTimer === 0){
            this.codeTimer = null;
            clearInterval(timer);
          }
        }, 1000);
      } else {
        this.$message.error(res && res.data && res.data.msg ? res.data.msg : '发送失败');
      }
    },
    /**
     * 首次绑定邮箱
     * @returns {Promise<void>}
     */
    async emailBind(){
      if(!this.email){
        this.$message.error('请输入邮箱');
        return;
      }
      if(!this.code){
        this.$message.error('请输入验证码');
        return;
      }
      if(!this.agreement){
        this.$message.error('请阅读并同意平台协议');
        return;
      }
      const res = await AccountApi.emailBind({
        email: this.email,
        code: this.code,
        twitter: {
          uid: this.userInfo.twitter_uid,
          name: this.userInfo.twitter_name
        }
      });
      if(res && res.data && res.data === 'success'){
        this.$message.success('绑定成功');
        this.showBindEmail = false;
        this.userInfo.email = this.email;
        localStorage.setItem('USERINFO', JSON.stringify(this.userInfo));
      } else {
        this.$message.error(res && res.data && res.data.msg ? res.data.msg : '绑定失败');
      }
    },
    /**
     * 已有邮箱验证码
     * @returns {Promise<void>}
     */
    async sendOldEmailCode(){
      if(this.oldCodeTimer){
        return;
      }
      if(!this.userInfo.email){
        return;
      }
      const res = this.showResetPwd
          ? await AccountApi.sendUpdatePwdCode()
          : await AccountApi.sendEmailCode({ email: this.userInfo.email });
      if(res && res.data && res.data === 'success'){
        this.oldCodeTimer = 60;
        const timer = setInterval(() => {
          this.oldCodeTimer = this.oldCodeTimer - 1;
          if(this.oldCodeTimer === 0){
            this.oldCodeTimer = null;
            clearInterval(timer);
          }
        }, 1000);
      } else {
        this.$message.error(res && res.data && res.data.msg ? res.data.msg : '发送失败');
      }
    },
    /**
     * 更换邮箱
     * @returns {Promise<void>}
     */
    async emailUpdateVerify(type){
      if(type === 'new'){
        if(this.codeTimer){
          return;
        }
        if(!this.email){
          this.$message.error('请输入邮箱');
          return;
        }
      } else {
        if(this.oldCodeTimer){
          return;
        }
        if(!this.userInfo.email){
          return;
        }
      }
      const res = await AccountApi.updateEmailVerify(this.email ? { email: this.email } : {});
      if(res && res.data && res.data === 'success'){
        if(type === 'new'){
          this.codeTimer = 60;
          const timer = setInterval(() => {
            this.codeTimer = this.codeTimer - 1;
            if(this.codeTimer === 0){
              this.codeTimer = null;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          this.oldCodeTimer = 60;
          const timer = setInterval(() => {
            this.oldCodeTimer = this.oldCodeTimer - 1;
            if(this.oldCodeTimer === 0){
              this.oldCodeTimer = null;
              clearInterval(timer);
            }
          }, 1000);
        }
      } else {
        this.$message.error(res && res.data && res.data.msg ? res.data.msg : '发送失败');
      }
    },
    async emailUpdate(){
      if(!this.oldCode){
        this.$message.error('请输入旧邮箱的验证码');
        return;
      }
      if(!this.email){
        this.$message.error('请输入新邮箱');
        return;
      }
      if(!this.code){
        this.$message.error('请输入新邮箱的验证码');
        return;
      }
      if(!this.agreement){
        this.$message.error('请阅读并同意平台协议');
        return;
      }
      const res = await AccountApi.updateEmail({
        oldCode: this.oldCode,
        newCode: this.code,
        email: this.email
      });
      if(res && res.status === 200 && res.msg === 'success'){
        this.$message.success('修改成功');
        this.showChangeEmail = false;
        this.userInfo.email = this.email;
        localStorage.setItem('USERINFO', JSON.stringify(this.userInfo));
        return;
      }
      this.$message.error('修改失败');
    },
    /**
     * 设置密码
     */
    async setPwd(){
      if(!this.pwd){
        this.$message.error('请输密码');
        return;
      }
      if(this.pwd.length < 8){
        this.$message.error('密码太简单');
        return;
      }
      if(!this.rePwd){
        this.$message.error('请再次输入密码');
        return;
      }
      if(this.pwd !== this.rePwd){
        this.$message.error('两次输入的密码不一致');
        return;
      }
      if(!this.agreement){
        this.$message.error('请阅读并同意平台协议');
        return;
      }
      const res = await AccountApi.updatePwd({ pwd: this.pwd });
      if(res && res.status === 200 && res.msg === 'success'){
        this.$message.success('设置成功');
        this.showSetPwd = false;
        this.userInfo.pwd = true;
        localStorage.setItem('USERINFO', JSON.stringify(this.userInfo));
        return;
      }
      this.$message.error('设置失败');
    },
    /**
     * 重置密码
     */
    async resetPwd(){
      if(!this.pwd){
        this.$message.error('请输密码');
        return;
      }
      if(this.pwd.length < 8){
        this.$message.error('密码太简单');
        return;
      }
      if(!this.oldCode){
        this.$message.error('请输入验证码');
        return;
      }
      if(!this.agreement){
        this.$message.error('请阅读并同意平台协议');
        return;
      }
      const res = await AccountApi.updatePwd({ pwd: this.pwd, code: this.oldCode });
      if(res && res.status === 200 && res.msg === 'success'){
        this.$message.success('修改成功');
        this.showResetPwd = false;
        this.userInfo.pwd = true;
        localStorage.setItem('USERINFO', JSON.stringify(this.userInfo));
        return;
      }
      this.$message.error('修改失败');
    },
    /**
     * 解绑推特
     */
    async unbindTwitter(){
      const res = await AccountApi.unbindTwitter();
      if(res && res.status === 200 && res.msg === 'success'){
        this.$message.success('解绑成功');
        this.showUnbindTwitter = false;
        this.userInfo.x_id = null;
        this.userInfo.x_account = null;
        localStorage.setItem('USERINFO', JSON.stringify(this.userInfo));

        // 重新加载绑定链接
        TwitterApi.getBind().then(res => {
          if(res && res.data){
            this.twitterBindUrl = res.data.bindUrl;
          }
        });

        return;
      }
      this.$message.error('解绑失败');
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.avatar-con {
  margin: 30px auto 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .avatar-img {
    position: relative;
    width: 60px;
    height: 60px;
    .setting-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-color: @light-color;
      width: 20px;
      height: 20px;
      color: #fff;
      border-radius: 10px;
      cursor: pointer;
      box-shadow: 0 0 5px #000;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 60%;
        height: 60%;
        filter: invert(100%);
      }
    }
  }
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }
  .nickname {
    font-size: 14px;
    color: @font-color;
    font-weight: bold;
    margin-top: 10px;
  }
}

.user-info {
  margin: 10px auto;
  width: 350px;

  .row-data {
    border-bottom: 2px solid @light-color;
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 10px 0;
    margin-top: 15px;
    .icon {
      width: 16px;
      margin-right: 10px;
    }
    .label {
      width: 70px;
    }
    .value {
      flex: 1;
      color: @sub-font-color;
    }
    .right-btn{
      color: @light-color;
      margin-left: 5px;
      cursor: pointer;
      a {
        color: @light-color;
        text-decoration: none;
      }
      img {
        height: 16px;
      }
      &.text-warning {
        color: @tag-color;
        a {
          color: @tag-color;
        }
      }
    }
  }
  .remark {
    color: @sub-font-color;
    font-size: 12px;
    margin-top: 10px;
  }
}
</style>