import request from '@/utils/request.js';

const prefix = 'https://eapi.parapack.net/';

export async function getSupport(params) {
    const url = prefix + 'events/support';

    return request.get({ url });
}

export async function getLists(params) {
    const url = prefix + 'events/lists';

    return request.get({ url, params });
}

export async function manageLists(params) {
    const url = prefix + 'events/manage';

    return request.get({ url, params });
}

export async function manageCollections(params) {
    const url = prefix + 'events/collections';

    return request.get({ url, params });
}

export async function getLikeLists(params) {
    const url = prefix + 'events/like-lists';

    return request.get({ url, params });
}

export async function getInfo(params) {
    const url = prefix + 'events/info';

    return request.get({ url, params });
}

export async function getRecord(params) {
    const url = prefix + 'events/record';

    return request.get({ url, params });
}

export async function publishEvent(params) {
    const url = prefix + 'events/publish';

    return request.post({ url, params });
}

export async function saveDraft(params) {
    const url = prefix + 'events/draft';

    return request.post({ url, params });
}

export async function getDraft(params) {
    const url = prefix + 'events/draft';

    return request.get({ url, params });
}

export async function delDraft(params) {
    const url = prefix + 'events/del-draft';

    return request.post({ url, params });
}

export async function delEvent(params) {
    const url = prefix + 'events/del';

    return request.post({ url, params });
}

export async function getDraftInfo(params) {
    const url = prefix + 'events/draft-info';

    return request.get({ url, params });
}

export async function checkEvent(params) {
    const url = prefix + 'events/check';

    return request.post({ url, params });
}

export async function getRechargeAddress(params) {
    const url = prefix + 'events/recharge-address';

    return request.get({ url, params });
}

export async function uploadImage(params) {
    const url = prefix + 'events/upload-image';

    return request.post({ url, params });
}