<template>
  <div>
    <div class="page-title">{{$t('events.eventsManage')}} - {{$t('events.publishCollection')}}</div>

    <el-form ref="form" :model="form" label-position="top" size="small" class="form-con show">
      <div class="lang-tags">
        <div class="lang-title">{{$t('publish.publishLang')}}</div>
        <span
            class="lang-item"
            :class="{on: titleLang === lang.split(':')[0]}"
            @click="titleLang = lang.split(':')[0]"
            v-for="(lang, lIdx) in langs"
            :key="lIdx"
        >{{lang.split(':')[1]}}</span>
      </div>

      <el-form-item
          :label="$t('events.collectionName')"
          prop="title"
          :rules="[{ required: true, message: $t('events.emptyCollectionName')}]"
      >
        <el-input v-model="form.title[titleLang]" :placeholder="$t('events.placeCollectionName')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('events.collectionLogo')" prop="logo" :error="formError.logo">
        <template slot="label">
          <div class="custom-label" style="display: flex;">
            <div><span class="text-red">* </span>{{$t('events.collectionLogo')}}</div>
            <div class="flex-1 mgl-20">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="changeLogoUpload">
                <img v-if="form.logo" :src="form.logo" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="text-info fs-12 mgt-10" v-html="$t('events.tipCollectionLogo')" style="line-height: 1.4em;"></div>
            </div>
          </div>
        </template>
      </el-form-item>

      <el-form-item
          :label="$t('events.collectionBrief')"
          prop="brief"
          :rules="[{ required: true, message: $t('events.emptyCollectionBrief')}]"
      >
        <el-input v-model="form.brief[titleLang]" :placeholder="$t('events.placeCollectionBrief')"></el-input>
      </el-form-item>
      <el-form-item
          :label="$t('events.eventTime')"
          prop="dateRange"
          :rules="[{ required: true, message: $t('events.emptyEventTime')}]"
      >
        <el-input v-model="form.dateRange" disabled :placeholder="$t('events.placeEventTime')">
          <template slot="append"><i class="el-icon-date"></i></template>
        </el-input>
      </el-form-item>
    </el-form>

    <div class="form-btns">
      <el-button size="mini" @click="resetForm()">{{$t('common.cancel')}}</el-button>
      <el-button type="primary" size="mini" @click="submitForm()">{{$t('events.createCollection')}}</el-button>
    </div>
  </div>
</template>

<script>
import * as EventsV2Api from '@/api/eventsV2';

export default {
  data() {
    return {
      langs: ['en_US:EN', 'el_GR:GR'],
      id: null,
      titleLang: 'en_US',
      form: {
        title: {},
        brief: {},
        logo: null,
        eventIds: [],
        startTime: null,
        endTime: null,
        dateRange: null
      },
      formError: {},
    };
  },
  mounted() {
    const { id, ids, startTime, endTime } = this.$route.query;
    if(id){
      this.id = id;
      this.loadInfo();
    } else {
      this.form.eventIds = ids.split(',');
      this.form.startTime = startTime;
      this.form.endTime = endTime;
      this.form.dateRange = this.$func.getDate(startTime) + ' - ' + this.$func.getDate(endTime);
    }
  },
  methods: {
    async loadInfo() {
      const res = await EventsV2Api.collInfo({ id: this.id });
      if(res && res.data){
        const info = res.data;
        this.form.id = info.id;
        this.form.logo = info.logo;
        this.form.title = info.title;
        this.form.brief = info.brief;
        this.form.eventIds = info.event_ids.split(',');
        this.form.startTime = info.start_time;
        this.form.endTime = info.end_time;
        this.form.dateRange = this.$func.getDate(info.start_time) + ' - ' + this.$func.getDate(info.end_time);
      }
    },
    resetForm(){
      this.form = Object.assign({}, this.form, {
        title: {},
        brief: {},
        logo: null,
      });

      this.$refs.form.resetFields();
    },
    /**
     * logo上传
     * @param file
     * @returns {boolean}
     */
    changeLogoUpload(file) {
      const sizeKB = file.size / 1024;
      if (['image/jpeg', 'image/png', 'image/gif'].indexOf(file.raw.type) === -1) {
        this.$message.error(this.$t('events.errorFormat'));
        return false;
      }

      const self = this;
      window.readFile(file.raw, (base64, size) => {
        if(size.width !== 168 || size.height !== 168){
          self.$message.error(this.$t('publish.errorPicSize'));
          return;
        }
        self.$set(this.form, 'logo', base64);
      });
    },
    /**
     * 压缩图片
     * @param image
     * @param file
     * @param radio
     * @returns {string}
     */
    compressImage(image, file, radio){
      const newWidth = file.width * radio;
      const newHeight = file.height * radio;

      const canvas = document.createElement('canvas')
      canvas.width = newWidth;
      canvas.height = newHeight;
      canvas.style.visibility = 'hidden';
      document.body.appendChild(canvas);
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, newWidth, newHeight);
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
      const compressImage = canvas.toDataURL('image/png', 0.8);
      canvas.remove();

      return compressImage;
    },
    /**
     * 校验表单
     * @param callback
     */
    checkForm(callback = null) {
      this.$refs.form.validate((valid) => {
        // logo
        if (!this.form.logo) {
          this.$set(this.formError, 'logo', this.$t('events.emptyLogo'));
          valid = false;
        } else {
          this.$set(this.formError, 'logo', null);
        }
        callback && callback(valid);
      });
    },
    /**
     * 提交
     */
    submitForm(){
      const self = this;
      if(this.form.eventIds.length < 2){
        this.$message.error(this.$t('events.minTwoEvents'));
      }
      this.checkForm(async (valid) => {
        if(valid){
          const postData = Object.assign({}, this.form);
          postData.eventIds = postData.eventIds.join(',');
          const res = await EventsV2Api.publishCollection(postData);
          if(res && res.data && res.data.id){
            this.$message.success(this.$t('events.submitAndWaitCheck'));
            self.$router.replace('/collections');
            self.resetForm();
          } else {
            self.$message.error(res && res.data && res.data.msg ? res.data.msg : this.$t('events.submitFailed'));
          }
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";
.form-con {
  max-width: 500px;
  margin: 0 auto;
}
.lang-tags {
  display: flex;
  align-items: center;
  margin: 20px 0 20px 0;

  .lang-title {
    font-size: 13px;
    font-weight: bold;
  }
  .lang-item {
    background-color: #ddd;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    margin: 0 10px;
    cursor: pointer;
    &.on {
      background-color: @light-color;
      color: #fff;
    }
  }
}
.form-btns {
  position: fixed;
  bottom: 0;
  right: 0;
  left: @left-menu-width;
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.avatar-uploader {
  /deep/.el-upload {
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    .avatar-uploader-icon {
      font-size: 30px;
      color: #d9d9d9;
    }
    .avatar {
      width: 100%;
      height: 100%;
    }
  }
}
.is-error {
  .avatar-uploader {
    /deep/.el-upload {
      border-color: #F56C6C;
    }
  }
}
</style>