<template>
  <div>
    <div class="page-title">{{$t('dapp.projectManage')}} - {{$t('dapp.dappManage')}}</div>

    <router-link to="/dapp-publish" v-if="lists.length > 0">
      <el-button size="small" type="primary">
        <div class="flex">
          <img src="../../assets/imgs/icons/icon-plus.png" height="13" class="mgr-5" />{{$t('dapp.submit')}}
        </div>
      </el-button>
    </router-link>

    <div class="no-app-data" v-if="lists.length === 0">
      <i class="el-icon-warning-outline" style="font-size: 30px;margin-bottom: 10px;margin-top: 180px;"></i>
      <router-link to="/dapp-publish">
        <el-button size="small" type="primary">
          <div class="flex">
            <img src="../../assets/imgs/icons/icon-plus.png" height="13" class="mgr-5" />{{$t('dapp.submit')}}
          </div>
        </el-button>
      </router-link>
    </div>

    <el-table
        :data="lists"
        stripe
        style="width: 100%"
        v-if="lists.length > 0"
    >
      <el-table-column
          prop="name"
          :label="$t('dapp.name')"
          width="240">
        <template slot-scope="scope">
          <div class="flex">
            <div class="flex">
              <img :src="scope.row.logo" style="height: 36px;width: 36px; border-radius: 50%;"/>
            </div>
            <div class="flex-1 mgl-10">
              <div class="bold" style="color:#333;">{{scope.row.name}}</div>
              <div style="line-height: 16px;">{{scope.row.sub_name}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="type"
          :label="$t('dapp.type')"
          width="120">
      </el-table-column>
      <el-table-column
          prop="tags"
          :label="$t('dapp.tags')"
          width="200">
      </el-table-column>
      <el-table-column
          prop="ctime"
          :label="$t('dapp.ctime')"
          width="180">
        <template slot-scope="scope">
          {{ scope.row.ctime.replace('T', ' ').split('.')[0] }}
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          :label="$t('dapp.status')"
          width="120">
        <template slot-scope="scope">
          <span :style="{color: statusValue[scope.row.is_draft === 1 ? -2 : scope.row.status]?.c}">
            {{statusValue[scope.row.is_draft === 1 ? -2 : scope.row.status]?.v}}
          </span>
          <el-tooltip effect="dark" :content="scope.row.ref_msg" placement="top" v-if="scope.row.ref_msg">
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
          :label="$t('dapp.handle')">
        <template slot-scope="scope">
          <router-link :to="'/dapp-publish?id=' + scope.row.id">
            <el-button size="mini" type="primary">{{$t('dapp.view')}}</el-button>
          </router-link>
          <el-button
              size="mini"
              type="danger"
              class="mgl-10"
              @click="deleteApp(scope.row)"
              v-if="scope.row.status != 0 || scope.row.is_draft === 1"
          >{{$t('dapp.del')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import * as DappApi from '@/api/dapp';

export default {
  data() {
    return {
      lists: [],
      total: 0,
      statusValue: {}
    }
  },
  mounted() {
    this.statusValue = {
      '-2': { v: this.$t('dapp.draft'), c: 'cyan' },
      '-1': { v: this.$t('dapp.refused'), c: 'red' },
      0: { v: this.$t('dapp.checking'), c: 'orange' },
      1: { v: this.$t('dapp.published'), c: 'green' }
    };
    this.getAppLists();
  },
  methods: {
    async getAppLists(){
      const res = await DappApi.getLists();
      if(res && res.data && res.data.lists){
        this.lists = res.data.lists.map(row => {
          if(row.app === 1 && row.dapp === 1){
            row.type = 'App&Dapp';
          } else if(row.app === 1) {
            row.type = 'App';
          } else if(row.dapp === 1) {
            row.type = 'Dapp';
          }
          return row;
        });
        this.total = res.data.total;
      }
    },
    deleteApp(item){
      this.$confirm(this.$t('dapp.delTip'), this.$t('dapp.tip'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(async () => {
        const res = await DappApi.delDapp({ id: item.id });
        if(res && res.data && res.data === 'success'){
          this.$message.success(this.$t('dapp.deleteSuccessful'));
          this.getAppLists();
        } else {
          this.$message.error(this.$t('dapp.deleteFailed'));
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>

/deep/.el-table {
  .el-table__body .el-table__cell {
    text-align: left;
  }
}
.no-app-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

</style>