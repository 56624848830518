<template>
  <div v-if="isInited">
    <div class="top-panel">
      <div class="agent-info">
        <div class="flex-1">
          <router-link to="/agent-supply">
            <el-button size="mini" type="primary">{{$t('agent.agentSupply')}}</el-button>
          </router-link>

          <div class="banner" :class="'bg-' + activeAgent.value" v-if="activeAgent">
            <img :src="activeAgent.icon"/>
          </div>
          <div class="grid">
            <div
                class="grid-item"
                :class="{active: item.value === currentAgent}"
                v-for="(item, idx) in agentTypes"
                :key="idx"
                @click="onChangeAgent(item)"
            >
              <img :src="item.icon" class="grid-icon"/>
              <div class="grid-name" :class="{'text-disable': !agentTypesObj[item.value] || !agentTypesObj[item.value].status}">{{item.name}}</div>
            </div>
          </div>
          <div class="stat-grid">
            <div class="grid-item bg-blue">
              <div class="grid-name">{{$t('agent.agentProfit')}}</div>
              <div class="grid-num">{{agentInfo.realizedProfit || 0}}</div>
            </div>
            <div class="grid-item bg-green">
              <div class="grid-name">{{$t('agent.urealizedProfit')}}</div>
              <div class="grid-num">{{agentInfo.urealizedProfit || 0}}</div>
            </div>
          </div>
        </div>
        <div class="flex-1 right-con">
          <div class="data-row mgt-0">
            <div class="label">{{$t('agent.shareRate')}}</div>
            <div class="value">{{agentInfo.shareRate ? (agentInfo.shareRate * 100) + '%' : '-'}}</div>
          </div>
          <div class="data-row">
            <div class="label">{{$t('agent.requirement')}}</div>
            <div class="value flex">
              <div>{{$t('agent.requirementCount')}}{{agentInfo.requirementCount || 0}}人</div>
              <span class="right-mark flex" v-if="agentInfo.realizedCount - agentInfo.requirementCount >= 0">
                <img src="../../assets/imgs/agent/agent-4.png" height="14"/> {{$t('agent.realizedYes')}}
              </span>
            </div>
          </div>
          <div class="data-row">
            <div class="label">{{$t('agent.groupShares')}}<router-link :to="'/agent-shares?type=' + currentAgent + '&shares=' + JSON.stringify(agentInfo.shares)" class="right-btn">设置</router-link></div>
            <div class="value">{{shares.length > 0 ? shares.join('、') : '未设置'}}</div>
          </div>
          <div style="height: 28px;"></div>
          <div class="stat-grid">
            <div class="grid-item bg-pink">
              <div class="grid-name">{{$t('agent.teamCount')}}</div>
              <div class="grid-num">{{agentInfo.teamCount || 0}}</div>
            </div>
            <div class="grid-item bg-cyan">
              <div class="grid-name">{{$t('agent.realizedCount')}}</div>
              <div class="grid-num">{{agentInfo.realizedCount || 0}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="invite-users">
        <div class="flex">
          <span class="section-title flex-1">{{$t('agent.teamUser')}}</span>
          <div>
            <el-input
                :placeholder="$t('agent.searchUserKey')"
                size="small"
                v-model="searchUserKey"
            >
              <i slot="append" class="el-icon-search" @click="loadUsers"></i>
            </el-input>
          </div>
        </div>
        <el-table
            :data="users.lists"
            stripe
            style="width: 100%"
            height="280"
            v-if="users.lists.length > 0"
        >
          <el-table-column
              prop="uid"
              label="UID"
              width="120">
          </el-table-column>
          <el-table-column
              prop="nickname"
              :label="$t('agent.nickname')">
          </el-table-column>
          <el-table-column
              prop="ctime"
              :label="$t('agent.regTime')"
              width="150">
            <template slot-scope="scope">
              {{$func.getDate(scope.row.ctime/1000)}}
            </template>
          </el-table-column>
          <el-table-column
              prop="brokerType"
              :label="$t('agent.brokerType')"
              width="120">
            <template slot-scope="scope">
              <div class="text-right" v-if="agentTypesObj[scope.row.brokerType]">
                <img :src="agentTypesObj[scope.row.brokerType].iconMini" height="20"/>
              </div>
              <div v-else>{{$t('agent.realizedNo')}}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center text-info fs-12" style="padding-top: 100px;" v-if="users.total === 0">{{$t('common.noData')}}</div>
      </div>
    </div>

    <div class="records">
      <div class="flex">
        <span class="section-title flex-1">{{$t('agent.incomeRecords')}}</span>
        <div>
          <el-input
              :placeholder="$t('agent.searchIncomeKey')"
              size="small"
              v-model="searchKey"
          >
            <i slot="append" class="el-icon-search" @click="loadProfits"></i>
          </el-input>
        </div>
      </div>
      <el-table
          :data="profits.lists"
          stripe
          style="width: 100%"
          v-if="profits.lists.length > 0"
      >
        <el-table-column
            prop="dateTime"
            :label="$t('agent.dateTime')"
            width="150">
          <template slot-scope="scope">
            {{$func.getDate(scope.row.dateTime/1000)}}
          </template>
        </el-table-column>
        <el-table-column
            prop="title"
            :label="$t('agent.incomeTitle')"
            width="150">
        </el-table-column>
        <el-table-column
            prop="brokerType"
            :label="$t('agent.brokerType')"
            width="120">
          <template slot-scope="scope">
            <div class="flex" v-if="agentTypesObj[scope.row.brokerType]">
              <img :src="agentTypesObj[scope.row.brokerType].iconMini" height="20" class="mgr-5"/>
              {{agentTypesObj[scope.row.brokerType].name}}
            </div>
            <div v-else>{{scope.row.brokerType}}</div>
          </template>
        </el-table-column>
        <el-table-column
            prop="inviteUid"
            :label="$t('agent.inviteUid')"
            width="120">
        </el-table-column>
        <el-table-column
            prop="brokerReward"
            :label="$t('agent.brokerReward')"
            width="120">
        </el-table-column>
        <el-table-column
            prop="rewards"
            :label="$t('agent.teamReward')">
          <template slot-scope="scope">
            {{ scope.row.rewards?.amount || 0 }}
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            :label="$t('agent.incomeStatus')"
            width="120">
          <template slot-scope="scope">
            {{ scope.row.status == 1 ? $t('agent.receivedYes') : $t('agent.receivedNo') }}
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center text-info fs-12" style="padding: 100px 0;" v-if="profits.total === 0">{{$t('common.noData')}}</div>
      <div class="w-100 text-center">
        <el-pagination
            small
            layout="prev, pager, next"
            :page-size="20"
            :total="profits.total">
        </el-pagination>
      </div>
    </div>
  </div>
  <div v-else>
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
import * as AgentApi from "@/api/agent";

export default {
  data(){
    return {
      isInited: false,
      agentTypes: [],
      agentTypesObj: {},
      currentAgent: 'activity',
      activeAgent: null,
      agentStatus: [],
      agentInfo: {},
      shares: [],
      users: {
        total: 0,
        lists: []
      },
      searchUserKey: null,
      profits: {
        total: 0,
        lists: []
      },
      searchKey: null
    }
  },
  mounted() {
    this.agentTypes = [{
      name: this.$t('agent.adAgent'),
      value: 'ad',
      icon: require('../../assets/imgs/agent/agent-1.png'),
      iconMini: require('../../assets/imgs/agent/agent-7.png')
    }, {
      name: this.$t('agent.activityAgent'),
      value: 'activity',
      icon: require('../../assets/imgs/agent/agent-2.png'),
      iconMini: require('../../assets/imgs/agent/agent-5.png')
    }, {
      name: this.$t('agent.appAgent'),
      value: 'app',
      icon: require('../../assets/imgs/agent/agent-3.png'),
      iconMini: require('../../assets/imgs/agent/agent-6.png')
    }];

    this.activeAgent = this.agentTypes[1];
    this.initData();
    this.loadInfo(this.currentAgent);
    this.loadUsers();
    this.loadProfits();
  },
  methods: {
    async initData(){
      this.agentTypes.map(r => {
        r.status = 0;
        this.agentTypesObj[r.value] = r;
      });

      // 状态
      let idShowIndex = false;
      const res = await AgentApi.getStatus();
      if(res && res.data && res.data.apply){
        this.agentStatus = res.data.apply;
        this.agentStatus.map(r => {
          if(r.status === 1 && this.agentTypesObj[r.brokerType]) {
            this.agentTypesObj[r.brokerType].status = 1;
            idShowIndex = true;
          }
        });
      }
      if(!idShowIndex){
        this.$router.replace('/agent-supply');
      }
      this.isInited = true;
    },
    onChangeAgent(row){
      if(!this.agentTypesObj[row.value] || !this.agentTypesObj[row.value].status){
        return;
      }
      this.currentAgent = row.value;
      this.activeAgent = row;
      this.loadInfo(this.currentAgent);
    },
    /**
     * 详情
     * @param type
     * @returns {Promise<void>}
     */
    async loadInfo(type = 'activity'){
      const res = await AgentApi.getInfo({ type });
      if(res && res.data){
        this.shares = [];
        for(let i = 1; i <= 9; i++){
          const k = 'level' + i;
          if(!res.data.shares[k]){
            continue;
          }
          if(parseFloat(res.data.shares[k]) === 0){
            delete res.data.shares[k];
          } else {
            const v = parseFloat(parseFloat(res.data.shares[k] * 100).toFixed(2));
            this.shares.push(v + '%');
          }
        }
        this.agentInfo = res.data;
      }
    },
    /**
     * 团队用户
     * @returns {Promise<void>}
     */
    async loadUsers(){
      const params = {page: 1, size: 20};
      if(this.searchUserKey){
        params.name = this.searchUserKey;
      }
      const res = await AgentApi.getUsers(params);
      if(res && res.data){
        this.users = res.data;
      }
    },
    /**
     * 收益记录
     * @returns {Promise<void>}
     */
    async loadProfits(){
      const params = { page: 1, size: 20 };
      if(this.searchKey){
        params.nickname = this.searchKey;
      }
      const res = await AgentApi.getProfits(params);
      if(res && res.data){
        this.profits = res.data;
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.bg-grey {
  background-color: #F9FAFE;
}
.top-panel {
  display: flex;
}
.agent-info {
  min-width: 500px;
  width: 45%;
  box-shadow: 0 0 2px #ccc;
  padding: 15px;
  display: flex;
  border-radius: 8px;

  .banner {
    height: 100px;
    width: 100%;
    background-color: #ccc;
    margin-top: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 60px;
    }
    &.bg-activity {
      background: linear-gradient(to right, #1F1F1F 10%, rgba(31, 31, 31, .6) 50%, #1F1F1F 90%);
    }
    &.bg-app {
      background: linear-gradient(to right, #709EE5 10%, rgba(112, 158, 229, .6) 50%, #709EE5 90%);
    }
    &.bg-ad {
      background: linear-gradient(to right, #ADB4FE 10%, rgba(173, 180, 254, .6) 50%, #ADB4FE 90%);
    }
  }
  .grid {
    display: flex;
    margin-top: 10px;

    .grid-item {
      text-align: center;
      flex: 1;
      margin-bottom: 10px;
      cursor: pointer;
      &:first-child, &:last-child {
        flex: unset;
      }
      .grid-icon {
        width: auto;
        height: 40px;
      }
      .grid-name {
        font-size: 13px;
      }
      &.active {
        .grid-name {
          color: @light-color;
        }
      }
    }
  }
  .stat-grid {
    display: flex;
    margin: 20px -5px 0 -5px;
    .grid-item {
      flex: 1;
      margin: 0 5px;
      background-color: #eee;
      text-align: center;
      padding: 10px 8px;
      border-radius: 8px;
      .grid-name {
        font-size: 13px;
      }
      .grid-num {
        font-size: 14px;
        margin-top: 6px;
        font-weight: bold;
      }
      &.bg-blue {
        background: linear-gradient(to right, #EFFCFF 10%, rgba(239, 252, 255, .6) 50%, #EFFCFF 90%);
      }
      &.bg-green {
        background: linear-gradient(to right, #E8FAEB 10%, rgba(232, 250, 235, .6) 50%, #E8FAEB 90%);
      }
      &.bg-pink {
        background: linear-gradient(to right, #EDEFFF 10%, rgba(246, 247, 252, .6) 50%, #EDEFFF 90%);
      }
      &.bg-cyan {
        background: linear-gradient(to right, #E2F2FD 10%, rgba(226, 242, 253, .6) 50%, #E2F2FD 90%);
      }
    }
  }

  .right-con {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #eee;
  }
  .data-row {
    margin-top: 25px;
    .label {
      color: @sub-font-color;
      font-size: 13px;
      .right-btn {
        margin-left: 10px;
        background-color: @light-color;
        color: #fff;
        font-size: 12px;
        padding: 3px 8px;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .value {
      color: #000;
      font-size: 15px;
      margin-top: 8px;
      .right-mark {
        color: @green;
        margin-left: 10px;
      }
    }
  }
}

.invite-users {
  box-shadow: 0 0 2px #ccc;
  padding: 15px 15px 0 15px;
  margin-left: 20px;
  border-radius: 8px;
  flex: 1;
}

.records {
  box-shadow: 0 0 2px #ccc;
  padding: 15px 15px 0 15px;
  border-radius: 8px;
  margin-top: 20px;
  height: calc(100vh - 50px - 330px - 75px);
  .section-title {
    font-size: 15px;
  }
}

/deep/.el-table {
  .el-table__body .el-table__cell {
    text-align: left;
    &:last-child {
      text-align: right;
    }
  }
  .el-table__header-wrapper .el-table__cell {
    &:nth-last-child(2) {
      text-align: right;
    }
  }
}
</style>