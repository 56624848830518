<template>
  <div class="container">
    <!-- header -->
    <div class="header-con">
      <div class="flex content-con">
        <div class="logo">
          <router-link to="/"><img src="../../assets/imgs/pc/22.png"/></router-link>
        </div>
        <div class="flex-1"></div>
        <div class="right-con">
          <div class="icon-btn">
            <img src="../../assets/imgs/pc/11.png"/>
          </div>
          <div class="icon-btn">
            <img src="../../assets/imgs/pc/12.png"/>
          </div>
        </div>
      </div>
    </div>

    <div class="content-con">
      <div class="title">
        ParaPack开发者入驻规则及协议
      </div>
      <div class="agreement-content scroll-con">
        1.定义<br/>
        1.1.ParaPack:指由公司开发和维护的加密货币钱包应用程序，包括但不限于“ParaPack“钱包。<br/>
        1.2.开发者:指希望入驻ParaPack并在其平台上开发或部署应用程序、智能合约或其他相关内容的个人或实体。<br/>
        2.入驻申请<br/>
        2.1.条件:开发者须满足公司规定的入驻条件，并提交相关材料和信息<br/>
        2.2.审批:公司将酌情批准或拒绝开发者的入驻申请。公司有权自行决定批准或拒绝任何入驻申请无需提供理由。<br/>
        3.开发者注入<br/>
        3.1.条款:一旦入驻申请被批准，开发者可以向ParaPack钱包注入其开发的应用程序或内容<br/>
        3.2.安全性:开发者必须确保其应用程序或内容不包含恶意代码、病毒、木马或其他可能对ParaPack钱包用户造成损害的内容<br/>
        3.3.更新和维护:开发者有责任及时更新其应用程序或内容，以确保其与ParaPack钱包的最新版本兼容，并解决可能的安全漏洞或问题<br/>
        4.安全性和合规性<br/>
        4.1.法律遵从:开发者必须遵守所有适用的法律法规，并确保其应用程序或内容不涉及非法活动、侵犯知识产权或其他违法行为。<br/>
        4.2.安全审查:公司有权随时对开发者的应用程序或内容进行安全审查，以确保其不对ParaPack钱包的安全性产生不利影响<br/>
        5.免责声明<br/>
        公司对于开发者注入的应用程序或内容不承担任何责任，包括但不限于直接或间接的损失或损害。开发者应自行承担其应用程序或内容可能带来的风险。<br/>
        6.终止<br/>
        公司有权随时终止开发者的入驻资格，无需提前通知，如果开发者违反了本协议的任何规定或公司认为其应用程序或内容对ParaPack钱包或其用户构成威胁<br/>
        7.修改<br/>
        公司有权随时修改本协议的内容，修改后的协议将在公司网站或ParaPack钱包内发布。开发者应定期查看协议变更<br/>
        8.适用法律<br/>
        本协议受[您所在地区]法律管辖，并应按其解释和执行<br/>
        9.生效<br/>
        本协议自[日期]起生效，自开发者首次入驻ParaPack钱包起生效<br/>
      </div>
      <div class="join-handle">
        <el-button type="primary" size="medium" style="width: 300px;" @click="submit">申请入驻</el-button>
        <el-checkbox v-model="isCheck" class="check">我已阅读并同意以上入驻协议</el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import * as AccountApi from '@/api/account';

export default {
  data(){
    return {
      isCheck: false
    }
  },
  methods: {
    submit(){
      if(!this.isCheck){
        this.$message.error('请阅读并同意入驻协议!');
        return;
      }
      AccountApi.joinCheck().then(res => {
        let userInfo = localStorage.getItem('USERINFO');
        userInfo = userInfo ? JSON.parse(userInfo) : null;

        if(res && res.data === 'success'){
          this.$message.success('申请成功!');

          // 更新
          if(userInfo){
            userInfo.user_type = 1;
            localStorage.setItem('USERINFO', JSON.stringify(userInfo));
            this.$eventBus.$emit('LOGIN-SUCCESS', userInfo);
            this.$eventBus.$emit('LOAD_INIT_DATA', false);
            this.$router.replace('/basic-info');
          }
        } else {
          this.$message.error('申请失败!');
          if(!userInfo || !userInfo.token){
            localStorage.removeItem('USERINFO');
            this.$router.replace('/basic-info');
          }
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.header-con {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  border-bottom: 1px solid #eee;
  .logo {
    font-size: 16px;
    font-weight: bold;
    img {
      height: 24px;
    }
  }
  .right-con {
    display: flex;
    align-items: center;
  }
  .icon-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 20px;
    img {
      height: 30px;
      width: 30px;
    }
  }
}
.content-con {
  max-width: 1920px;
  width: 90%;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
}
.agreement-content {
  font-size: 14px;
  line-height: 2.4em;
  width: 1000px;
  margin: 30px auto 30px auto;
  height: calc(100vh - 50px - 240px);
}
.join-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .check {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>