import request from '@/utils/request.js';

const prefix = 'https://eapi.parapack.net/';

export async function collInfo(params) {
    const url = prefix + 'v2/coll/info';

    return request.get({ url, params });
}

export async function publishCollection(params) {
    const url = prefix + 'v2/collection/publish';

    return request.post({ url, params });
}

export async function removeCollection(params) {
    const url = prefix + 'v2/collection/remove';

    return request.post({ url, params });
}

export async function removeCollEndEvents(params) {
    const url = prefix + 'v2/collection/removeEndEvents';

    return request.post({ url, params });
}

export async function delCollection(params) {
    const url = prefix + 'v2/collection/del';

    return request.post({ url, params });
}