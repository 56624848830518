<template>
  <div class="container">
    <div class="top-menus">
      <div class="menu-item" :class="{on: showSection === 1}" @click="showSection=1">
        <span v-if="showSection <= 1">1</span>
        <i class="el-icon-check" v-else-if="showSection > 1"></i>
        <b :class="{green:showSection > 1}">{{$t('dapp.basicInfo')}}</b>
      </div>
      <div class="h-line"></div>
      <div class="menu-item" :class="{on: showSection === 2}" @click="nextStep('other', 2)">
        <span v-if="showSection <= 2">2</span>
        <i class="el-icon-check" v-else-if="showSection > 2"></i>
        <b :class="{green:showSection > 2}">{{$t('dapp.otherInfo')}}</b>
      </div>
    </div>

    <el-form ref="form" :model="form" label-position="top" size="small" class="form-con" :class="{show: showSection === 1}">
      <el-form-item :label="$t('dapp.logo')" prop="logo" :error="formError.logo">
        <template slot="label">
          <div class="custom-label" style="display: flex;">
            <div><span class="text-red">* </span>{{$t('dapp.logo')}}</div>
            <div class="flex-1 mgl-20">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="changeLogoUpload">
                <img v-if="form.logo" :src="form.logo" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="text-info fs-12 mgt-10" style="line-height: 1.4em;" v-html="$t('dapp.logoTip')"></div>
            </div>
          </div>
        </template>
      </el-form-item>

      <el-form-item :label="$t('dapp.appName')" prop="name" :rules="[{ required: true, message: $t('dapp.errorAppName')}]">
        <el-input v-model="form.name" :placeholder="$t('dapp.placeAppName')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dapp.subName')" prop="sub_name" :rules="[{ required: true, message: $t('dapp.errorSubName')}]">
        <el-input v-model="form.sub_name" :placeholder="$t('dapp.placeSubName')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dapp.desc')" prop="desc" :error="formError.desc" v-if="form.desc">
        <template slot="label">
          <div class="flex custom-label">
            <div class="flex-1">{{$t('dapp.desc')}}</div>
            <span class="text-grey"><span class="text-red">* </span>{{$t('dapp.defLang')}}:English</span>
          </div>
        </template>
        <el-input type="textarea" rows="5" :placeholder="$t('dapp.placeDesc')" v-model="form.desc[idx]" v-for="(item, idx) in languageObj" :key="idx" v-if="idx === descLang"></el-input>
        <div class="mgt-5 text-right">
          <el-tag
              :type="idx === descLang ? 'primary' : 'info'"
              size="small"
              class="mgl-10 pointer"
              v-for="(item, idx) in languageObj"
              :key="idx"
              @click="descLang = idx"
          >{{item}}</el-tag>
        </div>
      </el-form-item>
      <el-form-item :label="$t('dapp.appType')" prop="type" :rules="[{ required: true, message: $t('dapp.errorAppType')}]">
        <el-select v-model="form.type" :placeholder="$t('dapp.errorAppType')" :no-data-text="$t('common.noData')" style="width:100%;">
          <el-option label="App" value="App"></el-option>
          <el-option label="Dapp" value="Dapp"></el-option>
          <el-option label="App&Dapp" value="App&Dapp"></el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="应用分类" prop="cates" :rules="[{ required: true }]" :error="formError.cates" v-if="false">-->
<!--        <el-select v-model="form.cates" multiple placeholder="请选择应用类型" style="width:100%;">-->
<!--          <el-option :label="item.label" :value="item.key" v-for="(item, idx) in cates" :key="idx"></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="默认主链" prop="chain" :rules="[{ required: true, message: '请选择应用默认主链'}]" v-if="form.type === 'Dapp' || form.type === 'App&Dapp'">-->
<!--        <el-select v-model="form.chain" placeholder="请选择应用默认主链" style="width:100%;">-->
<!--          <el-option :label="item.chainName" :value="item.chainName" v-for="(item, idx) in chains" :key="idx">-->
<!--            <div class="flex">-->
<!--              <img :src="item.logo" style="height: 16px;border-radius: 50%;margin-right: 10px;" />-->
<!--              <span class="bold flex-1">{{ item.chainName }}</span>-->
<!--              <span class="text-info">{{ item.chainFullName }}</span>-->
<!--            </div>-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item :label="$t('dapp.appChains')" prop="chains" :rules="[{ required: true, message: $t('dapp.errorAppChains')}]">
        <el-select v-model="form.chains" multiple :placeholder="$t('dapp.placeAppChains')" style="width:100%;">
          <el-option :label="item.chainName" :value="item.chainName" v-for="(item, idx) in chains" :key="idx">
            <div class="flex">
              <img :src="item.logo" style="height: 16px;border-radius: 50%;margin-right: 10px;" />
              <span class="bold flex-1">{{ item.chainName }}</span>
              <span class="text-info">{{ item.chainFullName }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('dapp.appTags')" prop="tags" :rules="[{ required: true }]" :error="formError.tags">
        <el-select v-model="form.tags" multiple :placeholder="$t('dapp.placeAppTags')" style="width:100%;">
          <el-option :label="item.label" :value="item.key" v-for="(item, idx) in tags" :key="idx"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Dapp URL" :rules="[{ required: true }]" :error="formError.dapp_url" v-if="form.type === 'Dapp' || form.type === 'App&Dapp'">
        <el-input v-model="form.dapp_url" :placeholder="$t('dapp.placeDappUrl')"></el-input>
      </el-form-item>
      <el-form-item label="App URL" prop="app_url" :rules="[{ required: true }]" :error="formError.app_url" v-if="form.type === 'App' || form.type === 'App&Dapp'">
        <el-input v-model="form.app_url" :placeholder="$t('dapp.placeAppUrl')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dapp.checkStatus')" prop="check_status" :error="formError.check_status">
        <template slot="label">
          <span class="text-red">* </span>{{ $t('dapp.checkStatus') }}
        </template>
        <div class="flex" style="margin-bottom: 10px;">
          <el-select v-model="form.check_status" :placeholder="$t('dapp.pleaseSelect')" class="flex-1" style="min-width: 150px;">
            <el-option :label="$t('dapp.checkStatusYes')" value="1"></el-option>
            <el-option :label="$t('dapp.checkStatusNo')" value="0"></el-option>
          </el-select>
          <el-input v-model="form.check_url" class="mgl-10" :placeholder="$t('dapp.placeCheckUrl')" v-if="form.check_status == 1"></el-input>
        </div>
      </el-form-item>
      <el-form-item :label="$t('dapp.telegramUrl')" prop="contact_telegram" :rules="[{ required: true, message: $t('dapp.errorTelegramUrl')}]">
        <el-input v-model="form.contact_telegram"></el-input>
      </el-form-item>
    </el-form>

    <!-- other -->
    <el-form ref="formOther" :model="formOther" label-position="left" label-width="80px" size="small" class="form-con" :class="{show: showSection === 2}">
      <div class="bold fs-15" style="padding-bottom: 25px;">{{$t('dapp.otherFormData')}}</div>
      <el-form-item :label="$t('dapp.siteUrl')" prop="site">
        <el-input v-model="formOther.site" :placeholder="$t('dapp.placeSiteUrl')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dapp.whiteBook')" prop="white_book">
        <el-input v-model="formOther.white_book" :placeholder="$t('dapp.placeWhiteBook')"></el-input>
      </el-form-item>
      <el-form-item label="X(Twitter)" prop="twitter">
        <el-input v-model="formOther.twitter" :placeholder="$t('dapp.placeTwitter')"></el-input>
      </el-form-item>
      <el-form-item label="Telegram" prop="telegram">
        <el-input v-model="formOther.telegram" :placeholder="$t('dapp.placeTg')"></el-input>
      </el-form-item>
      <el-form-item label="Discord" prop="discord">
        <el-input v-model="formOther.discord" :placeholder="$t('dapp.placeDiscord')"></el-input>
      </el-form-item>
      <el-form-item label="Facebook" prop="facebook">
        <el-input v-model="formOther.facebook" :placeholder="$t('dapp.placeFacebook')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dapp.ppLink')" prop="pp_link">
        <el-select v-model="formOther.pp_link" :placeholder="$t('dapp.placePPLink')" style="width:100%;">
          <el-option :label="$t('dapp.ppLinkOptions.v1')" value="0"></el-option>
          <el-option :label="$t('dapp.ppLinkOptions.v2')" value="1"></el-option>
          <el-option :label="$t('dapp.ppLinkOptions.v3')" value="2"></el-option>
          <el-option :label="$t('dapp.ppLinkOptions.v4')" value="1,2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('dapp.tokenAddress')" prop="token_address">
        <el-input v-model="formOther.token_address" :placeholder="$t('dapp.placeTokenAddress')"></el-input>
        <div class="text-right fs-12 text-info" style="margin-top: -10px;">{{$t('dapp.tipTokenAddress')}}</div>
      </el-form-item>
      <el-form-item :label="$t('dapp.tokenAddress2')" prop="token_address2">
        <el-input v-model="formOther.token_address2" :placeholder="$t('dapp.placeTokenAddress')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dapp.email')" prop="email">
        <el-input v-model="formOther.email" :placeholder="$t('dapp.placeEmail')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dapp.contact')" prop="contact">
        <el-input v-model="formOther.contact" :placeholder="$t('dapp.placeContact')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dapp.brief')" prop="brief">
        <el-input type="textarea" rows="5" v-model="formOther.brief" :placeholder="$t('dapp.placeBrief')"></el-input>
      </el-form-item>
    </el-form>

    <div class="form-btns">
      <el-button size="mini" @click="saveDraft()">{{$t('dapp.saveDraft')}}</el-button>
      <el-button size="mini" @click="resetForm()">{{$t('dapp.resetForm')}}</el-button>
      <el-button type="primary" size="mini" @click="nextStep('other', 2)" v-if="showSection===1">{{$t('dapp.nextStep')}}</el-button>
      <el-button type="primary" size="mini" @click="submitForm()" v-if="showSection===2">{{$t('dapp.submitForm')}}</el-button>
    </div>
  </div>
</template>

<script>
import * as DappApi from '@/api/dapp';
import * as EventApi from '@/api/events';

export default {
  data(){
    return {
      showSection: 1,
      languageObj: {
        en_US: 'English',
        zh_CN: '简体中文',
        el_GR: '繁体中文'
      },
      cates: [],
      tags: [],
      chains: [],
      coins: [],
      descLang: 'en_US',
      form: {},
      formOther: {},
      formError: {},
      id: null
    }
  },
  mounted() {
    this.resetForm();
    // init data
    this.getCatesAndTags();
    this.getSupport();
    // load draft
    const form = localStorage.getItem('APP_FORM');
    const formOther = localStorage.getItem('APP_OTHER_FORM');
    form && (this.form = JSON.parse(form));
    formOther && (this.formOther = JSON.parse(formOther));
    // info
    const { id } = this.$route.query;
    if(id){
      this.id = id;
      this.loadInfo(id);
    }
  },
  methods: {
    getCatesAndTags() {
      DappApi.getTags().then(res => {
        this.cates = res.data.cates;
        this.tags = res.data.tags;
      });
    },
    getSupport() {
      EventApi.getSupport().then(res => {
        this.chains = res.data.chains;
        this.coins = res.data.coins;
      });
    },
    /**
     * 详情
     * @param id
     */
    loadInfo(id) {
      DappApi.getInfo({ id }).then(res => {
        if(res && res.data && res.data.id){
          this.formOther = res.data.other;
          delete res.data.other;
          res.data.check_status = res.data.check_status + '';
          if(res.data.dapp == 1 && res.data.app == 1){
            res.data.type = 'App&Dapp';
          } else if(res.data.dapp == 1) {
            res.data.type = 'Dapp';
          } else if(res.data.app == 1) {
            res.data.type = 'App';
          }
          this.form = res.data;
        }
      });
    },
    /**
     * 下一步
     * @param name
     * @param idx
     */
    nextStep(name, idx){
      this.checkForm((valid) => {
        if(valid){
          this.showSection = idx;
        } else {
          this.showSection = idx - 1;
        }
      });
    },
    /**
     * logo上传
     * @param file
     * @returns {boolean}
     */
    changeLogoUpload(file) {
      const sizeKB = file.size / 1024;
      if (['image/jpeg', 'image/png', 'image/gif'].indexOf(file.raw.type) === -1) {
        this.$message.error(this.$t('events.errorFormat'));
        return false;
      }
      if (sizeKB > 300) {
        this.$message.error(this.$t('events.max300kb'));
        return false;
      }
      this.$set(this.form, 'logo', URL.createObjectURL(file.raw));
      // compress
      const self = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64 = e.target.result;
        this.$set(this.form, 'logo', base64);
        const image = new Image();
        image.src = base64;
        image.onload = () => {
          const width = image.width;
          const height = image.height;
          if (width > 500 || height > 500) {
            base64 = self.compressImage(image, {width, height}, 500 / Math.max(width, height));
            this.$set(this.form, 'logo', base64);
          }
        }
      }
      reader.readAsDataURL(file.raw);

      return false;
    },
    /**
     * 压缩图片
     * @param image
     * @param file
     * @param radio
     * @returns {string}
     */
    compressImage(image, file, radio){
      const newWidth = file.width * radio;
      const newHeight = file.height * radio;

      const canvas = document.createElement('canvas')
      canvas.width = newWidth;
      canvas.height = newHeight;
      canvas.style.visibility = 'hidden';
      document.body.appendChild(canvas);
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, newWidth, newHeight);
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
      const compressImage = canvas.toDataURL('image/png', 0.8);
      canvas.remove();

      return compressImage;
    },
    /**
     * 校验表单
     * @param callback
     */
    checkForm(callback = null){
      this.$refs.form.validate((valid) => {
        // logo
        if(!this.form.logo){
          this.$set(this.formError, 'logo', this.$t('dapp.errorLogo'));
          valid = false;
        } else {
          this.$set(this.formError, 'logo', null);
        }
        // desc
        if(!this.form.desc['en_US']){
          this.$set(this.formError, 'desc', this.$t('dapp.errorDesc'));
          valid = false;
        } else {
          this.$set(this.formError, 'desc', null);
        }
        // type
        if((this.form.type === 'App' || this.form.type === 'App&Dapp') && !this.form.app_url){
          this.$set(this.formError, 'app_url', this.$t('dapp.errorAppUrl'));
          valid = false;
        } else {
          this.$set(this.formError, 'app_url', null);
        }
        if((this.form.type === 'Dapp' || this.form.type === 'App&Dapp') && !this.form.dapp_url){
          this.$set(this.formError, 'dapp_url', this.$t('dapp.errorDappUrl'));
          valid = false;
        } else {
          this.$set(this.formError, 'dapp_url', null);
        }
        // cates
        /*if(!this.form.cates || this.form.cates.length === 0){
          this.$set(this.formError, 'cates', '请选择应用分类');
          valid = false;
        } else if(this.form.cates.length > 2) {
          this.$set(this.formError, 'cates', '最多支持2个分类');
          valid = false;
        } else {
          this.$set(this.formError, 'cates', null);
        }*/
        // tags
        if(!this.form.tags || this.form.tags.length === 0){
          this.$set(this.formError, 'tags', this.$t('dapp.errorAppTags'));
          valid = false;
        } else if(this.form.tags.length > 5) {
          this.$set(this.formError, 'tags', this.$t('dapp.errorAppTags2'));
          valid = false;
        } else {
          this.$set(this.formError, 'tags', null);
        }
        // 审计状态
        if(this.form.check_status != 1 && this.form.check_status != 0) {
          this.$set(this.formError, 'check_status', this.$t('dapp.errorCheckStatus'));
          valid = false;
        } else if(this.form.check_status == 1 && !this.form.check_url){
          this.$set(this.formError, 'check_status', this.$t('dapp.errorCheckUrl'));
          valid = false;
        } else {
          this.$set(this.formError, 'check_status', null);
        }

        callback && callback(valid);
      });
    },
    resetForm(){
      this.form = {
        desc: {},
        cates: [],
        tags: [],
        chains: []
      }
      this.formOther = {};

      this.$refs.form.resetFields();
      this.$refs.formOther.resetFields();
    },
    /**
     * 保存草稿
     */
    async saveDraft(){
      // localStorage.setItem('APP_FORM', JSON.stringify(this.form));
      // localStorage.setItem('APP_OTHER_FORM', JSON.stringify(this.formOther));

      const postData = Object.assign({}, this.form, { other: this.formOther });
      const res = await DappApi.saveDraft(postData);
      if(res && res.data && res.data.id){
        this.$message.success(this.$t('common.saveSuccessful'));
      } else {
        this.$message.error(res && res.data && res.data.msg ? res.data.msg : this.$t('common.saveFailed'));
      }
    },
    /**
     * 提交
     */
    submitForm(){
      const self = this;
      this.checkForm(async (valid) => {
        if(valid){
          const postData = Object.assign({}, this.form, { other: this.formOther });
          const res = await DappApi.publishDapp(postData);
          if(res && res.data && res.data.id){
            this.$message.success(this.$t('events.submitAndWaitCheck'));
            localStorage.removeItem('APP_FORM');
            localStorage.removeItem('APP_OTHER_FORM');
            self.$router.replace('/dapps');
            self.resetForm();
          } else {
            self.$message.error(res && res.data && res.data.msg ? res.data.msg : this.$t('events.submitFailed'));
          }
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.container {
  position: relative;
}

@pd: calc((100vw - @left-menu-width - 6px - 500px)/2);
.top-menus {
  height: 80px;
  padding-top: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: calc(100vw - @left-menu-width - 6px);
  left: calc(@left-menu-width + (100vw - @left-menu-width)/2);
  position: fixed;
  top: 50px;
  z-index: 10;
  margin-left: -150px;

  .h-line {
    flex: 1;
    height: 1px;
    border-top: 1px dashed #ccc;
    display: none;
  }
  .menu-item {
    font-size: 15px;
    cursor: pointer;
    color: #aaa;
    position: relative;
    width: 150px;
    height: 35px;
    padding: 0 10px;
    background-color: #fff;
    border: 1px solid @border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    &.on {
      color: @light-color;
      border-bottom: 2px solid @light-color;
      span {
        background-color: @light-color;
        color: #fff;
      }
    }
    i {
      background-color: @green;
      border-radius: 50%;
      color: #fff;
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
    span {
      background-color: #eee;
      color: #aaa;
      border-radius: 50%;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      font-size: 13px;
    }
    b {
      &.green {
        color: @green;
      }
    }
  }
}

.form-con {
  margin: 80px auto 50px auto;
  width: 600px;
  position: fixed;
  top: -5000px;
  &.show {
    position: unset;
    top: unset;
  }
  .custom-label {
    width: 600px;
  }
  /deep/.el-form-item__error {
    width: 100%;
    text-align: right;
  }
}
.avatar-uploader {
  /deep/.el-upload {
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    .avatar-uploader-icon {
      font-size: 30px;
      color: #d9d9d9;
    }
    .avatar {
      width: 100%;
      height: 100%;
    }
  }
}
.is-error {
  .avatar-uploader {
    /deep/.el-upload {
      border-color: #F56C6C;
    }
  }
}
.form-btns {
  position: fixed;
  bottom: 0;
  right: 0;
  left: @left-menu-width;
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
</style>