<template>
  <div class="container">
    <div class="top-bg">
      <pc-header></pc-header>

      <section class="content-center">
        <div class="big-title mgt-100">
          {{$t('web.activity.ppCompagin')}}
        </div>
        <div class="main-title">
          {{$t('web.activity.ppCompaginSubTitle')}}
        </div>
        <div class="flex">
          <a href="https://www.parapack.net/#/download" target="_blank"><div class="btn-big mgt-50">{{$t('web.activity.joinActivity')}}</div></a>
          <div style="width: 50px;"></div>
          <a href="https://business.parapack.net/#/basic-info" target="_blank"><div class="btn-big mgt-50">{{$t('web.activity.publishActivity')}}</div></a>
        </div>
      </section>

      <section class="stat-section">
        <div class="number-row">
          <div class="number">
            <p><img src="../assets/imgs/index/icon-s-1.png" class="icon"/>{{$t('web.activity.regUsers')}}</p>
            <h2>{{consoleData.activity.userCount || 0}}</h2>
          </div>
          <div class="flex-1"></div>
          <div class="number">
            <p><img src="../assets/imgs/index/icon-s-2.png" class="icon"/>{{$t('web.activity.publishCount')}}</p>
            <h2>{{consoleData.activity.activitiesCount || 0}}</h2>
          </div>
          <div class="flex-1"></div>
          <div class="number">
            <p><img src="../assets/imgs/index/icon-s-3.png" class="icon"/>{{$t('web.activity.rewardsAmount')}}({{consoleData.activity.rewardQuote}})</p>
            <h2>{{consoleData.activity.reward ? $func.friendNum(consoleData.activity.reward) : 0}}</h2>
          </div>
        </div>
      </section>
    </div>

    <div class="bg-2">
      <section class="content-center con-w">
        <div class="main-title mgt-100">
          {{$t('web.activity.title2')}}
        </div>
        <div class="brief mgt-16">
          {{$t('web.activity.subTitle2')}}
        </div>
        <div class="poster-pic">
          <img src="../assets/imgs/activity/pic-a-2.png" class="poster mgt-30"/>
        </div>
      </section>
    </div>

    <section class="content-center con-w">
      <div class="main-title mgt-100">
        {{$t('web.activity.title3')}}
      </div>
      <div class="brief mgt-16">
        {{$t('web.activity.subTitle3')}}
      </div>
      <div class="poster-pic pic-a-3">
        <el-image src="../assets/imgs/activity/pic-a-3.png" class="poster w-auto"></el-image>
      </div>
    </section>

    <div class="section-4 bg-3">
      <section class="flex con-w">
        <div class="left-con flex-1">
          <div class="main-title mgt-100 mgt-reset">
            {{$t('web.activity.title4')}}
          </div>
          <div class="brief mgt-16">
            {{$t('web.activity.subTitle4')}}
          </div>
          <div class="icons">
            <img src="../assets/imgs/activity/icon-a-19.png"/>
            <img src="../assets/imgs/activity/icon-a-20.png"/>
            <img src="../assets/imgs/activity/icon-a-21.png" />
          </div>
        </div>
        <div class="right-con">
          <img src="../assets/imgs/activity/pic-a-4.png" class="pic-a-4"/>
        </div>
      </section>
    </div>

    <section class="section-5 content-right con-w pdt-50">
      <div class="fixed-con">
        <div class="main-title">{{$t('web.activity.title5')}}</div>
        <div class="brief">{{$t('web.activity.subTitle5')}}</div>
      </div>
      <div class="poster-pic text-right">
        <img src="../assets/imgs/activity/pic-a-5.png" class="poster pic-a-5"/>
      </div>
    </section>

    <div class="mgt-100"></div>

    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "@/components/Header";
import PcFooter from "@/components/Footer";
import * as WebApi from "@/api/web";
import {friendNum} from "@/utils/func";

export default {
  components: {
    PcHeader,
    PcFooter
  },
  data(){
    return {
      consoleData: {
        activity: {}
      }
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    friendNum,
    async initData(){
      const res = await WebApi.getConsoleData();
      const targetRes = JSON.parse(JSON.stringify(res.data));
      for(let i = 1; i <= 10; i++){
        for(let k in res.data){
          if(['activity', 'homePage', 'wallet'].indexOf(k) === -1) continue;
          for(let kk in res.data[k]){
            const v = parseFloat(targetRes[k][kk]);
            if(!/^\d+(\.\d+)?$/.test(v)) continue;
            res.data[k][kk] = parseInt(v * (i/10));
          }
        }

        this.$set(this, 'consoleData', res.data);
        await this.$func.sleep(100);
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";
@import "../assets/css/pc.less";

a {
  text-decoration: none;
}

.container {
  .top-bg {
    background-image: url("../assets/imgs/activity/bg-a-1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bg-2 {
    background-image: url("../assets/imgs/activity/bg-a-2.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom center;
    padding-bottom: 80px;
  }
  .bg-3 {
    background-image: url("../assets/imgs/activity/bg-a-3.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 80px 0;
  }
}

.btn-big {
  height: 50px;
  background-color: @light-color;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  border-radius: 25px;
}

.icons {
  margin-top: 80px;
  img {
    height: 60px;
    margin-right: 50px;
  }
}

.pic-a-4 {
  width: 900px;
}

.pic-a-5 {
  width: 1700px;
}

.main-title {
  font-size: 30px;
}
.brief {
  font-size: 20px;
}

@media (max-width: 1000px) {
  .pic-a-4 {
    max-height: 300px;
  }
  .icons {
    margin-top: 50px;
    img {
      height: 40px;
      margin-right: 30px;
    }
  }
  .mgt-reset {
    &.mgt-100 {
      margin-top: 0;
    }
  }
}
</style>