module.exports = {
    header: {
        logout: 'Log keluar'
    },
    web: {
        header: {
            index: 'Laman Utama',
            found: 'Ditemui',
            ecology: 'Ekologi',
            activity: 'Aktiviti',
            download: 'Muat Turun',
            wallet: 'Dompet',
            video: 'Video',
            service: 'Platform Perkhidmatan'
        },
        footer: {
            slogn: 'Fokus pada sokongan aktiviti Web3, pertumbuhan pemasaran, mencapai pertumbuhan penggabungan trafik',
            useApp: 'Cuba APP',
            viewWhiteBook: 'Lihat Buku Putih',
            wallet: 'Dompet',
            appPublish: 'Penerbitan Aplikasi',
            tokenPublish: 'Penghantaran Token',
            video: 'Video',
            kolJoin: 'KOL Sertai',
            groupJoin: 'Komuniti Sertai',
            creatorCenter: 'Pusat Kreator',
            videoGuide: 'Tutorial Video',
            activity: 'Aktiviti',
            activityGuide: 'Tutorial Aktiviti',
            activityPublish: 'Penerbitan Aktiviti',
            airdrop: 'Airdrop Bersama',
            support: 'Sokongan',
            about: 'Tentang ParaPack',
            contact: 'Hubungi ParaPack',
            agentSupply: 'Permohonan Agen',
            serverPlatform: 'Platform Perkhidmatan',
            serviceAgreement: 'Perjanjian Perkhidmatan',
            privacy: 'Terma Privasi'
        },
        index: {
            web3Title: 'WEB3.0',
            web3SubTitle: 'Platform Pengumpulan Media Sendiri Satu Henti Global',
            web3Brief: `Melalui ParaPack, pengguna boleh menguruskan pelbagai akaun media sendiri secara serentak, membolehkan<br/> pengurusan bersatu, analisis data, penyelarasan kandungan, dan pemasaran pelbagai saluran, dengan matlamat ParaPack adalah untuk membantu pencipta kandungan dan pemasar menyederhanakan aliran kerja,<br/> serta meningkatkan keberkesanan dan efisiensi penyebaran kandungan.`,
            find: 'Jelajah',
            userCount: 'Pengguna Daftar',
            dailyActiveCount: 'Pengguna Aktif Harian',
            merchants: 'Peniaga Bergabung',
            web3Title2: 'Pemasaran Pengumpulan Trafik Web3',
            web3SubTitle2: `Menggunakan dompet terdesentralisasi sebagai medium, aktiviti dan video pendek AI sebagai alat`,
            web3Title3: 'Dompet Web3 Pelbagai Rantaian',
            web3SubTitle3: `Dompet aset kripto terdesentralisasi, serasi dengan pelbagai rangkaian blockchain, menyokong pelbagai aset kripto utama`,
            walletTag1: 'Menyokong pengurusan 20+ Token&NFTS rangkaian',
            walletTag2: 'Pelayar Dapp dan Magic Store',
            walletTag3: 'Transaksi tidak dipegang di atas rangkaian selamat dan pantas',
            web3Title4: 'Platform Tugas',
            web3SubTitle4: `Menghubungkan pengguna dengan pihak projek, alat pemasaran pertumbuhan pengguna, bantuan kukuh untuk pertumbuhan komuniti`,
            taskTag1: 'Pengumpulan data pelbagai dimensi dalam talian dan luar talian',
            taskTag2: 'Pengesahan pra-pendaftaran automatik dan pengagihan ganjaran yang telus dan terjamin',
            taskTag3: 'Kaedah promosi pelbagai saluran yang efisien dan mudah disebarkan',
            web3Title5: 'Video Pendek AI',
            web3SubTitle5: `Platform penciptaan video pendek AIGC, memperkasakan pemasaran dan penyebaran kandungan`,
            videoTag1: 'Fokus pada perkongsian kandungan Web3 untuk membina IP industri',
            videoTag2: 'AIGC aplikasi pintar mudah digunakan dan berkesan dengan kos rendah',
            videoTag3: 'Penyelarasan pelbagai sektor dalam satu tempat',
            web3Title6: 'Hubungan Rakan Kongsi',
            web3SubTitle6: `Dompet aset kripto terdesentralisasi, serasi dengan pelbagai rangkaian blockchain, menyokong pelbagai aset kripto utama`,
            web3Title7: 'Pertumbuhan Penukaran Trafik'
        },
        activity: {
            ppCompagin: 'ParaPackCompagin',
            ppCompaginSubTitle: 'Pengurusan Tugas Web3 dan Pertumbuhan Trafik',
            joinActivity: 'Sertai Aktiviti',
            publishActivity: 'Penerbitan Aktiviti',
            regUsers: 'Pengguna Daftar',
            activeUsers: 'Pengguna Aktif Harian',
            shopes: 'Peniaga Bergabung',
            publishCount: 'Penerbitan Aktiviti',
            rewardsAmount: 'Skala Ganjaran',
            title2: 'Pengumpulan Dalam Talian dan Luar Talian',
            subTitle2: 'Pertimbangan tingkah laku pengguna pelbagai dimensi, penukaran pengguna antara platform yang berbeza',
            title3: 'Penyebaran Matriks',
            subTitle3: 'Interkoneksi pelbagai sektor, penyebaran pelbagai bentuk seperti video, iklan, pengenalan aplikasi secara serentak',
            title4: 'Ganjaran yang Adil dan Telus',
            subTitle4: 'Ganjaran aktiviti dikunci dalam peti simpanan terlebih dahulu, menjamin keaslian aktiviti dan penyaluran ganjaran',
            title5: 'Perkhidmatan Data yang Mudah Digunakan',
            subTitle5: 'Menyediakan perkhidmatan data pelbagai dimensi, membantu penerbit menilai kesan dan menganalisis pengguna'
        },
        down: {
            title: 'Platform Pengumpulan Media Sendiri Satu Henti Global',
            subTitle: 'Global one-stop self media aggregation platform'
        },
        wallet: {
            title: 'Dompet ParaPack',
            subTitle: 'Pintu masuk dan perkhidmatan Web3 satu henti',
            brief: `Bagi pengguna, dompet digital adalah percuma dan boleh diperoleh dengan mudah. Sebagai contoh, apabila pengguna membeli barang di<br/> laman web peniaga yang mengendalikan dompet digital di server, dia memasukkan namanya, jumlah pembayaran dan maklumat penghantaran<br/> dalam borang peniaga itu sendiri.`,
            userScale: 'Skala Pengguna',
            txs: 'Transaksi Harian',
            swap: 'Skala Tukaran Pantas',
            title2: 'Sokongan Aset Kripto',
            subTitle2: 'Pengurusan aset kripto dengan selamat di mana saja dan bila-bila masa',
            title3: 'Perkhidmatan Tukaran Pantas yang Cepat dan Mudah',
            brief3: `Menyokong pertukaran silang aset pelbagai rangkaian, transaksi terdesentralisasi,<br/> selamat dan mudah`,
            title4: 'Magic Store yang Kaya',
            brief4: `DAPP/APP terbaru dan terhangat dalam Web3, dilengkapi dengan pelayar blockchain,<br/> mudah untuk mengakses Dapp`,
            title5: 'Ekosistem Aplikasi yang Lengkap',
            subTitle5: 'Kami sedang membangunkan dan akan membangunkan ciri aplikasi, mencipta susun atur ekosistem yang lebih lengkap',
            tag1: 'Maklumat Industri',
            tag1Brief: `Menghubungkan maklumat terkini berkaitan token dan projek, memudahkan<br/> pengguna untuk memahami perkembangan terkini, menyokong pengambilan keputusan`,
            tag2: 'Peringkat Penyelidikan dan Pelaburan',
            tag2Brief: `Pasukan penyelidikan dan pelaburan Para mempunyai sistem penyelidikan dan pelaburan pasaran peringkat pertama yang lengkap,<br/> akan terus menggali dan mengikuti projek awal yang berkualiti`,
            tag3: 'Alat Berguna',
            tag3Brief: `Menyediakan pelbagai alat berguna dalam talian, seperti: penyemakan dan pembatalan kelulusan, amaran turun naik harga, dll`
        }
    },
    leftMenus: {
        setting: 'Tetapan Pengguna',
        basicInfo: 'Maklumat Pameran',
        accountManage: 'Pengurusan Akaun',
        eventsManage: 'Pengurusan Aktiviti',
        eventsLists: 'Senarai Aktiviti',
        eventsPublish: 'Penerbitan Aktiviti',
        projectManage: 'Pengurusan Projek',
        appManage: 'Pengurusan Aplikasi',
        tokenManage: 'Pengurusan Token',
        agentManage: 'Agen',
        collections: 'Pengurusan koleksi'
    },
    basicInfo: {
        title1: 'Maklumat Asas (Klik Dua Kali untuk Mengedit)',
        title2: 'Komuniti Rasmi (Klik Dua Kali untuk Mengedit)',
        nickname: 'Nama',
        brief: 'Pengenalan',
        site: 'Web',
        infoTips: 'Maklumat pameran akan muncul di halaman profil anda',
        noSet: 'Tiada maklumat disimpan'
    },

    common: {
        'confirm': 'Sahkan',
        'cancel': 'Batal',
        'save': 'Simpan',
        'noData': 'Tiada Data',
        'loading': 'Memuatkan...',
        'deleteSuccessful': 'Penghapusan Berjaya',
        'deleteFailed': 'Penghapusan Gagal',
        'saveSuccessful': 'Penyimpanan Berjaya',
        'saveFailed': 'Penyimpanan Gagal',
        'total': 'Jumlah',
        'per': 'Bagi',
        'tasks': 'Tugas',
        'brief': 'Pengenalan',
        'noSetting': 'Tiada Tetapan',
        'login': 'Log Masuk',
        'notLogin': 'Anda Belum Log Masuk',
        'pleaseSelect': 'Sila Pilih',
        'copySuccess': 'Salin berjaya'
    },
    setting: {
        'uidTips': 'UID adalah pengenalan unik, semua akaun yang diikat di bawah UID ini boleh log masuk menggunakan UID ini.',
        'userSetting': 'Tetapan Pengguna',
        'accountManage': 'Pengurusan Akaun',
        'email': 'Emel',
        'change': 'Tukar',
        'loginPwd': 'KLLM',
        'noSetPwd': 'Kata laluan belum ditetapkan',
        'set': 'Tetapkan',
        'notBind': 'Tidak Terikat',
        'unbind': 'Lepaskan',
        'bind': 'Ikat',
        'updateAvatar': 'Kemas Kini Avatar',
        'confirm': 'Sahkan',
        'cancel': 'Batal',
        'save': 'Simpan',
        'bindEmailTip': 'Selesaikan pendaftaran selepas mengikat emel',
        'verifyCode': 'Kod Pengesahan',
        'send': 'Hantar',
        'sendVerifyCode': 'Hantar Kod Pengesahan',
        'toBind': 'Ikat Sekarang',
        'userAgreement': 'Perjanjian Pengguna',
        'privacyPolicy': 'Dasar Privasi',
        'read': 'Saya telah membaca dan bersetuju',
        'userAgreementApfix': 'Akaun yang belum didaftarkan akan secara automatik didaftarkan setelah disahkan',
        'changeEmail': 'Tukar Emel',
        'bindEmail': 'Ikat Emel',
        'needEmail': 'Emel baru yang perlu diikat',
        'unbindTwitter': 'Lepaskan Ikatan',
        'isUnbindTwitter': 'Adakah anda pasti ingin melepaskan ikatan Twitter?',
        'changePwd': 'Tukar Kata Laluan',
        'newPwd': 'Kata Laluan Log Masuk Baru',
        'newPwd_': 'Kata Laluan Baru',
        'setPwd': 'Tetapkan Kata Laluan',
        'pleaseSetPwd': 'Sila tetapkan kata laluan log masuk',
        'repeatPwd': 'Ulang Kata Laluan',
        'bindedEmail': 'Emel yang telah diikat'
    },
    events: {
        'basicInfo': 'Asas',
        'tasksNeed': 'Keperluan',
        'tasksReward': 'Ganjaran',
        'eventPublish': 'Lepaskan',
        'eventsManage': 'Pengurusan Acara',
        'eventsLists': 'Senarai Acara',

        'collectionsLists': 'Senarai Koleksi',
        'collectionManage': 'Pengurusan koleksi',
        'collectionInfo': 'Perincian Koleksi',
        'removeCollection': 'Keluarkan Koleksi',
        'removeEndEvents': 'Buang aktiviti berakhir',
        'isRemoveColl': 'Adakah anda pasti ingin melepaskan koleksi ini?',
        'isRemoveEndEvents': 'Anda pasti ingin membuang aktiviti yang telah berakhir?',

        'notSetTitle': 'Tajuk belum ditetapkan',
        'isDeleteDraft': 'Padam draf semasa?',
        'getReward': 'Cabutan Bertuah',
        'fcfs': 'FCFS',
        'divideAmount': 'Perkongsian Jumlah',
        'toCreate': 'Cipta Acara',
        'isPublished': 'Telah Diterbitkan',
        'isChecking': 'Dalam Semakan',
        'isRunning': 'Sedang Berlangsung',
        'waitRunning': 'Akan Bermula',
        'isEnd': 'Telah Tamat',
        'draft': 'Draf',
        'preview': 'Pratonton',
        'eventData': 'Data Acara',
        'basicData': 'Data Asas',
        'taskData': 'Data Tugas',
        'finished': 'Selesai',
        'join': 'Sertai',
        'view': 'Lihat',
        'share': 'Kongsi',
        'like': 'Suka',
        'eventsCount': 'Bilangan Acara',
        'myEvents': 'Acara Saya',
        'hotEvents': 'Acara Hangat',
        'moreEvents': 'Lebih Banyak Acara Menarik',
        'myCreated': 'Yang Saya Cipta',
        'myJoined': 'Yang Saya Sertai',
        'myLiked': 'Yang Saya Suka',
        'publishCollection': 'Cipta Koleksi',
        'edit': 'Edit',
        'collection': 'Koleksi',
        'submitBy': 'Dihantar Oleh',
        'ifDel': 'Padam acara semasa?',
        'cancel': 'Batal',
        'confirm': 'Sahkan',
        'minTwoEvents': 'Sila pilih sekurang-kurangnya dua acara',
        'failedCheck': 'Semakan Gagal',
        'checking': 'Dalam Semakan',
        'cancelSelect': 'Batal Pilihan',
        'selectEvents': 'Pilih Acara',
        'createCollection': 'Hasilkan Koleksi',
        'endTime': 'Masa Tamat',
        'startTime': 'Masa Mula',
        'submitAndWaitCheck': 'Penghantaran berjaya, menunggu semakan!',
        'submitFailed': 'Penghantaran Gagal',
        'emptyLogo': 'Ikon projek tidak boleh kosong',
        'max300kb': 'Saiz gambar tidak boleh melebihi 300KB!',
        'errorFormat': 'Format gambar tidak sah!',
        'collectionName': 'Nama Koleksi',
        'emptyCollectionName': 'Nama koleksi tidak boleh kosong',
        'placeCollectionName': 'Sila masukkan nama koleksi',
        'collectionLogo': 'Logo Koleksi',
        'tipCollectionLogo': `Hanya menyokong muat naik gambar jpg/png, resolusi disyorkan adalah 168*168,<br/>Saiz fail kurang daripada 300KB, pastikan elemen visual berada di tengah.`,
        'collectionBrief': 'Penerangan Koleksi',
        'emptyCollectionBrief': 'Penerangan koleksi tidak boleh kosong',
        'placeCollectionBrief': 'Sila masukkan penerangan koleksi',
        'eventTime': 'Masa Acara',
        'emptyEventTime': 'Masa acara tidak boleh kosong',
        'placeEventTime': 'Sila masukkan masa acara'
    },
    task: {
        'xTask': 'Tugas X',
        'tradeNeed': 'Tugas Perdagangan',
        'platformTasks': 'Tugas Platform',
        'projectTasks': 'Tugas Projek',
        'trade': 'Perdagangan',
        'eventReward': 'Ganjaran Acara',
        'allRewards': 'Semua Hadiah',
        'rewardMode': 'Mod Ganjaran',
        'issuerReward': 'Ganjaran Penandatanganan',
        'regLongPre': 'Pendaftaran akaun tidak kurang daripada',
        'perMonth': 'bulan',
        'followX': 'Ikuti akaun X',
        'retweet': 'Retweet',
        'retweetfix': 'Tweet dan @ tiga rakan',
        'retweetApfix': 'Tweet terkini dan @ tiga rakan',
        'like': 'Suka',
        'lastTweet': 'Tweet Terkini',
        'tweet': 'Tweet',
        'balanceNeed': 'Keperluan Aset',
        'has': 'Memiliki',
        'hasToken': 'Token Rantaian',
        'noLessThan': 'Tidak kurang daripada',
        'other': 'Lain-lain',
        'receiveAddress': 'Alamat Penerimaan Ganjaran',
        'pleaseUse': 'Sila gunakan',
        'pleaseUseApfix': 'Alamat penerimaan token. Jika anda mendapat ganjaran untuk acara ini, ganjaran akan secara automatik dihantar ke alamat ini',
        'textCollect': 'Pengumpulan Teks',
        'textInfo': 'Maklumat Teks',
        'userNickname': 'Nama Panggilan Pengguna',
        'userID': 'ID Pengguna',
        'autoTweet': 'Hantar Secara Automatik',
        'hasTokens': 'Memiliki token tertentu',
        'tradeTokens': 'Perdagangan token tertentu',
        'otherTasks': 'Tugas Lain',
        'selfTasks': 'Tugas Tersuai',
        'promote': 'Promosi Acara',
        'syncX': 'Selaraskan acara ini ke akaun x pegawai acara PP Wallet',
        'placeAutoTweet': 'Isi kandungan tweet automatik'
    },
    publish: {
        'saveDraft': 'Simpan Draf',
        'nextStep': 'Langkah Seterusnya',
        'default': 'Lazim',
        'title': 'Tajuk Acara',
        'time': 'Masa Acara',
        'startTime': 'Tarikh Mula',
        'to': '-',
        'endTime': 'Tarikh Tamat',
        'cover': 'Poster Acara',
        'logo': 'Logo Acara',
        'coverSize': 'Disyorkan 168*168',
        'coverBytes': 'Tidak melebihi 3MB',
        'coverWait': 'Sila tunggu sehingga gambar selesai dimuat naik',
        'content': 'Keterangan Acara',
        'tags': 'Label Acara',
        'taskLists': 'Senarai Tugas',
        'noTaskLists': 'Sila tambah sekurang-kurangnya satu syarat tugas!',
        'regLong': 'Bilangan bulan minimum pendaftaran akaun X peserta',
        'followX': 'Ikuti Twitter tertentu',
        'xActive': 'Sertai interaksi tweet terbaru Twitter tertentu',
        'tweetActive': 'Sertai interaksi tweet tertentu',
        'retweet3': 'Retweet dan tandakan 3 rakan',
        'like': 'Suka',
        'linkTweetUrl': 'Url tweet yang berkaitan',
        'autoTweetActive': 'Sertai interaksi tweet automatik',
        'autoTweetContent': 'Kandungan tweet automatik',
        'tweetContentTip': 'Sila edit kandungan tweet (maksimum 200 aksara)',
        'tweetContentTip2': 'Setelah acara diterbitkan dengan jayanya, Parapack akan menghantar tweet ini melalui',
        'tweetContentTip3': 'akaun X yang anda berikan kuasa',
        'authedX': 'Twitter telah diberi kuasa',
        'notAuthX': 'Twitter belum diberi kuasa',
        'toBind': 'Pergi untuk mengikat',
        'balanceNeed': 'Keperluan Aset',
        'hasToken': 'Memiliki token tertentu',
        'chain': 'Rantaian Penerbitan',
        'contractAddress': 'Alamat Kontrak',
        'tokenName': 'Nama Aset',
        'minNum': 'Bilangan Minimum',
        'other': 'Lain-lain',
        'textDesp': 'Huraian Teks',
        'rewardNum': 'Bahagian Ganjaran',
        'rewardSetting': 'Tetapan Ganjaran',
        'topUpReward': 'Masukkan Ganjaran',
        'rewartValue': 'Jumlah Ganjaran',
        'rewardTip': 'Sebahagian dana ini akan dipertaruhkan di PP untuk memastikan acara berjalan lancar, bahagian yang masih tinggal setelah acara tamat akan dikembalikan sepenuhnya',
        'serviceFee': 'Yuran Perkhidmatan',
        'serviceFeeTip': 'Yuran perkhidmatan yang dikenakan oleh PP untuk acara ini, yuran tidak akan dikembalikan setelah acara diterbitkan',
        'topUpAmount': 'Jumlah Deposit',
        'topUpAmountTip': 'Jumlah token yang perlu dimasukkan untuk penerbitan acara ini (termasuk jumlah ganjaran dan yuran perkhidmatan)',
        'topUpAddress': 'Alamat Deposit',
        'topUpTip': 'Alamat ini hanya boleh menerima',
        'topUpTip2': 'aset rantaian; memasukkan aset lain mungkin menyebabkan kerugian dana',
        'backAddressTip': 'Apabila acara tamat, jika terdapat token yang tinggal atau sebab lain yang menyebabkan pengembalian token, token yang tinggal akan dikembalikan ke alamat pengalihan.',
        'backAddressTip2': 'Alamat ini mesti berada di rangkaian yang sama dengan alamat deposit, jika tidak, anda mungkin mengalami kerugian aset!',
        'topUpAddressPlaceholder': 'Sila berikan alamat penerimaan token ganjaran anda',
        'backAddress': 'Alamat Kembali',
        'netAddress': 'Alamat Rangkaian',
        'txid': 'Hash Pemindahan',
        'txidPlaceholder': 'Sila berikan hash rantaian pemindahan anda ke alamat deposit',
        'tokenAmountPlaceholder': 'Sila masukkan jumlah token ganjaran',
        'xAccount': 'Akaun Twitter',
        'xAccountPlace': 'contoh. @elonmusk',
        'toPublish': 'Saya telah menyelesaikan pembayaran, terbitkan acara',
        'setRewardCount': 'Tetapkan Bilangan Pemenang (bahagian)',
        'publishLang': 'Bahasa Penerbitan',
        'customTask': 'Tugas Tersuai',
        'hasTrade': 'Tugas Perdagangan',
        'tradePath': 'Laluan Perdagangan',
        'tradeNum': 'Bilangan Perdagangan',
        'minTradeNumber': 'Bilangan Perdagangan Minimum',
        'minAmount': 'Jumlah Perdagangan',
        'errorFileSize': 'Saiz fail melebihi had',
        'errorPicSize': 'Saiz imej tidak memenuhi keperluan',
        'errorTitle': 'Tajuk acara tidak boleh kosong',
        'errorTimeRange': 'Masa acara tidak boleh kosong',
        'errorEndTime': 'Masa tamat mesti lebih besar daripada masa mula',
        'errorCover': 'Poster acara tidak boleh kosong',
        'coverUploading': 'Poster acara sedang dimuat naik, sila tunggu...',
        'errorContent': 'Keterangan acara tidak boleh kosong',
        'minTasksCount': 'Sila tambah sekurang-kurangnya satu syarat tugas!',
        'errorXLong': 'Sila tetapkan tempoh pendaftaran akaun x',
        'errorTasksNeed': 'Sila lengkapkan syarat tugas',
        'repeatTasks': 'Tugas berulang',
        'errorRewardToken': 'Sila tetapkan token ganjaran',
        'errorRewardType': 'Sila pilih jenis ganjaran',
        'errorAutoTweet': 'Sila isi kandungan tweet automatik',
        'isUploading': 'Gambar sedang dimuat naik, sila tunggu...',
        'isPublishing': 'Sedang menerbitkan...',
        'publishSuccessful': 'Penerbitan berjaya',
        'publishFailed': 'Penerbitan gagal',
        'taskBrief': 'Pengenalan',
        'customLink': 'Sambungan',
        'customCheckLink': 'Sahkan API',
        'placeCustomCheckLink': 'Antaramuka API pengesahan tugas',

        'errorTags': 'Tag aktiviti tidak boleh kosong',
        'errorLangs': 'Sila memperbaiki kandungan berbilang bahasa'
    },
    login: {
        title: 'Log Masuk Platform Perkhidmatan',
        email: 'Emel',
        verifyCode: 'Kod Pengesahan',
        pwd: 'Kata Laluan',
        toLogin: 'Log Masuk Sekarang',
        sendCode: 'Hantar Kod Pengesahan',
        thirdLogin: 'Log Masuk Akaun Pihak Ketiga',
        xLogin: 'Log Masuk Twitter',
        scanLogin: 'Log Masuk dengan Imbasan',
        downApp: 'Muat Turun Parapack',
        toBind: 'Ikatan Sekarang',
        otherLogin: 'Kaedah Log Masuk Lain',
        bindEmail: 'Ikatan Emel',
        bindEmailTip: 'Selesaikan pendaftaran setelah mengikat emel'
    },
    errMsg: {
        pleaseRead: 'Sila baca dan setujui perjanjian platform',
        badNetwork: 'Jaringan anda tidak stabil, sila segarkan dan cuba lagi nanti!',
        pleaseInputEmail: 'Sila masukkan emel',
        pleaseInputCode: 'Sila masukkan kod pengesahan',
        pleaseInputPwd: 'Sila masukkan kata laluan',
        sendFailed: 'Penghantaran gagal',
        loginFailed: 'Log masuk gagal',
        bindFailed: 'Ikatan gagal',
        noLoginPermit: 'Anda bukan seorang pedagang saat ini. Sila pergi ke aplikasi untuk melamar untuk mendaftar'
    },
    successMsg: {
        loginSuccess: 'Log masuk berjaya',
        bindSuccess: 'Ikatan berjaya'
    },
    server: {
        'Not login!': 'Sila log masuk semula',
        'Invalid params!': 'Parameter permintaan tidak sah',
        'No data!': 'Tiada data',
        'success': 'Operasi berjaya',
        'fail': 'Operasi gagal',
        'Uploading, please wait!': 'Sedang memuat naik, sila tunggu!',
        'Publishing too frequently, please wait a moment!': 'Mengeluarkan terlalu kerap, sila tunggu sebentar!',
        'Cover image is being uploaded, please submit again later!': 'Imej penutup sedang dimuat naik, sila hantar semula nanti!',
        'Asset verification failed, please refresh the recharge address!': 'Pengesahan aset gagal, sila segarkan alamat pengisian semula!',
        'Asset verification failed and the recharge address has expired!': 'Pengesahan aset gagal dan alamat pengisian semula telah tamat tempoh!',
        'Hash has been used!': 'Hash telah digunakan!',
        'Asset verification failed and the asset balance is insufficient!': 'Pengesahan aset gagal dan baki aset tidak mencukupi!',
        'Asset verification failed, invalid hash!': 'Pengesahan aset gagal, hash tidak sah!',
        'Mission request contains an invalid Twitter account:': 'Permintaan misi mengandungi akaun Twitter yang tidak sah:',
        'X account is not bound!': 'Akaun X tidak diikat!',
        'The URL of the specified tweet is incorrectly filled in!': 'URL tweet yang ditentukan diisi dengan tidak betul!',
        'Unable to find the specified tweet!': 'Tidak dapat mencari tweet yang ditentukan!',
        'Save failed!': 'Simpan gagal!',
        'Save event failed!': 'Simpan acara gagal!',
        'Save params failed!': 'Simpan parameter acara gagal!'
    },
    dapp: {
        projectManage: 'Pengurusan Projek',
        dappManage: 'Pengurusan Aplikasi',
        basicInfo: 'Maklumat Asas',
        otherInfo: 'Maklumat Lain',
        submit: 'Hantar Permohonan',
        name: 'Nama',
        type: 'Jenis',
        tags: 'Tag',
        ctime: 'Masa Tindakan',
        status: 'Status',
        handle: 'Tindakan',
        view: 'Lihat',
        del: 'Hapus',
        draft: 'Draf',
        refused: 'Ditolak',
        checking: 'Dalam Semakan',
        published: 'Diterbitkan',
        tip: 'Tip',
        delTip: 'Selepas penghapusan, aplikasi anda akan secara automatik diturunkan dan tidak akan disenaraikan dalam kedai aplikasi PP!',
        logo: 'Logo Projek',
        errorLogo: 'Logo projek tidak boleh kosong',
        logoTip: `Hanya menyokong muat naik gambar jpg/png, resolusi disyorkan adalah lebih daripada 100*100, <br/>saiz fail kurang dari 300KB, kekalkan elemen visual di tengah.`,
        appName: 'Nama Aplikasi',
        errorAppName: 'Nama aplikasi tidak boleh kosong',
        placeAppName: 'Sila masukkan nama aplikasi',
        subName: 'Nama Pendek Aplikasi',
        errorSubName: 'Nama pendek aplikasi tidak boleh kosong',
        placeSubName: '1 hingga 2 perkataan yang paling mudah dikenali untuk aplikasi',
        desc: 'Penerangan Aplikasi',
        errorDesc: 'Penerangan aplikasi dalam bahasa default mesti diisi',
        placeDesc: 'Kandungan penerangan dalam bahasa default mesti diisi',
        defLang: 'Default',
        appType: 'Jenis Aplikasi',
        errorAppType: 'Sila pilih jenis aplikasi',
        appChains: 'Rantaian yang Disokong',
        errorAppChains: 'Sila pilih rantaian yang disokong oleh aplikasi',
        placeAppChains: 'Sila pilih rantaian yang disokong oleh aplikasi',
        appTags: 'Tag Aplikasi',
        errorAppTags: 'Sila pilih tag aplikasi',
        errorAppTags2: 'Maksimum menyokong 5 tag',
        placeAppTags: 'Sila pilih tag aplikasi, maksimum 5 pilihan',
        dappUrl: 'URL Aplikasi',
        errorDappUrl: 'Sila isi pautan akses aplikasi',
        placeDappUrl: 'Pautan akses Dapp aplikasi',
        appUrl: 'URL Aplikasi',
        errorAppUrl: 'Sila isi pautan muat turun aplikasi',
        placeAppUrl: 'Pautan muat turun aplikasi',
        checkStatus: 'Maklumat Semakan',
        checkStatusYes: 'Sudah disemak',
        checkStatusNo: 'Belum disemak',
        errorCheckStatus: 'Sila pilih status semakan',
        checkUrl: 'Pautan Laporan Semakan',
        errorCheckUrl: 'Sila isi pautan laporan semakan',
        placeCheckUrl: 'Sila isi pautan laporan semakan',
        pleaseSelect: 'Sila pilih',
        telegramUrl: 'Telegram Kontak',
        errorTelegramUrl: 'Sila isi Telegram kontak',
        otherFormData: 'Maklumat Lain (Pilihan)',
        siteUrl: 'Laman Web Rasmi',
        placeSiteUrl: 'Sila masukkan pautan URL https://',
        whiteBook: 'Buku Putih',
        placeWhiteBook: 'Sila masukkan pautan buku putih https://',
        placeTwitter: 'Sila masukkan pautan akaun X https://',
        placeTg: 'Sila masukkan pautan akaun Telegram https://',
        placeDiscord: 'Sila masukkan pautan Discord https://',
        placeFacebook: 'Sila masukkan pautan akaun Facebook https://',
        ppLink: 'Pautan PP',
        placePPLink: 'Mengaitkan membolehkan anda pergi ke pusat peribadi akaun PP penerbit',
        ppLinkOptions: {
            v1: 'Tidak mengaitkan',
            v2: 'Mengaitkan acara',
            v3: 'Mengaitkan video',
            v4: 'Mengaitkan acara & video'
        },
        tokenAddress: 'Maklumat Token 1',
        tipTokenAddress: 'Jika token anda diterbitkan di PP, kami akan membantu anda mengaitkan token',
        tokenAddress2: 'Maklumat Token 2',
        placeTokenAddress: 'Sila masukkan URL kontrak token https://',
        email: 'Emel Kontak',
        placeEmail: 'Alamat emel kontak projek',
        contact: 'Penghubung',
        placeContact: 'Jika ada BD yang dihubungi, sila isi',
        brief: 'Penerangan Projek',
        placeBrief: 'Perkenalkan aplikasi anda kepada kami, supaya kami dapat memahami aplikasi anda dengan lebih baik, ini akan membantu dalam semakan permohonan kali ini',
        saveDraft: 'Simpan Draf',
        resetForm: 'Reset',
        nextStep: 'Langkah Seterusnya',
        submitForm: 'Hantar Semakan'
    },
    agent: {
        agentSupply: 'Permohonan Ejen',
        agentManage: 'Pengurusan Ejen',
        agentProfit: 'Pendapatan Ejen',
        urealizedProfit: 'Pendapatan Belum Ditarik',
        shareRate: 'Kadar Pembahagian Perniagaan',
        requirement: 'Keperluan Penilaian Pasukan',
        requirementCount: 'Pengguna Baru untuk Aktiviti Baharu',
        profitsBriefTitle: 'Penerangan Pembahagian Pasukan',
        realizedYes: 'Sasaran Dicapai',
        realizedNo: 'Sasaran Tidak Dicapai',
        groupShares: 'Kadar Pembahagian Pasukan',
        teamCount: 'Jumlah Pasukan',
        realizedCount: 'Pengguna Baru untuk Aktiviti',
        teamUser: 'Pengguna Pasukan',
        searchUserKey: 'UID/Nama Panggilan',
        nickname: 'Nama Panggilan',
        regTime: 'Tarikh Pendaftaran',
        brokerType: 'Jenis Sasaran',
        incomeRecords: 'Rekod Pendapatan',
        searchIncomeKey: 'UID/Perkara',
        dateTime: 'Masa',
        incomeTitle: 'Perkara',
        inviteUid: 'UID Pengembang',
        brokerReward: 'Komisen Ejen',
        teamReward: 'Komisen Pasukan',
        incomeStatus: 'Status',
        receivedNo: 'Belum Diterima',
        receivedYes: 'Sudah Diterima',
        profitsBrief: `1. Setelah menetapkan kadar pembahagian, pengguna pasukan akan menikmati pendapatan perniagaan yang disumbangkan oleh bawahannya mengikut kadar yang ditetapkan. Contoh: jika menetapkan 30% untuk peringkat 1, dan seorang pengguna yang diundang menyumbang 100,000 pendapatan perniagaan, penaja langsung akan menerima 30,000 ganjaran;<br/><br/>2. Jumlah semua kadar peringkat yang anda tetapkan tidak boleh melebihi 100%.`,
        pleaseInput: 'Sila Masukkan',
        gradeMark: 'Peringkat',
        errorParams: 'Parameter Permintaan Tidak Sah',
        errorProfit: 'Kadar mesti merupakan angka lebih daripada 0, tidak boleh kosong!',
        minOneProfit: 'Sekurang-kurangnya satu kadar mesti ditetapkan',
        maxProfit: 'Jumlah kadar tidak boleh melebihi 100%',
        setSuccessful: 'Tetapan Berjaya',
        setFailed: 'Tetapan Gagal',
        adAgent: 'Ejen Iklan',
        activityAgent: 'Ejen Aktiviti',
        appAgent: 'Ejen Aplikasi'
    }
}