<template>
  <div class="container wallet-page">
    <div class="top-bg">
      <pc-header></pc-header>

      <section class="first-content con-w" style="margin-bottom: 0;">
        <div class="left-con">
          <div class="main-title">{{$t('web.wallet.title')}}</div>
          <div class="main-title">{{$t('web.wallet.subTitle')}}</div>
          <div class="brief" v-html="$t('web.wallet.brief')"></div>
          <div style="height: 50px;"></div>
          <div class="btns">
            <div class="btn">
              <img src="../assets/imgs/down/icon-4.png"/>
              <div class="btn-brief">
                <div class="sub-name">Available on the</div>
                <div class="name">App Store</div>
              </div>
            </div>
            <div class="btn">
              <img src="../assets/imgs/down/icon-5.png"/>
              <div class="btn-brief">
                <div class="sub-name">Download APK</div>
                <div class="name">Android</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-con">
          <img src="../assets/imgs/wallet/pic-w-13.png" style="width:400px;margin-right: 200px;"/>
        </div>
      </section>

      <section class="stat-section mgt-0">
        <div class="number-row">
          <div class="number">
            <p><img src="../assets/imgs/index/icon-s-1.png" class="icon"/>{{$t('web.wallet.userScale')}}</p>
            <h2>{{consoleData.wallet.userCount}}</h2>
          </div>
          <div class="flex-1"></div>
          <div class="number">
            <p><img src="../assets/imgs/index/icon-s-2.png" class="icon"/>{{$t('web.wallet.txs')}}</p>
            <h2>{{consoleData.wallet.avgDayTradeCount ? $func.friendNum(consoleData.wallet.avgDayTradeCount) : 0}}</h2>
          </div>
          <div class="flex-1"></div>
          <div class="number">
            <p><img src="../assets/imgs/index/icon-s-3.png" class="icon"/>{{$t('web.wallet.swap')}}({{consoleData.wallet.fastTradeQuote}})</p>
            <h2>{{consoleData.wallet.fastTradeCount ? $func.friendNum(consoleData.wallet.fastTradeCount) : 0}}</h2>
          </div>
        </div>
      </section>
    </div>

    <section class="content-center con-w">
      <div class="main-title mgt-100">
        100,000+{{$t('web.wallet.title2')}}
      </div>
      <div class="brief mgt-16">
        {{$t('web.wallet.subTitle2')}}
      </div>
      <div style="height: 30px;"></div>
      <div class="tokens">
        <div class="token-item" v-for="(item, idx) in 9" :key="'r1-' + idx">
          <img :src="'../assets/imgs/coins-logo/' + idx + '.png'"/>
        </div>
      </div>
      <div class="tokens">
        <div class="token-item" :class="{active: idx + 9 === 14}" v-for="(item, idx) in 11" :key="'r2-' + idx">
          <img :src="'../assets/imgs/coins-logo/' + (idx + 9) + '.png'"/>
        </div>
      </div>
      <div class="tokens">
        <div class="token-item" v-for="(item, idx) in 9" :key="'r3-' + idx">
          <img :src="'../assets/imgs/coins-logo/' + (idx + 20) + '.png'"/>
        </div>
      </div>
      <div style="height: 80px;" class="placeholder-height-80"></div>
    </section>

    <div class="bg-2 poster-pic-19">
      <section class="content-left con-w pdt-50">
        <div class="fixed-con">
          <div class="main-title">{{$t('web.wallet.title3')}}</div>
          <div class="brief" v-html="$t('web.wallet.brief3')"></div>
        </div>
        <div class="poster-pic text-right">
          <img src="../assets/imgs/wallet/pic-w-19.png" class="poster w-auto pic-w-19"/>
        </div>
      </section>
    </div>

    <div class="bg-3 poster-pic-20">
      <section class="content-left con-w pdt-50">
        <div class="fixed-con">
          <div class="main-title">{{$t('web.wallet.title4')}}</div>
          <div class="brief" v-html="$t('web.wallet.brief4')"></div>
        </div>
        <div class="poster-pic text-right">
          <img src="../assets/imgs/wallet/pic-w-20.png" class="poster w-auto pic-w-20"/>
        </div>
      </section>
    </div>

    <section class="content-center con-w">
      <div class="main-title mgt-100 mgt-reset">
        {{$t('web.wallet.title5')}}
      </div>
      <div class="brief mgt-16">
        {{$t('web.wallet.subTitle5')}}
      </div>
      <div class="grid-con mgt-50">
        <div class="grid-item">
          <div class="icon-img">
            <img src="../assets/imgs/wallet/icon-w-7.png"/>
          </div>
          <div class="text bold">{{$t('web.wallet.tag1')}}</div>
          <div class="sub-text" v-html="$t('web.wallet.tag1Brief')"></div>
        </div>
        <div class="flex-1"></div>
        <div class="grid-item">
          <div class="icon-img">
            <img src="../assets/imgs/wallet/icon-w-8.png"/>
          </div>
          <div class="text bold">{{$t('web.wallet.tag2')}}</div>
          <div class="sub-text" v-html="$t('web.wallet.tag2Brief')"></div>
        </div>
        <div class="flex-1"></div>
        <div class="grid-item">
          <div class="icon-img">
            <img src="../assets/imgs/wallet/icon-w-9.png"/>
          </div>
          <div class="text bold">{{$t('web.wallet.tag3')}}</div>
          <div class="sub-text" v-html="$t('web.wallet.tag3Brief')"></div>
        </div>
      </div>
    </section>

    <div class="mgt-100"></div>

    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "@/components/Header";
import PcFooter from "@/components/Footer";
import * as EventsApi from "@/api/events";
import * as WebApi from "@/api/web";

export default {
  components: {
    PcHeader,
    PcFooter
  },
  data(){
    return {
      consoleData: {
        wallet: {}
      },
      support: {
        coins: []
      }
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData(){
      let res = await WebApi.getConsoleData();
      const targetRes = JSON.parse(JSON.stringify(res.data));
      for(let i = 1; i <= 10; i++){
        for(let k in res.data){
          if(['activity', 'homePage', 'wallet'].indexOf(k) === -1) continue;
          for(let kk in res.data[k]){
            const v = parseFloat(targetRes[k][kk]);
            if(!/^\d+(\.\d+)?$/.test(v)) continue;
            res.data[k][kk] = parseInt(v * (i/10));
          }
        }

        this.$set(this, 'consoleData', res.data);
        await this.$func.sleep(100);
      }

      // support
      res = await EventsApi.getSupport();
      if(res && res.data){
        this.support = res.data;
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";
@import "../assets/css/pc.less";

.container {
  .top-bg {
    background-image: linear-gradient(to right top, #fff 10%, #D6ECFE 90%);
  }
  .bg-2 {
    background-image: url("../assets/imgs/wallet/bg-2.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  .bg-3 {
    background-image: url("../assets/imgs/wallet/bg-3.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}

.first-content {
  margin: 50px auto;
  display: flex;
  flex-direction: row;

  .left-con {
    flex: 1;

    .main-title {
      font-size: 40px;
    }
    .brief {
      font-size: 16px;
      color: #aaa;
      margin-top: 20px;
    }
    .btns {
      display: flex;
      .btn {
        width: 160px;
        height: 60px;
        background: #000;
        border-radius: 15px;
        margin: 30px 30px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 36px;
          margin-right: 10px;
          margin-left: 10px;
        }
        .btn-brief {
          color: #fff;
          flex: 1;
          .sub-name {
            font-size: 14px;
            opacity: .8;
          }
          .name {
            font-size: 16px;
            margin-top: 3px;
          }
        }
      }
    }
  }
}

.tokens {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .token-item {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 20px;
    box-shadow: 0 0 1px #eee;
    border-radius: 8px;

    &:hover {
      border-color: @light-color;
      cursor: pointer;
      transform: scale(1.2);
    }
    &.active {
      transform: scale(1.8);
      transform-origin: center center;
      box-shadow: none;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>