<template>
  <div class="container service-platform">
    <!-- header -->
    <div class="header-con">
      <div class="flex content-con">
        <div class="logo">
          <a href="https://www.parapack.net"><img src="../assets/imgs/pc/22.png"/></a>
        </div>
        <div class="flex-1"></div>
        <div class="right-con">
          <div class="icon-btn">
            <a href="https://t.me/ParaPack_Community" target="_blank"><img src="../assets/imgs/pc/11.png"/></a>
          </div>
          <div class="icon-btn">
            <a href="https://twitter.com/parapack_pp" target="_blank"><img src="../assets/imgs/pc/12.png"/></a>
          </div>
        </div>
      </div>
    </div>

    <!-- section1 -->
    <div class="section-con bg-1">
      <div class="left-con" style="padding-top: 80px;">
        <div>
          <div class="title black-bold" style="font-size: 50px;">ParaPack服务平台</div>
          <div class="brief">活动发布、视频创作、Dapp/App上架、项目/代币信息维护服务等多方位一站式服务</div>
        </div>
        <div class="btn-con">
          <el-button size="medium" type="primary" @click="startUse">开始使用</el-button>
        </div>
      </div>
      <div class="flex-1"></div>
      <div>
        <img src="../assets/imgs/pc/home/img-1.png" width="450"/>
      </div>
    </div>

    <!-- section2 -->
    <div class="section-con column bg-2">
      <div class="text-center">
        <img src="../assets/imgs/pc/home/pic-7.png" height="36"/>
      </div>
      <div class="flex content-con mgtb">
        <div>
          <img src="../assets/imgs/pc/home/pic-1.png" width="450"/>
        </div>
        <div class="flex-1"></div>
        <div>
          <div class="title black-bold">活动发布及管理</div>
          <div class="brief-2">
            提供关注项目方社交账号、答题、链上操作以及任务悬赏等任务，用户完<br/>
            成后 可获得积分、NFT以及token奖励。 Para任务平台聚合Web3<br/>
            和Web2，兼容链上链下。
          </div>
          <div class="brief-rows">
            <div class="brief-row">
              <i class="el-icon-check"></i>
              创建发布新活动，查看活动进程和数据
            </div>
            <div class="brief-row">
              <i class="el-icon-check"></i>
              协助您进行活动效果分析
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section3 -->
    <div class="section-con bg-3">
      <div class="flex content-con">
        <div class="left-con">
          <div class="title text-right black-bold">视频创作</div>
          <div class="flex mgt-16">
            <div class="flex-1"></div>
            <img src="../assets/imgs/pc/home/pic-11.png" height="20" class="mgr-5"/>
            视频发布
          </div>
          <div class="brief-2 text-right">提供视频发布和作品管理服务，方便于创作者进行作品管理和效果分析</div>
          <div class="flex mgt-16">
            <div class="flex-1"></div>
            <img src="../assets/imgs/pc/home/pic-12.png" height="20" class="mgr-5"/>
            数字直播
          </div>
          <div class="brief-2 text-right">AIGC数字人服务，自动生成文字、图片和音频等素材，自动生成视频和直播内容</div>
        </div>
        <div class="flex-1"></div>
        <div>
          <img src="../assets/imgs/pc/home/pic-2.png" width="550"/>
        </div>
      </div>
    </div>

    <!-- section4 -->
    <div class="section-con bg-4">
      <div class="flex-1"></div>
      <div class="right-con" style="margin-right: 100px;">
        <div class="title black-bold" style="color:#fff;">改变正在悄悄发生</div>
        <div class="brief-2" style="color:#fff;">
          我们一起为Web3提供更有价值的内容和服务
        </div>
      </div>
    </div>

    <!-- section5 -->
    <div class="section-con bg-2">
      <div class="flex content-con">
        <div>
          <img src="../assets/imgs/pc/home/pic-3.png" width="450"/>
        </div>
        <div class="flex-1"></div>
        <div class="right-con">
          <div class="title black-bold">项目信息维护</div>
          <div class="brief-2">
            项目官方信息更新维护
          </div>
          <div class="brief-rows">
            <div class="brief-row">
              <i class="num">1</i>
              DApp/App、代币logo上架申请
            </div>
            <div class="brief-row">
              <i class="num">2</i>
              项目官方信息更新维护
            </div>
            <div class="brief-row">
              <i class="num">3</i>
              多板块一站式营销分发
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section6 -->
    <div class="section-con bg-5">
      <div class="flex content-con">
        <div class="left-con">
          <div class="title text-right black-bold">数据服务</div>
          <div class="brief-2 text-right">
            提供内容及数据收集反馈，用户喜好和行为数据分析，效果评<br/>
            估和增长改进建议等全方位的数据服务，帮助您明晰用户偏好<br/>
            并做好用户服务。精确的数据服务为您的决策提供科学、准确<br/>
            的数据支持，帮助您做出更明智的决策。
          </div>
        </div>
        <div class="flex-1"></div>
      </div>
    </div>

    <div class="footer-con">
      <div class="copyright">
        <span class="mark">©</span>2024 PP ParaPack  All Rights Reserved
      </div>
    </div>

    <div class="tip-dialog">
      <div>请使用PC访问Parapack服务平台<br/>网址：https://business.parapack.net</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$com.copyText('https://business.parapack.net')">复 制</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Setting from "@/pages/user/Setting";
import Basic from "@/pages/user/Basic";
import EventLists from "@/pages/events/Lists";

export default {
  components: { Basic, Setting, EventLists },
  data(){
    return {
      userInfo: false,
      menuActive: 'userinfo'
    }
  },

  mounted() {
    this.$eventBus.$on('LOGIN-SUCCESS', userInfo => {
      this.userInfo = userInfo;
    });
    this.$eventBus.$on('ACCOUNT_INFO', res => {
      this.userInfo = res.userInfo;
    });

    // 请求加载初始化数据
    this.$eventBus.$emit('LOAD_INIT_DATA');

    const { menu } = this.$route.query;
    if(menu){
      this.menuActive = menu;
    }
  },
  methods: {
    startUse(){
      this.$router.replace('/basic-info');
      location.reload();
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.header-con {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 100px;
  background: linear-gradient(to left, #E1E4FC 10%, #E8F7FF 30%, #fff 100%);
  .content-con {
    flex: 1;
    width: auto;
    margin: 0 auto;
  }
  .logo {
    font-size: 16px;
    font-weight: bold;
    img {
      height: 24px;
    }
  }
  .right-con {
    display: flex;
    align-items: center;
  }
  .icon-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 20px;
    img {
      height: 30px;
      width: 30px;
    }
  }
}
.section-con {
  display: flex;
  padding: 50px 100px;

  &.bg-1 {
    background: linear-gradient(to left, #E1E4FC 10%, #E8F7FF 30%, #fff 100%);
  }
  &.bg-2 {
    background-color: #fff;
  }
  &.bg-3 {
    background-color: #F6FCFF;
  }
  &.bg-4 {
    background-color: @light-color;
    background-image: url("../assets/imgs/pc/home/pic-13.png");
    background-size: 100% auto;
    height: calc(100vw * 0.12);
  }
  &.bg-5 {
    background-color: #F6FCFF;
    background-image: url("../assets/imgs/pc/home/pic-4.png");
    background-size: auto 300px;
    background-position: right bottom;
    background-repeat: no-repeat;
    height: 300px;
  }
  &.column {
    flex-direction: column;
  }
  .content-con {
    width: 1140px;
    margin: 0 auto;
    &.mgtb {
      margin: 50px auto;
    }
  }
  .w-1200 {
    width: 1200px;
    margin: 0 auto;
  }
  .w-800 {
    width: 800px;
    margin: 0 auto;
  }
  .left-con {
    width: 500px;
  }
  .right-con {
    width: 500px;
  }
  .main-title {
    font-size: 40px;
    font-weight: bold;
    transform: scaleY(.85);
  }
  .brief {
    margin-top: 30px;
    font-size: 20px;
    line-height: 36px;
  }
  .btn-con {
    margin-top: 50px;
  }
  .title {
    font-size: 30px;
  }
  .brief-2 {
    margin-top: 15px;
    font-size: 16px;
    line-height: 30px;
    color: #aaa;
  }
  .brief-rows {
    .brief-row {
      display: flex;
      align-items: center;
      margin-top: 20px;
      i {
        background-color: #D3F4DE;
        color: #63AB7D;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 14px;
        margin-right: 10px;

        &.num {
          background-color: transparent;
          border: 1px solid #000;
          box-sizing: border-box;
          color: #000;
        }
      }
    }
  }
}
.footer-con {
  background-color: #000;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 100px;
  color: #fff;
  font-size: 13px;
  .mark {
    color: #aaa;
  }
}

.tip-dialog {
  display: none;
}
@media (max-width: 750px) {
  .service-platform {
    > * {
      display: none;
    }
    .tip-dialog {
      display: block;
      position: absolute;
      width: 90%;
      box-sizing: border-box;
      background-color: #fff;
      padding: 20px 30px;
      margin: 0 auto;
      border-radius: 10px;
      top: 40vh;
      left: 5%;
      .dialog-footer {
        margin-top: 20px;
        text-align: right;
      }
    }
  }
}

</style>
