<template>
  <div>
    <div class="page-title">{{$t('agent.agentManage')}} - {{$t('agent.agentSupply')}}</div>

    <div class="no-data" v-if="!isInited">
      <i class="el-icon-loading"></i>
    </div>

    <div class="message-box" v-if="showBrief">
      <div class="title">代理商说明</div>
      <div class="sub-title">
        代理商是ParaPack的商业合作伙伴，拥有拓展PP相关业务的权利。PP授权代理商发<br/>
        展商业伙伴，并给予代理一定的经济报酬。代理商是ParaPack的商业合作伙伴，拥有<br/>
        拓展PP相关业务的权利。PP授权代理商发展商业伙伴，并给予代理一定的经济报酬。<br/>
        <br/>
        代理商拉新考核:<br/>
        代理商是ParaPack的商业合作伙伴，拥有拓展PP相关业务的权利。PP授权代理商发<br/>
        展商业伙伴，并给予代理一定的经济报酬。代理商是ParaPack的商业合作伙伴，拥有<br/>
        拓展PP相关业务的权利。PP授权代理商发展商业伙伴，并给予代理一定的经济报酬。
      </div>
      <div class="flex btns">
        <el-button size="medium" type="primary" style="width: 520px;" @click="showBrief=false">立即申请</el-button>
      </div>
    </div>

    <div class="message-box" v-if="!showBrief && failedCheck">
      <img src="../../assets/imgs/agent/agent-9.png" class="icon"/>
      <div class="title text-red">您提交的“{{agentTypesObj[failedCheck.brokerType].name}}”申请审核未通过!</div>
      <div class="sub-title">{{failedCheck.refMsg}}</div>
      <div class="flex btns">
        <el-button size="medium" type="primary" @click="supplyAgain(failedCheck)">重新申请</el-button>
        <el-button size="medium" @click="closeAgent(failedCheck)">关闭</el-button>
      </div>
    </div>

    <div class="message-box" v-if="!showBrief && waitCheck">
      <img src="../../assets/imgs/agent/agent-8.png" class="icon"/>
      <div class="title">您已提交“{{agentTypesObj[waitCheck.brokerType].name}}”代理商申请，请耐心等待审核</div>
      <div class="sub-title">同时请您留意您的邮箱和tg账号，以使我们能与您及时联系。</div>
<!--      <div class="flex btns">-->
<!--        <el-button size="medium" type="primary" style="width:400px;" @click="supplyAgain(waitCheck)">重新申请</el-button>-->
<!--      </div>-->
    </div>

    <el-form ref="form" :model="form" label-position="top" size="small" class="form-con show" v-if="isInited && !showBrief && waitCheck === null && failedCheck === null">
      <el-form-item label="拟申请代理类型" prop="type" style="margin-bottom: 8px;" :error="formError.type">
        <template slot="label">
          <div class="flex">
            <div class="fs-20"><span class="text-red">* </span>拟申请代理类型</div>
            <el-select v-model="form.type" size="small" class="agent-type mgl-10" @change="onChangeType">
              <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="(item, idx) in agentTypes"
                  :key="idx"
                  v-if="!agentTypesObj[item.value].status"
              ></el-option>
            </el-select>
          </div>
        </template>
      </el-form-item>
      <el-form-item
          label="拟申请业务分成比例"
          prop="shareRate"
          :rules="[{ required: true, message: '拟申请业务分成比例不能为空'}, { validator: validateNumber, trigger: 'blur' }]"
      >
        <el-input v-model="form.shareRate" placeholder="拟申请业务分成比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="拟完成拉新用户量" prop="newUserCount" :error="formError.newUserCount">
        <template slot="label">
          <div class="flex custom-label">
            <span class="text-red">*</span>拟完成拉新用户量
            <el-tooltip class="tip-box" effect="light" placement="right-end">
              <i class="el-icon-info fs-15 mgl-5"></i>
              <div slot="content" class="tip-content">
                <div class="fs-14 bold">拉新用户量</div>
                <div class="fs-13">
                  代理商需完成各自板块的拉新任务才能领取业务收<br/>
                  入，在完成拉新任务之前业务收入正常发放但不可<br/>
                  领取。每个板块的拉新任务指自代理申请审核通过<br/>
                  之后的新注册用户完成相应板块的任务要求。
                </div>
                <div class="fs-14 bold mgt-10">活动代理新用户考核标准</div>
                <div class="fs-13">
                  <div v-for="(row, idx) in demandLists" :key="idx">
                    <span class="text-red" v-if="row.must">* </span>{{demandRemarks[row.config] ? demandRemarks[row.config].replace('N', row.value) : '其他要求' + row.value}}
                  </div>
                </div>
                <div class="fs-12 text-info mgt-10">
                  *表示必须满足<br/>
                  表示可选满足，可选完成2个任务完成1个即达标
                </div>
              </div>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="form.newUserCount" placeholder="拟完成拉新用户量">
          <template slot="append">人</template>
        </el-input>
      </el-form-item>
      <el-form-item label="您的联系邮箱" prop="email" :rules="[{ required: true, message: '联系邮箱不能为空'}]">
        <el-input v-model="form.email" placeholder="您的联系邮箱"></el-input>
      </el-form-item>
      <el-form-item label="您的电报号(Telegram)" prop="telegram" :rules="[{ required: true, message: '电报号(Telegram)不能为空'}]">
        <el-input v-model="form.telegram" placeholder="您的电报号(Telegram)"></el-input>
      </el-form-item>
      <el-form-item label="您和您的团队介绍" prop="remark">
        <el-input type="textarea" rows="5" v-model="form.remark" placeholder="方便我们更好的了解您，这将有助于您的申请审核"></el-input>
      </el-form-item>
      <div class="fs-13">
        <div class="text-blue">*代理权益</div>
        享受本团队业务收入的分成;<br/>
        可以配置本团队各层级业务收入分配比例;<br/>
        代表ParaPack与活动发布方进行业务合作洽谈<br/>
      </div>
      <el-button size="medium" type="primary" @click="submitForm" style="width: 100%; margin-top: 20px;">立即申请</el-button>
      <div class="flex mgt-10" style="justify-content: center;">
        <el-checkbox v-model="isChecked">我已阅读并同意<span class="text-blue">代理商协议</span></el-checkbox>
      </div>
    </el-form>
  </div>
</template>

<script>
import * as AgentApi from "@/api/agent";
import {deleteAgent, getDemand} from "@/api/agent";

export default {
  data(){
    return {
      isInited: false,
      isChecked: false,
      agentTypes: [{
        name: '活动代理',
        value: 'activity'
      }, {
        name: '应用代理',
        value: 'app'
      }, {
        name: '广告代理',
        value: 'ad'
      }],
      agentTypesObj: {},
      showBrief: false,
      agentStatus: [],
      waitCheck: null,
      failedCheck: null,
      demandRemarks: {
        TASK_PP_WALLET_TRANSFERS: '使用PP钱包完成N笔转账',
        TASK_PP_WALLET_DAPPS: '使用PP钱包完成N次Dapp交互（签名或发送交易）',
        TASK_PARTICIPATION_IN_ACTIVITIES: '成功参加N次活动（以成功提交为准）',
        TASK_WATCH_VIDEO_MINUTES: '累计观看视频时长N分钟（重复播放的视频只计算一次）',
        TASK_WATCH_VIDEOS: '累计观看视频作品数N部（每部观看时常超15秒）',
        TASK_SUBSCRIBES: '关注视频创作者N人',
        TASK_POST_VIDEOS: '发布视频作品N部',
        TASK_VIDEO_PLAYS: '视频作品累计播放N次',
        TASK_VIDEO_COLLECTIONS: '视频作品累计收藏N',
        TASK_VIDEO_THUMBS_UP: '视频作品累计点赞N',
        TASK_INVITE_USERS: '邀请N个注册用户'
      },
      demandLists: [],
      form: {},
      formError: {}
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    /**
     * 初始化数据
     * @returns {Promise<void>}
     */
    async initData(){
      this.agentTypes.map(r => {
        this.agentTypesObj[r.value] = r;
      });
      // 申请状态
      let hasChecked = false;
      const res = await AgentApi.getStatus();
      if(res && res.data && res.data.apply){
        this.agentStatus = res.data.apply;
        this.agentStatus.map(r => {
          if(r.status === 1 && this.agentTypesObj[r.brokerType]) {
            this.agentTypesObj[r.brokerType].status = 1;
            hasChecked = true;
          }
        });
      }
      for(let i in this.agentStatus){
        const row = this.agentStatus[i];
        if(row.status === 2){
          this.failedCheck = row;
          break;
        }
        if(row.status === 0){
          this.waitCheck = row;
          break;
        }
      }

      this.isInited = true;
      if(this.failedCheck === null && this.waitCheck === null){
        this.showBrief = hasChecked ? false : true;
      }
    },
    async onChangeType(v){
      // demand
      const res = await AgentApi.getDemand({ type: v });
      if(res && res.data && res.data.lists){
        this.demandLists = res.data.lists;
      }
    },
    supplyAgain(row){
      this.form = {
        type: row.brokerType,
        newUserCount: row.newUserCount,
        shareRate: row.shareRate * 100,
        email: row.email,
        telegram: row.telegram,
        remark: row.remark
      };
      this.failedCheck = null;
      this.waitCheck = null;
      this.onChangeType(this.form.type);
    },
    /**
     * 校验表单
     * @param callback
     */
    checkForm(callback = null) {
      this.$refs.form.validate((valid) => {
        // type
        if(!this.form.type){
          this.$set(this.formError, 'type', '请选择拟申请代理类型');
          valid = false;
        } else {
          this.$set(this.formError, 'type', null);
        }
        // newUserCount
        if(!this.form.newUserCount){
          this.$set(this.formError, 'newUserCount', '拟完成拉新用户量必须大于0');
          valid = false;
        } else {
          this.$set(this.formError, 'newUserCount', null);
        }

        if(!this.isChecked){
          this.$message.error('请阅读并同意代理商协议');
          valid = false;
        }
        callback && callback(valid);
      });
    },
    validateNumber(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入数值'));
      }
      if(!/^\d+(\.\d+)?$/.test(value)){
        return callback(new Error('必须为大于0且小于等于100的数字'));
      }
      if (value <= 0 || value > 100) {
        return callback(new Error('必须为大于0且小于等于100的数字'));
      } else {
        callback();
      }
    },
    submitForm(){
      const self = this;
      this.checkForm(async (valid) => {
        if (valid) {
          const postData = self.form;
          const res = await AgentApi.supplyAgent(postData);
          if(res && res.data && res.data.code === 200){
            this.$message.success('提交成功，请等待审核...');
            this.$router.replace('/agent');
          } else {
            this.$message.error(res && res.data ? res.data.msg : '提交失败');
          }
        }
      });
    },
    /**
     * 关闭代理
     * @param row
     */
    async closeAgent(row){
      const res = await AgentApi.deleteAgent({ type: row.brokerType });
      if(res && res.data && res.data.code === 200){
        this.$message.success('申请已关闭');
        this.$router.replace('/agent');
      } else {
        this.$message.error('关闭失败');
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.message-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 50px - 100px);

  .icon {
    height: 100px;
  }
  .title {
    font-size: 20px;
    margin-top: 30px;
  }
  .sub-title {
    font-size: 14px;
    margin-top: 10px;
    line-height: 2.4em;
  }
  .btns {
    margin-top: 30px;
    .el-button {
      min-width: 150px;
      margin: 0 10px;
    }
  }
}

.form-con {
  margin: 80px auto 50px auto;
  width: 400px;
  position: fixed;
  top: -5000px;
  &.show {
    position: unset;
    top: unset;
  }
  .custom-label {
    width: 400px;
    .text-red {
      padding-right: 4px;
    }
  }
  /deep/.el-form-item__label {
    font-weight: bold;
  }
  /deep/.el-form-item__error {
    width: 100%;
    text-align: right;
  }
  .agent-type {
    /deep/input {
      background-color: @light-color;
      line-height: 26px;
      height: 26px;
      color: #fff;
      padding: 0 10px;
      &::placeholder{
        font-size: 12px;
        color: #fff;
        opacity: .8;
      }
    }
    /deep/.el-input {
      i {
        color: #fff;
      }
    }
  }
}

.tip-content {
  line-height: 2em;
}
</style>