<template>
  <div class="login-dialog" v-if="showDialog && !isBindEmail">
    <div class="title">{{$t('login.title')}}</div>

    <div class="top-con" :class="pageName==='twitter-login' ? 'page-twitter' : ''">

      <div class="form-con" v-if="pageName==='email-login' || pageName==='pwd-login'">
        <label>{{$t('login.email')}}</label>
        <el-input v-model="email" :placeholder="$t('login.email')" class="mgt-10"></el-input>
        <div v-if="pageName==='email-login'">
          <label>{{$t('login.verifyCode')}}</label>
          <el-input v-model="code" :placeholder="$t('login.verifyCode')" class="mgt-10">
            <template slot="append">
              <div class="send-code-btn" v-if="codeTimer">{{codeTimer}}s</div>
              <div class="send-code-btn" @click="sendEmailCode" v-else>{{$t('login.sendCode')}}</div>
            </template>
          </el-input>
        </div>
        <div v-else-if="pageName==='pwd-login'">
          <label>{{$t('login.pwd')}}</label>
          <el-input v-model="pwd" :placeholder="$t('login.pwd')" class="mgt-10"></el-input>
        </div>

        <div style="height: 30px;"></div>
        <div class="btn" @click="emailLogin">{{$t('login.toLogin')}}</div>
      </div>

      <div class="twitter-con" v-else-if="pageName==='twitter-login'">
        <div class="twitter-title">{{$t('login.thirdLogin')}}</div>
        <div class="twitter-btn" @click="twitterLogin">
          <img src="../assets/imgs/3.png" />
          <div class="text">{{$t('login.xLogin')}}</div>
        </div>
      </div>

<!--      <div class="v-line"></div>-->

      <div class="qrcode-con text-center">
        <div class="qrcode-title">{{$t('login.scanLogin')}}</div>
        <div class="qrcode" v-if="scanStatus === 'waitConnect'">
          <i class="el-icon-loading"></i>
        </div>
        <div class="qrcode" v-else>
          <canvas ref="qrcodeCanvas"></canvas>
          <div class="scanned" v-if="scanStatus === 'SCANNED'">
            已扫码，请确认
          </div>
          <div class="scanned text-red" v-else-if="scanStatus === 'DENY'">
            已拒绝登录
          </div>
        </div>
        <div style="height: 30px;"></div>
        <a href="https://www.parapack.net/#/download" target="_blank" class="btn-plain" v-if="pageName!=='twitter-login'">
          {{$t('login.downApp')}}
        </a>
      </div>
    </div>

    <div class="agreement" :class="{on:agreement}" @click="agreement = !agreement" v-if="false">
      {{$t('setting.read')}}<a href="https://insex.parapack.net/ServiceAgreement.html" target="_blank">{{$t('setting.userAgreement')}}</a>、<a href="https://insex.parapack.net/PrivacyAgreement.html" target="_blank">{{$t('setting.privacyPolicy')}}</a>，{{$t('setting.userAgreementApfix')}}
    </div>

    <!-- other login -->
    <div class="login-btns" v-if="false">
      <div class="title">
        <div class="text">{{$t('login.otherLogin')}}</div>
      </div>
      <div class="btns">
        <img src="../assets/imgs/3.png" @click="pageName = 'twitter-login'" v-if="pageName !== 'twitter-login'"/>
        <img src="../assets/imgs/4.png" @click="pageName = 'email-login'" v-if="pageName !== 'email-login'"/>
        <img src="../assets/imgs/pc/3.png" @click="pageName = 'pwd-login'" v-if="pageName !== 'pwd-login'"/>
      </div>
    </div>

    <div class="toast-msg" v-if="toastMsg">{{toastMsg}}</div>
  </div>

  <div class="login-dialog small" v-else-if="isBindEmail">
    <div><i class="el-icon-close close-btn" @click="isBindEmail=false"></i></div>
    <div class="title">{{$t('login.bindEmail')}}<div class="sub-title">{{$t('login.bindEmailTip')}}</div></div>
    <div class="form-con">
      <label>{{$t('login.email')}}</label>
      <el-input v-model="email" :placeholder="$t('login.email')" class="mgt-10"></el-input>
      <div>
        <label>{{$t('login.verifyCode')}}</label>
        <el-input v-model="code" :placeholder="$t('login.verifyCode')" class="mgt-10">
          <template slot="append">
            <div class="send-code-btn" v-if="codeTimer">{{codeTimer}}s</div>
            <div class="send-code-btn" @click="sendEmailCode" v-else>{{$t('login.sendCode')}}</div>
          </template>
        </el-input>
      </div>
      <div style="height: 30px;"></div>
      <div class="btn" @click="emailBind">{{$t('login.toBind')}}</div>
      <div class="agreement" :class="{on:agreement}" @click="agreement = !agreement">
        {{$t('setting.read')}}<a href="https://insex.parapack.net/ServiceAgreement.html" target="_blank">{{$t('setting.userAgreement')}}</a>、<a href="https://insex.parapack.net/PrivacyAgreement.html" target="_blank">{{$t('setting.privacyPolicy')}}</a>，{{$t('setting.userAgreementApfix')}}
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import * as AccountApi from "@/api/account";
import * as TwitterApi from "@/api/twitter";

let ws = null, reconnectTimer = null;

export default {
  data(){
    return {
      showDialog: false,
      userInfo: false,
      isBindEmail: false,
      pageName: location.href.indexOf('http://localhost') > -1 ? 'email-login' : 'qrcode-login',
      // form
      agreement: true,
      email: null,
      code: null,
      pwd: null,
      codeTimer: null,
      inviteCode: null,
      // twitter
      twitterLoginUrl: false,
      twitterUser: null,
      toastMsg: null,
      // scan
      scanStatus: 'waitConnect'
    }
  },
  mounted() {
    this.$eventBus.$on('ACCOUNT_INFO', res => {
      this.userInfo = res.userInfo;
      this.twitterLoginUrl = res.twitterLoginUrl;
      if(!this.userInfo){
        this.showDialog = true;
        // 扫码登录
        this.connectWebSocket();
      }
    });

    const path = this.$route.path;
    const { u, icode } = this.$route.query;
    if(path === '/bind-email' && u){
      this.twitterUser = JSON.parse(atob(u));
      this.isBindEmail = true;
      this.showDialog = false;
    }
    if(icode){
      this.inviteCode = icode;
    }

    // 获取推特登录链接
    this.getTwitterLoginUrl();
    setTimeout(() => {
      if(!this.twitterLoginUrl){
        this.getTwitterLoginUrl();
      }
    }, 3000);
  },
  methods: {
    /**
     * 扫码登录
     */
    connectWebSocket(){
      const self = this;
      self.reconnectTimer = null;
      ws = new WebSocket('wss://walletapinew.parapack.net/wallet-app-api/websocket/v1/QRCode');
      ws.onopen = function () {
        ws.send(JSON.stringify({"op":"qrcode"}));
      };
      ws.onclose = function (event) {
        !self.reconnectTimer && (self.reconnectTimer = setTimeout(self.connectWebSocket, 3000));
      };
      ws.onerror = function (error) {
        !self.reconnectTimer && (self.reconnectTimer = setTimeout(self.connectWebSocket, 3000));
      };
      ws.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if(data.msg === 'op success'){
          self.scanStatus = 'WAIT_SCAN';
          setTimeout(() => {
            self.generateQRCode(JSON.stringify({ scenes: data.scenes, sid: data.sid }));
          }, 300);
        } else if(data.msg === 'SCANNED') {
          self.scanStatus = 'SCANNED';
        } else if(data.msg === 'DENY') {
          self.scanStatus = 'DENY';
        } else if(data.msg === 'PASS') {
          const token = data.token;
          if(!token){
            this.$message.error(this.$t('errMsg.loginFailed'));
            return;
          }
          const userData = { token };
          localStorage.setItem('USERINFO', JSON.stringify(userData));
          // 通过token获取用户信息
          AccountApi.getUserInfo().then(res => {
            res.data.token = token;
            console.log(res.data)
            if(res.data.user_type === 0){
              self.$message.error(self.$t('errMsg.loginFailed') + ':' + self.$t('errMsg.noLoginPermit'));
              return;
            }
            if(res && res.data && res.data.id){
              localStorage.setItem('USERINFO', JSON.stringify(res.data));
              self.$eventBus.$emit('LOGIN-SUCCESS', res.data);
              self.$message.success(self.$t('successMsg.loginSuccess'));
              self.showDialog = false;
            } else {
              self.$message.error(self.$t('errMsg.loginFailed'));
            }
          });
        }
      }
    },
    /**
     * 获取推特登录链接
     */
    getTwitterLoginUrl(){
      TwitterApi.getLogin().then(loginRes => {
        if(loginRes && loginRes.data){
          this.twitterLoginUrl = loginRes.data.loginUrl;
        }
      });
    },
    twitterLogin(){
      if(!this.agreement){
        this.$message.error(this.$t('errMsg.pleaseRead'));
        return;
      }
      if(!this.twitterLoginUrl){
        this.$message.error(this.$t('errMsg.badNetwork'));
        return;
      }
      window.open(this.twitterLoginUrl, '_self');
    },
    /**
     * 发送验证码
     * @returns {Promise<void>}
     */
    async sendEmailCode(){
      if(this.codeTimer){
        return;
      }
      if(!this.email){
        this.$message.error(this.$t('errMsg.pleaseInputEmail'));
        return;
      }
      const res = await AccountApi.sendEmailCode({ email: this.email });
      if(res && res.data && res.data === 'success'){
        this.codeTimer = 60;
        const timer = setInterval(() => {
          this.codeTimer = this.codeTimer - 1;
          if(this.codeTimer === 0){
            this.codeTimer = null;
            clearInterval(timer);
          }
        }, 1000);
      } else {
        this.$message.error(res && res.data && res.data.msg ? res.data.msg : this.$t('errMsg.sendFailed'));
      }
    },
    /**
     * 邮箱登录
     * @returns {Promise<void>}
     */
    async emailLogin(){
      if(!this.email){
        this.$message.error(this.$t('errMsg.pleaseInputEmail'));
        return;
      }
      if(this.loginMode === 'code' && !this.code){
        this.$message.error(this.$t('errMsg.pleaseInputCode'));
        return;
      }
      if(this.loginMode === 'pwd' && !this.pwd){
        this.$message.error(this.$t('errMsg.pleaseInputPwd'));
        return;
      }
      if(!this.agreement){
        this.$message.error(this.$t('errMsg.pleaseRead'));
        return;
      }
      const res = await AccountApi.emailLogin({
        email: this.email,
        code: this.code,
        pwd: this.pwd,
        icode: this.inviteCode
      });
      if(res && res.data && res.data.id){
        this.$eventBus.$emit('LOGIN-SUCCESS', res.data);
        this.$message.success(this.$t('successMsg.loginSuccess'));
        this.showDialog = false;
        localStorage.setItem('USERINFO', JSON.stringify(res.data));
      } else {
        this.$message.error(res && res.data && res.data.msg ? res.data.msg : this.$t('errMsg.loginFailed'));
      }
    },
    async emailBind(){
      if(!this.email){
        this.$message.error(this.$t('errMsg.pleaseInputEmail'));
        return;
      }
      if(!this.code){
        this.$message.error(this.$t('errMsg.pleaseInputCode'));
        return;
      }
      if(!this.agreement){
        this.$message.error(this.$t('errMsg.pleaseRead'));
        return;
      }
      const res = await AccountApi.emailBind({
        email: this.email,
        code: this.code,
        twitter: {
          uid: this.twitterUser.xId,
          name: this.twitterUser.xName
        }
      });
      if(res && res.data && res.data.id){
        this.$eventBus.$emit('LOGIN-SUCCESS', res.data);
        this.$message.success(this.$t('successMsg.bindSuccess'));
        this.showDialog = false;
        localStorage.setItem('USERINFO', JSON.stringify(res.data));
        setTimeout(() => {
          location.replace(location.href.split('/#/')[0]);
        }, 2000);
      } else {
        this.$message.error(res && res.data && res.data.msg ? res.data.msg : this.$t('errMsg.bindFailed'));
      }
    },
    /**
     * 生成登录二维码
     */
    generateQRCode(msg) {
      const canvas = this.$refs.qrcodeCanvas;
      QRCode.toCanvas(canvas, msg, error => {
        if (error) console.error(error)
      });
    },
    toast(msg){
      this.toastMsg = msg;
      setTimeout(() => {
        this.toastMsg = null;
      }, 2000);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.login-dialog {
  width: 400px;
  height: 350px;
  .top-con {
    display: flex;
  }

  .twitter-con {
    flex: 1;

    a {
      text-decoration: none;
    }
    .twitter-title {
      text-align: center;
      margin-top: 30px;
      padding-bottom: 28px;
      font-size: 16px;
    }
    .twitter-btn {
      margin: 0 auto;
      width: 135px;
      height: 135px;
      border-radius: 6px;
      box-shadow: 0 0 10px #eee;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 60px;
        height: 60px;
      }
      .text{
        font-size: 16px;
        margin-top: 16px;
        color: #000;
      }
    }
  }

  .qrcode-con {
    width: 100%;
    margin: 30px 0;
    .qrcode-title {
      padding-bottom: 28px;
      text-align: center;
    }
    .qrcode {
      width: 135px;
      height: 135px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 0 10px #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      position: relative;
      i {
        font-size: 18px;
      }
      canvas {
        width: 100% !important;
        height: 100% !important;
        border-radius: 6px;
      }
      .scanned {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: rgba(0,0,0,.3);
        font-size: 13px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
      }
    }
  }
  .v-line {
    width: 1px;
    height: 260px;
    margin: 0 25px;
    background-color: #eee;
  }
  .page-twitter {
    .qrcode-con {
      width: auto;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .qrcode-title {
        margin-top: 30px;
      }
    }
    .v-line {
      height: 220px;
    }
  }

  .btn-plain {
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid @light-color;
    padding: 7px 16px;
    color: @light-color;
    text-align: center;
    cursor: pointer;
  }

  .login-btns {
    margin-top: 40px;
    .title {
      font-size: 14px;
      color: @sub-font-color;
      text-align: center;
      position: relative;
      margin: 0 auto;
      &::before{
        display: block;
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background-color: @sub-font-color;
        top: 50%;
        opacity: .3;
      }
      .text {
        width: 90px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        background-color: @panel-bg-color;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      img {
        width: 50px;
        height: 50px;
        margin: 0 50px;
      }
    }
  }
}

.toast-msg {
  position: fixed;
  bottom: 50px;
  background-color: #eee;
  font-size: 13px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 18px;
}
</style>