import { eventBus } from '@/utils/eventBus.js';
import Vue from 'vue'

export function fetchData(action, callback, errorCallback, resolve, reject, proxyIP){
    const params = action.params;
    const requestParama = {
        method: params.method
    }
    if(params.headers){
        requestParama.headers = params.headers;
    }
    if(params.body){
        requestParama.body = params.body;
    }
    if(params.query){
        requestParama.query = params.query;
    }
    fetch(action.url, requestParama).then(res => {
        return res.json()
    }).then(res => {
        const returnData = {
            status: 200,
            msg: 'success',
            data: res && res.data ? res.data : res
        };

        callback && callback(returnData);
        resolve && resolve(returnData);
    }).catch(err => {
        const errorData = {
            status: 0,
            msg: err.message
        }
        errorCallback && errorCallback(errorData);
        reject && reject(errorData);
    });
}

export function formatDecimal(number) {
    // 将科学计数法转为字符串
    const numStr = parseFloat(number).toString();

    // 查找小数点的位置
    const dotIndex = numStr.indexOf('.');

    // 如果没有小数点，直接返回
    if (dotIndex === -1) {
        return numStr;
    }

    // 获取小数点后零的个数
    const zerosCount = numStr.substr(dotIndex + 1).match(/0*/)[0].length;
    if(zerosCount < 5){
        return numStr;
    }
    // 格式化成指定格式
    return `${numStr.substr(0, dotIndex)}.0{${zerosCount}}${numStr.substr(dotIndex + zerosCount + 1)}`;
}

export function formatString(str, first, last, replace){
    if(!str || str.length < first + last){
        return str;
    }
    replace = replace ? replace : '...'
    return str.substring(0, first) + replace +  str.substring(str.length-last)
}

export function formatNumber(number) {
    if(!number){
        return number;
    }
    if (number >= 1e9) {
        return (number / 1e9).toFixed(1) + 'B';
    } else if (number >= 1e6) {
        return (number / 1e6).toFixed(1) + 'M';
    } else if (number >= 1e3) {
        return (number / 1e3).toFixed(1) + 'K';
    } else {
        return number.toString();
    }
}

export function copyText(str){
    const input = document.createElement("input")
    input.value = str
    document.body.appendChild(input)
    input.select()
    document.execCommand("Copy")
    document.body.removeChild(input)
    eventBus.$emit('message', 'common.copySuccess', 'success');
}

export function fixed(number, decimal){
    decimal = decimal ? decimal : 6;
    return typeof(number) != 'undefined' ? parseFloat(parseFloat(number).toFixed(decimal)) : null
}

/*
 * 随机数，前后全包含
 */
export function random (min, max) {
    return Math.round(Math.random() * (max - min)) + min;
}