<template>
  <div>
    <div class="page-title">{{$t('events.collectionManage')}} - {{$t('events.collectionsLists')}}</div>
    <div class="top-tabs">
      <div class="tab-item" :class="{active: eventActiveTab==='running'}" @click="loadEventsLists(1, 1),eventActiveTab='running'">
        {{$t('events.isRunning')}}({{runningNum}})
      </div>
      <div class="tab-item" :class="{active: eventActiveTab==='published'}" @click="loadEventsLists('1'),eventActiveTab='published'">
        {{$t('events.isPublished')}}({{checkedNum}})
      </div>
      <div class="tab-item" :class="{active: eventActiveTab==='check'}" @click="loadEventsLists('-1,0'),eventActiveTab='check'">
        {{$t('events.isChecking')}}({{waitCheckNum}})
      </div>
      <div class="tab-item" :class="{active: eventActiveTab==='end'}" @click="loadEventsLists(null, -1),eventActiveTab='end'">
        {{$t('events.isEnd')}}({{endNum}})
      </div>

      <div class="flex-1"></div>
      <div class="flex" v-if="eventActiveTab !== 'draft'">
        <el-input v-model="searchKword" size="mini">
          <template slot="append">
            <i class="el-icon-search pointer" @click="searchEvents"></i>
          </template>
        </el-input>
      </div>
    </div>

    <!-- 已发布 -->
    <div class="events-lists" v-if="['published', 'running', 'end'].indexOf(eventActiveTab) > -1">
      <div class="event-item" v-for="(item, idx) in eventsLists" :key="idx">
        <div class="flex" style="margin-right: 20px;" v-if="showSelectIds && item.type !== 2 && item.end_time > new Date().getTime()/1000">
          <el-checkbox v-model="checkIds[item.id]"></el-checkbox>
        </div>
        <router-link :to="'/coll-info?id=' + item.id + '&eids=' + item.event_ids" class="cover-box">
          <div class="type-tag" v-if="item.type === 2">{{$t('events.collection')}}</div>
          <el-image class="cover" :src="item.cover[item.cover.def]" v-if="item.cover && item.cover.def"></el-image>
        </router-link>
        <div class="brief-con">
          <div class="name">
            <router-link :to="'/coll-info?id=' + item.id + '&eids=' + item.event_ids">
              {{item.title[lang] || item.title[item.title.def]}}
            </router-link>
          </div>

          <div class="tags" v-if="item.rewards">
            <div class="fs-14 bold">{{item.rewards.token_amount}} {{item.rewards.token_name}}</div>
            <div class="tag fs-12">{{rewardTypes[item.rewards.reward_type]}}</div>
          </div>

          <div class="tags" v-if="item.type === 2">
            <div class="ellipsis" style="max-height: 20px;max-width: 500px;">{{ item.brief[lang] || item.brief[item.brief.def]}}</div>
          </div>

          <div class="time" v-if="now - item.end_time > 0">
            {{$func.getDate(item.start_time)}} - {{$func.getDate(item.end_time)}}
          </div>
          <div class="time" v-else-if="now - item.start_time < 0">
            {{$func.getDate(item.start_time)}} - {{$func.getDate(item.end_time)}}
            <span class="light">({{$t('events.startTime')}} {{countDown(item.start_time - now)}})</span>
          </div>
          <div class="time" v-else>
            {{$func.getDate(item.start_time)}} - {{$func.getDate(item.end_time)}}
            <span class="green">({{$t('events.endTime')}} {{countDown(item.end_time - now)}})</span>
          </div>

          <div class="statistic-con">
            <div class="status grey" v-if="now - item.end_time > 0">{{$t('events.isEnd')}}</div>
            <div class="status orange" v-else-if="now - item.start_time < 0">{{$t('events.waitRunning')}}</div>
            <div class="status green" v-else>{{$t('events.isRunning')}}</div>

            <div class="flex" v-if="item.type === 2">
              <div class="value">{{$t('events.eventsCount')}} {{item.event_ids.split(',').length}}</div>
              <div class="value">{{$t('events.view')}} {{item.view_count}}</div>
              <div class="value">{{$t('events.join')}} {{item.join_count}}</div>
            </div>
            <div class="flex" v-else>
              <div class="value">{{$t('events.view')}} {{item.view_count}}</div>
              <div class="value">{{$t('events.join')}} {{item.join_count}}</div>
              <div class="value">{{$t('events.finished')}} {{item.finished_count}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="eventsLists.length === 0">
        <i class="el-icon-warning-outline"></i>
        <div class="remark">{{$t('common.noData')}}</div>
      </div>
      <div class="pagination" v-if="checkedNum > 20">
        <el-pagination
            layout="prev, pager, next"
            :total="checkedNum"
            :page-size="20"
            @current-change="(p)=> loadEventsLists(1, null, p)"
        ></el-pagination>
      </div>
    </div>

    <!-- 待审核 -->
    <div class="events-lists grid" v-if="eventActiveTab === 'check'">
      <div class="event-item" v-for="(item, idx) in eventsCheckLists" :key="idx">
        <router-link :to="'/coll-info?id=' + item.id + '&eids=' + item.event_ids">
          <el-image class="cover" :src="item.cover[item.cover.def]" v-if="item.cover && item.cover.def"></el-image>
        </router-link>
        <div class="handle-con">
          <div class="type-tag" v-if="item.type === 2">{{$t('events.collection')}}</div>
          <div class="fs-13 bold" :class="{'text-red': item.status === -1}">{{item.status === -1 ? $t('events.failedCheck') : $t('events.checking')}}</div>
          <div class="fs-12 mgt-5 text-red" v-if="item.status === -1">{{item.ref_msg}}</div>
          <div class="fs-12 mgt-5" v-else>{{$t('events.submitBy')}}{{$func.getDate(item.create_time)}}</div>
          <div class="btns">
            <router-link :to="'/event-info?id=' + item.id" style="margin-right: 20px;" v-if="item.type !== 2">
              <el-button size="mini">{{$t('events.preview')}}</el-button>
            </router-link>
            <router-link :to="'/publish-collection?id=' + item.id" v-if="item.status === -1">
              <el-button size="mini" type="primary">{{$t('events.edit')}}</el-button>
            </router-link>
          </div>
        </div>
        <div class="brief-con">
          <div class="name">
            <div v-if="item.type === 2">{{item.title[lang] || '[' + lang.split('_')[0] + ']' + $t('events.notSetTitle')}}</div>
            <router-link :to="'/coll-info?id=' + item.id + '&eids=' + item.event_ids" v-else>
              {{item.title[lang] || '[' + lang.split('_')[0] + ']' + $t('events.notSetTitle')}}
            </router-link>
          </div>

          <div class="tags" v-if="item.rewards">
            <div class="fs-14 bold">{{item.rewards.token_amount}} {{item.rewards.token_name}}</div>
            <div class="tag fs-12">{{rewardTypes[item.rewards.reward_type]}}</div>
          </div>
          <div class="tags ellipsis" v-if="item.type === 2">
            {{ item.brief ? item.brief[lang] : '' }}
          </div>

          <div class="user">
            <img :src="userInfo.avatar || '../../assets/imgs/2.png'" class="avatar"/>
            <span class="name">{{userInfo.nickname || $com.formatString(userInfo.email, 8, 0)}}</span>
            <div class="del" @click="$set(eventsCheckLists[idx], 'toDel', true)" v-if="item.status===-1">
              <i class="el-icon-delete"></i>
            </div>
          </div>
        </div>
        <div class="del-confirm" v-if="eventsCheckLists[idx].toDel">
          <div class="is-del-con">
            <div class="fs-15">{{$t('events.ifDel')}}</div>
            <div class="btns">
              <el-button size="mini" @click="$set(eventsCheckLists[idx], 'toDel', false)">{{$t('events.cancel')}}</el-button>
              <el-button size="mini" type="primary" @click="delEvent(item)">{{$t('events.confirm')}}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="eventsCheckLists.filter(r => r.status!==1).length === 0">
        <i class="el-icon-warning-outline"></i>
        <div class="remark">{{$t('common.noData')}}</div>
      </div>
    </div>
    <div class="pagination" v-if="waitCheckNum > 20">
      <el-pagination
          layout="prev, pager, next"
          :total="waitCheckNum"
          :page-size="20"
          @current-change="(p)=> loadEventsLists('-1,0', null, p)"
      ></el-pagination>
    </div>

    <!-- draft -->
    <div class="events-lists grid" v-if="eventActiveTab === 'draft'">
      <div class="event-item" v-for="(item, idx) in draftLists" :key="idx">
        <router-link :to="'/event-publish?did=' + item.id">
          <el-image class="cover" :src="item.cover[item.coverLang]" v-if="item.cover && item.coverLang"></el-image>
        </router-link>
        <div class="brief-con">
          <div class="name">
            <router-link :to="'/event-publish?did=' + item.id">
              {{item.title ? item.title[item.titleLang] : $t('events.notSetTitle')}}
            </router-link>
          </div>
          <div class="tags" v-if="item.rewards">
            <div class="fs-14 bold">{{item.rewards.token_amount}} {{item.rewards.token_name}}</div>
          </div>
          <div class="user">
            <img :src="userInfo.avatar || '../../assets/imgs/2.png'" class="avatar"/>
            <span class="name">{{userInfo.nickname || $com.formatString(userInfo.email, 8, 0)}}</span>
            <div class="del" @click="$set(draftLists[idx], 'toDel', true)">
              <i class="el-icon-delete"></i>
            </div>
          </div>
        </div>
        <div class="del-confirm" v-if="draftLists[idx].toDel">
          <div class="is-del-con">
            <div class="fs-15">{{$t('events.isDeleteDraft')}}</div>
            <div class="btns">
              <el-button size="mini" @click="$set(draftLists[idx], 'toDel', false)">{{$t('common.cancel')}}</el-button>
              <el-button size="mini" type="primary" @click="delDraft(item)">{{$t('common.confirm')}}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="draftLists.length === 0">
        <i class="el-icon-warning-outline"></i>
        <div class="remark">{{$t('common.noData')}}</div>
      </div>
    </div>

    <div class="loading-con" v-if="showLoading">
      <i class="el-icon-loading"></i>
      <span class="msg">{{$t('common.loading')}}</span>
    </div>
  </div>
</template>

<script>
import * as EventsApi from "@/api/events";
import * as func from "@/utils/func";
import * as EventsV2Api from "@/api/eventsV2";

const currentLanguage = localStorage.getItem('CURRENT_LANGUAGE');

export default {
  data(){
    return {
      lang: currentLanguage ? currentLanguage : 'en_US',
      userInfo: {},
      eventActiveTab: 'published',
      searchKword: '',
      rewardTypes: {},
      eventsLists: [],
      eventsCheckLists: [],
      draftLists: [],
      runningNum: 0,
      endNum: 0,
      checkedNum: 0,
      waitCheckNum: 0,
      draftNum: 0,
      countdownTimer: null,
      now: Math.round(new Date().getTime()/1000),
      showLoading: false,
      queryParams: {},
      showSelectIds: false,
      checkIds: {}
    }
  },
  async mounted() {
    this.rewardTypes = {
      1: this.$t('events.getReward'),
      2: this.$t('events.fcfs'),
      3: this.$t('events.divideAmount')
    };

    const userInfo = localStorage.getItem('USERINFO');
    this.userInfo = userInfo ? JSON.parse(userInfo) : {};

    await this.loadEventsLists(1);

    this.countdownTimer = setInterval(() => {
      this.now = Math.round(new Date().getTime()/1000);
    }, 1000);
  },
  beforeDestroy() {
    this.countdownTimer && clearInterval(this.countdownTimer);
  },
  methods:{
    /**
     * 搜索活动
     */
    searchEvents(){
      if(!this.searchKword){
        return;
      }
      this.loadEventsLists(
          this.queryParams.status || null,
          this.queryParams.progress || null,
          1
      );
    },
    /**
     * 加载活动列表
     * @returns {Promise<void>}
     */
    async loadEventsLists(status = 1, progress = null, page = 1){
      this.showLoading = true;
      const params = {
        sort: 'create_time',
        desc: true,
        page
      }
      if(status !== null){
        params.status = status;
      }
      if(progress !== null){
        params.progress = progress;
      }
      if(this.searchKword){
        params.title = this.searchKword;
      }
      this.queryParams = params;
      const res = await EventsApi.manageCollections(params);
      if(res && res.data){
        res.data.lists.sort((a, b) => b.create_time - a.create_time);

        if(status === '-1,0'){
          this.eventsCheckLists = res.data.lists;
        } else {
          this.eventsLists = res.data.lists;
        }
        this.checkedNum = parseInt(res.data.checkedNum);
        this.waitCheckNum = parseInt(res.data.waitCheckNum);
        this.draftNum = parseInt(res.data.draftNum);
        this.runningNum = parseInt(res.data.runningNum);
        this.endNum = parseInt(res.data.endNum);
      }

      this.showLoading = false;
    },
    /**
     * 选择活动
     */
    cancelSelectedIds() {
      this.checkIds = {};
      this.showSelectIds = false;
    },
    createCollection() {
      let ids = [];
      for(let id in this.checkIds){
        if(this.checkIds[id]){
          ids.push(id);
        }
      }
      if(ids.length > 1){
        let startTime = null, endTime = null;
        for(let i in this.eventsLists){
          const item = this.eventsLists[i];
          if(!startTime || startTime - item.start_time > 0){
            startTime = item.start_time;
          }
          if(!endTime || endTime - item.end_time < 0){
            endTime = item.end_time;
          }
        }
        this.$router.push('/publish-collection?ids=' + ids.join(',') + '&startTime=' + startTime + '&endTime=' + endTime);
      } else {
        this.$message.error(this.$t('events.minTwoEvents'));
      }
    },
    /**
     * 加载草稿列表
     * @returns {Promise<void>}
     */
    async loadDraftLists(){
      this.showLoading = true;
      const res = await EventsApi.getDraft();
      if(res && res.data){
        res.data.sort((a, b) => b.update_time - a.update_time);
        this.draftLists = res.data.map(row => {
          let item = this.$func.toJson(row.detail);
          console.log(item)
          item.id = row.id;
          //item = this.parseEventByLang(item, 'en_US');

          return item;
        });
      }
      this.showLoading = false;
    },
    /**
     * 删除草稿
     * @param item
     * @returns {Promise<void>}
     */
    async delDraft(item){
      const res = await EventsApi.delDraft({ draftId: item.id });
      if(res && res.data){
        this.$message.success(this.$t('common.deleteSuccessful'));
        this.loadDraftLists();
      } else {
        this.$message.error(this.$t('common.deleteFailed'));
      }
    },
    /**
     * 删除合集
     * @param item
     */
    delEvent(item){
      EventsV2Api.delCollection({ id: item.id }).then(res => {
        if(res && res.msg === 'success'){
          this.$message.success(this.$t('common.deleteSuccessful'));
          this.loadEventsLists(
              this.queryParams.status || null,
              this.queryParams.progress || null,
              1
          );
        } else {
          this.$message.error(this.$t('common.deleteFailed'));
        }
      });
    },
    /**
     * 倒计时
     * @param second
     */
    countDown(second){
      const day = parseInt(second / (3600*24));
      second = second % (3600*24);
      let hour = parseInt(second / 3600);
      hour = hour < 10 ? '0' + hour : hour;
      second = second % 3600;
      let minute = parseInt(second / 60);
      minute = minute < 10 ? '0' + minute : minute;
      second = second % 60;
      second = second < 10 ? '0' + second : second;
      return (day ? day + 'D ' : '') + (hour + ':') + (minute + ':') + second;
    },
    parseEventByLang(item, lang){
      try {
        item.title = func.filterStr(item.title);
        item.title = item.title ? JSON.parse(item.title) : {};
      } catch (e) {
        item.title = { 'en_US': item.title, def: 'en-en_US' };
      }
      try {
        item.cover = item.cover ? JSON.parse(item.cover) : {};
      } catch (e) {
        item.cover = { 'en_US': item.cover, def: 'en_US' };
      }
      try {
        item.content = func.filterStr(item.content);
        item.content = item.content ? JSON.parse(item.content) : {};
      } catch (e) {
        item.content = { 'en_US': item.content, def: 'en_US' };
      }

      item.title = item.title[item.titleLang] ?? item.title[item.titleLang];
      item.content = item.content[item.contentLang] ?? item.content[item.contentLang];
      item.cover = item.cover[item.coverLang] ?? item.cover[item.coverLang];

      return item;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.events-lists {
  &.grid {
    display: flex;
    flex-wrap: wrap;
    .event-item {
      width: calc(20% - 10px);
      flex-direction: column;
      background-color: #eee;
      margin: 5px 5px;
      padding: 0;
      border-radius: 8px;
      position: relative;
      .cover {
        width: 121px;
        height: 121px;
        margin-left: 50%;
        margin-top: 15px;
        transform: translateX(-50%);
      }
      .brief-con {
        padding: 10px;
        a {
          text-decoration: none;
          color: #000;
        }
        .name {
          max-height: 36px;
          overflow: hidden;
        }
      }
      .handle-con {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 151px;
        background-color: rgba(0, 0, 0, .6);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        .btns {
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
        .type-tag {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          background-color: rgba(0, 0, 0, .6);
          padding: 3px 5px;
          border-bottom-right-radius: 8px;
          border-top-left-radius: 5px;
        }
      }
    }
    .del-confirm {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .3);
      border-radius: 5px;
      .is-del-con {
        width: 60%;
        height: 55px;
        background-image: url("../../assets/imgs/pc/14.png");
        background-size: 100% 100%;
        padding: 15px 15px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        .btns {
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
      }
    }
  }
  .event-item {
    display: flex;
    padding-bottom: 15px;
    .cover-box {
      position: relative;
      .type-tag {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .6);
        border-radius: 8px 0 5px 0;
        color: #fff;
        font-size: 12px;
        padding: 0 3px;
        z-index: 1;
      }
    }
    .cover {
      width: 120px;
      height: 120px;
      margin-right: 15px;
      border-radius: 8px;
    }
    .brief-con {
      a {
        text-decoration: none;
        color: #000;
      }
      .name {
        font-weight: bold;
        font-size: 14px;
      }
      .tags {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;

        .tag {
          margin: 0 10px;
          color: @light-color;
        }
      }
      .time {
        font-size: 13px;
        color: #aaa;
        margin-top: 10px;
        .light {
          color: @tag-color;
        }
        .green {
          color: @green;
        }
      }
      .statistic-con {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-top: 15px;
        .status {
          background-color: @light-color;
          color: #fff;
          padding: 5px 8px;
          border-radius: 5px;
          margin-right: 15px;
          &.green {
            background-color: @green;
          }
          &.red {
            background-color: @red;
          }
          &.orange {
            background-color: orange;
          }
          &.grey {
            background-color: #ccc;
          }
        }
        .value {
          color: #aaa;
          margin-right: 20px;
          &.text-red {
            color: @red;
          }
        }
      }
    }
    a {
      color: @sub-font-color;
    }
    .user {
      display: flex;
      align-items: center;
      margin-top: 8px;
      .avatar {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
      .name {
        font-size: 13px;
        flex: 1;
        padding-left: 5px;
      }
      .del{
        color: @red;
        font-size: 13px;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1200px){
  .events-lists {
    &.grid {
      .event-item {
        width: calc(25% - 10px);
      }
    }
  }
}
@media screen and (max-width: 1000px){
  .events-lists {
    &.grid {
      .event-item {
        width: calc(33.33% - 10px);
      }
    }
  }
}
@media screen and (max-width: 800px){
  .events-lists {
    &.grid {
      .event-item {
        width: calc(50% - 10px);
      }
    }
  }
}

.footer-handle-bar {
  position: fixed;
  bottom: 20px;
  left: @left-menu-width;
  right: 0;
  text-align: center;
}
</style>