import Vue from 'vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter)

import Index from './pages/Index';
import Down from './pages/Down.vue';
import Wallet from './pages/Wallet.vue';
import Activity from './pages/Activity.vue';
import Home from './pages/Home';
import Publish from './pages/Publish';
import EventLists from './pages/EventLists';
import EventInfo from './pages/EventInfo';
import EventManage from './pages/events/Lists';
import Collections from './pages/events/Collections';
import CollInfo from './pages/events/CollInfo';
import EventPublish from './pages/Publish';
import BasicInfo from './pages/user/Basic';
import Setting from './pages/user/Setting';
import Agreement from './pages/user/Agreement';
import DappLists from './pages/dapp/Lists';
import DappPublish from './pages/dapp/Publish';
import AgentIndex from './pages/agent/Index';
import AgentSupply from './pages/agent/Supply';
import AgentShares from './pages/agent/Shares';
import PublishCollection from './pages/events/PublishCollection';
import Temp from './pages/Temp.vue';

const routes = [
  {
    path: location.href.indexOf('https://business.') === -1 ? '/' : '/index',
    name: 'Index',
    component: Index
  },
  {
    path: '/download',
    name: 'Down',
    component: Down
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity
  },
  {
    path: location.href.indexOf('https://business.') > -1 ? '/' : '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/publish',
    name: 'Publish',
    component: Publish
  },
  {
    path: '/event-lists',
    name: 'EventLists',
    component: EventLists
  },
  {
    path: '/event-info',
    name: 'EventInfo',
    component: EventInfo
  },
  {
    path: '/event-manage',
    name: 'EventManage',
    component: EventManage
  },
  {
    path: '/event-publish',
    name: 'EventPublish',
    component: EventPublish
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections
  },
  {
    path: '/coll-info',
    name: 'CollInfo',
    component: CollInfo
  },
  {
    path: '/publish-collection',
    name: 'PublishCollection',
    component: PublishCollection
  },
  {
    path: '/basic-info',
    name: 'BasicInfo',
    component: BasicInfo
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: Agreement
  },
  {
    path: '/dapps',
    name: 'DappLists',
    component: DappLists
  },
  {
    path: '/dapp-publish',
    name: 'DappPublish',
    component: DappPublish
  },
  {
    path: '/agent',
    name: 'AgentIndex',
    component: AgentIndex
  },
  {
    path: '/agent-supply',
    name: 'AgentSupply',
    component: AgentSupply
  },
  {
    path: '/agent-shares',
    name: 'AgentShares',
    component: AgentShares
  },
  {
    path: '/temp',
    name: 'Temp',
    component: Temp
  }
];

const router = new VueRouter({
  mode: 'hash', // history, hash
  routes
})

export default router;
