module.exports = {
    header: {
        'logout': '退出'
    },
    web: {
        header: {
            index: '首頁',
            found: '發現',
            ecology: '生態',
            activity: '活動',
            download: '下載',
            wallet: '錢包',
            video: '視頻',
            service: '服務平臺'
        },
        footer: {
            slogn: '專註Web3活動支持、營銷增長，實現流量的聚合增長',
            useApp: '體驗APP',
            viewWhiteBook: '查看白皮書',
            wallet: '錢包',
            appPublish: '應用上架',
            tokenPublish: '代幣提交',
            video: '視頻',
            kolJoin: 'KOL入駐',
            groupJoin: '社區入駐',
            creatorCenter: '創作中心',
            videoGuide: '視頻教程',
            activity: '活動',
            activityGuide: '活動教程',
            activityPublish: '活動發布',
            airdrop: '聯合空投',
            support: '支持',
            about: '關於ParaPack',
            contact: '聯系ParaPack',
            agentSupply: '代理商申請',
            serverPlatform: '服務平臺',
            serviceAgreement: '服務協議',
            privacy: '隱私條款'
        },
        index: {
            'web3Title': 'WEB3.0',
            'web3SubTitle': '全球一站式自媒體聚合平臺',
            'web3Brief': `通過ParaPack，用戶可以實現同時管理多個自媒體賬號，實現統一<br/>管理、數據分析、內容同步、多渠道營銷等功能，ParaPack的目標是幫助內容創作者和營銷人員簡化工作流程，<br/>提高內容傳播的效率和效果。`,
            'find': '探索',
            'userCount': '註冊用戶',
            'dailyActiveCount': '日活用戶',
            'merchants': '入駐商家',
            'web3Title2': 'Web3流量聚合營銷',
            'web3SubTitle2': `以去中心化錢包為載體，活動和AI短視頻為工具`,
            'web3Title3': '多鏈Web3錢包',
            'web3SubTitle3': `去中心化加密資產錢包，兼容多種區塊鏈網絡，支持多種主流加密資產`,
            'walletTag1': '支持管理20+網絡Token&NFTS',
            'walletTag2': 'Dapp區塊瀏覽器及Magic Sotre',
            'walletTag3': '鏈上非托管交易安全快捷',
            'web3Title4': '任務平臺',
            'web3SubTitle4': `鏈接用戶與項目方，用戶增長營銷工具，社區成長有力幫手`,
            'taskTag1': '聚合鏈上鏈下多維度數據驅動',
            'taskTag2': '先行驗資自動發獎透明有保障',
            'taskTag3': '多渠道推廣方式高效易傳播',
            'web3Title5': 'AI短視頻',
            'web3SubTitle5': `AIGC數字人短視頻創作平臺，賦能於營銷和內容傳播`,
            'videoTag1': '專註Web3內容分享打造行業IP',
            'videoTag2': 'AIGC智能應用易用高效成本低',
            'videoTag3': '多板塊聯動一站式分',
            'web3Title6': '夥伴關系',
            'web3SubTitle6': `去中心化加密資產錢包，兼容多種區塊鏈網絡，支持多種主流加密資產去中心化<br/>
        加密資產錢包，兼容多種區塊鏈網絡，支持多種主流加密資產去中心化加密資產錢包，兼容多種區`,
            'web3Title7': '流量聚合轉化增長'
        },
        activity: {
            ppCompagin: 'ParaPackCompagin',
            ppCompaginSubTitle: 'Web3任務管理及流量增長',
            joinActivity: '參與活動',
            publishActivity: '發布活動',
            regUsers: '註冊用戶',
            activeUsers: '日活用戶',
            shopes: '入駐商家',
            publishCount: '活動發布',
            rewardsAmount: '獎勵規模',
            title2: '聚合鏈上鏈下',
            subTitle2: '多維度用戶行為考量，不同平臺用戶相互轉化',
            title3: '矩陣式傳播',
            subTitle3: '多板塊互聯，視頻、廣告、應用介紹等多形式並行宣發',
            title4: '獎勵公正透明',
            subTitle4: '活動獎勵提前進入金庫鎖定，保障活動的真實性和獎勵的發放',
            title5: '易用的數據服務',
            subTitle5: '提供多維度數據服務，幫助發布者做好效果評估和用戶分析'
        },
        down: {
            title: '全球一站式自媒體聚合平臺',
            subTitle: 'Global one-stop self media aggregation platform'
        },
        wallet: {
            title: 'ParaPack Wallet',
            subTitle: '一站式的Web3入口及服務',
            brief: `對消費者來說，數字錢包是免費的，可以相當容易得到。例如，當消費者在建立<br/>了處理服務器端數字錢包的商家網站上購買東西時，他把其名字、付款額和送貨<br/>信息輸入到商家自己的表格。`,
            userScale: '用戶規模',
            txs: '日均交易',
            swap: '閃兌規模',
            title2: '加密資產支持',
            subTitle2: '隨時隨地安全管理加密資產',
            title3: '快速便捷的閃兌服務',
            brief3: `支持不同公鏈資產跨鏈兌換，去中心化交易，<br/>安全便捷`,
            title4: '豐富的Magic Store',
            brief4: `Web3最新最熱DAPP/APP,內置區塊瀏覽器，<br/>輕松訪問Dapp`,
            title5: '完善的應用生態',
            subTitle5: '我們正在開發和即將開發的應用功能，打造更加完善的生態布局',
            tag1: '行業資訊',
            tag1Brief: `接入Token及項目相關最新資訊，方便<br/>用戶及時了解最新動向，輔助決策`,
            tag2: '投研排行',
            tag2Brief: `Para投研團隊有完備的一級市場投研體<br/>系，會不斷發掘跟進優質早期項目`,
            tag3: '實用工具',
            tag3Brief: `提供多種實用的鏈上工具，如:授權查<br/>詢及取消、價格波動提醒等`
        }
    },
    leftMenus: {
        'setting': '用戶設置',
        'basicInfo': '展示信息',
        'accountManage': '賬號管理',
        'eventsManage': '活動管理',
        'eventsLists': '活動列表',
        'eventsPublish': '發布活動',
        'projectManage': '項目管理',
        'appManage': '應用管理',
        'tokenManage': '代幣管理',
        'agentManage': '代理商',
        'collections': '合集管理'
    },
    basicInfo: {
        'title1': '基本信息（雙擊可編輯）',
        'title2': '官方社區（雙擊可編輯）',
        'nickname': '賬戶昵稱',
        'brief': '介紹簽名',
        'site': '官方網站',
        'infoTips': '展示信息將出現在您的個人主頁',
        'noSet': '未設置'
    },
    common: {
        'confirm': '確認',
        'cancel': '取消',
        'save': '保存',
        'noData': '暫無數據',
        'loading': '加載中...',
        'deleteSuccessful': '刪除成功',
        'deleteFailed': '刪除失敗',
        'saveSuccessful': '保存成功',
        'saveFailed': '保存失敗',
        'total': '共',
        'per': '份',
        'tasks': '任務',
        'brief': '介紹',
        'noSetting': '未設置',
        'login': '登錄',
        'notLogin': '您還未登錄',
        'pleaseSelect': '請選擇',
        'copySuccess': '複製成功'
    },
    setting: {
        'uidTips': 'UID是唯一身份區別，UID下綁定的其他賬戶都可以登錄此UID',
        'userSetting': '用戶設置',
        'accountManage': '賬號管理',
        'email': '郵箱',
        'change': '更換',
        'loginPwd': '登錄密碼',
        'noSetPwd': '暫未設置密碼',
        'set': '設置',
        'notBind': '未綁定',
        'unbind': '解綁',
        'bind': '綁定',
        'updateAvatar': '修改頭像',
        'confirm': '確認',
        'cancel': '取消',
        'save': '保存',
        'bindEmailTip': '綁定郵箱後完成註冊',
        'verifyCode': '驗證碼',
        'send': '發送',
        'sendVerifyCode': '發送驗證碼',
        'toBind': '立即綁定',
        'userAgreement': '用戶協議',
        'privacyPolicy': '隱私政策',
        'read': '我已閱讀並同意',
        'userAgreementApfix': '未註冊的賬號驗證後將自動註冊',
        'changeEmail': '更換郵箱',
        'bindEmail': '綁定郵箱',
        'needEmail': '需要綁定的新郵箱',
        'unbindTwitter': '解除綁定',
        'isUnbindTwitter': '確定要解綁推特嗎',
        'changePwd': '更換密碼',
        'newPwd': '新的登錄密碼',
        'newPwd_': '新密碼',
        'setPwd': '設置密碼',
        'pleaseSetPwd': '請設置登錄密碼',
        'repeatPwd': '重復密碼',
        'bindedEmail': '已綁定郵箱'
    },
    events: {
        'basicInfo': '基本信息',
        'tasksNeed': '任務要求',
        'tasksReward': '任務獎勵',
        'eventPublish': '活動發布',
        'eventsManage': '活動管理',
        'eventsLists': '活動列表',

        'collectionsLists': '合集清單',
        'collectionManage': '合集管理',
        'collectionInfo': '合集詳情',
        'removeCollection': '釋放合集',
        'removeEndEvents': '移除結束的活動',
        'isRemoveColl': '確認要釋放合集嗎？',
        'isRemoveEndEvents': '確認要移除已結束的活動嗎？',

        'notSetTitle': '標題未設置',
        'isDeleteDraft': '刪除當前草稿？',
        'getReward': '抽獎',
        'fcfs': 'FCFS',
        'divideAmount': '總量瓜分',
        'toCreate': '創建活動',
        'isPublished': '已發布',
        'isChecking': '審核中',
        'isRunning': '進行中',
        'waitRunning': '即將開始',
        'isEnd': '已結束',
        'draft': '草稿',
        'preview': '預覽',
        'eventData': '活動數據',
        'basicData': '基本數據',
        'taskData': '任務數據',
        'finished': '完成',
        'join': '參與',
        'view': '查看',
        'share': '分享',
        'like': '收藏',
        'eventsCount': '活動數',
        'myEvents': '我的活動',
        'hotEvents': '熱門活動',
        'moreEvents': '更多精彩活動',
        'myCreated': '我創建的',
        'myJoined': '我參與的',
        'myLiked': '我收藏的',
        'publishCollection': '創建合集',
        'edit': '編輯',
        'collection': '合集',
        'submitBy': '提交於',
        'ifDel': '刪除當前活動？',
        'cancel': '取消',
        'confirm': '確認',
        'minTwoEvents': '至少選擇兩個活動',
        'failedCheck': '審核失敗',
        'checking': '審核中',
        'cancelSelect': '取消選擇',
        'selectEvents': '選擇活動',
        'createCollection': '生成合集',
        'endTime': '結束時間',
        'startTime': '開始時間',
        'submitAndWaitCheck': '提交成功，等待審核！',
        'submitFailed': '提交失敗',
        'emptyLogo': '項目圖標不能為空',
        'max300kb': '上傳圖片大小不能超過 300KB!',
        'errorFormat': '圖片格式無效!',
        'collectionName': '合集名稱',
        'emptyCollectionName': '合集名稱不能為空',
        'placeCollectionName': '請輸入合集名稱',
        'collectionLogo': '合集Logo',
        'tipCollectionLogo': `僅支持jpg/png圖片上傳，分辨率推薦為168*168，<br/>文件大小小於300KB，保持視覺元素居中。`,
        'collectionBrief': '合集介紹',
        'emptyCollectionBrief': '合集介紹不能為空',
        'placeCollectionBrief': '請輸入合集介紹',
        'eventTime': '活動時間',
        'emptyEventTime': '活動時間不能為空',
        'placeEventTime': '請輸入活動時間'
    },
    task: {
        'xTask': 'X任務',
        'tradeNeed': '交易任務',
        'platformTasks': '平臺任務',
        'projectTasks': '項目任務',
        'trade': '交易',
        'eventReward': '活動獎勵',
        'allRewards': '全部贈品',
        'rewardMode': '獎勵機製',
        'issuerReward': '中簽獎勵',
        'regLongPre': '賬號註冊不少於',
        'perMonth': '個月',
        'followX': '關註X賬戶',
        'retweet': '轉發',
        'retweetfix': '推文並@三個好友',
        'retweetApfix': '最新推文並@三個好友',
        'like': '點贊',
        'lastTweet': '最新推文',
        'tweet': '推文',
        'balanceNeed': '資產要求',
        'has': '持有',
        'hasToken': '鏈Token',
        'noLessThan': '不少於',
        'other': '其他',
        'receiveAddress': '獎勵接收地址',
        'pleaseUse': '請使用',
        'pleaseUseApfix': '代幣接收地址，若您獲得本次活動獎勵,獎勵會自動發放到該地址',
        'textCollect': '文本收集',
        'textInfo': '文本信息',
        'userNickname': '用戶昵稱',
        'userID': '用戶ID',
        'autoTweet': '代發',
        'hasTokens': '持有指定token',
        'tradeTokens': '交易指定token',
        'otherTasks': '其他',
        'selfTasks': '自定義任務',
        'promote': '活動推廣',
        'syncX': '將此活動同步到PP錢包活動官x賬號',
        'placeAutoTweet': '代發推文內容'
    },
    publish: {
        'saveDraft': '保存草稿',
        'nextStep': '下一步',
        'default': '默認',
        'title': '活動標題',
        'time': '活動時間',
        'startTime': '開始日期',
        'to': '至',
        'endTime': '結束日期',
        'cover': '活動海報',
        'logo': '活動Logo',
        'coverSize': '建議168*168',
        'coverBytes': '不超過3MB',
        'coverWait': '請等待圖片上傳完成',
        'content': '活動說明',
        'tags': '活動標簽',
        'taskLists': '任務列表',
        'noTaskLists': '請至少添加一項任務要求！',
        'regLong': '參與者X賬號最小註冊月數',
        'followX': '關註指定推特',
        'xActive': '參與指定推特最新推文互動',
        'tweetActive': '參與指定推文互動',
        'retweet3': '轉發並標記3個好友',
        'like': '點贊',
        'linkTweetUrl': '關聯已有推文Url',
        'autoTweetActive': '參與代發推文互動',
        'autoTweetContent': '代發推文內容',
        'tweetContentTip': '請編輯推文內容（200字符以內）',
        'tweetContentTip2': '活動發布成功以後，Parapack會通過您授權的',
        'tweetContentTip3': 'X賬戶幫您發送此推文',
        'authedX': '已授權Twitter',
        'notAuthX': 'Twitter未授權',
        'toBind': '去綁定',
        'balanceNeed': '資產要求',
        'hasToken': '持有指定token',
        'chain': '發行鏈',
        'contractAddress': '合約地址',
        'tokenName': '資產名稱',
        'minNum': '最小數量',
        'other': '其他',
        'textDesp': '文本描述',
        'rewardNum': '贈品份額',
        'rewardSetting': '贈品設置',
        'topUpReward': '存入贈品',
        'rewartValue': '贈品金額',
        'rewardTip': '該部分資金將質押在PP，確保活動順利進行，活動結束後有剩余的部分會如數退回',
        'serviceFee': '服務費用',
        'serviceFeeTip': '此次活動PP收取的服務費，活動一經發布，不予退回',
        'topUpAmount': '存款金額',
        'topUpAmountTip': '此次活動發布需要存入的token數量(包含了贈品金額和服務費用兩部分)',
        'topUpAddress': '存入地址',
        'topUpTip': '此地址只可存入',
        'topUpTip2': '鏈資產，存入其他資產可能會造成資金損失',
        'backAddressTip': '當活動結束後仍有代幣剩余或其他原因導致的代幣退回，剩余代幣將如數退回到轉賬地址。',
        'backAddressTip2': '此地址必須與存款地址同一網絡，否則您可能會有資產損失!',
        'topUpAddressPlaceholder': '請提供一個您的贈品代幣接收地址',
        'backAddress': '退回地址',
        'netAddress': '網絡地址',
        'txid': '轉賬哈希',
        'txidPlaceholder': '請提供您往存款地址轉賬的鏈上哈希',
        'tokenAmountPlaceholder': '請輸入獎勵Token總量',
        'xAccount': '推特號',
        'xAccountPlace': 'eg. @elonmusk',
        'toPublish': '我已完成付款，發布活動',
        'setRewardCount': '設置中獎人數（份）',
        'publishLang': '發布語言',
        'customTask': '自定義任務',
        'hasTrade': '交易任務',
        'tradePath': '交易路徑',
        'tradeNum': '交易次數',
        'minTradeNumber': '最小交易次數',
        'minAmount': '交易數額',
        'errorFileSize': '文件大小超出限製',
        'errorPicSize': '文件大小超出限制',
        'errorTitle': '活動標題不能為空',
        'errorTimeRange': '活動時間不能為空',
        'errorEndTime': '結束時間必須大於開始時間',
        'errorCover': '活動海報不能為空',
        'coverUploading': '活動海報正在上傳中，請稍侯...',
        'errorContent': '活動說明不能為空',
        'minTasksCount': '請至少添加一項任務要求！',
        'errorXLong': '請設置x賬號註冊時長',
        'errorTasksNeed': '請完善任務要求',
        'repeatTasks': '任務重復',
        'errorRewardToken': '請設置獎勵token',
        'errorRewardType': '請選擇獎勵類型',
        'errorAutoTweet': '請填代發推文內容',
        'isUploading': '圖片正在上傳中，請稍侯...',
        'isPublishing': '正在發布...',
        'publishSuccessful': '發布成功',
        'publishFailed': '發布失敗',
        'taskBrief': '任務介紹',
        'customLink': '任務連結',
        'customCheckLink': '驗證API',
        'placeCustomCheckLink': '任務校驗API介面',

        'errorTags': '活動標籤不能為空',
        'errorLangs': 'Please improve multilingual content'
    },
    login: {
        title: '服務平臺登錄',
        email: '郵箱',
        verifyCode: '驗證碼',
        pwd: '密碼',
        toLogin: '立即登錄',
        sendCode: '發送驗證碼',
        thirdLogin: '三方賬號登錄',
        xLogin: 'Twitter登錄',
        scanLogin: '掃碼登錄',
        downApp: '下載Parapack',
        toBind: '立即綁定',
        otherLogin: '其他登錄方式',
        bindEmail: '綁定郵箱',
        bindEmailTip: '綁定郵箱後完成註冊'
    },
    errMsg: {
        pleaseRead: '請閱讀並同意平臺協議',
        badNetwork: '您當前的網絡不佳，請稍後刷新重試！',
        pleaseInputEmail: '請輸入郵箱',
        pleaseInputCode: '請輸入驗證碼',
        pleaseInputPwd: '請輸入密碼',
        sendFailed: '發送失敗',
        loginFailed: '登錄失敗',
        bindFailed: '綁定失敗',
        noLoginPermit: '您當前還不是商家，請前往APP申請入駐'
    },
    successMsg: {
        loginSuccess: '登錄成功',
        bindSuccess: '綁定成功'
    },
    server: {
        'Not login!': '請重新登錄',
        'Invalid params!': '請求參數無效',
        'No data!': '暫無數據',
        'success': '操作成功',
        'fail': '操作失敗',
        'Uploading, please wait!': '上傳中，請稍候！',
        'Publishing too frequently, please wait a moment!': '發布太頻繁，請稍後再試！',
        'Cover image is being uploaded, please submit again later!': '正在上傳封面圖！',
        'Asset verification failed, please refresh the recharge address!': '資產校驗失敗，請刷新充值地址！',
        'Asset verification failed and the recharge address has expired!': '資產校驗失敗，充值地址已失效！',
        'Hash has been used!': 'Hash已使用過！',
        'Asset verification failed and the asset balance is insufficient!': '資產校驗失敗，資產余額不足！',
        'Asset verification failed, invalid hash!': '資產校驗失敗，無效的hash！',
        'Mission request contains an invalid Twitter account:': '任務要求含有無效的推特賬戶',
        'X account is not bound!': 'X賬號未綁定',
        'The URL of the specified tweet is incorrectly filled in!': '指定推文的Url填寫錯誤',
        'Unable to find the specified tweet!': '無法查詢到指定的推文',
        'Save failed!': '保存失敗',
        'Save event failed!': '活動保存失敗',
        'Save params failed!': '活動參數保存失敗',
    },
    dapp: {
        projectManage: '項目管理',
        dappManage: '應用管理',
        basicInfo: '基本信息',
        otherInfo: '其他信息',
        submit: '提交申請',
        name: '名稱',
        type: '類型',
        tags: '標簽',
        ctime: '操作時間',
        status: '狀態',
        handle: '操作',
        view: '查看',
        del: '刪除',
        draft: '草稿',
        refused: '已拒絕',
        checking: '審核中',
        published: '已上架',
        tip: '提示',
        delTip: '刪除後您的應用將會自動下架，將不再收錄在PP的應用商城！',
        logo: '項目圖標',
        errorLogo: '項目圖標不能為空',
        logoTip: `僅支持jpg/png圖片上傳，分辨率推薦為100*100以上，<br/>文件大小小於300KB，保持視覺元素居中。`,
        appName: '應用名稱',
        errorAppName: '應用名稱不能為空',
        placeAppName: '請輸入應用名稱',
        subName: '應用簡稱',
        errorSubName: '應用簡稱不能為空',
        placeSubName: '最能識別應用的1到2個詞',
        desc: '應用介紹',
        errorDesc: '默認語言的應用介紹必須填寫',
        placeDesc: '默認語言的介紹內容必須填寫',
        defLang: '默認',
        appType: '應用類型',
        errorAppType: '請選擇應用類型',
        appChains: '支持的鏈',
        errorAppChains: '請選擇應用支持的鏈',
        placeAppChains: '請選擇應用支持的鏈',
        appTags: '應用標簽',
        errorAppTags: '請選擇應用標簽',
        errorAppTags2: '最多支持5個標簽',
        placeAppTags: '請選擇應用標簽，最多選擇5個',
        dappUrl: '',
        errorDappUrl: '請填寫應用訪問鏈接',
        placeDappUrl: 'Dapp應用的訪問鏈接',
        appUrl: '',
        errorAppUrl: '請填寫應用下載鏈接',
        placeAppUrl: '應用的下載鏈接',
        checkStatus: '審計信息',
        checkStatusYes: '已審計',
        checkStatusNo: '未審計',
        errorCheckStatus: '請選擇審計狀態',
        checkUrl: '',
        errorCheckUrl: '請填寫審計報告鏈接',
        placeCheckUrl: '請填寫審計報告鏈接',
        pleaseSelect: '請選擇',
        telegramUrl: '聯系人Telegram',
        errorTelegramUrl: '請填寫聯系人Telegram',
        otherFormData: '其他信息（選填）',
        siteUrl: '官方網站',
        placeSiteUrl: '請輸入網址鏈接https://',
        whiteBook: '白皮書',
        placeWhiteBook: '請輸入白皮書鏈接https://',
        placeTwitter: '請輸入X賬號鏈接https://',
        placeTg: '請輸入Telegram賬號鏈接https://',
        placeDiscord: '請輸入Discord鏈接https://',
        placeFacebook: '請輸入Facebook賬號鏈接https://',
        ppLink: '關聯PP',
        placePPLink: '關聯後可以跳轉發布者PP賬號個人中心',
        ppLinkOptions: {
            v1: '不關聯',
            v2: '關聯活動',
            v3: '關聯視頻',
            v4: '關聯活動&視頻'
        },
        tokenAddress: '代幣信息1',
        tipTokenAddress: '如果您的代幣在PP上架，我們將幫您關聯代幣',
        tokenAddress2: '代幣信息2',
        placeTokenAddress: '請輸入代幣合約URL https://',
        email: '聯系郵箱',
        placeEmail: '項目聯系人郵箱地址',
        contact: '對接人',
        placeContact: '如有對接的BD，請填寫',
        brief: '項目描述',
        placeBrief: '向我們介紹一下你的應用，方便我們對你的應用更充分了解，這有助於此次申請審核',
        saveDraft: '保存草稿',
        resetForm: '重置',
        nextStep: '下一步',
        submitForm: '提交審核'
    },
    agent: {
        agentSupply: '代理申請',
        agentManage: '代理管理',
        agentProfit: '代理收益',
        urealizedProfit: '未提現收益',
        shareRate: '業務分成比例',
        requirement: '團隊考核要求',
        requirementCount: '新增活動新用戶',
        profitsBriefTitle: '團隊分成說明',
        realizedYes: '已達標',
        realizedNo: '未達標',
        groupShares: '團隊分成比例',
        teamCount: '團隊人數',
        realizedCount: '活動拉新',
        teamUser: '團隊用戶',
        searchUserKey: 'UID/昵稱',
        nickname: '昵稱',
        regTime: '註冊日期',
        brokerType: '達標類型',
        incomeRecords: '收益記錄',
        searchIncomeKey: 'UID/事項',
        dateTime: '時間',
        incomeTitle: '事項',
        inviteUid: '拓展者UID',
        brokerReward: '代理提成',
        teamReward: '團隊提成',
        incomeStatus: '狀態',
        receivedNo: '待領取',
        receivedYes: '已領取',
        profitsBrief: `1.設定分成比例後，團隊用戶將按設定的分成比例享受下級貢獻的業務收入，例:設定1級30%，直接邀請一個用戶參與生態貢獻了10萬業務收入，直接推薦人可享受3萬獎勵;<br/><br/>2.您設定的所有層級比例之和不能大於100%。`,
        pleaseInput: '請輸入',
        gradeMark: '級',
        errorParams: '請求參數無效',
        errorProfit: '比例必須為大於0的數字，沒有可留空！',
        minOneProfit: '至少設置一項比例',
        maxProfit: '總比例不能超過100%',
        setSuccessful: '設置成功',
        setFailed: '設置失敗',
        adAgent: '廣告代理',
        activityAgent: '活動代理',
        appAgent: '應用代理'
    }
}