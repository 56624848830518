import request from '@/utils/request.js';

const prefix = 'https://eapi.parapack.net/';

export async function getLists(params) {
    const url = prefix + 'dapp/lists';

    return request.get({ url });
}

export async function getTags(params) {
    const url = prefix + 'dapp/tags';

    return request.get({ url });
}

export async function getInfo(params) {
    const url = prefix + 'dapp/info';

    return request.get({ url, params });
}

export async function publishDapp(params) {
    const url = prefix + 'dapp/publish';

    return request.post({ url, params });
}

export async function saveDraft(params) {
    const url = prefix + 'dapp/save-draft';

    return request.post({ url, params });
}

export async function delDapp(params) {
    const url = prefix + 'dapp/del';

    return request.post({ url, params });
}