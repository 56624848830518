<template>
  <header>
    <div class="header-con">
      <a href="https://www.parapack.net" title="Parapack" class="logo">
        <img src="../assets/imgs/pc/22.png" alt="ParaPack">
      </a>
      <div class="flex-1"></div>
      <nav>
        <ul>
          <li><router-link to="/" :class="{active: $route.path === '/'}">{{$t('web.header.index')}}</router-link></li>
          <li><router-link to="/wallet" :class="{active: $route.path === '/wallet'}">{{$t('web.header.wallet')}}</router-link></li>
          <li><router-link to="/activity" :class="{active: $route.path === '/activity'}">{{$t('web.header.activity')}}</router-link></li>
          <li><a style="cursor: not-allowed;" :class="{active: $route.path === '/video'}">{{$t('web.header.video')}}</a></li>
          <li><router-link to="/download" :class="{active: $route.path === '/download'}">{{$t('web.header.download')}}</router-link></li>
          <li><a href="https://business.parapack.net" :class="{active: $route.path === '/service'}">{{$t('web.header.service')}}</a></li>
        </ul>
      </nav>
      <div class="lang-switcher" @click="showLangs = !showLangs">
        <div class="icon"><img src="../assets/imgs/index/icon-lang.png"/></div>
        <div class="lang-active">{{languageObj[language]}}</div>
        <div class="lang-lists" v-if="showLangs" @mouseleave="showLangs = false">
          <div v-for="(item, idx) in languageObj" :key="idx" @click="changeLanguage(idx)">{{item}}</div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data(){
    return {
      showLangs: false,
      language: localStorage.getItem('CURRENT_LANGUAGE'),
      languageObj: {
        en_US: 'English',
        // zh_CN: '简体中文',
        el_GR: '繁体中文',
        ms_MY: 'Bahasa Malaysia'
      }
    }
  },
  methods: {
    /**
     * 切换语言
     * @param lang
     */
    changeLanguage(lang){
      localStorage.setItem('CURRENT_LANGUAGE', lang);
      location.reload();
    },
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/pc.less";
</style>