import request from '@/utils/request.js';
import CryptoJS from "crypto-js";

const prefix = 'https://eapi.parapack.net/';

export async function getUserInfo(params) {
    const url = prefix + 'users/info';

    return request.get({ url, params });
}

export async function sendEmailCode(params) {
    if(!params.email){
        return;
    }
    const url = prefix + 'users/email-code';

    return request.post({ url, params });
}

export async function emailLogin(params) {
    if(!params.email){
        return;
    }
    if(!params.code && !params.pwd){
        return;
    }
    if(params.pwd){
        params.pwd = CryptoJS.MD5(params.pwd);
    }
    const url = prefix + 'users/email-login';

    return request.post({ url, params });
}

export async function emailBind(params) {
    if(!params.email){
        return;
    }
    if(!params.code){
        return;
    }
    const url = prefix + 'users/email-bind';

    return request.post({ url, params });
}

export async function updatePwd(params) {
    if(!params.pwd){
        return;
    }
    if(params.pwd){
        params.pwd = CryptoJS.MD5(params.pwd);
    }
    const url = prefix + 'users/update-pwd';

    return request.post({ url, params });
}
export async function sendUpdatePwdCode(params = {}) {
    const url = prefix + 'users/update-pwd';

    return request.get({ url, params });
}

export async function updateEmailVerify(params) {
    const url = prefix + 'users/update-email';

    return request.get({ url, params });
}
export async function updateEmail(params) {
    if(!params.email){
        return;
    }
    const url = prefix + 'users/update-email';

    return request.post({ url, params });
}
export async function getBasicInfo(params = {}) {
    const url = prefix + 'users/basic';

    return request.get({ url, params });
}
export async function updateBasicInfo(params) {
    const url = prefix + 'users/update-basic';

    return request.post({ url, params });
}
export async function unbindTwitter(params = {}) {
    const url = prefix + 'users/unbind-twitter';

    return request.post({ url, params });
}
export async function updateAvatar(params = {}) {
    const url = prefix + 'users/update-avatar';

    return request.post({ url, params });
}
export async function joinCheck(params = {}) {
    const url = prefix + 'users/join-check';

    return request.post({ url, params });
}