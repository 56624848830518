<template>
  <div id="app" :class="showBg && !userInfo && !isWhite ? 'bg' : ''">
    <div class="loading-con" v-if="showLoading">
      <i class="el-icon-loading"></i>
      <span class="msg">loading...</span>
    </div>

    <login v-if="!isWhite && $route.path !== '/home'"></login>

    <div v-if="$route.path === '/home'">
      <home></home>
    </div>
    <div v-else-if="isWhite">
      <router-view></router-view>
    </div>
    <div v-else>
      <!-- header -->
      <div class="header-con" v-if="userInfo && !isWhite">
        <div class="logo">
          <a href="https://www.parapack.net" title="Parapack"><img src="../assets/imgs/pc/22.png"/></a>
        </div>
        <div class="flex-1"></div>
        <div class="right-con">
          <div class="text-btn lang" @click="showLanguage=!showLanguage">
            <img src="../assets/imgs/pc/home/pic-11.png"/>
            {{languageObj[language]}}
            <div class="langs" v-if="showLanguage" @mouseleave="showLanguage=false">
              <div class="lang-row" v-for="(item, idx) in languageObj" :key="idx" @click="changeLanguage(idx)">{{item}}</div>
            </div>
          </div>
          <div class="user">
            <img :src="userInfo.avatar || '../assets/imgs/default-avatar.png'" class="avatar"/>
            <span>UID:{{userInfo ? userInfo.id : ''}}</span>
          </div>
          <div class="text-btn" @click="logout">
            <img src="../assets/imgs/pc/20.png"/>
            {{$t('header.logout')}}
          </div>
        </div>
      </div>

      <!-- left menu -->
      <div class="left-menus" v-if="userInfo && !isWhite">
        <div class="mgt-16"></div>
        <!-- users -->
        <div class="menu">
          <i class="el-icon-user bold"></i>
          <span>{{$t('leftMenus.setting')}}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <router-link to="/basic-info" class="sub-menu" :class="{on:$route.path==='/basic-info'}">
          <span>{{$t('leftMenus.basicInfo')}}</span>
        </router-link>
        <router-link to="/setting" class="sub-menu" :class="{on:$route.path==='/setting'}">
          <span>{{$t('leftMenus.accountManage')}}</span>
        </router-link>

        <!-- events -->
        <div class="menu">
          <img src="../assets/imgs/icons/icon-event.png" class="menu-icon"/>
          <span>{{$t('leftMenus.eventsManage')}}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <router-link to="/event-manage" class="sub-menu" :class="{on:$route.path==='/event-manage'}">
          <span>{{$t('leftMenus.eventsLists')}}</span>
        </router-link>
        <router-link to="/event-publish" class="sub-menu" :class="{on:$route.path==='/event-publish'}">
          <span>{{$t('leftMenus.eventsPublish')}}</span>
        </router-link>
        <router-link to="/collections" class="sub-menu" :class="{on:$route.path==='/collections'}">
          <span>{{$t('leftMenus.collections')}}</span>
        </router-link>

        <!-- apps -->
        <div class="menu">
          <img src="../assets/imgs/icons/icon-project.png" class="menu-icon"/>
          <span>{{$t('leftMenus.projectManage')}}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <router-link to="/dapps" class="sub-menu" :class="{on:$route.path==='/dapps'}">
          <span>{{$t('leftMenus.appManage')}}</span>
        </router-link>
<!--        <router-link to="/tokens" class="sub-menu" :class="{on:$route.path==='/tokens'}">-->
<!--          <span>{{$t('leftMenus.tokenManage')}}</span>-->
<!--        </router-link>-->

        <!-- agent -->
        <div class="menu" :class="{on:$route.path==='/agent'}" v-if="showAgentMenu">
          <router-link to="/agent">
            <img src="../assets/imgs/icons/icon-agent-active.png" class="menu-icon" v-if="$route.path==='/agent'"/>
            <img src="../assets/imgs/icons/icon-agent.png" class="menu-icon" v-else/>
            <span>{{$t('leftMenus.agentManage')}}</span>
            <i class="el-icon-arrow-right"></i>
          </router-link>
        </div>

      </div>

      <div class="main-con scroll-con" :class="{bg: userInfo}">
        <router-view v-if="userInfo || isWhite"></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import * as TwitterApi from "@/api/twitter";
import * as AccountApi from "@/api/account";
import Login from "@/components/Login";
import Home from "@/pages/Home";
import EventLists from "@/pages/EventLists";
import * as AgentApi from "@/api/agent";

const whiteRoute = ['/', '/index', '/download', '/wallet', '/activity', '/event-lists', '/agreement'];

export default {
  name: 'app',
  components: { Home, Login, EventLists },
  data(){
    return {
      isWhite: false,
      language: localStorage.getItem('CURRENT_LANGUAGE'),
      showLanguage: false,
      showAgentMenu: false,
      languageObj: {
        en_US: 'English',
        zh_CN: '简体中文',
        el_GR: '繁体中文',
        ms_MY: 'Bahasa Malaysia'
      },
      showBg: false,
      userInfo: null,
      twitterLoginUrl: null,
      showLoading: false
    }
  },
  watch: {
    userInfo(newData, oldData){
      if(newData && newData.user_type !== 1){
        // 不是商家
        this.isWhite = true;
        this.$router.replace('/agreement');
      }
    }
  },
  created() {
    window.addEventListener('message', (event) => {
      if(!event.data || event.data.target !== 'fireBotResult'){
        return;
      }
      const data = event.data;
      const key = data.key;
      delete data.key;
      delete data.target;

      this.$eventBus.$emit('RES_' + key, data);
    });
  },
  async mounted() {
    this.$eventBus.$on('LOGIN-SUCCESS', userInfo => {
      this.userInfo = userInfo;
      this.showBg = false;
    });
    this.$eventBus.$on('LOAD_INIT_DATA', (isWhite = null) => {
      isWhite !== null && (this.isWhite = isWhite);
      this.initData();
    });
    window.addEventListener('unload', function (e) {
      localStorage.removeItem('CACHE_EVENT_FORM');
    });

    this.showLoading = true;

    // 白名单路由不需要登录
    if(whiteRoute.indexOf(this.$route.path) > -1){
      this.isWhite = true;
    }

    // show agent menu
    if(location.href.indexOf('ppjuvsxcsntwn2uj4w8h69y2des9s') > -1) {
      this.showAgentMenu = true;
    }

    // 推特授权成功回调
    let queryStr = location.href.split('?')[1];
    queryStr = queryStr ? queryStr.split('&') : [];
    const query = {};
    queryStr.map(r => {
      r = r.split('=');
      query[r[0]] = r[1];
    });
    if(query.t){
      this.$router.replace('/basic-info');
      AccountApi.getUserInfo({ t: query.t }).then(res => {
        if(res && res.status === 200 && res.data){
          this.userInfo = res.data;
          localStorage.setItem('USERINFO', JSON.stringify(res.data));
        }
        this.showBg = !this.userInfo;
        this.$eventBus.$emit('ACCOUNT_INFO', {
          userInfo: this.userInfo
        });
      });
    } else {
      setTimeout(async () => {
        this.initData();
      }, 1000);
    }
  },
  methods: {
    /**
     * 切换语言
     * @param lang
     */
    changeLanguage(lang){
      localStorage.setItem('CURRENT_LANGUAGE', lang);
      location.reload();
    },
    /**
     * 初始化用户信息
     */
    initData(){
      if(this.$route.path === '/'){
        this.showLoading = false;
        return;
      }
      // 加载userInfo
      let userInfo = localStorage.getItem('USERINFO');
      userInfo = userInfo ? JSON.parse(userInfo) : null;
      const now = parseInt(new Date().getTime()/1000);
      if(userInfo && now - userInfo.expire_time + 300 <= 0){
        this.userInfo = userInfo;
        window.postMessage({ action: 'loginSuccess', target: 'Events', options: { userInfo } }, window.location.origin);
        // 代理状态
        AgentApi.getStatus().then(res => {
          res.data.apply.map(r => {
            r.status === 1 && (this.showAgentMenu = true);
          });
        });
      }

      this.showBg = !userInfo;
      this.$eventBus.$emit('ACCOUNT_INFO', {
        userInfo: this.userInfo
      });

      // 与插件同步状态
      TwitterApi.syncCurrentUser().then(async res => {
        let isLogin = false;
        if(res && res.data){
          const userInfo = res.data;
          const now = parseInt(new Date().getTime()/1000);
          if(userInfo.twitter_uid && now - userInfo.expire_time <= 0){
            this.userInfo = userInfo;
            isLogin = true;
          }
        }
        if(!isLogin){
          this.showBg = true;
        }
        this.$eventBus.$emit('ACCOUNT_INFO', {
          userInfo: this.userInfo,
          twitterLoginUrl: this.twitterLoginUrl
        });
      }).catch(err => {
        console.log(err);
      });

      this.showLoading = false;
    },
    /**
     * 退出
     * @returns {Promise<void>}
     */
    async logout(){
      localStorage.removeItem('USERINFO');
      this.userInfo = null;
      this.showBg = true;
      const loginRes = await TwitterApi.getLogin();
      if(loginRes && loginRes.data){
        this.twitterLoginUrl = loginRes.data.loginUrl;
      }
      this.$eventBus.$emit('ACCOUNT_INFO', {
        userInfo: null,
        twitterLoginUrl: this.twitterLoginUrl
      });
      TwitterApi.syncCurrentUser({ logout: true });
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";

#app.bg {
  background-color: #000;
  background-image: url("../assets/imgs/pc/login-bg.jpg");
  background-size: 100% 100%;
  height: 100vh;
}
.header-con {
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 5px 10px #eee;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;
  .logo {
    font-size: 16px;
    font-weight: bold;
    img {
      height: 24px;
    }
  }
  .right-con {
    display: flex;
    align-items: center;

    .user {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .avatar {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }
      span {
        font-size: 14px;
      }
    }
    .text-btn {
      font-size: 14px;
      color: #aaa;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 20px;
      img {
        height: 20px;
        margin-right: 5px;
      }
    }
  }
  .lang {
    position: relative;
    .langs {
      position: absolute;
      left: 0;
      top: 35px;
      min-width: 100px;
      background-color: #fff;
      box-shadow: 0 0 3px #ccc;
      padding: 5px 0;
      .lang-row {
        padding: 8px 12px;

        &:hover {
          color: #3F9CFF;
        }
      }
    }
  }
}
.main-con {
  position: fixed;
  left: @left-menu-width;
  top: 50px;
  right: 0;
  bottom: 0;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;

  &.bg {
    background: linear-gradient(to bottom, #CFE6FE 0%, #ffffff 20%, #ffffff 100%);
  }
}
</style>