module.exports = {
    header: {
        'logout': 'Logout'
    },
    web: {
        header: {
            index: 'Home',
            found: 'Discover',
            ecology: 'Ecology',
            activity: 'Activity',
            download: 'Download',
            wallet: 'Wallet',
            video: 'Video',
            service: 'Service Platform'
        },
        footer: {
            slogn: 'Focus on Web3 event support, marketing growth, and achieving traffic aggregation',
            useApp: 'Try the App',
            viewWhiteBook: 'View Whitepaper',
            wallet: 'Wallet',
            appPublish: 'App Listing',
            tokenPublish: 'Token Submission',
            video: 'Video',
            kolJoin: 'KOL Joining',
            groupJoin: 'Community Joining',
            creatorCenter: 'Creator Center',
            videoGuide: 'Video Tutorial',
            activity: 'Activity',
            activityGuide: 'Activity Tutorial',
            activityPublish: 'Activity Publishing',
            airdrop: 'Joint Airdrop',
            support: 'Support',
            about: 'About ParaPack',
            contact: 'Contact ParaPack',
            agentSupply: 'Agent Application',
            serverPlatform: 'Service Platform',
            serviceAgreement: 'Service Agreement',
            privacy: 'Privacy Policy'
        },
        index: {
            'web3Title': 'WEB3.0',
            'web3SubTitle': 'Global One-Stop Self-Media Aggregation Platform',
            'web3Brief': `With ParaPack, users can manage multiple self-media accounts at once, enabling unified <br/> management, data analysis, content synchronization, multi-channel marketing, etc. The goal of ParaPack is to help content creators and marketers simplify workflows, <br/> improve content dissemination efficiency and effectiveness.`,
            'find': 'Explore',
            'userCount': 'Registered Users',
            'dailyActiveCount': 'Daily Active Users',
            'merchants': 'Merchants',
            'web3Title2': 'Web3 Traffic Aggregation Marketing',
            'web3SubTitle2': `Using decentralized wallets as carriers, events and AI short videos as tools`,
            'web3Title3': 'Multi-Chain Web3 Wallet',
            'web3SubTitle3': `Decentralized crypto asset wallet, compatible with multiple blockchain networks, supporting various mainstream crypto assets`,
            'walletTag1': 'Supports managing 20+ networks of Tokens & NFTs',
            'walletTag2': 'Dapp Block Explorer and Magic Store',
            'walletTag3': 'On-Chain Non-Custodial Transactions, Secure & Fast',
            'web3Title4': 'Task Platform',
            'web3SubTitle4': `Connecting users and project parties, a user growth marketing tool, a strong helper for community growth`,
            'taskTag1': 'Aggregate multi-dimensional data from on-chain and off-chain sources',
            'taskTag2': 'Pre-verification and automatic reward distribution, transparent and secure',
            'taskTag3': 'Efficient and easily spread multi-channel promotion methods',
            'web3Title5': 'AI Short Video',
            'web3SubTitle5': `AIGC digital human short video creation platform, empowering marketing and content dissemination`,
            'videoTag1': 'Focus on Web3 content sharing and creating industry IP',
            'videoTag2': 'AIGC smart applications, easy to use, efficient, and cost-effective',
            'videoTag3': 'Multi-sectional collaboration, one-stop distribution',
            'web3Title6': 'Partnership',
            'web3SubTitle6': `Decentralized crypto asset wallet, compatible with multiple blockchain networks, supporting various mainstream crypto assets, decentralized <br/>
        crypto asset wallet, compatible with multiple blockchain networks, supporting various mainstream crypto assets decentralized crypto asset wallet, compatible with multiple blockchains`,
            'web3Title7': 'Traffic Aggregation and Conversion Growth'
        },
        activity: {
            ppCompagin: 'ParaPack Campaign',
            ppCompaginSubTitle: 'Web3 Task Management & Traffic Growth',
            joinActivity: 'Join Activity',
            publishActivity: 'Publish Activity',
            regUsers: 'Registered Users',
            activeUsers: 'Daily Active Users',
            shopes: 'Merchants',
            publishCount: 'Activity Published',
            rewardsAmount: 'Reward Scale',
            title2: 'On-Chain & Off-Chain Integration',
            subTitle2: 'Multi-dimensional user behavior assessment, cross-platform user conversion',
            title3: 'Matrix Communication',
            subTitle3: 'Interconnected sections, simultaneous promotion via videos, ads, app introductions, etc.',
            title4: 'Fair and Transparent Rewards',
            subTitle4: 'Rewards are locked in the treasury before the event, ensuring authenticity and secure distribution',
            title5: 'User-Friendly Data Services',
            subTitle5: 'Provides multi-dimensional data services, aiding publishers in performance evaluation and user analysis'
        },
        down: {
            title: 'Global One-Stop Self-Media Aggregation Platform',
            subTitle: 'Global one-stop self media aggregation platform'
        },
        wallet: {
            title: 'ParaPack Wallet',
            subTitle: 'One-Stop Web3 Entry & Service',
            brief: `For consumers, digital wallets are free and easily accessible. For example, when a consumer <br/> makes a purchase on a merchant site that handles server-side digital wallets, they enter their name, payment amount, and shipping <br/> information into the merchant's form.`,
            userScale: 'User Scale',
            txs: 'Daily Transactions',
            swap: 'Swap Scale',
            title2: 'Crypto Asset Support',
            subTitle2: 'Securely manage crypto assets anytime, anywhere',
            title3: 'Fast and Convenient Swap Service',
            brief3: `Support cross-chain asset swaps across different public chains, decentralized trading, <br/> safe and easy`,
            title4: 'Rich Magic Store',
            brief4: `Latest and hottest Web3 DAPP/APP, built-in block explorer, <br/> easy access to Dapps`,
            title5: 'Comprehensive App Ecosystem',
            subTitle5: 'We are developing and will develop applications to build a more comprehensive ecosystem',
            tag1: 'Industry News',
            tag1Brief: `Access the latest news related to Tokens and projects, making it easier <br/> for users to stay updated and make informed decisions`,
            tag2: 'Investment Research Rankings',
            tag2Brief: `Para's investment research team has a complete primary market research system, <br/> continuously discovering and following high-quality early projects`,
            tag3: 'Practical Tools',
            tag3Brief: `Provides various practical on-chain tools, such as: authorization inquiry and revocation, price fluctuation alerts, etc.`
        },
    },
    leftMenus: {
        'setting': 'User Settings',
        'basicInfo': 'Display Information',
        'accountManage': 'Account Management',
        'eventsManage': 'Event Management',
        'eventsLists': 'Event List',
        'eventsPublish': 'Publish Event',
        'projectManage': 'Project Management',
        'appManage': 'App Management',
        'tokenManage': 'Token Management',
        'agentManage': 'Agent Management',
        'collections': 'Collection Management'
    },
    basicInfo: {
        'title1': 'Basic Information (Double-click to edit)',
        'title2': 'Official Community (Double-click to edit)',
        'nickname': 'Nickname',
        'brief': 'Introduction',
        'site': 'Website',
        'infoTips': 'Display information will appear on your personal homepage',
        'noSet': 'Not Set'
    },
    common: {
        'confirm': 'Confirm',
        'cancel': 'Cancel',
        'save': 'Save',
        'noData': 'No Data',
        'loading': 'Loading...',
        'deleteSuccessful': 'Deleted Successfully',
        'deleteFailed': 'Delete Failed',
        'saveSuccessful': 'Saved Successfully',
        'saveFailed': 'Save Failed',
        'total': 'Total',
        'per': 'Items',
        'tasks': 'Tasks',
        'brief': 'Introduction',
        'noSetting': 'Not Set',
        'login': 'Login',
        'notLogin': 'You are not logged in',
        'pleaseSelect': 'Please Select',
        'copySuccess': 'Copy successful'
    },
    setting: {
        'uidTips': 'UID is a unique identifier. Other accounts bound to this UID can log in using this UID',
        'userSetting': 'User Settings',
        'accountManage': 'Account Management',
        'email': 'Email',
        'change': 'Change',
        'loginPwd': 'Password',
        'noSetPwd': 'Password not set',
        'set': 'Set',
        'notBind': 'Not Bound',
        'unbind': 'Unbind',
        'bind': 'Bind',
        'updateAvatar': 'Update Avatar',
        'confirm': 'Confirm',
        'cancel': 'Cancel',
        'save': 'Save',
        'bindEmailTip': 'Complete registration by binding email',
        'verifyCode': 'Verification Code',
        'send': 'Send',
        'sendVerifyCode': 'Send Verification Code',
        'toBind': 'Bind Now',
        'userAgreement': 'User Agreement',
        'privacyPolicy': 'Privacy Policy',
        'read': 'I have read and agree',
        'userAgreementApfix': 'Unregistered accounts will be automatically registered after verification',
        'changeEmail': 'Change Email',
        'bindEmail': 'Bind Email',
        'needEmail': 'New email to bind',
        'unbindTwitter': 'Unlink Twitter',
        'isUnbindTwitter': 'Are you sure you want to unbind Twitter?',
        'changePwd': 'Change Password',
        'newPwd': 'New Login Password',
        'newPwd_': 'New Password',
        'setPwd': 'Set Password',
        'pleaseSetPwd': 'Please set a login password',
        'repeatPwd': 'Repeat Password',
        'bindedEmail': 'Bound Email'
    },
    events: {
        'basicInfo': 'Basic',
        'tasksNeed': 'Task',
        'tasksReward': 'Rewards',
        'eventPublish': 'Publish',
        'eventsManage': 'Event Management',
        'eventsLists': 'Event List',

        'collectionsLists': 'Collection List',
        'collectionManage': 'Collection Management',
        'collectionInfo': 'Collection Details',
        'removeCollection': 'Release Collection',
        'removeEndEvents': 'Remove ended activities',
        'isRemoveColl': 'Are you sure you want to release the collection?',
        'isRemoveEndEvents': 'Are you sure you want to remove the activity that has ended?',

        'notSetTitle': 'Title Not Set',
        'isDeleteDraft': 'Delete current draft?',
        'getReward': 'Draw Reward',
        'fcfs': 'FCFS',
        'divideAmount': 'Total Amount Sharing',
        'toCreate': 'Create Event',
        'isPublished': 'Published',
        'isChecking': 'Under Review',
        'isRunning': 'In Progress',
        'waitRunning': 'Upcoming',
        'isEnd': 'Ended',
        'draft': 'Draft',
        'preview': 'Preview',
        'eventData': 'Event Data',
        'basicData': 'Basic Data',
        'taskData': 'Task Data',
        'finished': 'Completed',
        'join': 'Join',
        'view': 'View',
        'share': 'Share',
        'like': 'Favorite',
        'eventsCount': 'Event Count',
        'myEvents': 'My Events',
        'hotEvents': 'Popular Events',
        'moreEvents': 'More Exciting Events',
        'myCreated': 'My Created',
        'myJoined': 'My Joined',
        'myLiked': 'My Favorites',
        'publishCollection': 'Create Collection',
        'edit': 'Edit',
        'collection': 'Collection',
        'submitBy': 'Submitted On',
        'ifDel': 'Delete this event?',
        'cancel': 'Cancel',
        'confirm': 'Confirm',
        'minTwoEvents': 'Select at least two events',
        'failedCheck': 'Review Failed',
        'checking': 'Under Review',
        'cancelSelect': 'Cancel Selection',
        'selectEvents': 'Select Events',
        'createCollection': 'Create Collection',
        'endTime': 'End Time',
        'startTime': 'Start Time',
        'submitAndWaitCheck': 'Submitted successfully, awaiting review!',
        'submitFailed': 'Submission Failed',
        'emptyLogo': 'Project logo cannot be empty',
        'max300kb': 'Image size should not exceed 300KB!',
        'errorFormat': 'Invalid image format!',
        'collectionName': 'Collection Name',
        'emptyCollectionName': 'Collection name cannot be empty',
        'placeCollectionName': 'Please enter the collection name',
        'collectionLogo': 'Collection Logo',
        'tipCollectionLogo': `Only jpg/png images supported, recommended resolution 168*168, <br/> file size under 300KB, keep visual elements centered.`,
        'collectionBrief': 'Collection Description',
        'emptyCollectionBrief': 'Collection description cannot be empty',
        'placeCollectionBrief': 'Please enter the collection description',
        'eventTime': 'Event Time',
        'emptyEventTime': 'Event time cannot be empty',
        'placeEventTime': 'Please enter the event time'
    },
    task: {
        'xTask': 'X Task',
        'tradeNeed': 'Trading Task',
        'platformTasks': 'Platform Tasks',
        'projectTasks': 'Project Tasks',
        'trade': 'Trade',
        'eventReward': 'Event Reward',
        'allRewards': 'All Prizes',
        'rewardMode': 'Reward Mechanism',
        'issuerReward': 'Winning Reward',
        'regLongPre': 'Account registration for no less than',
        'perMonth': 'months',
        'followX': 'Follow X Account',
        'retweet': 'Retweet',
        'retweetfix': 'Retweet and @ three friends',
        'retweetApfix': 'Latest tweet and @ three friends',
        'like': 'Like',
        'lastTweet': 'Latest Tweet',
        'tweet': 'Tweet',
        'balanceNeed': 'Asset Requirement',
        'has': 'Hold',
        'hasToken': 'Chain Token',
        'noLessThan': 'No less than',
        'other': 'Other',
        'receiveAddress': 'Reward Receiving Address',
        'pleaseUse': 'Please use',
        'pleaseUseApfix': 'Token receiving address. If you win, the reward will be automatically sent to this address',
        'textCollect': 'Text Collection',
        'textInfo': 'Text Information',
        'userNickname': 'User Nickname',
        'userID': 'User ID',
        'autoTweet': 'Auto Tweet',
        'hasTokens': 'Hold Specific Token',
        'tradeTokens': 'Trade Specific Token',
        'otherTasks': 'Other',
        'selfTasks': 'Custom Tasks',
        'promote': 'Event Promotion',
        'syncX': 'Sync this event to PP Wallet activity official X account',
        'placeAutoTweet': 'Auto tweet content'
    },
    publish: {
        'saveDraft': 'Save Draft',
        'nextStep': 'Next Step',
        'default': 'Default',
        'title': 'Event Title',
        'time': 'Event Time',
        'startTime': 'Start Date',
        'to': 'to',
        'endTime': 'End Date',
        'cover': 'Event Poster',
        'logo': 'Event Logo',
        'coverSize': 'Recommended 168*168',
        'coverBytes': 'No more than 3MB',
        'coverWait': 'Please wait for the image to finish uploading',
        'content': 'Event Description',
        'tags': 'Event Tags',
        'taskLists': 'Task List',
        'noTaskLists': 'Please add at least one task requirement!',
        'regLong': 'Minimum registration period for participant X accounts',
        'followX': 'Follow specified Twitter',
        'xActive': 'Interact with the latest tweet from a specific Twitter',
        'tweetActive': 'Engage with a specified tweet',
        'retweet3': 'Retweet and tag 3 friends',
        'like': 'Like',
        'linkTweetUrl': 'Link existing tweet URL',
        'autoTweetActive': 'Engage with auto-posted tweet',
        'autoTweetContent': 'Auto-post tweet content',
        'tweetContentTip': 'Please edit the tweet content (up to 200 characters)',
        'tweetContentTip2': 'Once the event is successfully published, ParaPack will post this tweet via your authorized',
        'tweetContentTip3': 'X account',
        'authedX': 'Authorized Twitter',
        'notAuthX': 'Twitter Not Authorized',
        'toBind': 'Bind Now',
        'balanceNeed': 'Asset Requirement',
        'hasToken': 'Hold specified token',
        'chain': 'Issuing Chain',
        'contractAddress': 'Contract Address',
        'tokenName': 'Asset Name',
        'minNum': 'Minimum Amount',
        'other': 'Other',
        'textDesp': 'Text Description',
        'rewardNum': 'Reward Shares',
        'rewardSetting': 'Reward Settings',
        'topUpReward': 'Deposit Rewards',
        'rewartValue': 'Reward Value',
        'rewardTip': 'These funds will be staked in PP to ensure the smooth running of the event. Any remaining funds after the event will be refunded.',
        'serviceFee': 'Service Fee',
        'serviceFeeTip': 'The service fee charged by PP for this event is non-refundable once published',
        'topUpAmount': 'Deposit Amount',
        'topUpAmountTip': 'The total amount of tokens required for this event, including reward value and service fees',
        'topUpAddress': 'Deposit Address',
        'topUpTip': 'Only deposit',
        'topUpTip2': 'chain assets; depositing other assets may result in loss of funds',
        'backAddressTip': 'If there are remaining tokens after the event or for any other reasons, the tokens will be refunded to the transfer address.',
        'backAddressTip2': 'The address must be on the same network as the deposit address, or you may face asset loss!',
        'topUpAddressPlaceholder': 'Please provide a receiving address for your reward tokens',
        'backAddress': 'Refund Address',
        'netAddress': 'Network Address',
        'txid': 'Transaction Hash',
        'txidPlaceholder': 'Please provide the blockchain transaction hash of your transfer to the deposit address',
        'tokenAmountPlaceholder': 'Enter the total reward token amount',
        'xAccount': 'Twitter Account',
        'xAccountPlace': 'e.g., @elonmusk',
        'toPublish': 'I have completed the payment, publish the event',
        'setRewardCount': 'Set Number of Winners (Shares)',
        'publishLang': 'Publish Language',
        'customTask': 'Custom Task',
        'hasTrade': 'Trading Task',
        'tradePath': 'Trading Path',
        'tradeNum': 'Number of Trades',
        'minTradeNumber': 'Minimum Number of Trades',
        'minAmount': 'Transaction Amount',
        'errorFileSize': 'File size exceeds limit',
        'errorPicSize': 'The image size does not meet the requirements',
        'errorTitle': 'Event title cannot be empty',
        'errorTimeRange': 'Event time cannot be empty',
        'errorEndTime': 'End time must be later than start time',
        'errorCover': 'Event poster cannot be empty',
        'coverUploading': 'Event poster is being uploaded, please wait...',
        'errorContent': 'Event description cannot be empty',
        'minTasksCount': 'Please add at least one task requirement!',
        'errorXLong': 'Please set the X account registration period',
        'errorTasksNeed': 'Please complete the task requirements',
        'repeatTasks': 'Duplicate task',
        'errorRewardToken': 'Please set the reward token',
        'errorRewardType': 'Please select the reward type',
        'errorAutoTweet': 'Please enter auto-tweet content',
        'isUploading': 'Image is being uploaded, please wait...',
        'isPublishing': 'Publishing...',
        'publishSuccessful': 'Published Successfully',
        'publishFailed': 'Publish Failed',
        'taskBrief': 'Introduction',
        'customLink': 'Task Link',
        'customCheckLink': 'Verify API',
        'placeCustomCheckLink': 'Task verification API interface',

        'errorTags': 'Event Tags cannot be empty',
        'errorLangs': 'Please improve multilingual content'
    },
    login: {
        title: 'Service Platform Login',
        email: 'Email',
        verifyCode: 'Verification Code',
        pwd: 'Password',
        toLogin: 'Login Now',
        sendCode: 'Send Verification Code',
        thirdLogin: 'Third-Party Account Login',
        xLogin: 'Twitter Login',
        scanLogin: 'Scan to Login',
        downApp: 'Download ParaPack',
        toBind: 'Bind Now',
        otherLogin: 'Other Login Methods',
        bindEmail: 'Bind Email',
        bindEmailTip: 'Complete registration by binding email'
    },
    errMsg: {
        pleaseRead: 'Please read and agree to the platform agreement',
        badNetwork: 'Your network is not stable, please refresh and try again later!',
        pleaseInputEmail: 'Please enter your email',
        pleaseInputCode: 'Please enter the verification code',
        pleaseInputPwd: 'Please enter the password',
        sendFailed: 'Sending Failed',
        loginFailed: 'Login Failed',
        bindFailed: 'Binding Failed',
        noLoginPermit: 'You are not currently a merchant. Please go to the app to apply for registration'
    },
    successMsg: {
        loginSuccess: 'Login Successful',
        bindSuccess: 'Binding Successful'
    },
    server: {
        'Not login!': 'Please log in again',
        'Invalid params!': 'Invalid request parameters',
        'No data!': 'No data available',
        'success': 'Operation successful',
        'fail': 'Operation failed',
        'Uploading, please wait!': 'Uploading, please wait!',
        'Publishing too frequently, please wait a moment!': 'Too many publications in a short period, please wait!',
        'Cover image is being uploaded, please submit again later!': 'Cover image is being uploaded, please try submitting again later!',
        'Asset verification failed, please refresh the recharge address!': 'Asset verification failed, please refresh the deposit address!',
        'Asset verification failed and the recharge address has expired!': 'Asset verification failed, and the deposit address has expired!',
        'Hash has been used!': 'Hash has already been used!',
        'Asset verification failed and the asset balance is insufficient!': 'Asset verification failed, insufficient asset balance!',
        'Asset verification failed, invalid hash!': 'Asset verification failed, invalid hash!',
        'Mission request contains an invalid Twitter account:': 'Task requirements include an invalid Twitter account:',
        'X account is not bound!': 'X account not bound!',
        'The URL of the specified tweet is incorrectly filled in!': 'Incorrectly filled specified tweet URL!',
        'Unable to find the specified tweet!': 'Unable to find the specified tweet!',
        'Save failed!': 'Save failed!',
        'Save event failed!': 'Event save failed!',
        'Save params failed!': 'Failed to save event parameters!'
    },
    dapp: {
        projectManage: 'Project Management',
        dappManage: 'App Management',
        basicInfo: 'Basic',
        otherInfo: 'Other',
        submit: 'Submit Application',
        name: 'Name',
        type: 'Type',
        tags: 'Tags',
        ctime: 'Operation Time',
        status: 'Status',
        handle: 'Action',
        view: 'View',
        del: 'Delete',
        draft: 'Draft',
        refused: 'Rejected',
        checking: 'Under Review',
        published: 'Published',
        tip: 'Tip',
        delTip: 'After deletion, your app will be automatically removed from the PP app store!',
        logo: 'Project Logo',
        errorLogo: 'Project logo cannot be empty',
        logoTip: `Only jpg/png images supported, recommended resolution of 100*100 or higher, <br/> file size below 300KB, keep visual elements centered.`,
        appName: 'App Name',
        errorAppName: 'App name cannot be empty',
        placeAppName: 'Please enter the app name',
        subName: 'App Abbreviation',
        errorSubName: 'App abbreviation cannot be empty',
        placeSubName: '1-2 words that best identify the app',
        desc: 'App Description',
        errorDesc: 'App description in the default language must be filled',
        placeDesc: 'App description in the default language must be provided',
        defLang: 'Default',
        appType: 'App Type',
        errorAppType: 'Please select an app type',
        appChains: 'Supported Chains',
        errorAppChains: 'Please select the chains supported by the app',
        placeAppChains: 'Please select the supported chains',
        appTags: 'App Tags',
        errorAppTags: 'Please select app tags',
        errorAppTags2: 'Up to 5 tags supported',
        placeAppTags: 'Choose up to 5 app tags',
        dappUrl: 'Dapp URL',
        errorDappUrl: 'Please provide the app access link',
        placeDappUrl: 'Access link for the Dapp application',
        appUrl: 'App Download URL',
        errorAppUrl: 'Please provide the app download link',
        placeAppUrl: 'Download link for the app',
        checkStatus: 'Audit Information',
        checkStatusYes: 'Audited',
        checkStatusNo: 'Not Audited',
        errorCheckStatus: 'Please select the audit status',
        checkUrl: 'Audit Report URL',
        errorCheckUrl: 'Please provide the audit report link',
        placeCheckUrl: 'Provide the audit report link',
        pleaseSelect: 'Please Select',
        telegramUrl: 'Contact Telegram',
        errorTelegramUrl: 'Please provide a contact Telegram',
        otherFormData: 'Other Information (Optional)',
        siteUrl: 'Official Website',
        placeSiteUrl: 'Enter website link, e.g., https://',
        whiteBook: 'Whitepaper',
        placeWhiteBook: 'Enter whitepaper link, e.g., https://',
        placeTwitter: 'Enter X account link, e.g., https://',
        placeTg: 'Enter Telegram account link, e.g., https://',
        placeDiscord: 'Enter Discord link, e.g., https://',
        placeFacebook: 'Enter Facebook account link, e.g., https://',
        ppLink: 'Link to PP',
        placePPLink: 'After linking, redirect to the publisher\'s PP account personal center',
        ppLinkOptions: {
            v1: 'No Link',
            v2: 'Link to Event',
            v3: 'Link to Video',
            v4: 'Link to Event & Video'
        },
        tokenAddress: 'Token Info 1',
        tipTokenAddress: 'If your token is listed on PP, we will help you link the token',
        tokenAddress2: 'Token Info 2',
        placeTokenAddress: 'Enter token contract URL, e.g., https://',
        email: 'Contact Email',
        placeEmail: 'Email address of the project contact',
        contact: 'Contact Person',
        placeContact: 'If there is a BD to connect, please fill it in',
        brief: 'Project Description',
        placeBrief: 'Introduce your app to help us better understand it, aiding in the review process',
        saveDraft: 'Save Draft',
        resetForm: 'Reset',
        nextStep: 'Next Step',
        submitForm: 'Submit for Review'
    },
    agent: {
        agentSupply: 'Agent Application',
        agentManage: 'Agent Management',
        agentProfit: 'Agent Earnings',
        urealizedProfit: 'Unwithdrawn Earnings',
        shareRate: 'Business Share Ratio',
        requirement: 'Team Assessment Requirements',
        requirementCount: 'New Event Users',
        profitsBriefTitle: 'Team Share Description',
        realizedYes: 'Met',
        realizedNo: 'Not Met',
        groupShares: 'Team Share Ratio',
        teamCount: 'Team Size',
        realizedCount: 'Event User Acquisition',
        teamUser: 'Team User',
        searchUserKey: 'UID/Nickname',
        nickname: 'Nickname',
        regTime: 'Registration Date',
        brokerType: 'Achievement Type',
        incomeRecords: 'Earnings Records',
        searchIncomeKey: 'UID/Items',
        dateTime: 'Date',
        incomeTitle: 'Items',
        inviteUid: 'Referrer UID',
        brokerReward: 'Agent Commission',
        teamReward: 'Team Commission',
        incomeStatus: 'Status',
        receivedNo: 'Pending',
        receivedYes: 'Received',
        profitsBrief: `1. After setting the share ratio, team users will enjoy business income contributions from subordinates based on the set share ratio. For example: if a 30% share ratio is set at level 1, and a user contributes $100,000 in business income, the direct recommender will receive $30,000 as a reward.<br/><br/>2. The sum of all level share ratios cannot exceed 100%.`,
        pleaseInput: 'Please Enter',
        gradeMark: 'Level',
        errorParams: 'Invalid request parameters',
        errorProfit: 'The ratio must be a number greater than 0, can be left empty if not applicable!',
        minOneProfit: 'Set at least one ratio',
        maxProfit: 'Total ratio cannot exceed 100%',
        setSuccessful: 'Settings Saved',
        setFailed: 'Settings Failed',
        adAgent: 'Advertising Agent',
        activityAgent: 'Event Agent',
        appAgent: 'App Agent'
    }

};