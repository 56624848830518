<template>
  <div class="container">
    <div class="top-menus">
      <div class="menu-item" :class="{on: showSection === 1}" @click="showSection=1">
        <span v-if="showSection <= 1">1</span>
        <i class="el-icon-check" v-else-if="showSection > 1"></i>
        <b :class="{green:showSection > 1}">{{$t('events.basicInfo')}}</b>
      </div>
      <div class="h-line"></div>
      <div class="menu-item" :class="{on: showSection === 2}" @click="nextStep('task', 2)">
        <span v-if="showSection <= 2">2</span>
        <i class="el-icon-check" v-else-if="showSection > 2"></i>
        <b :class="{green:showSection > 2}">{{$t('events.tasksNeed')}}</b>
      </div>
      <div class="h-line"></div>
      <div class="menu-item" :class="{on: showSection === 3}" @click="checkStep1() && nextStep('reward', 3)">
        <span v-if="showSection <= 3">3</span>
        <i class="el-icon-check" v-else-if="showSection > 3"></i>
        <b :class="{green:showSection > 3}">{{$t('events.tasksReward')}}</b>
      </div>
      <div class="h-line"></div>
      <div class="menu-item" :class="{on: showSection === 4}" @click="checkStep1() && checkStep2() && nextStep('publish', 4)">
        <span v-if="showSection <= 4">4</span>
        <i class="el-icon-check" v-else-if="showSection > 4"></i>
        <b :class="{green:showSection > 4}">{{$t('events.eventPublish')}}</b>
      </div>
    </div>

    <div class="form-con" v-if="userInfo">
      <!-- 基本信息 -->
      <div class="basic-info scroll-con" v-if="showSection===1">
        <div class="form-section w-420px">
          <div class="lang-tags">
            <div class="lang-title">{{$t('publish.publishLang')}}</div>
            <span
                class="lang-item"
                :class="{on: form.titleLang === lang.split(':')[0]}"
                @click="form.titleLang = lang.split(':')[0],form.contentLang=form.titleLang,form.coverLang=form.titleLang"
                v-for="(lang, lIdx) in langs"
                :key="lIdx"
            >{{lang.split(':')[1]}}</span>
            <span class="pdl-5">
              {{$t('publish.default')}}[{{getLangName(form.contentLang)}}]
            </span>
          </div>

          <div class="row-item row-input">
            <label class="flex">
              <span class="flex-1">{{$t('publish.title')}}</span>
            </label>
            <el-input
                v-model="form.title[lang.split(':')[0]]"
                size="medium"
                @input="$func.getStringLen(form.title[lang.split(':')[0]]) > 89 && (form.title[lang.split(':')[0]]=form.title[lang.split(':')[0]].slice(0,89))"
                v-for="(lang, lIdx) in langs"
                v-if="form.titleLang === lang.split(':')[0]"
            >
              <template slot="append">{{form.title[lang.split(':')[0]] ? $func.getStringLen(form.title[lang.split(':')[0]]) : 0}}/89</template>
            </el-input>
          </div>

          <div class="row-item row-input">
            <label>{{$t('publish.time')}}</label>
            <el-date-picker
                v-model="form.timeRange"
                size="medium"
                type="datetimerange"
                :range-separator="$t('publish.to')"
                :start-placeholder="$t('publish.startTime')"
                :end-placeholder="$t('publish.endTime')"
                :picker-options="pickerOptions"
            ></el-date-picker>
          </div>

          <!-- LOGO -->
          <div class="row-item row-input">
            <label class="flex">
              <span class="flex-1">{{$t('publish.logo')}}</span>
            </label>
            <div
                class="cover-selector"
                v-for="(lang, lIdx) in langs"
                :key="lIdx"
                v-if="form.coverLang === lang.split(':')[0]"
            >
              <input
                  type="file"
                  :ref="'coverSelector' + langIdx"
                  v-on:change="onChangeCover"
                  accept="image/*"
                  style="opacity: 0;position: absolute;top:-500px;left:0;"
                  v-for="(lang, langIdx) in langs"
                  :key="langIdx+'agent'"
              />
              <div
                  class="remove-pic"
                  @click="form.cover[lang.split(':')[0]] = null,$refs['coverSelector' + lIdx][0].value = null"
                  v-if="form.cover[lang.split(':')[0]]"
              >
                <i class="el-icon-close"></i>
              </div>
              <img
                  :src="form.cover[lang.split(':')[0]]"
                  v-if="form.cover[lang.split(':')[0]]"
              />
              <div @click="onSelectCover(lIdx)" v-else>
                <div class="remark">
                  {{$t('publish.coverSize')}}<br/>{{$t('publish.coverBytes')}}
                </div>
              </div>
              <div class="uploading" v-if="isUploading">
                <i class="el-icon-loading"></i> Uploading...
              </div>
            </div>
          </div>

          <div class="row-item row-textarea">
            <label class="flex">
              <span class="flex-1">{{$t('publish.content')}}</span>
            </label>
            <el-input
                type="textarea"
                v-model="form.content[lang.split(':')[0]]"
                rows="8"
                maxlength="200"
                show-word-limit
                v-for="(lang, lIdx) in langs"
                v-if="form.contentLang === lang.split(':')[0]"
            ></el-input>
          </div>

          <div class="row-item row-input">
            <label class="flex">
              <span class="flex-1">{{$t('publish.tags')}}</span>
            </label>
            <el-select v-model="form.tags" :placeholder="$t('common.pleaseSelect')" :no-data-text="$t('common.noData')">
              <el-option :label="item" :value="idx" v-for="(item, idx) in tags" :key="idx">
                {{item}}
              </el-option>
            </el-select>
          </div>

          <div class="btn-group flex mgt-20">
            <el-button size="small" @click="saveDraft">{{$t('publish.saveDraft')}}</el-button>
            <el-button size="small" type="primary" @click="nextStep('task', 2)">{{$t('publish.nextStep')}}</el-button>
          </div>
        </div>
      </div>

      <!-- 任务要求 -->
      <div class="form-section basic-info flex align-top" v-else-if="showSection===2">
        <div class="middle-panel flex-1" v-if="!isHasTask()">
          <div class="max-500">
            <h2>{{$t('publish.taskLists')}}</h2>
            <div class="text-red">{{$t('publish.noTaskLists')}}</div>
          </div>
        </div>

        <div class="middle-panel flex-1 scroll-con" v-else>
          <div class="max-500">
            <h2>{{$t('publish.taskLists')}}</h2>
            <div class="task-section-title" v-if="isHasXTask()">
              <img src="../assets/imgs/pc/publish/16.png"/>
              Twitter
            </div>
          </div>
          <div style="display: none;">{{addTaskIdx(0)}}</div>

          <div
              class="task-item"
              :class="{error:formError.twitter_reg_long===false}"
              v-if="tasksNeed.twitter_reg_long"
          >
            <div class="bold">Task{{addTaskIdx()}}</div>
            <div class="mgt-5">
              {{$t('publish.regLong')}}
              <input type="text" v-model="form.tasks.twitter_reg_long"/>
            </div>
            <i class="el-icon-close close-btn" @click="tasksNeed.twitter_reg_long=false"></i>
          </div>

          <div
              class="task-item"
              :class="{error:formError.like_twitter !== undefined && formError.like_twitter[i]===false}"
              v-for="(r,i) in form.tasks.like_twitter"
              v-if="tasksNeed.like_twitter"
          >
            <div class="bold">Task{{addTaskIdx()}}</div>
            <div class="mgt-5">
              {{$t('publish.followX')}}
              <input type="text" v-model="form.tasks.like_twitter[i]" :placeholder="$t('publish.xAccountPlace')"/>
            </div>
            <i class="el-icon-close close-btn" @click="tasksNeed.like_twitter-=1,form.tasks.like_twitter.splice(i,1)"></i>
          </div>

          <div
              class="task-item"
              :class="{error:formError.twitter_active_account && formError.twitter_active_account[i]===false}"
              v-for="(r,i) in form.tasks.twitter_active_account"
              v-if="tasksNeed.twitter_active_account"
          >
            <div class="bold">Task{{addTaskIdx()}}</div>
            <div class="mgt-5">
              {{$t('publish.xActive')}}
              <input type="text" v-model="form.tasks.twitter_active_account[i].name" :placeholder="$t('publish.xAccountPlace')"/>
            </div>
            <div class="flex mgt-5">
              <input type="checkbox" value="1" checked v-model="form.tasks.twitter_active_account[i].like"/>
              {{$t('publish.like')}}
              <div class="w-32"></div>
              <input type="checkbox" value="1" v-model="form.tasks.twitter_active_account[i].retweet3"/>
              {{$t('publish.retweet3')}}
            </div>
            <i
                class="el-icon-close close-btn"
                @click="tasksNeed.twitter_active_account-=1,
                form.tasks.twitter_active_account.splice(i,1)"
            ></i>
          </div>

          <div
              class="task-item"
              :class="{error:formError.tweet_active_id && formError.tweet_active_id[i]===false}"
              v-for="(r,i) in form.tasks.tweet_active_id"
              v-if="tasksNeed.tweet_active_id"
          >
            <div class="bold">Task{{addTaskIdx()}}</div>
            <div class="flex mgt-5">
              <span>{{$t('publish.tweetActive')}}</span>
            </div>
            <div class="flex mgt-5">
              <label>{{$t('publish.linkTweetUrl')}}</label>
              <input type="text" v-model="form.tasks.tweet_active_id[i].tweet_id" class="flex-1 fs-12" placeholder="eg: https://x.com/elonmusk/status/1804204747601424648" />
            </div>
            <div class="flex mgt-5">
              <input type="checkbox" value="1" v-model="form.tasks.tweet_active_id[i].like"/>
              {{$t('publish.like')}}
              <div class="w-32"></div>
              <input type="checkbox" value="1" v-model="form.tasks.tweet_active_id[i].retweet3"/>
              {{$t('publish.retweet3')}}
            </div>
            <i
                class="el-icon-close close-btn"
                @click="tasksNeed.tweet_active_id-=1,
                form.tasks.tweet_active_id.splice(i,1)"
            ></i>
          </div>

          <div
              class="task-item auto-tweet-area"
              :class="{error:formError.auto_tweet===false}"
              v-if="false && tasksNeed.auto_tweet"
          >
            <div class="bold">Task{{addTaskIdx()}}</div>
            <div class="flex mgt-5">
              <span>{{$t('publish.autoTweetActive')}}</span>
            </div>
            <div class="row-textarea mgt-5">
              <label class="flex">
                {{checkXAuth()}}
                <span class="flex-1">{{$t('publish.autoTweetContent')}}</span>
                <a :href="authXUrl" target="_blank" class="text-red pointer" v-if="userInfo.x_expire">请授权X账户</a>
                <span class="text-green" v-else-if="userInfo.x_id">{{$t('publish.authedX')}}: @{{userInfo.x_account}}</span>
                <router-link to="/setting" class="text-red pointer" v-else>{{$t('publish.notAuthX')}},<i>{{$t('publish.toBind')}}</i></router-link>
              </label>
              <el-input
                  v-model="form.tasks.tweet_content"
                  type="textarea"
                  rows="5"
                  maxlength="200"
                  show-word-limit
                  class="mgt-10"
              ></el-input>
              <div class="auto-tweet-tips" v-if="!form.tasks.tweet_content">
                {{$t('publish.tweetContentTip')}}<br/>
                {{$t('publish.tweetContentTip2')}}<br/>
                {{$t('publish.tweetContentTip3')}}
              </div>
            </div>
            <div class="flex mgt-5">
              <input type="checkbox" value="1" v-model="form.tasks.tweet_active_like"/>
              {{$t('publish.like')}}
              <div class="w-32"></div>
              <input type="checkbox" value="1" v-model="form.tasks.tweet_active_retweet3"/>
              {{$t('publish.retweet3')}}
            </div>
            <i class="el-icon-close close-btn" @click="tasksNeed.auto_tweet=false,form.tasks.auto_tweet=false"></i>
          </div>

          <!-- 平台任务 -->
          <div class="task-section-title" v-if="tasksNeed.has_token || tasksNeed.has_nft || tasksNeed.has_trade">
            <img src="../assets/imgs/pc/publish/15.png"/>
            {{$t('publish.balanceNeed')}}
          </div>

          <!-- 持有token -->
          <div
              class="task-item"
              :class="{error:formError.has_token && formError.has_token[i]===false}"
              v-for="(r,i) in form.tasks.has_token"
              v-if="tasksNeed.has_token > 0"
          >
            <div class="bold">Task{{addTaskIdx()}}</div>
            <div class="bold mgt-10">{{$t('publish.hasToken')}}</div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.chain')}}</label>
              <el-select
                  size="small"
                  v-model="form.tasks.has_token[i].chain"
                  class="flex-1"
                  @change="onChangeTaskChain"
                  :placeholder="$t('common.pleaseSelect')"
                  :no-data-text="$t('common.noData')"
              >
                <el-option :label="item.chainName" :value="[idx, i]" v-for="(item, idx) in support.chains" :key="idx">
                  {{item.chainName}}
                </el-option>
              </el-select>
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.tokenName')}}</label>
              <el-select
                  size="small"
                  v-model="form.tasks.has_token[i].name"
                  :placeholder="$t('publish.tokenName')"
                  class="flex-1"
                  @change="onChangeTaskCoin"
                  :no-data-text="$t('common.noData')"
              >
                <el-option
                    :label="item.coinName"
                    :value="[idx, i]"
                    v-for="(item, idx) in supportTaskCoins['coins_' + i]"
                    :key="idx"
                >
                  {{item.coinName}}
                </el-option>
              </el-select>
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.contractAddress')}}</label>
              <input v-model="form.tasks.has_token[i].contract" readonly :placeholder="$t('publish.contractAddress')" />
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.minNum')}}</label>
              <input v-model="form.tasks.has_token[i].amount" :placeholder="$t('publish.minNum')" />
            </div>

            <i class="el-icon-close close-btn" @click="tasksNeed.has_token--,form.tasks.has_token.splice(i,1)"></i>
          </div>

          <div class="task-item" v-if="tasksNeed.has_nft > 0">
            <div class="bold">Task{{addTaskIdx()}}</div>
            <div class="bold mgt-10">持有指定nft</div>
            <div class="flex mgt-10">
              <label style="width:58px;">发行链</label>
              <el-select size="small" v-model="form.tasks.has_nft_chain" :placeholder="$t('common.pleaseSelect')" :no-data-text="$t('common.noData')">
                <el-option :label="item.chainName" :value="item.chainName" v-for="(item, idx) in support.chains" :key="idx">
                  {{item.chainName}}
                </el-option>
              </el-select>
            </div>
            <el-input size="small" v-model="form.tasks.has_nft_contract" placeholder="合约地址" class="mgt-10">
              <template slot="prepend">合约地址</template>
            </el-input>
            <el-input size="small" v-model="form.tasks.has_nft_name" placeholder="token名称" class="mgt-10">
              <template slot="prepend">系列名称</template>
            </el-input>
            <el-input size="small" v-model="form.tasks.has_nft_amount" placeholder="数量" class="mgt-10">
              <template slot="prepend">最小数量</template>
            </el-input>
            <i class="el-icon-close close-btn" @click="tasksNeed.has_nft=false"></i>
          </div>

          <!-- 交易任务 -->
          <div style="display: none;">{{tasksNeed.has_trade}}</div>
          <div
              class="task-item"
              :class="{error:formError.has_trade && formError.has_trade[i]===false}"
              v-for="(r,i) in form.tasks.has_trade"
              v-if="tasksNeed.has_trade > 0"
          >
            <div class="bold">Task{{addTaskIdx()}}</div>
            <div class="bold mgt-10">{{$t('publish.hasTrade')}}</div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.chain')}}</label>
              <el-select
                  size="small"
                  v-model="form.tasks.has_trade[i].chain"
                  class="flex-1"
                  @change="onChangeTradeTaskChain"
                  :placeholder="$t('common.pleaseSelect')"
                  :no-data-text="$t('common.noData')"
              >
                <el-option :label="item.chainName" :value="[idx, i]" v-for="(item, idx) in support.chains" :key="idx">
                  {{item.chainName}}
                </el-option>
              </el-select>
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.tokenName')}}</label>
              <el-select
                  size="small"
                  v-model="form.tasks.has_trade[i].name"
                  :placeholder="$t('publish.tokenName')"
                  class="flex-1"
                  @change="onChangeTradeTaskCoin"
                  :no-data-text="$t('common.noData')"
              >
                <el-option
                    :label="item.coinName"
                    :value="[idx, i]"
                    v-for="(item, idx) in supportTaskCoins['coins_' + i]"
                    :key="idx"
                >
                  {{item.coinName}}
                </el-option>
              </el-select>
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.contractAddress')}}</label>
              <input v-model="form.tasks.has_trade[i].contract" readonly disabled :placeholder="$t('publish.contractAddress')" />
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.tradePath')}}</label>

              <el-select
                  size="mini"
                  v-model="hasTradePath[i + 'A']"
                  :placeholder="$t('publish.tokenName')"
                  class="flex-1"
                  @change="(v) => onChangeTradePath(v, i, 'A')"
                  :no-data-text="$t('common.noData')"
              >
                <el-option
                    :label="item.coinName"
                    :value="item.coinName"
                    v-for="(item, idx) in supportTaskCoins['coins_' + i]"
                    :key="idx"
                    v-if="!hasTradePath[i + 'B'] || item.coinName !== hasTradePath[i + 'B']"
                >
                  {{item.coinName}}
                </el-option>
              </el-select>
              <span style="padding: 0 10px;">/</span>
              <el-select
                  size="mini"
                  v-model="hasTradePath[i + 'B']"
                  :placeholder="$t('publish.tokenName')"
                  class="flex-1"
                  @change="(v) => onChangeTradePath(v, i, 'B')"
                  :no-data-text="$t('common.noData')"
              >
                <el-option
                    :label="item.coinName"
                    :value="item.coinName"
                    v-for="(item, idx) in supportTaskCoins['coins_' + i]"
                    :key="idx"
                    v-if="!hasTradePath[i + 'A'] || item.coinName !== hasTradePath[i + 'A']"
                >
                  {{item.coinName}}
                </el-option>
              </el-select>

<!--              <input v-model="form.tasks.has_trade[i].pair" :placeholder="$t('publish.tradePath') + ' eg. ETH/USDT'" />-->
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.tradeNum')}}</label>
              <input v-model="form.tasks.has_trade[i].number" :placeholder="$t('publish.minTradeNumber')" />
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.minAmount')}}</label>
              <el-input
                  v-model="form.tasks.has_trade[i].amount"
                  :placeholder="$t('publish.minAmount')"
                  @input="() => { onCustomContentInput() }"
                  size="small"
              >
                <template slot="append">USDT</template>
              </el-input>
            </div>

            <i class="el-icon-close close-btn" @click="tasksNeed.has_trade--,form.tasks.has_trade.splice(i,1)"></i>
          </div>

          <!-- 自定义任务 -->
          <div class="task-section-title" v-if="tasksNeed.has_custom">
            <img src="../assets/imgs/pc/publish/15.png"/>
            {{$t('publish.customTask')}}
          </div>
          <div
              class="task-item"
              :class="{error:formError.has_custom && formError.has_custom[i]===false}"
              v-for="(r,i) in form.tasks.has_custom"
              v-if="tasksNeed.has_custom > 0"
          >
            <div class="bold">Task{{addTaskIdx()}}</div>

            <div class="input-item mgt-10">
              <label style="padding-right: 10px;">{{$t('publish.taskBrief')}}</label>
              <el-input
                  size="small"
                  v-model="form.tasks.has_custom[i].brief[customTaskLang[i]]"
                  :placeholder="$t('publish.taskBrief')"
                  @input="() => { onCustomContentInput(i) }"
              >
                <template slot="append">
                  <el-tag
                      size="mini"
                      v-for="(lang, lIdx) in langs"
                      :key="lIdx"
                      :type="customTaskLang[i] === lang.split(':')[0] ? 'primary' : 'info'"
                      class="mgl-5 pointer"
                      @click="
                      customTaskLang[i] = lang.split(':')[0],$forceUpdate(),
                      !form.tasks.has_custom[i].brief[customTaskLang[i]] && (form.tasks.has_custom[i].brief[customTaskLang[i]] = '')"
                  >{{lang.split(':')[1]}}</el-tag>
                </template>
              </el-input>
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.customLink')}}</label>
              <el-input
                  size="small"
                  v-model="form.tasks.has_custom[i].link"
                  :placeholder="$t('publish.customLink')"
                  @input="() => { onCustomContentInput(i) }"
              ></el-input>
            </div>
            <div class="input-item mgt-10">
              <label>{{$t('publish.customCheckLink')}}</label>
              <el-input
                  size="small"
                  v-model="form.tasks.has_custom[i].checkLink"
                  :placeholder="$t('publish.placeCustomCheckLink')"
                  @input="() => { onCustomContentInput(i) }"
              ></el-input>
            </div>

            <i class="el-icon-close close-btn" @click="tasksNeed.has_custom--,form.tasks.has_custom.splice(i,1)"></i>
          </div>

          <!-- 其他任务 -->
          <div class="task-section-title" v-if="tasksNeed.other">
            <img src="../assets/imgs/pc/publish/17.png"/>
            {{$t('publish.other')}}
          </div>

          <div class="task-item" :class="{error:formError.other===false}" v-if="tasksNeed.other">
            <div class="bold">Task{{addTaskIdx()}}</div>
            <div class="mgt-10 flex">
              <div class="flex-1">{{$t('publish.textDesp')}}</div>
              <div>
                <el-tag
                    size="mini"
                    v-for="(lang, lIdx) in langs"
                    :key="lIdx"
                    :type="otherTaskLang === lang.split(':')[0] ? 'primary' : 'info'"
                    class="mgl-5 pointer"
                    @click="otherTaskLang = lang.split(':')[0]"
                >{{lang.split(':')[1]}}</el-tag>
              </div>
            </div>
            <el-input v-model="form.tasks.other[otherTaskLang]" type="textarea" rows="4" class="mgt-5">></el-input>
          </div>

          <div class="btn-group flex mgt-20">
            <el-button size="small" @click="saveDraft">{{$t('publish.saveDraft')}}</el-button>
            <el-button size="small" type="primary" @click="nextStep('reward', 3)">{{$t('publish.nextStep')}}</el-button>
          </div>
        </div>

        <!-- 右侧任务选择 -->
        <div class="right-panel">
          <div class="top-tabs">
<!--            <span :class="{on: tabsTabActive === 0}" @click="tabsTabActive=0">-->
<!--              <img src="../assets/imgs/pc/publish/16.png"/>Twitter-->
<!--            </span>-->
            <span :class="{on: tabsTabActive === 1}" @click="tabsTabActive=1">
              <img src="../assets/imgs/pc/publish/15.png"/>{{$t('task.platformTasks')}}
            </span>
            <span :class="{on: tabsTabActive === 2}" @click="tabsTabActive=2">
              <img src="../assets/imgs/pc/publish/17.png"/>{{$t('task.projectTasks')}}
            </span>
          </div>

          <!-- twitter tasks options -->
          <div class="task-options" v-if="tabsTabActive === 0">
            <div
                class="option-item"
                @click="tasksNeed.twitter_reg_long = !tasksNeed.twitter_reg_long,form.tasks.twitter_reg_long=24"
            >
              <span>参与者x账号注册时长</span>
              <i class="el-icon-success text-success" v-if="tasksNeed.twitter_reg_long"></i>
              <i class="el-icon-circle-plus-outline bold" v-else></i>
            </div>
            <div
                class="option-item"
                @click="$set(form.tasks.like_twitter,tasksNeed.like_twitter,null),tasksNeed.like_twitter++"
            >
              <span>关注x账号</span>
              <i class="el-icon-circle-plus-outline bold"></i>
            </div>
            <div
                class="option-item"
                @click="$set(form.tasks.twitter_active_account,tasksNeed.twitter_active_account,{name:null,like:true,retweet3:true}),tasksNeed.twitter_active_account++"
            >
              <span>参与最新推文互动</span>
              <i class="el-icon-circle-plus-outline bold"></i>
            </div>
            <div
                class="option-item"
                @click="
                $set(form.tasks.tweet_active_id,tasksNeed.tweet_active_id,{tweet_id:null,like:true,retweet3:true}),
                tasksNeed.tweet_active_id++"
            >
              <span>参与指定推文互动</span>
              <i class="el-icon-circle-plus-outline bold"></i>
            </div>
            <div
                class="option-item"
                @click="
                $set(form.tasks,'auto_tweet',!form.tasks.auto_tweet),
                tasksNeed.auto_tweet=!tasksNeed.auto_tweet"
            >
              <span>参与代发推文互动</span>
              <i class="el-icon-success text-success" v-if="tasksNeed.auto_tweet"></i>
              <i class="el-icon-circle-plus-outline bold" v-else></i>
            </div>
          </div>

          <!-- platform tasks options -->
          <div class="task-options" v-else-if="tabsTabActive===1">
            <div class="option-item" @click="form.tasks.has_token[tasksNeed.has_token]={chain:null,contract:null,name:null,amount:null},tasksNeed.has_token++">
              <span>{{$t('task.hasTokens')}}</span>
              <i class="el-icon-circle-plus-outline bold"></i>
            </div>
            <div class="option-item" @click="form.tasks.has_trade[tasksNeed.has_trade]={chain:null,contract:null,name:null,pair:null,number:null,amount:null},tasksNeed.has_trade++">
              <span>{{$t('task.tradeTokens')}}</span>
              <i class="el-icon-circle-plus-outline bold"></i>
            </div>
<!--            <div class="option-item" @click="tasksNeed.has_nft = !tasksNeed.has_nft">-->
<!--              <span>持有指定nft</span>-->
<!--              <i class="el-icon-circle-plus-outline bold"></i>-->
<!--            </div>-->
            <div class="option-item" @click="tasksNeed.other = !tasksNeed.other,tasksNeed.other ? (!form.tasks.other && (form.tasks.other={})) : (form.tasks.other=null)">
              <span>{{$t('task.otherTasks')}}</span>
              <i class="el-icon-success text-success" v-if="tasksNeed.other"></i>
              <i class="el-icon-circle-plus-outline bold" v-else></i>
            </div>
          </div>

          <div class="task-options" v-else-if="tabsTabActive===2">
            <div class="option-item" @click="
            form.tasks.has_custom[tasksNeed.has_custom]={brief:{en_US:''},link:null,checkLink:null},
            customTaskLang[tasksNeed.has_custom]='en_US',
            tasksNeed.has_custom++">
              <span>{{$t('task.selfTasks')}}</span>
              <i class="el-icon-circle-plus-outline bold"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- 赠品设置 -->
      <div class="form-section basic-info flex" v-else-if="showSection===3">
        <div class="middle-panel flex-1">
          <div class="max-500">
            <!-- 赠品设置 -->
            <div class="mgt-20">
              <div class="bold fs-13">{{$t('publish.rewardSetting')}}</div>
              <div class="flex mgt-10">
                <el-select
                    size="small"
                    v-model="form.rewards.token_chain"
                    class="flex-1"
                    @change="onChangeChain"
                    :placeholder="$t('common.pleaseSelect')"
                    :no-data-text="$t('common.noData')"
                >
                  <el-option
                      :label="item.chainName"
                      :value="idx"
                      v-for="(item, idx) in support.chains"
                      :key="idx"
                  >
                    {{item.chainName}}
                  </el-option>
                </el-select>
                <div class="w-8"></div>
                <el-select
                    size="small"
                    v-model="form.rewards.token_name"
                    class="flex-1"
                    @change="onChangeCoin"
                    :placeholder="$t('common.pleaseSelect')"
                    :no-data-text="$t('common.noData')"
                >
                  <el-option
                      :label="item.coinName"
                      :value="idx"
                      v-for="(item, idx) in support.coins"
                      :key="idx"
                  >
                    {{item.coinName}}
                  </el-option>
                </el-select>
              </div>
              <el-input size="small" v-model="form.rewards.token_amount" :placeholder="$t('publish.tokenAmountPlaceholder')" class="mgt-10"></el-input>
            </div>

            <!-- 奖励机制 -->
            <div class="mgt-30">
              <div class="bold fs-13">{{$t('task.rewardMode')}}</div>
              <div class="row-radio mgt-10">
                <div class="flex flex-1">
                  <input type="radio" name="reward_type" value="1" v-model="form.rewards.reward_type"/>
                  <label>{{$t('events.getReward')}}</label>
                </div>
                <div class="flex flex-1">
                  <input type="radio" name="reward_type" value="2" v-model="form.rewards.reward_type"/>
                  <label>{{$t('events.fcfs')}}</label>
                </div>
                <div class="flex flex-1">
                  <input type="radio" name="reward_type" value="3" v-model="form.rewards.reward_type"/>
                  <label>{{$t('events.divideAmount')}}</label>
                </div>
              </div>

              <el-input
                  size="small"
                  v-model="form.rewards.reward_users_count"
                  :placeholder="$t('publish.setRewardCount')"
                  class="mgt-30"
                  v-if="form.rewards.reward_type != 3"
              >
                <template slot="prepend">
                  <span class="bold">{{$t('publish.rewardNum')}}</span>
                </template>
              </el-input>
            </div>

            <div class="mgt-30">
              <div class="bold fs-13">{{$t('task.promote')}}</div>
              <div class="row-radio mgt-10">
                <div class="flex flex-1">
                  <input type="checkbox" name="auto_tweet" value="1" v-model="form.auto_tweet" @change="onChangeAutoTweet"/>
                  <label>{{$t('task.syncX')}}</label>
                </div>
              </div>
              <div
                  class="task-item auto-tweet-area"
                  :class="{error:formError.auto_tweet===false}"
                  v-if="form.auto_tweet"
              >
                <el-input
                    v-model="form.tasks.auto_tweet.tweet_content"
                    type="textarea"
                    rows="5"
                    maxlength="200"
                    show-word-limit
                    :placeholder="$t('task.placeAutoTweet')"
                    class="hide-border"
                ></el-input>

                <div class="pics-uploader">
                  <div class="preview-item" v-for="(pic, i) in form.tasks.auto_tweet.tweet_pics" :key="i">
                    <img :src="pic">
                    <i class="el-icon-close remove-btn" @click="form.tasks.auto_tweet.tweet_pics.splice(i, 1),$forceUpdate()"></i>
                  </div>
                  <el-upload
                      action=""
                      :show-file-list="false"
                      :auto-upload="false"
                      :on-change="onUploadPic"
                      class="preview-item upload-image-btn"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
            </div>

            <div class="btn-group flex mgt-30">
              <el-button size="small" @click="saveDraft">{{$t('publish.saveDraft')}}</el-button>
              <el-button size="small" type="primary" @click="nextStep('publish', 4)">{{$t('publish.nextStep')}}</el-button>
            </div>
          </div>
        </div>

        <div class="preview-panel">
          <div class="box-con">
            <mobile-preview :support="support"></mobile-preview>
          </div>
        </div>
      </div>

      <!-- 最终发布 -->
      <div class="form-section w-600px" v-else-if="showSection===4">
        <div class="fs-16 bold">{{$t('publish.topUpReward')}}</div>
        <div v-if="form.rewards.token_amount">
          <div class="mgt-30">
            <div class="bold">{{$t('publish.rewartValue')}}：{{form.rewards.token_amount}} {{form.rewards.token_name}} ({{form.rewards.token_chain}})</div>
            <div class="text-dark mgt-5 pdl-65">{{$t('publish.rewardTip')}}</div>
          </div>
          <div class="mgt-30">
            <div class="bold">{{$t('publish.serviceFee')}}：{{form.rewards.token_amount*0.01}} {{form.rewards.token_name}}</div>
            <div class="text-dark mgt-5 pdl-65">{{$t('publish.serviceFeeTip')}}</div>
          </div>
          <div class="mgt-30">
            <div class="bold">{{$t('publish.topUpAmount')}}：{{parseFloat(form.rewards.token_amount)*1.01}} {{form.rewards.token_name}}</div>
            <div class="text-dark mgt-5 pdl-65">{{$t('publish.topUpAmountTip')}}</div>
          </div>
          <div class="mgt-30 flex align-top">
            <div class="bold" style="width:75px;">{{$t('publish.topUpAddress')}}：</div>
            <div>
              <div class="flex">
                <div class="address-qrcode">
                  <canvas ref="qrcodeCanvas"></canvas>
                </div>
                <div class="mgl-10">
                  <div>{{$t('publish.netAddress')}}</div>
                  <div class="mgt-10">{{this.form.rewards.token_chain}}</div>
                  <div class="mgt-10">{{this.rechargeInfo.address}}</div>
                </div>
              </div>
              <div class="text-red mgt-5">{{$t('publish.topUpTip')}} {{this.form.rewards.token_chain}} {{$t('publish.topUpTip2')}}</div>
            </div>
          </div>
          <div class="flex mgt-30">
            <div class="bold">{{$t('publish.txid')}}：</div>
            <div class="flex-1">
              <el-input
                  size="small"
                  v-model="form.txid"
                  :placeholder="$t('publish.txidPlaceholder')"
              ></el-input>
            </div>
          </div>
<!--          <div class="mgt-30">-->
<!--            <div class="flex">-->
<!--              <div class="bold">{{$t('publish.backAddress')}}：</div>-->
<!--              <div class="flex-1">-->
<!--                <div>{{$t('publish.backAddressTip')}}</div>-->
<!--                <div class="text-red mgt-5">{{$t('publish.backAddressTip2')}}</div>-->
<!--                <el-input-->
<!--                    size="small"-->
<!--                    v-model="form.back_address"-->
<!--                    :placeholder="$t('publish.topUpAddressPlaceholder')"-->
<!--                ></el-input>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div class="text-center">
          <el-button size="small" type="primary" class="mgt-16" @click="publishEvent">{{$t('publish.toPublish')}}</el-button>
        </div>

      </div>

      <div class="loading-con" v-if="loading">
        <i class="el-icon-loading"></i>
        <div class="msg">{{loading}}</div>
      </div>

    </div>
    <div v-else-if="userInfo === null">{{$t('common.notLogin')}}</div>

    <div class="login-dialog confirm" v-if="dialog && dialog.msg">
      <div class="title" v-if="dialog.title">{{dialog.title}}</div>
      <div class="sub-title">
        {{dialog.msg}}
      </div>
      <div class="btn-group">
        <div class="cancel-btn" @click="dialog.msg=null">{{$t('common.cancel')}}</div>
        <div class="confirm-btn">{{$t('common.confirm')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as EventsApi from "@/api/events";
import * as TwitterApi from "@/api/twitter";
import MobilePreview from "./publish/MobilePreview";
import QRCode from "qrcode";
import { updater, eventBus } from "@rainbow654/reloader";

let updatePreviewTimer = null;
let taskIdx = 0;

export default {
  components: { MobilePreview },
  data(){
    return {
      userInfo: false,
      showSection: 1,
      tabsTabActive: 1,
      dialog: {
        title: null,
        msg: null,
        cancel: null,
        confirm: null
      },
      // 支持的链
      support: {},
      supportTaskCoins: {},
      pickerOptions: {
        disabledDate(time) {
          // return time.getTime() < Date.now(); //当天不可选
          return time.getTime() < new Date().getTime(); //  - 86400000是否包括当天
        }
      },
      formError: {},
      tasksNeed: {
        twitter_reg_long: false,
        like_twitter: 0,
        twitter_active_account: 0,
        tweet_active_id: 0,
        auto_tweet: false,
        has_token: 0,
        has_nft: 0,
        has_trade: 0,
        has_custom: 0,
        other: false
      },
      hasTradePath: {},
      tags: ['Bridge', 'Defi', 'Loan', 'Swap', 'NFT', 'Game', 'Other'],
      langs: ['en_US:EN', 'el_GR:GR'],
      otherTaskLang: 'en_US',
      customTaskLang: [],
      eventId: null,
      form: {
        title: {},
        titleLang: 'en_US',
        cover: {},
        coverLang: 'en_US',
        content: {},
        contentLang: 'en_US',
        timeRange: [],
        start_time: null,
        end_time: null,
        tags: null,
        twitter_uid: null,
        twitter_name: null,
        txid: null,
        tasks: {
          twitter_reg_long: null, // 24个月
          like_twitter: [],
          // 指定账号互动
          twitter_active_account: [],
          // 指定推文互动
          tweet_active_id: [],
          // 代发推文互动
          auto_tweet: {},
          /*auto_tweet: false,
          tweet_content: null,
          tweet_id: null,
          tweet_active_like: true,
          tweet_active_retweet3: true,*/
          // 资产要求
          has_token: [],
          has_nft: [],
          has_trade: [],
          has_custom: [],
          // 其他任务
          other: {}
        },
        rewards: {
          token_name: null,
          token_chain: null,
          token_amount: null,
          reward_type: 1,
          reward_users_count: null,
        }
      },
      rechargeInfo: {},
      loading: null,
      isUploading: false,
      authXUrl: null,
      // x 图片上传
      previewDialogVisible: false,
      previewDialogImageUrl: null
    }
  },
  mounted() {
    this.$eventBus.$on('LOGIN-SUCCESS', userInfo => {
      this.userInfo = userInfo;
    });
    this.$eventBus.$on('ACCOUNT_INFO', res => {
      const now = parseInt(new Date().getTime()/1000);
      if(res.userInfo && now - res.userInfo.expire_time <= 0){
        this.userInfo = res.userInfo;
        this.form.twitter_uid = this.userInfo.x_id;
        this.form.twitter_name = this.userInfo.x_account;
        this.twitterLoginUrl = res.twitterLoginUrl;
      }
    });
    this.$eventBus.$on('UPDATE-INDEX', idx => {
      taskIdx = idx;
    });

    // 请求加载初始化数据
    this.$eventBus.$emit('LOAD_INIT_DATA');

    // 加载支持的链
    this.loadSupport();

    // 加载草稿和缓存
    const { did, id } = this.$route.query;
    if(id) {
      this.eventId = id;
      this.loadEventInfo(id);
    } else if(did){
      this.loadDraft(did);
    } else {
      let cacheForm = localStorage.getItem('CACHE_EVENT_FORM');
      if(cacheForm){
        cacheForm = JSON.parse(cacheForm);
        this.form = Object.assign({}, this.form, cacheForm.form);
        this.tasksNeed = cacheForm.need;
        this.showSection = cacheForm.showSection || 1;
      }
    }

    // 更新预览并本地缓存数据
    updatePreviewTimer = setInterval(() => {
      if(this.form.title){
        for(let k in this.form.title){
          //this.form.title[k] = this.$func.filterStr(this.form.title[k]);
        }
        for(let k in this.form.content){
          //this.form.content[k] = this.$func.filterStr(this.form.content[k]);
        }
      }

      this.$eventBus.$emit('UPDATE_PREVIEW', this.form);
      let cacheForm = { need: this.tasksNeed, showSection: this.showSection };
      cacheForm = Object.assign(cacheForm, { form: this.form });
      if(cacheForm.from && cacheForm.from.cover && cacheForm.from.cover.indexOf('data:image') === 0){
        delete cacheForm.from.cover;
      }
      localStorage.setItem('CACHE_EVENT_FORM', JSON.stringify(cacheForm));
    }, 1000);
  },
  beforeDestroy() {
    updatePreviewTimer && clearInterval(updatePreviewTimer);
  },
  methods: {
    /**
     * 语言名称
     * @param lang
     * @returns {string|null}
     */
    getLangName(lang){
      lang = this.langs.filter(l => l.split(':')[0] === lang);
      if(lang && lang[0]){
        return lang[0].split(':')[1];
      }
      return null;
    },
    /**
     * 加载支持的链
     * @returns {Promise<void>}
     */
    async loadSupport(){
      const res = await EventsApi.getSupport();
      if(res && res.data){
        res.data.chains = res.data.chains.filter(r => r.supportAcvitity);
        this.support = res.data;
        this.$set(this.support, 'coins', []);

        // form selector
        if(this.tasksNeed.has_trade){
          this.form.tasks.has_trade.map((r, idx) => {
            this.support.chains.map((chainInfo, i) => {
              if(r.chain === chainInfo.chainName){
                this.onChangeTradeTaskChain([i, idx]);
              }
            });
            if(r.pair){
              const pair = r.pair.split('/');
              this.hasTradePath[idx + 'A'] = pair[0];
              this.hasTradePath[idx + 'B'] = pair[1];
            }
          });
        }
      }
    },
    /**
     * 资产校验
     */
    onChangeTaskChain(arr){
      const idx = arr[0], i = arr[1];
      const item = this.support.chains[idx];
      this.$set(this.supportTaskCoins, 'coins_' + i, item.relatedCoins.filter(r => r.supportAcvitity));
      this.form.tasks.has_token[i].chain = item.chainName;
      console.log(this.supportTaskCoins)
    },
    onChangeTaskCoin(arr){
      const idx = arr[0], i = arr[1];
      const item = this.supportTaskCoins['coins_' + i][idx];
      this.$set(this.form.tasks.has_token[i], 'name', item.coinName);
      this.$set(this.form.tasks.has_token[i], 'contract', item.contractAddr ? item.contractAddr : '0x0000000000000000000000000000000000000000');
      this.$forceUpdate();
    },
    /**
     * 交易校验
     */
    onChangeTradeTaskChain(arr){
      const idx = arr[0], i = arr[1];
      const item = this.support.chains[idx];
      this.$set(this.supportTaskCoins, 'coins_' + i, item.relatedCoins.filter(r => r.supportAcvitity));
      this.form.tasks.has_trade[i].chain = item.chainName;
    },
    onChangeTradeTaskCoin(arr){
      const idx = arr[0], i = arr[1];
      const item = this.supportTaskCoins['coins_' + i][idx];
      this.$set(this.form.tasks.has_trade[i], 'name', item.coinName);
      this.$set(this.form.tasks.has_trade[i], 'contract', item.contractAddr ? item.contractAddr : '0x0000000000000000000000000000000000000000');
      this.$forceUpdate();
    },
    onChangeTradePath(v, i, p) {
      let pair;
      if(this.hasTradePath[i + 'A'] && p === 'B'){
        pair = this.hasTradePath[i + 'A'] + '/' +  v;
      } else if(this.hasTradePath[i + 'B'] && p === 'A') {
        pair = v + '/' +  this.hasTradePath[i + 'B'];
      }

      pair && this.$set(this.form.tasks.has_trade[i], 'pair', pair);
      this.$forceUpdate();
    },
    /**
     * 奖励资产
     * @param idx
     */
    onChangeChain(idx){
      const item = this.support.chains[idx];
      this.$set(this.support, 'coins', item.relatedCoins.filter(r => r.supportAcvitity));
      this.form.rewards.token_chain = item.chainName;
    },
    onChangeCoin(idx){
      const item = this.support.coins[idx];
      this.form.rewards.token_name = item.coinName;
      this.form.rewards.token_address = item.contractAddr;
      this.form.rewards.token_decimals = item.contractDecimal;
    },
    /**
     * 加载草稿
     * @param id
     * @returns {Promise<void>}
     */
    async loadDraft(id){
      const res = await EventsApi.getDraftInfo({ draftId: id });
      if(res && res.data && res.data.detail){
        const detail = JSON.parse(res.data.detail);
        this.form.draft_id = id;

        const tasks = Object.assign({}, this.form.tasks, detail.tasks);
        this.form = Object.assign({}, this.form, detail);
        this.form.tasks = tasks;

        this.tasksNeed.twitter_reg_long = !!tasks.twitter_reg_long;
        this.tasksNeed.like_twitter = tasks.like_twitter.length;
        this.tasksNeed.tweet_active_id = tasks.tweet_active_id.length;
        this.tasksNeed.twitter_active_account = tasks.twitter_active_account.length;
        this.tasksNeed.auto_tweet = tasks.auto_tweet;
        this.tasksNeed.has_token = tasks.has_token.length;
        this.tasksNeed.has_trade = tasks.has_trade?.length || 0;
        this.tasksNeed.has_custom = tasks.has_custom?.length || 0;
        this.tasksNeed.other = tasks.other && Object.keys(tasks.other).length > 0;

        if(this.tasksNeed.has_custom){
          for(let i = 0; i < this.tasksNeed.has_custom; i++){
            this.customTaskLang[i] = 'en_US';
          }
        }

        if(this.form.auto_tweet && !this.form.tasks.auto_tweet){
          this.form.tasks.auto_tweet = {
            tweet_content: null,
            tweet_pics: []
          };
        }
      }
    },
    /**
     * 加载活动详情
     * @param id
     * @returns {Promise<void>}
     */
    async loadEventInfo(id){
      const res = await EventsApi.getInfo({ id, edit: 1 });
      if(res && res.data){
        const detail = res.data;
        detail.title = this.$func.toJson(detail.title);
        detail.content = this.$func.toJson(detail.content);
        detail.cover = detail.cover && detail.cover !== 'null' ? JSON.parse(detail.cover) : null;
        detail.timeRange = [
          this.$func.getDate(detail.start_time),
          this.$func.getDate(detail.end_time)
        ];
        for(let k in detail){
          if(this.form[k] === undefined){
            delete detail[k];
          }
        }

        delete detail.tweets;

        const tasks = Object.assign({}, this.form.tasks, detail.tasks);
        this.form = Object.assign({}, this.form, detail);
        this.form.tasks = tasks;

        this.tasksNeed.twitter_reg_long = !!tasks.twitter_reg_long;
        this.tasksNeed.like_twitter = tasks.like_twitter.length;
        this.tasksNeed.tweet_active_id = tasks.tweet_active_id.length;
        this.tasksNeed.twitter_active_account = tasks.twitter_active_account.length;
        this.tasksNeed.auto_tweet = tasks.auto_tweet;
        this.tasksNeed.has_token = tasks.has_token.length;
        this.tasksNeed.has_trade = tasks.has_trade?.length || 0;
        this.tasksNeed.has_custom = tasks.has_custom?.length || 0;
        this.tasksNeed.other = tasks.other && Object.keys(tasks.other).length > 0 ? true : false;

        if(this.form.auto_tweet && !this.form.tasks.auto_tweet){
          this.form.tasks.auto_tweet = {
            tweet_content: null,
            tweet_pics: []
          };
        }
      }
    },

    /**
     * 切换封面图
     * @param
     */
    onSelectCover(idx){
      this.$refs['coverSelector' + idx][0].click();
    },
    onChangeCover(e){
      const file = e.target.files[0]; // 获取文件引用
      if (!file) return;
      if(file.size/1024 - 10*1024 > 0){
        this.$message.error(this.$t('publish.errorFileSize'));
        return;
      }
      const self = this;
      self.isUploading = true;
      window.readFile(file, (base64, size) => {
        if(size.width !== 168 || size.height !== 168){
          this.$message.error(this.$t('publish.errorPicSize'));
          self.isUploading = false;
          return;
        }
        this.$set(this.form.cover, this.form.coverLang, base64);
        self.syncUploadCover(base64).then(cover => {
          if(cover && cover.indexOf('http') === 0){
            self.$set(this.form.cover, this.form.coverLang, cover);
            self.isUploading = false;
          }
        });
      });
    },
    /**
     * 异步上传图片
     * @param cover
     * @returns {Promise<null|*|null>}
     */
    async syncUploadCover(cover){
      if(!cover){
        return;
      }
      const res = await EventsApi.uploadImage({ cover });
      if(res && res.data && res.data.cover){
        return res.data.cover;
      }
      return null;
    },
    /**
     * 任务编号
     * @param v
     * @returns {number}
     */
    addTaskIdx(v){
      if(v !== undefined){
        updater.counter(v);
      } else {
        updater.counter(taskIdx + 1);
      }

      return taskIdx;
    },
    /**
     * 是否被选中任意一项任务
     */
    isHasTask(){
      let flag = false;
      for(let i in this.tasksNeed){
        if(this.tasksNeed[i]){
          flag = true;
          break;
        }
      }
      return flag;
    },
    /**
     * 是否有x任务
     */
    isHasXTask(){
      let flag = false;
      for(let i in this.tasksNeed){
        if(i.indexOf('twitter') > -1 && this.tasksNeed[i]){
          flag = true;
          break;
        }
      }
      return flag;
    },
    /**
     * 检测X账号是否已授权
     */
    checkXAuth(){
      const run = async () => {
        while(true){
          const res = await TwitterApi.getUserInfo(this.userInfo.x_id);
          if(!res || !res.data || res.data.is_expire){
            this.authXUrl = res.data.auth_url;
            this.$set(this.userInfo, 'x_expire', true);
          } else {
            this.$set(this.userInfo, 'x_expire', false);
            break;
          }
          await this.$func.sleep(3000)
        }
      }
      run();
    },
    /**
     * 下一步
     * @param stepName
     * @param idx
     */
    nextStep(stepName, idx){
      this.formError = {};
      let flag = true;
      if(stepName === 'task'){
        flag = this.checkStep1();
      } else if(stepName === 'reward'){
        flag = this.checkStep2();
      } else if(stepName === 'publish'){
        flag = this.checkStep3();
        // 获取充值地址
        EventsApi.getRechargeAddress({ chain: this.form.rewards.token_chain }).then(res => {
          if(res && res.data){
            this.rechargeInfo = res.data;
            this.generateQRCode(this.rechargeInfo.address);
          }
        });
      }
      flag && (this.showSection = idx);
    },
    /**
     * 生成二维码
     * @param txt
     */
    generateQRCode(txt) {
      const canvas = this.$refs.qrcodeCanvas;
      QRCode.toCanvas(canvas, txt, error => {
        if (error) console.error(error)
      });
    },
    onCustomContentInput(i){
      this.$forceUpdate()
    },
    /**
     * 校验表单
     * @returns {boolean}
     */
    checkStep1(){
      // filter
      this.langs.map(r => {
        const lang = r.split(':')[0]
        if(!this.form.title[lang]) delete this.form.title[lang];
        if(!this.form.content[lang]) delete this.form.content[lang];
        if(!this.form.cover[lang]) delete this.form.cover[lang];
      });

      let flag = true;
      if(!this.form.title || !this.form.title[this.form.titleLang]){
        this.$message.error(this.$t('publish.errorTitle'));
        flag = false;
        return flag;
      }
      if(!this.form.timeRange || !this.form.timeRange[0] || !this.form.timeRange[1]){
        this.$message.error(this.$t('publish.errorTimeRange'));
        flag = false;
        return flag;
      }
      this.form.start_time = parseInt(new Date(this.form.timeRange[0]).getTime()/1000);
      this.form.end_time = parseInt(new Date(this.form.timeRange[1]).getTime()/1000);
      if(this.form.end_time - this.form.start_time <= 0){
        this.$message.error(this.$t('publish.errorEndTime'));
        flag = false;
        return flag;
      }
      if(!this.form.cover || !this.form.cover[this.form.coverLang]){
        flag = false;
        this.$message.error(this.$t('publish.errorCover'));
        return flag;
      }
      if(!this.checkPicUploaded()){
        this.$message.error(this.$t('publish.coverUploading'));
        flag = false;
        return flag;
      }
      if(!this.form.content || !this.form.content[this.form.contentLang]){
        this.$message.error(this.$t('publish.errorContent'));
        flag = false;
        return flag;
      }
      if(!this.form.tags){
        this.$message.error(this.$t('publish.errorTags'));
        flag = false;
        return flag;
      }
      // mul langs check
      const langs = {
        title: Object.keys(this.form.title),
        content: Object.keys(this.form.content),
        cover: Object.keys(this.form.cover)
      };
      for(let k in langs){
        if(langs[k].indexOf('def') > -1){
          langs[k].splice(langs[k].indexOf('def'), 1);
        }
      }
      let vc = 0, checkBasicField = null;
      for(let field in langs){
        if(langs[field].length > vc){
          vc = langs[field].length;
          checkBasicField = field;
        }
      }
      let langFlag = true;
      langs[checkBasicField].map(l => {
        for(let field in langs){
          if(field === checkBasicField) continue;
          if(langs[field].indexOf(l) === -1) {
            langFlag = false;
          }
        }
      });
      if(!langFlag){
        this.$message.error(this.$t('publish.errorLangs'));
        flag = false;
        return flag;
      }

      return flag;
    },
    checkStep2(){
      const langs = {
        title: Object.keys(this.form.title),
        content: Object.keys(this.form.content),
        cover: Object.keys(this.form.cover)
      };
      for(let k in langs){
        if(langs[k].indexOf('def') > -1){
          langs[k].splice(langs[k].indexOf('def'), 1);
        }
      }

      let flag = true;

      const taskNum = this.tasksNeed.like_twitter + this.tasksNeed.twitter_active_account
        + this.tasksNeed.tweet_active_id + this.tasksNeed.has_token + this.tasksNeed.has_trade + this.tasksNeed.has_custom;
      if(
          taskNum === 0
          // && !this.tasksNeed.twitter_reg_long
          // && !this.tasksNeed.auto_tweet
          && !this.tasksNeed.other
      ){
        flag = false;
        this.$message.error(this.$t('publish.minTasksCount'));
        return flag;
      }

      if(
          this.tasksNeed.twitter_reg_long
          && (!this.form.tasks.twitter_reg_long || this.form.tasks.twitter_reg_long - 1 < 0)
      ){
        this.$message.error(this.$t('publish.errorXLong'));
        flag = false;
        this.formError.twitter_reg_long = false;
        this.$forceUpdate();
        if(!flag) return flag;
      }
      if(this.tasksNeed.like_twitter){
        const _likes = [];
        for(let i in this.form.tasks.like_twitter){
          if(!this.form.tasks.like_twitter[i]){
            this.$message.error(this.$t('publish.errorTasksNeed'));
            flag = false;
            !this.formError.like_twitter && (this.formError.like_twitter = []);
            this.$set(this.formError.like_twitter, i, false);
            this.$forceUpdate();
            break;
          }
          if(_likes.indexOf(this.form.tasks.like_twitter[i]) > -1){
            this.$message.error(this.$t('publish.repeatTasks'));
            flag = false;
            !this.formError.like_twitter && (this.formError.like_twitter = []);
            this.$set(this.formError.like_twitter, i, false);
            this.$forceUpdate();
            break;
          }
          _likes.push(this.form.tasks.like_twitter[i]);
        }
        if(!flag) return flag;
      }
      if(this.tasksNeed.twitter_active_account){
        const _names = [];
        for(let i in this.form.tasks.twitter_active_account){
          const row = this.form.tasks.twitter_active_account[i];
          if(!row.name){
            this.$message.error(this.$t('publish.errorTasksNeed'));
            flag = false;
            !this.formError.twitter_active_account && (this.formError.twitter_active_account = []);
            this.formError.twitter_active_account[i] = false;
            this.$forceUpdate();
            break;
          }
          if(_names.indexOf(row.name) > -1){
            this.$message.error(this.$t('publish.repeatTasks'));
            flag = false;
            !this.formError.twitter_active_account && (this.formError.twitter_active_account = []);
            this.formError.twitter_active_account[i] = false;
            this.$forceUpdate();
            break;
          }
          _names.push(row.name);
          if(!row.like && !row.retweet3){
            this.$message.error('推文互动的类型至少选择一项');
            flag = false;
            !this.formError.twitter_active_account && (this.formError.twitter_active_account = []);
            this.formError.twitter_active_account[i] = false;
            this.$forceUpdate();
            break;
          }
        }
        if(!flag) return flag;
      }
      if(this.tasksNeed.tweet_active_id){
        const _tweets = [];
        for(let i in this.form.tasks.tweet_active_id){
          const row = this.form.tasks.tweet_active_id[i];
          if(!row.tweet_id){
            this.$message.error(this.$t('publish.errorTasksNeed'));
            flag = false;
            !this.formError.tweet_active_id && (this.formError.tweet_active_id = []);
            this.formError.tweet_active_id[i] = false;
            this.$forceUpdate();
            break;
          }

          let tid = row.tweet_id.split('/');
          tid = tid[tid.length - 1];
          if(!/^\d+$/.test(tid) || (row.tweet_id.indexOf('x.com/') === -1 && row.tweet_id.indexOf('twitter.com/') === -1)){
            this.$message.error('推文Url填写不正确');
            flag = false;
            !this.formError.tweet_active_id && (this.formError.tweet_active_id = []);
            this.formError.tweet_active_id[i] = false;
            this.$forceUpdate();
            break;
          }

          if(_tweets.indexOf(tid) > -1){
            this.$message.error(this.$t('publish.repeatTasks'));
            flag = false;
            !this.formError.tweet_active_id && (this.formError.tweet_active_id = []);
            this.formError.tweet_active_id[i] = false;
            this.$forceUpdate();
            break;
          }
          _tweets.push(tid);

          if(!row.like && !row.retweet3){
            this.$message.error('推文互动的类型至少选择一项');
            flag = false;
            !this.formError.tweet_active_id && (this.formError.tweet_active_id = []);
            this.formError.tweet_active_id[i] = false;
            this.$forceUpdate();
            break;
          }
        }
        if(!flag) return flag;
      }
      /*if(this.tasksNeed.auto_tweet){
        if(!this.userInfo.x_account || this.userInfo.x_expire){
          this.$message.error('代发推文需要授权推特');
          flag = false;
          this.formError.auto_tweet = false;
          this.$forceUpdate();
          return flag;
        }
        if(!this.form.tasks.tweet_content){
          this.$message.error(this.$t('publish.errorTasksNeed'));
          flag = false;
          this.formError.auto_tweet = false;
          this.$forceUpdate();
          return flag;
        }
        if(this.form.tasks.tweet_content.length > 200){
          this.$message.error(this.$t('publish.errorTasksNeed'));
          flag = false;
          this.formError.auto_tweet = false;
          this.$forceUpdate();
          return flag;
        }
      }*/
      if(this.tasksNeed.has_token){
        for(let i in this.form.tasks.has_token){
          if(
              !this.form.tasks.has_token[i].chain
              || !this.form.tasks.has_token[i].name
              || !this.form.tasks.has_token[i].contract
              || !this.form.tasks.has_token[i].amount
          ){
            this.$message.error(this.$t('publish.errorTasksNeed'));
            flag = false;
            !this.formError.has_token && (this.formError.has_token = []);
            this.formError.has_token[i] = false;
            this.$forceUpdate();
            break;
          }
        }
        if(!flag) return flag;
      }
      if(this.tasksNeed.has_nft){
        for(let i in this.form.tasks.has_nft){
          if(
              !this.form.tasks.has_nft[i].chain
              || !this.form.tasks.has_nft[i].name
              || !this.form.tasks.has_nft[i].contract
              || !this.form.tasks.has_nft[i].amount
          ){
            this.$message.error('请完善任务要求');
            flag = false;
            !this.formError.has_nft && (this.formError.has_nft = []);
            this.formError.has_nft[i] = false;
            this.$forceUpdate();
            break;
          }
        }
        if(!flag) return flag;
      }
      if(this.tasksNeed.has_trade){
        for(let i in this.form.tasks.has_trade){
          if(
              !this.form.tasks.has_trade[i].chain
              || !this.form.tasks.has_trade[i].name
              || !this.form.tasks.has_trade[i].contract
              || !this.form.tasks.has_trade[i].pair
              || !this.form.tasks.has_trade[i].amount
              || !this.form.tasks.has_trade[i].number
          ){
            this.$message.error(this.$t('publish.errorTasksNeed'));
            flag = false;
            !this.formError.has_trade && (this.formError.has_trade = []);
            this.formError.has_trade[i] = false;
            this.$forceUpdate();
            break;
          }
        }
        if(!flag) return flag;
      }

      if(this.tasksNeed.has_custom) {
        for(let i in this.form.tasks.has_custom){
          const taskRow = this.form.tasks.has_custom[i];
          // filter
          this.langs.map(r => {
            const lang = r.split(':')[0]
            if(!taskRow.brief[lang]) {
              delete taskRow.brief[lang];
              delete this.form.tasks.has_custom[i].brief[lang];
            }
          });
          langs['has_custom_brief_' + i] = Object.keys(taskRow.brief);
          if(!taskRow.brief || !taskRow.link || !taskRow.checkLink){
            this.$message.error(this.$t('publish.errorTasksNeed'));
            flag = false;
            !this.formError.has_custom && (this.formError.has_custom = []);
            this.formError.has_custom[i] = false;
            this.$forceUpdate();
            break;
          }
        }
        if(!flag) return flag;
      }

      // filter
      this.langs.map(r => {
        const lang = r.split(':')[0]
        if(this.form.tasks.other && !this.form.tasks.other[lang]) delete this.form.tasks.other[lang];
      });
      if(this.tasksNeed.other) {
        langs.other = Object.keys(this.form.tasks.other)
        if(Object.keys(this.form.tasks.other).length === 0){
          flag = false;
        }
        console.log(this.form.tasks.other)
        for(let l in this.form.tasks.other){
          if(!this.form.tasks.other[l]){
            flag = false;
          }
        }
        if(!flag){
          this.$message.error(this.$t('publish.errorTasksNeed'));
          this.formError.other = false;
          this.$forceUpdate();
        }
        if(!flag) return flag;
      }

      // mul langs check
      let vc = 0, checkBasicField = null;
      for(let field in langs){
        if(langs[field].length > vc){
          vc = langs[field].length;
          checkBasicField = field;
        }
      }
      console.log(langs)
      let langFlag = true;
      langs[checkBasicField].map(l => {
        for(let field in langs){
          if(field === checkBasicField) continue;
          if(langs[field].indexOf(l) === -1) {
            langFlag = false;
            console.log(field)
            console.log(l)
          }
        }
      });
      if(!langFlag){
        this.$message.error(this.$t('publish.errorLangs'));
        flag = false;
        return flag;
      }

      return flag;
    },
    checkStep3(){
      let flag = true;
      if(!this.form.rewards.token_chain || !this.form.rewards.token_name || !this.form.rewards.token_amount){
        this.$message.error(this.$t('publish.errorRewardToken'));
        flag = false;
        return flag;
      }
      if(!this.form.rewards.reward_type){
        this.$message.error(this.$t('publish.errorRewardType'));
        flag = false;
        return flag;
      }
      if(this.form.rewards.reward_type != 3){
        if(!this.form.rewards.reward_users_count){
          this.$message.error('请填写奖励份额');
          flag = false;
          return flag;
        }
      }
      if(this.form.auto_tweet && (!this.form.tasks.auto_tweet || !this.form.tasks.auto_tweet.tweet_content)){
        this.$message.error(this.$t('publish.errorAutoTweet'));
        flag = false;
        return flag;
      }
      return flag;
    },
    checkStep4(){
      let flag = true;
      if(!this.form.txid){
        this.$message.error('请填写付款交易txid');
        flag = false;
        return flag;
      }
      return flag;
    },
    checkPicUploaded(){
      if(this.form.cover){
        let flag = true;
        console.log(this.form.cover)
        for(let l in this.form.cover){
          if(this.form.cover[l].indexOf('data:image') === 0){
            console.log(l)
            flag = false;
            break;
          }
        }
        if(!flag){
          return false;
        }
      }
      return true;
    },
    /**
     * 保存成功
     * @returns {Promise<void>}
     */
    async saveDraft(){
      let flag = this.checkStep1();
      if(!flag){
        return;
      }
      if(!this.checkPicUploaded()){
        this.$message.error(this.$t('publish.isUploading'));
        return;
      }
      this.loading = 'Saving...';
      const res = await EventsApi.saveDraft(this.form);
      if(res && res.data && res.data.draft_id){
        this.$message.success(this.$t('common.saveSuccessful'));
        this.form.draft_id = res.data.draft_id;
      } else {
        this.$message.error(this.$t('common.saveFailed'));
      }
      this.loading = null;
    },
    /**
     * 发布活动
     * @returns {Promise<void>}
     */
    async publishEvent(){
      let flag = this.checkStep1();
      if(!flag) { this.showSection = 1;return; }
      flag = this.checkStep2();
      if(!flag) { this.showSection = 2;return; }
      flag = this.checkStep3();
      if(!flag) { this.showSection = 3;return; }
      flag = this.checkStep4();
      if(!flag){
        return;
      }
      if(!this.checkPicUploaded()){
        this.$message.error(this.$t('publish.isUploading'));
        return;
      }
      this.loading = this.$t('publish.isPublishing');

      // 无用参数
      delete this.form.tweets;
      this.form.id = this.eventId;
      // 充值地址
      this.form.rechargeKey = this.rechargeInfo.rechargeKey;
      const res = await EventsApi.publishEvent(this.form);
      if(res && res.data && res.data.id){
        this.$message.success(this.$t('publish.publishSuccessful'));
        await this.$func.sleep(2000);
        location.replace(location.href.split('/#/')[0] + '/#/event-manage');
        localStorage.removeItem('CACHE_EVENT_FORM');
      } else {
        this.$message.error(res && res.data && res.data.status === 0 ? res.data.msg : this.$t('publish.publishFailed'));
      }
      this.loading = null;
    },

    /**
     * 代发推文
     */
    onChangeAutoTweet(v) {
      if(this.form.auto_tweet){
        this.form.auto_tweet = 1;
        if(!this.form.tasks.auto_tweet){
          this.form.tasks.auto_tweet = {
            tweet_content: null,
            tweet_pics: []
          };
        }
      } else {
        this.form.auto_tweet = 0;
        this.form.tasks.auto_tweet = {};
      }
    },
    /**
     * 多图上传
     */
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.previewDialogImageUrl = file.url;
      this.previewDialogVisible = true;
    },
    onUploadPic(file) {
      const reader = new FileReader();
      reader.addEventListener('load', async ()=> {
        const res = await EventsApi.uploadImage({ cover: reader.result });
        if(!this.form.tasks.auto_tweet.tweet_pics){
          this.form.tasks.auto_tweet.tweet_pics = [];
        }
        if(res && res.data && res.data.cover){
          this.form.tasks.auto_tweet.tweet_pics.push(res.data.cover);
          this.$forceUpdate();
        }
      });
      reader.readAsDataURL(file.raw);
    },
    handleUploadSuccess(res, file, fileList){
      console.log(file, fileList);
      console.log(fileList)
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.container {
  position: relative;
}
.top-menus {
  height: 80px;
  padding-top: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: calc(100vw - @left-menu-width - 6px);
  left: calc(@left-menu-width + (100vw - @left-menu-width)/2);
  position: fixed;
  top: 50px;
  z-index: 10;
  margin-left: -300px;

  .h-line {
    flex: 1;
    height: 1px;
    border-top: 1px dashed #ccc;
    display: none;
  }
  .menu-item {
    font-size: 15px;
    cursor: pointer;
    color: #aaa;
    position: relative;
    min-width: 150px;
    height: 35px;
    background-color: #fff;
    border: 1px solid @border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    box-sizing: border-box;

    &.on {
      color: @light-color;
      border-bottom: 2px solid @light-color;
      span {
        background-color: @light-color;
        color: #fff;
      }
    }
    i {
      background-color: @green;
      border-radius: 50%;
      color: #fff;
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
    span {
      background-color: #eee;
      color: #aaa;
      border-radius: 50%;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      font-size: 13px;
    }
    b {
      &.green {
        color: @green;
      }
    }
  }
}

.lang-tags {
  display: flex;
  align-items: center;
  margin: 20px 10px 0 10px;

  .lang-title {
    font-size: 13px;
    font-weight: bold;
  }
  .lang-item {
    background-color: #ddd;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    margin: 0 10px;
    cursor: pointer;
    &.on {
      background-color: @light-color;
      color: #fff;
    }
  }
}

.max-500 {
  max-width: 522px;
  margin: 0 auto;
}
.form-section {
  width: 100%;
  margin: 0 auto;

  &.w-420px {
    width: 420px;
  }
  &.w-600px {
    width: 600px;
  }
  .middle-panel {
    margin-right: 20px;
    border-right: 1px solid #ddd;
    padding-right: 20px;
    height: calc(100vh - 50px - 40px - 60px);
  }
  .right-panel {
    width: 380px;

    .top-tabs {
      display: flex;
      align-items: center;
      margin: 0 -10px;

      span {
        flex: 1;
        text-align: center;
        border: 1px solid #aaa;
        border-radius: 5px;
        margin: 0 10px;
        padding: 8px 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &.on {
          color: #fff;
          border: 1px solid @light-color;
          background-color: @light-color;
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
    }
    .task-options {
      margin-top: 16px;
      .option-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        border:1px solid #aaa;
        padding: 8px 10px;
        border-radius: 5px;
        cursor: pointer;
        span {
          flex: 1;
        }
      }
    }
  }
  .preview-panel {
    margin-top: 80px;
    width: 356px;
    height: 720px;
    background-image: url("../assets/imgs/pc/publish/11.png");
    background-size: 100% 100%;
    position: relative;
    .box-con {
      margin: 55px 15px 10px 15px;
      border-radius: 0 0 45px 45px;
      overflow: hidden;
    }
    .border {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.form-con {
  margin-top: calc(40px + 20px);
  padding: 0;
  font-size: 13px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .basic-info {
    width: 100%;
    height: calc(100vh - 50px - 40px - 60px);
    overflow-x: hidden;
  }
  label {
    padding-right: 10px;
  }
  .row-item {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 10px;
    label {
      margin-bottom: 10px;
      font-size:13px;
      font-weight: bold;
    }
  }
  .row-input {
    flex: 1;
    input {
      height: 26px;
      border-radius: 5px;
      width: 80%;
    }
  }
  .row-radio {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px 10px;
    label {
      margin: 0;
    }
  }
  .cover-selector {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 20px;
    color: #aaa;
    cursor: pointer;
    position: relative;
    i {
      font-size: 30px;
    }
    .remove-pic {
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .3);
      border-radius: 0 0 0 5px;
      width: 20px;
      height: 20px;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 14px;
        color: #ff0000;
      }
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .uploading {
      position: absolute;
      right: 10px;
      bottom: 5px;
      z-index: 2;
      font-size: 12px;
      color: @red;
      text-shadow: 0 0 3px #fff;
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, .8);
      border-radius: 3px;
      padding: 0 5px;
      font-weight: bold;
      i {
        font-size: 13px;
        margin-right: 5px;
      }
    }
  }
  .remark {
    margin-top: 10px;
  }
  .btn {
    margin-top: 10px;
  }
  .task-section-title {
    display: flex;
    font-size: 15px;
    font-weight: bold;
    margin: 20px auto 0 auto;
    max-width: 522px;
    img {
      height: 20px;
      margin-right: 10px;
    }
  }
  .task-item {
    margin: 10px auto;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    max-width: 500px;

    &.error {
      border-color: @red;
    }
    input[type=text]{
      color: @light-color;
      border: none;
      border-bottom: 1px solid @light-color;
      width: auto;
      text-align: center;
    }
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .address-qrcode {
    width: 80px;
    height: 80px;
    background-color: #eee;
    border-radius: 8px;

    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.btn {
  background-color: #409EFF;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.input-item {
  display: flex;
  align-items: center;
  label {
    font-size: 13px;
    padding-right: 10px;
    min-width: 56px;
  }
  input, select {
    border: 1px solid @border-color;
    height: 30px;
    border-radius: 5px;
    padding: 0 15px;
    box-sizing: border-box;
    flex: 1;
  }
}
input {
  outline: none;
}

.auto-tweet-area {
  position: relative;
  .el-textarea {
    z-index: 2;
    /deep/.el-textarea__inner {
      background-color: transparent;
    }
    &.hide-border {
      /deep/.el-textarea__inner {
        background-color: transparent;
        border: none;
      }
    }
  }
  .auto-tweet-tips {
    position: absolute;
    top: 80px;
    left: 10px;
    right: 10px;
    height: 116px;
    z-index: 1;
    text-align: center;
    line-height: 30px;
    color: #ccc;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.pics-uploader {
  display: flex;
  .upload-image-btn {
    border: 1px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #ccc;
    font-size: 18px;
  }
  .preview-item {
    width: 80px;
    height: 80px;
    position: relative;
    margin-right: 10px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .remove-btn {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 13px;
      color: @red;
      background-color: rgba(0, 0, 0, .6);
      padding: 3px;
      border-radius: 0 0 0 5px;
    }
    /deep/.el-upload--picture-card {
      width: 80px;
      height: 80px;
      line-height: unset;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
