import request from '@/utils/request.js';

const prefix = 'https://eapi.parapack.net/';

export async function getTest(params) {
    const url = prefix + 'test/db';

    return request.get({ url });
}

/**
 * 登录初始化信息
 * @returns {Promise<unknown>}
 */
export async function getLogin(params = {}) {
    const url = prefix + 'twitter/login';

    return request.get({ url, params });
}

/**
 * 登录初始化信息
 * @returns {Promise<unknown>}
 */
export async function getBind() {
    const url = prefix + 'twitter/bind';

    return request.get({ url });
}

/**
 * 登录初始化信息
 * @returns {Promise<unknown>}
 */
export async function getUserInfo(twitterUid) {
    const url = prefix + 'twitter/user-info?uid=' + twitterUid;

    return request.get({ url });
}

export async function syncCurrentUser(params){
    return request.get({ action: 'SyncCurrentUser', url: 'SyncCurrentUser', params, target: 'Events' });
}

export async function syncTweet(id){
    const url = 'https://twitter.com/i/api/graphql/zJvfJs3gSbrVhC0MKjt_OQ/TweetDetail?variables=%7B%22focalTweetId%22%3A%22' + id + '%22%2C%22with_rux_injections%22%3Afalse%2C%22includePromotedContent%22%3Atrue%2C%22withCommunity%22%3Atrue%2C%22withQuickPromoteEligibilityTweetFields%22%3Atrue%2C%22withBirdwatchNotes%22%3Atrue%2C%22withVoice%22%3Atrue%2C%22withV2Timeline%22%3Atrue%7D&features=%7B%22rweb_tipjar_consumption_enabled%22%3Atrue%2C%22responsive_web_graphql_exclude_directive_enabled%22%3Atrue%2C%22verified_phone_label_enabled%22%3Afalse%2C%22creator_subscriptions_tweet_preview_api_enabled%22%3Atrue%2C%22responsive_web_graphql_timeline_navigation_enabled%22%3Atrue%2C%22responsive_web_graphql_skip_user_profile_image_extensions_enabled%22%3Afalse%2C%22communities_web_enable_tweet_community_results_fetch%22%3Atrue%2C%22c9s_tweet_anatomy_moderator_badge_enabled%22%3Atrue%2C%22articles_preview_enabled%22%3Atrue%2C%22tweetypie_unmention_optimization_enabled%22%3Atrue%2C%22responsive_web_edit_tweet_api_enabled%22%3Atrue%2C%22graphql_is_translatable_rweb_tweet_is_translatable_enabled%22%3Atrue%2C%22view_counts_everywhere_api_enabled%22%3Atrue%2C%22longform_notetweets_consumption_enabled%22%3Atrue%2C%22responsive_web_twitter_article_tweet_consumption_enabled%22%3Atrue%2C%22tweet_awards_web_tipping_enabled%22%3Afalse%2C%22creator_subscriptions_quote_tweet_preview_enabled%22%3Afalse%2C%22freedom_of_speech_not_reach_fetch_enabled%22%3Atrue%2C%22standardized_nudges_misinfo%22%3Atrue%2C%22tweet_with_visibility_results_prefer_gql_limited_actions_policy_enabled%22%3Atrue%2C%22tweet_with_visibility_results_prefer_gql_media_interstitial_enabled%22%3Atrue%2C%22rweb_video_timestamps_enabled%22%3Atrue%2C%22longform_notetweets_rich_text_read_enabled%22%3Atrue%2C%22longform_notetweets_inline_media_enabled%22%3Atrue%2C%22responsive_web_enhance_cards_enabled%22%3Afalse%7D&fieldToggles=%7B%22withArticleRichContentState%22%3Atrue%2C%22withArticlePlainText%22%3Afalse%7D';
}

export async function openExt(){
    return request.get({ action: 'OpenExt', url: 'OpenExt', target: 'Events' });
}