<template>
  <div class="container" v-if="info">

    <div class="page-title">{{$t('events.eventData')}}</div>

    <div class="mgt-20"></div>
    <div class="top-con">
      <div class="flex-1">
        <div class="basic-info">
          <div class="section-title">{{$t('events.basicData')}}</div>

          <div class="progress-row flex">
            <label>{{$t('events.finished')}}: {{info.finished_count}}</label>
            <div class="progress-bg flex-1">
              <div class="progress" :style="{width: (info.finished_count ? info.finished_count/info.view_count : 0) + '%'}"></div>
            </div>
            <div class="value">{{info.finished_count ? parseFloat(info.finished_count/info.view_count).toFixed(2) : 0}}%</div>
          </div>
          <div class="progress-row flex">
            <label>{{$t('events.join')}}: {{info.join_count}}</label>
            <div class="progress-bg flex-1">
              <div class="progress" :style="{width: (info.join_count ? info.join_count/info.view_count : info.join_count) + '%'}"></div>
            </div>
            <div class="value">{{info.join_count ? parseFloat(info.join_count/info.view_count).toFixed(2) : 0}}%</div>
          </div>
          <div class="progress-row flex">
            <label>{{$t('events.view')}}: {{info.view_count}}</label>
            <div class="progress-bg flex-1">
              <div class="progress"></div>
            </div>
          </div>
        </div>

        <div class="basic-info tweet-stat">
          <div class="progress-row flex">
            <label>{{$t('events.share')}}: {{info.share_count}}</label>
            <div class="progress-bg flex-1">
              <div class="progress green" :style="{width: (info.share_count ? 100 : 0) + '%'}"></div>
            </div>
          </div>
          <div class="progress-row flex">
            <label>{{$t('events.like')}}: {{info.like_count}}</label>
            <div class="progress-bg flex-1">
              <div class="progress yellow" :style="{width: (info.like_count ? 100 : 0) + '%'}"></div>
            </div>
          </div>
        </div>

        <div class="basic-info" v-if="info.tasks">
          <div class="section-title">{{$t('events.taskData')}}</div>

          <div class="row-data" v-if="info.tasks.twitter_reg_long">
            <label>{{$t('task.regLongPre')}} {{info.tasks.twitter_reg_long}} {{$t('task.perMonth')}}</label>
            <div class="value">0</div>
          </div>
          <div class="row-data" v-for="(row, idx) in info.tasks.like_twitter" v-if="info.tasks.like_twitter">
            <label>{{$t('task.followX')}} <a :href="'https://x.com/' + row" target="_blank" class="text-blue">{{row}}</a></label>
            <div class="value">0</div>
          </div>
          <div v-for="(row, i) in info.tasks.twitter_active_account">
            <div class="row-data" v-if="row.retweet3">
              <label>{{$t('task.retweet')}} <a :href="'https://x.com/' + row.name" target="_blank" class="text-blue">{{row.name}}</a> {{$t('task.retweetApfix')}}</label>
              <div class="value">0</div>
            </div>
            <div class="row-data" v-if="row.like">
              <label>{{$t('task.like')}} <a :href="'https://x.com/' + row.name" target="_blank" class="text-blue">{{row.name}}</a> {{$t('task.lastTweet')}}</label>
              <div class="value">0</div>
            </div>
          </div>

          <div v-for="(row, i) in info.tasks.tweet_active_id">
            <div class="row-data" v-if="row.retweet3">
              <label>{{$t('task.retweet')}} <a :href="row.tweet_url" target="_blank" class="text-blue">
              {{row.tweet_id.split('/').pop()}}
              </a> {{$t('task.retweetApfix')}}</label>
              <div class="value">0</div>
            </div>
            <div class="row-data" v-if="row.like">
              <label>{{$t('task.like')}} <a :href="row.tweet_url" target="_blank" class="text-blue">
              {{row.tweet_id.split('/').pop()}}
              </a> {{$t('task.tweet')}}</label>
              <div class="value">0</div>
            </div>
          </div>

        </div>
      </div>

      <div class="preview-panel">
        <div class="box-con">
          <mobile-preview :support="support" v-if="support"></mobile-preview>
        </div>
      </div>
    </div>

    <div class="mgt-20"></div>
    <div class="statistic-con" v-if="info.tasks.other">
      <div class="section-title">{{$t('task.textCollect')}}</div>
      <div class="mgt-10"></div>
      <div class="list-row thead">
        <div class="uid">{{$t('task.userID')}}</div>
        <div class="nickname">{{$t('task.userNickname')}}</div>
        <div class="text">{{$t('task.textInfo')}}</div>
      </div>
      <div class="list-row" v-for="(row, idx) in records" :key="idx">
        <div class="uid">{{row.uid}}</div>
        <div class="nickname">{{row.user.nickname || row.user.email}}</div>
        <div class="text">{{row.other && row.other[0] ? row.other[0]['other'] : '-'}}</div>
      </div>
    </div>
    <div style="height: 20px;"></div>

  </div>
</template>

<script>
import * as EventsApi from "@/api/events";
import MobilePreview from "./publish/MobilePreview";
import {getRecord} from "@/api/events";

export default {
  components: { MobilePreview },
  data(){
    return {
      support: null,
      info: null,
      records: []
    }
  },
  mounted() {
    this.loadSupport();
    // info
    const { id } = this.$route.query;
    if(id){
      this.loadInfo(id)
    }
  },
  methods: {
    /**
     * 加载支持的链
     * @returns {Promise<void>}
     */
    async loadSupport(){
      const res = await EventsApi.getSupport();
      if(res && res.data){
        this.support = res.data;
      }
    },
    async loadInfo(id){
      const res = await EventsApi.getInfo({ id, preview: 1 });
      if(res && res.data){
        this.info = res.data;
        // 更新预览
        await this.$func.sleep(1000);
        this.$eventBus.$emit('UPDATE_PREVIEW', this.info);
        // 其他
        if(this.info.tasks.other){
          const recordRes = await EventsApi.getRecord({ id, preview: 1 });
          if(!recordRes || !recordRes.data || !recordRes.data.lists){
            return;
          }
          this.records = recordRes.data.lists;
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";

.page-title {
  font-size: 18px;
  font-weight: bold;
  width: 1140px;
  margin: 0 auto 0 auto;
  padding-top: 20px;
}

.top-con {
  margin: 0 auto;
  width: 1140px;
  display: flex;
}
.statistic-con {
  width: 1140px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;

  .list-row {
    display: flex;
    font-size: 13px;
    padding: 5px 10px;
    &:nth-child(2n){
      background-color: #F6F6F6;
    }
    &.thead {
      color: #aaa;
    }
    .uid {
      width: 100px;
    }
    .nickname {
      width: 150px;
    }
    .text {
      flex: 1;
    }
  }
}
.section-title {
  font-size: 14px;
  font-weight: bold;
}
.basic-info {
  flex: 1;
  margin-right: 150px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;

  .progress-row {
    margin-top: 15px;
    display: flex;
    label {
      font-size: 14px;
      padding-right: 15px;
    }
    .value {
      font-size: 13px;
      padding-left: 10px;
    }
    .progress-bg {
      background-color: #eee;
      height: 16px;
      border-radius: 3px;
      overflow: hidden;
    }
    .progress {
      background: linear-gradient(
          135deg,
          rgba(63, 156, 255, 0.8) 25%,
          rgba(63, 156, 255, 1) 25%,
          rgba(63, 156, 255, 1) 50%,
          rgba(63, 156, 255, 0.8) 50%,
          rgba(63, 156, 255, 0.8) 75%,
          rgba(63, 156, 255, 1) 75%,
          rgba(63, 156, 255, 1)
      );
      background-size: 20px 20px;
      border-radius: 3px;
      height: 16px;
      width: 100%;

      &.green {
        background: linear-gradient(
            135deg,
            rgba(1, 210, 67, 0.8) 25%,
            rgba(1, 210, 67, 1) 25%,
            rgba(1, 210, 67, 1) 50%,
            rgba(1, 210, 67, 0.8) 50%,
            rgba(1, 210, 67, 0.8) 75%,
            rgba(1, 210, 67, 1) 75%,
            rgba(1, 210, 67, 1)
        );
        background-size: 20px 20px;
        border-radius: 3px;
        height: 16px;
        width: 100%;
      }
      &.yellow {
        background: linear-gradient(
            135deg,
            rgba(240, 185, 11, 0.8) 25%,
            rgba(240, 185, 11, 1) 25%,
            rgba(240, 185, 11, 1) 50%,
            rgba(240, 185, 11, 0.8) 50%,
            rgba(240, 185, 11, 0.8) 75%,
            rgba(240, 185, 11, 1) 75%,
            rgba(240, 185, 11, 1)
        );
        background-size: 20px 20px;
        border-radius: 3px;
        height: 16px;
        width: 100%;
      }
    }
  }
  .row-data {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    margin-top: 10px;
    label {
      font-size: 14px;
      flex: 1;
    }
    .value {
      font-size: 13px;
    }
    a {
      text-decoration: none;
    }
  }
}
.preview-panel {
  width: 356px;
  height: 720px;
  background-image: url("../assets/imgs/pc/publish/11.png");
  background-size: 100% 100%;
  position: relative;
  .box-con {
    margin: 55px 15px 10px 15px;
    border-radius: 0 0 45px 45px;
    overflow: hidden;
  }
  .border {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
