<template>
  <div v-if="userInfo">
    <div class="page-title">{{$t('leftMenus.setting')}} - {{$t('leftMenus.basicInfo')}}</div>
    <div style="height: 30px;"></div>

    <div class="avatar-con">
      <div class="avatar-img">
        <img
            :src="userInfo.avatar || '../../assets/imgs/2.png'"
            class="avatar"
        />
      </div>
      <div class="nickname">{{userInfo.twitter_name}}</div>
    </div>

    <div class="user-info">
      <div class="title">{{$t('basicInfo.title1')}}</div>
      <div class="row-data">
        <div class="label">{{$t('basicInfo.nickname')}}</div>
        <div class="value">
          <input
              v-model="form.nickname"
              ref="nickname"
              :readonly="formStatus.nickname"
              :placeholder="$t('basicInfo.noSet')"
              @dblclick="setEditable('nickname')"
              @blur="saveField('nickname')"
          />
        </div>
      </div>
      <div class="row-data">
        <div class="label">{{$t('basicInfo.site')}}</div>
        <div class="value">
          <input
              v-model="form.site"
              ref="site"
              :readonly="formStatus.site"
              :placeholder="$t('basicInfo.noSet')"
              @dblclick="setEditable('site')"
              @blur="saveField('site')"
          />
        </div>
      </div>
      <div class="row-data">
        <div class="label">{{$t('basicInfo.brief')}}</div>
        <div class="value">
          <input
              v-model="form.brief"
              ref="brief"
              :readonly="formStatus.brief"
              :placeholder="$t('basicInfo.noSet')"
              @dblclick="setEditable('brief')"
              @blur="saveField('brief')"
          />
        </div>
      </div>
      <div style="height: 30px;"></div>
      <div class="title">{{$t('basicInfo.title2')}}</div>
      <div class="row-data">
        <img src="../../assets/imgs/pc/12.png" class="icon"/>
        <div class="label">Twitter</div>
        <div class="value">
          <input
              v-model="form.twitter"
              ref="twitter"
              :readonly="formStatus.twitter"
              :placeholder="$t('basicInfo.noSet')"
              @dblclick="setEditable('twitter')"
              @blur="saveField('twitter')"
          />
        </div>
      </div>
      <div class="row-data">
        <img src="../../assets/imgs/pc/11.png" class="icon"/>
        <div class="label">Telegram</div>
        <div class="value">
          <input
              v-model="form.telegram"
              ref="telegram"
              :readonly="formStatus.telegram"
              :placeholder="$t('basicInfo.noSet')"
              @dblclick="setEditable('telegram')"
              @blur="saveField('telegram')"
          />
        </div>
      </div>
      <div class="row-data">
        <img src="../../assets/imgs/pc/18.png" class="icon"/>
        <div class="label">Discord</div>
        <div class="value">
          <input
              v-model="form.discord"
              ref="discord"
              :readonly="formStatus.discord"
              :placeholder="$t('basicInfo.noSet')"
              @dblclick="setEditable('discord')"
              @blur="saveField('discord')"
          />
        </div>
      </div>
      <div class="remark">
        <b class="text-red">*</b>{{$t('basicInfo.infoTips')}}
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import * as AccountApi from "@/api/account";
import * as TwitterApi from "@/api/twitter";
import {getBasicInfo, updateBasicInfo} from "@/api/account";

export default {
  data() {
    return {
      userInfo: false,
      formStatus: {
        nickname: true,
        site: true,
        brief: true,
        twitter: true,
        telegram: true,
        discord: true
      },
      form: {
        nickname: null,
        site: null,
        brief: null,
        twitter: null,
        telegram: null,
        discord: null
      }
    }
  },
  mounted() {
    this.$eventBus.$on('LOGIN-SUCCESS', userInfo => {
      this.userInfo = userInfo;
      this.form.nickname = userInfo.nickname;
    });
    this.$eventBus.$on('ACCOUNT_INFO', res => {
      this.userInfo = res.userInfo;
      this.form.nickname = res.userInfo?.nickname;
    });

    // 请求加载初始化数据
    this.$eventBus.$emit('LOAD_INIT_DATA');

    this.loadBasicInfo();
  },
  methods: {
    /**
     * 加载展示信息
     * @returns {Promise<void>}
     */
    async loadBasicInfo(){
      const res = await AccountApi.getBasicInfo();
      if(res && res.data){
        this.form = Object.assign(this.form, res.data);
      }
    },
    setEditable(key){
      this.formStatus[key] = false;
      this.$refs[key].focus();
    },
    saveField(key){
      this.formStatus[key] = true;
      if(this.form[key]){
        AccountApi.updateBasicInfo(this.form).then(res => {
          if(res && res.data === 'success' && this.form.nickname){
            this.userInfo.nickname = this.form.nickname;
            localStorage.setItem('USERINFO', JSON.stringify(this.userInfo));
          }
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vars.less";
@import "../../assets/css/dark.less";

.avatar-con {
  margin: 30px auto 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .avatar-img {
    position: relative;
    width: 60px;
    height: 60px;
    .setting-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-color: @light-color;
      width: 20px;
      height: 20px;
      color: #fff;
      border-radius: 10px;
      cursor: pointer;
      box-shadow: 0 0 5px #000;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }
  .nickname {
    font-size: 14px;
    color: @font-color;
    font-weight: bold;
    margin-top: 10px;
  }
}

.user-info {
  margin: 10px auto;
  width: 350px;

  .title {
    font-size: 14px;
    color: #000;
  }
  .row-data {
    border-bottom: 2px solid @border-color;
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 10px 0;
    margin-top: 15px;

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .label {
      width: 70px;
      color: #aaa;
    }
    .value {
      flex: 1;
      input {
        outline: none;
        width: 100%;
        border: none;
      }
    }
    .right-btn{
      color: @light-color;
      margin-left: 5px;
      cursor: pointer;
      a {
        color: @light-color;
        text-decoration: none;
      }
      img {
        height: 16px;
      }
    }
  }
  .remark {
    color: @sub-font-color;
    font-size: 12px;
    margin-top: 10px;
  }
}
</style>