import { fetchData } from "./common.js";
import { eventBus } from './eventBus.js';
import Crypto from 'crypto-js';

let baseURL = 'https://eapi.parapack.net/';
const headers = {
    'Content-Type': 'application/json',
    'wallet-language': localStorage.getItem('CURRENT_LANGUAGE')
}
!headers['wallet-language'] && (headers['wallet-language'] = 'en_US');

function getAuthorization(){
    return new Promise((resolve, reject) => {
        if(typeof(chrome) !== 'undefined' && chrome.storage && chrome.storage.local){
            chrome.storage.local.get(['USER'], res => {
                console.log(res)
                let userInfo = null;
                if(res && res.USER){
                    userInfo = JSON.parse(res.USER);
                }
                if(userInfo && userInfo.token){
                    resolve(userInfo.token);
                } else {
                    resolve('');
                }
            });
        } else {
            let userInfo = localStorage.getItem('USERINFO');
            userInfo = userInfo ? JSON.parse(userInfo) : {};
            if(userInfo && userInfo.token){
                resolve(userInfo.token);
            } else {
                resolve('');
            }
        }
    });
}

export default {
    get: async (options) => {
        const authorization = await getAuthorization();
        return new Promise((resolve, reject) => {
            const queryString = options.params ? '?' + (new URLSearchParams(options.params).toString()) : '';
            if(options.url && options.url.indexOf('http') === -1){
                options.url = baseURL + options.url + queryString;
            } else {
                options.url = options.url + queryString;
            }
            options.params = {
                headers: Object.assign(headers, options.headers, { authorization })
            };
            options.params.method = 'GET';
            if(options.target && options.target === 'Events') {
                const action = options.action || 'request';
                delete options.action;
                const key = Crypto.MD5(JSON.stringify(options)).toString();
                const timer = setTimeout(() => {
                    eventBus.$off('RES_' + key);
                    reject({ code: 0, msg: 'Request Timeout! -> ' + options.url });
                }, 30*1000);
                eventBus.$once('RES_' + key, res => {
                    clearTimeout(timer);
                    resolve(res);
                });
                window.postMessage({ action, target: 'Events', key, options }, window.location.origin);
            } else {
                fetchData(options, null, null, resolve, reject, options.proxyIP)
            }
        });
    },
    post: async (options) => {
        const authorization = await getAuthorization();
        return new Promise((resolve, reject) => {
            const data = options.params;
            const body = typeof data === 'string' || data instanceof String ? data : JSON.stringify(data);
            if(options.url && options.url.indexOf('http') === -1){
                options.url = baseURL + options.url;
            }
            options.params = {
                headers: Object.assign(headers, options.headers, { authorization })
            };
            options.params.method = options.method || 'POST';
            options.params.body = body;
            if(options.target && options.target === 'Events'){
                const action = options.action || 'request';
                delete options.action;
                const key = Crypto.MD5(JSON.stringify(options)).toString();
                const timer = setTimeout(() => {
                    eventBus.$off('RES_' + key);
                    reject({ code: 0, msg: 'Request Timeout! -> ' + options.url });
                }, 30*1000);
                eventBus.$once('RES_' + key, res => {
                    clearTimeout(timer);
                    resolve(res);
                });
                window.postMessage({ action, target: 'Events', key, options }, window.location.origin);
            } else {
                fetchData(options, null, null, resolve, reject, options.proxyIP)
            }
        });
    }
}