<template>
  <div class="container top-bg down-page">
    <pc-header></pc-header>

    <div class="main-content con-w">
      <div class="left-con">
        <div class="main-title">ParaPack</div>
        <div class="main-title" v-html="$t('web.down.title')"></div>
        <div class="brief" v-html="$t('web.down.subTitle')"></div>
        <div style="height: 50px;"></div>
        <div class="btns">
          <a href="https://app.s.gamego.vip/mmpnd?p=P2j9df3Ei09MyE2P" class="btn">
            <img src="../assets/imgs/down/icon-4.png"/>
            <div class="btn-brief">
              <div class="sub-name">Available on the</div>
              <div class="name">App Store</div>
            </div>
          </a>
          <a href="https://parapack-android.s3.ap-southeast-1.amazonaws.com/ParaPack.apk" class="btn">
            <img src="../assets/imgs/down/icon-5.png"/>
            <div class="btn-brief">
              <div class="sub-name">Download APK</div>
              <div class="name">Android</div>
            </div>
          </a>
        </div>
        <div class="btns" v-if="false">
          <div class="btn">
            <img src="../assets/imgs/down/icon-6.png"/>
            <div class="btn-brief">
              <div class="sub-name">Get it on</div>
              <div class="name">Google Play</div>
            </div>
          </div>
          <div class="btn">
            <img src="../assets/imgs/down/icon-7.png"/>
            <div class="btn-brief">
              <div class="sub-name">Get it on</div>
              <div class="name">Chrome</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-con">
        <img src="../assets/imgs/down/d-3.png" style="width:650px;"/>
      </div>
    </div>

    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "@/components/Header";
import PcFooter from "@/components/Footer";

export default {
  components: {
    PcHeader,
    PcFooter
  },
  data(){
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/vars.less";
@import "../assets/css/dark.less";
@import "../assets/css/pc.less";

.top-bg {
  background-image: url("../assets/imgs/down/d-bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.main-content {
  margin: 50px auto;
  display: flex;
  padding-bottom: 100px;

  .left-con {
    flex: 1;

    .main-title {
      font-size: 40px;
    }
    .brief {
      font-size: 16px;
      color: #aaa;
      margin-top: 20px;
    }
    .btns {
      display: flex;
      .btn {
        width: 180px;
        height: 60px;
        background: #000;
        border-radius: 15px;
        margin: 30px 100px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        img {
          height: 36px;
          margin-right: 10px;
          margin-left: 20px;
        }
        .btn-brief {
          color: #fff;
          flex: 1;
          .sub-name {
            font-size: 14px;
            opacity: .8;
          }
          .name {
            font-size: 16px;
            margin-top: 3px;
          }
        }
      }
    }
  }
}
</style>