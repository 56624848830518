import Vue from 'vue'
import App from './App.vue'
import router from '../router';

import dataV from '@jiaminghi/data-view'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "@/assets/css/reset.less";
import "@/assets/css/public.less";

Vue.use(dataV)
Vue.use(ElementUI);

Vue.config.productionTip = false;

import { eventBus } from "@rainbow654/reloader";
Vue.prototype.$eventBus = eventBus;

import * as func from "@/utils/func";
Vue.prototype.$func = func;

import * as common from "@/utils/common";
Vue.prototype.$com = common;

import globalData from "@/globalData";
Vue.prototype.$globalData = globalData;

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import elLocale from 'element-ui/lib/locale/lang/el'; // 繁体
import msLocale from 'element-ui/lib/locale/lang/ms';// 马来
const currentLanguage = localStorage.getItem('CURRENT_LANGUAGE');
if(!currentLanguage){
    localStorage.setItem('CURRENT_LANGUAGE', 'en_US');
}
const i18n = new VueI18n({
    locale: currentLanguage ? currentLanguage : 'en_US',
    fallbackLocale: 'en_US',
    messages: {
        'zh_CN': Object.assign(require('@/language/zh_CN'), zhLocale),
        'en_US': Object.assign(require('@/language/en_US'), enLocale),
        'el_GR': Object.assign(require('@/language/el_GR'), elLocale),
        'ms_MY': Object.assign(require('@/language/ms_MY'), msLocale),
    }
});

const app = new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app');

import message from "@/utils/message";
message(app);